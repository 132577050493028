import axios from "axios"
import { useMemo } from "react"
import { useUser } from "../providers/AuthProvider"
const partner_url = process.env.REACT_APP_PARTNER_URL!

export const usePartnersService = () => {
  const { token } = useUser()

  return useMemo(() => makePartnersService(token), [token])
}

export const makePartnersService = (token: string) => {
  const axiosInstance = axios.create({
    baseURL: partner_url,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  })

  return {
    getOrg: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .get(`/org/${data.id}`, {
          signal,
        })
        .then((res) => {
          if (res.data) {
            return Promise.resolve(res.data)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getOrgRoles: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .get(`/org-roles/${data.partnerId}`, {
          signal,
        })
        .then((res) => {
          if (res.data) {
            return Promise.resolve(res.data)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    updateUserOrgRole: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .put(
          "/user-org-role",
          {
            user_id: data.userId,
            org_role_id: data.oldOrgRoleId,
            new_role: data.newOrgRoleId,
          },
          {
            signal,
          }
        )
        .then((res) => {
          if (res) {
            return Promise.resolve(res)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getProfiles: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .get(`/profile/${data.userId}`, {
          signal,
        })
        .then((res) => {
          if (res) {
            return Promise.resolve(res)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getUsers: async (
      offset?: number,
      limit?: number,
      roles?: string[],
      search?: string,
      orderBy?: string,
      orderDirection?: string,
      signal?: AbortSignal
    ) => {
      return await axiosInstance.get("/users", {
        params: {
          limit,
          offset,
          // roles,
          search,
          orderBy,
          orderDirection,
        },
        signal,
      })
    },
    getOrgUsers: async (
      offset?: number,
      limit?: number,
      roles?: string[],
      search?: string,
      orderBy?: string,
      orderDirection?: string,
      orgId?: string,
      signal?: AbortSignal
    ) => {
      return await axiosInstance.get(`/org/${orgId}/users`, {
        params: {
          limit,
          offset,
          roles,
          orgId,
          search,
          orderBy,
          orderDirection,
        },
        signal,
      })
    },
    deleteUser: async (userId: string, signal?: AbortSignal) => {
      return await axiosInstance.delete(`/users/${userId}`, {
        signal,
      })
    },
    deleteUserAssociations: async (data: any, signal?: AbortSignal) => {
      return await axiosInstance.delete(`/associations`, {
        data: {
          organization_id: data.organizationId,
          user_id: data.userId,
        },
        signal,
      })
    },
    updateUserRole: async (data: any, signal?: AbortSignal) => {
      return await axiosInstance.put(
        `/org/${data.orgId}/users/${data.userId}/org-roles`,
        data.newRoles,
        { signal }
      )
    },
    updateUserProfile: async (data: any, signal?: AbortSignal) => await axiosInstance.put(`/profile`, data, { signal }),
    updateUser: async (data: any, signal?: AbortSignal) => {
      return await axiosInstance
        .put(
          `/users/${data.userId}`,
          {
            email: data.email || undefined,
            phoneNumber: data.phoneNumber || undefined,
          },
          {
            signal,
          }
        )
        .catch((response) => {
          return response?.response
        })
    },
    updateAssociation: async (data: any, signal?: AbortSignal) => {
      try {
        return await axiosInstance.put("association", data, { signal })
      } catch (err) {
        console.log(err)
        return null
      }
    },
  }
}
