import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell, tableCellClasses } from "@mui/material"
import { Paper, styled } from "@material-ui/core"

import "../../validations.css"
import { SortField, Validation, ValidationsItem } from "../../../interfaces"
import { Loader } from "../../../../common/Loader"

type Props = {
  sortHandler: (type: SortField) => void
  onRowClick: (v: Validation) => void
  getApprovedValidationsCount: (v: ValidationsItem[]) => number
  getPendingValidationsCount: (v: ValidationsItem[]) => number
  sort:
    | {
        by: SortField
        order: "desc" | "asc" | "DESC" | "ASC"
      }
    | undefined
  data: Validation[]
  loading: boolean
}

const SnowValidationTable = ({ sortHandler, onRowClick, getApprovedValidationsCount, getPendingValidationsCount, sort, data, loading }: Props) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f9f9f9",
      color: "#232F64",
      fontSize: "20px",
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
      width: "min-content",
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }))

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell onClick={() => sortHandler("site")}>
              <div className="clickable flex w-min flex-row items-center">
                <span>Site</span>
                <div className="flex flex-col pl-4">
                  <span className={`${sort?.by === "site" && sort?.order === "ASC" ? "text-case-grad-from" : "text-gray-bid-dates"}`}>&#9206;</span>
                  <span className={`${sort?.by === "site" && sort?.order === "DESC" ? "text-case-grad-from" : "text-gray-bid-dates"}`}>&#9207;</span>
                </div>
              </div>
            </StyledTableCell>
            <StyledTableCell onClick={() => sortHandler("stormId")}>
              <div className="clickable flex flex-row items-center">
                <span>Storm ID</span>
                <div className="flex flex-col pl-4">
                  <span className={`${sort?.by === "stormId" && sort?.order === "ASC" ? "text-case-grad-from" : "text-gray-bid-dates"}`}>
                    &#9206;
                  </span>
                  <span className={`${sort?.by === "stormId" && sort?.order === "DESC" ? "text-case-grad-from" : "text-gray-bid-dates"}`}>
                    &#9207;
                  </span>
                </div>
              </div>
            </StyledTableCell>
            <StyledTableCell onClick={() => sortHandler("storm.total_accumulation")}>
              <div className="clickable flex flex-row items-center">
                <span>Accumulation</span>
                <div className="flex flex-col pl-4">
                  <span
                    className={`${sort?.by === "storm.total_accumulation" && sort?.order === "ASC" ? "text-case-grad-from" : "text-gray-bid-dates"}`}
                  >
                    &#9206;
                  </span>
                  <span
                    className={`${sort?.by === "storm.total_accumulation" && sort?.order === "DESC" ? "text-case-grad-from" : "text-gray-bid-dates"}`}
                  >
                    &#9207;
                  </span>
                </div>
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className="flex flex-row items-center">
                <span>Completed WOs</span>
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className="flex flex-row items-center">
                <span>Pending WOs</span>
              </div>
            </StyledTableCell>
            <StyledTableCell onClick={() => sortHandler("storm.start")}>
              <div className="clickable flex flex-row items-center whitespace-nowrap">
                <span>Start Date</span>
                <div className="flex flex-col pl-4">
                  <span className={`${sort?.by === "storm.start" && sort?.order === "ASC" ? "text-case-grad-from" : "text-gray-bid-dates"}`}>
                    &#9206;
                  </span>
                  <span className={`${sort?.by === "storm.start" && sort?.order === "DESC" ? "text-case-grad-from" : "text-gray-bid-dates"}`}>
                    &#9207;
                  </span>
                </div>
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className="flex flex-row items-center">
                <span>Underage / Overage</span>
              </div>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        {loading ? (
          <StyledTableCell colSpan={7}>
            <Loader />
          </StyledTableCell>
        ) : !!data?.length ? (
          <TableBody>
            {data?.map((row, index) => (
              <StyledTableRow
                key={index}
                className={`rowItem clickable ${
                  row.status === "ReviewCompleted" || row.status === "Completed" || row.status === "ReviewComplete"
                    ? "completedValidationRow"
                    : "nonCompleted"
                }`}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
                // onClick={() => navigate(`${row.id}`)}
                onClick={() => onRowClick(row)}
              >
                <StyledTableCell>{row.siteName}</StyledTableCell>
                <StyledTableCell>{row.stormId}</StyledTableCell>
                <StyledTableCell>{row.storm.total_accumulation}"</StyledTableCell>
                <StyledTableCell>{getApprovedValidationsCount(row.validations)}</StyledTableCell>
                <StyledTableCell>{getPendingValidationsCount(row.validations)}</StyledTableCell>
                <StyledTableCell>{new Date(row.storm.start).toLocaleDateString()}</StyledTableCell>
                <StyledTableCell>{row.underover}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            <StyledTableRow>
              <StyledTableCell align="center" colSpan={7}>
                EMPTY
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}

export default SnowValidationTable
