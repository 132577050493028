import { memo, useContext, useEffect, useState } from "react"

import { Checkbox, InputAdornment } from "@material-ui/core"
import { useDebounce } from "use-debounce"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete/Autocomplete"
import TextField from "@mui/material/TextField/TextField"
import PersonIcon from "@mui/icons-material/Person"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import EngineeringIcon from "@mui/icons-material/Engineering"
import RefreshIcon from "@mui/icons-material/Refresh"

import { ValidationContext } from "../../../../../providers/ValidationProvider"
import { useGraphQLService } from "../../../../../services/graphqlService"

const LandFilteringComponent = () => {
  const [inputLoading, setInputLoading] = useState(false)
  const [siteInputText, setSiteInputText] = useState("")
  const { validationState, setValidation } = useContext(ValidationContext)
  const [siteList, setSiteList] = useState(validationState?.siteList)
  const [value] = useDebounce(siteInputText, 1000)
  const { getAccounts } = useGraphQLService()

  useEffect(() => {
    const getSites = async () => {
      setInputLoading(true)
      const response = await getAccounts("site", value)

      if (!response) return

      const result = response.accounts.map((s) => ({ id: s.sfid, label: s.name })).sort((a, b) => (a.label > b.label ? 1 : -1))

      setSiteList(result)
      setInputLoading(false)
    }

    getSites()
  }, [value])

  return (
    <div className="mt-2 flex w-full select-none flex-row items-center justify-between">
      <div className="filterRowContainer w-full">
        <div className="filterRowOptions">
          <p className="whitespace-nowrap">Search for:</p>
          <Autocomplete
            disablePortal
            size="medium"
            sx={{ maxWidth: 500, width: 250 }}
            noOptionsText="All"
            value={validationState.currentFilter?.customer}
            options={(validationState.customerList ?? []).map((option) => option.label)}
            filterOptions={createFilterOptions({ limit: 100, matchFrom: "any" })}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  shrink: true,
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                label="Customer"
              />
            )}
            onChange={(e, value, r, d) =>
              setValidation({
                ...validationState,
                currentFilter: {
                  ...validationState.currentFilter,
                  customer: value ?? "",
                },
                selectedService: undefined,
                validations: [],
              })
            }
          />

          <Autocomplete
            freeSolo
            size="medium"
            sx={{ maxWidth: 500, minWidth: 400 }}
            loading={inputLoading}
            loadingText="Loading..."
            value={validationState?.currentFilter?.siteId}
            options={(siteList ?? []).map((option) => option.label)}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  shrink: true,
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={async (e) => setSiteInputText(e.target.value)}
                label="Site"
              />
            )}
            onChange={(e, value, r, d) => {
              setValidation({
                ...validationState,
                currentFilter: {
                  ...validationState.currentFilter,
                  siteId: value ?? "",
                },
                selectedService: undefined,
                validations: [],
              })
            }}
          />

          <Autocomplete
            disablePortal
            disableListWrap
            size="medium"
            sx={{ maxWidth: 500, width: 300 }}
            value={validationState?.currentFilter?.servicePartner}
            noOptionsText="All"
            options={(validationState.servicePartnerList ?? []).map((option) => option.label)}
            filterOptions={createFilterOptions({ limit: 100, matchFrom: "any" })}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  shrink: true,
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <EngineeringIcon />
                    </InputAdornment>
                  ),
                }}
                label="Service Partner"
              />
            )}
            onChange={(e, value, r, d) =>
              setValidation({
                ...validationState,
                currentFilter: {
                  ...validationState.currentFilter,
                  servicePartner: value ?? "",
                },
                selectedService: undefined,
                validations: [],
              })
            }
          />

          <div className="flex flex-row items-center">
            <Checkbox
              size="medium"
              checked={validationState?.currentFilter?.showOnlyRequiresReview}
              onChange={(e, checked) =>
                setValidation({
                  ...validationState,
                  currentFilter: {
                    ...validationState?.currentFilter,
                    showOnlyRequiresReview: checked,
                  },
                  selectedService: undefined,
                  validations: [],
                })
              }
              style={{
                color: "#1b3a8c",
              }}
            />
            <p>WOs requiring review</p>
          </div>
        </div>

        <button
          onClick={() =>
            setValidation({
              ...validationState,
              selectedService: undefined,
              currentFilter: {
                ...validationState.currentFilter,
                acc: undefined,
              },
              validations: [],
            })
          }
          className="m-4 self-end rounded-full p-2 shadow-lg transition duration-200 ease-in-out hover:bg-case-grad-from hover:text-white"
        >
          <RefreshIcon />
        </button>
      </div>
    </div>
  )
}

export default memo(LandFilteringComponent)
