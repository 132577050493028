import {
  createStyles,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core"

import { Dispatch, useEffect, useMemo, useState } from "react"
import { useUser } from "../../providers/AuthProvider"
import { useGraphQLService } from "../../services/graphqlService"

export type OrganizationRoleSelectProps = {
  organization?: any
  onChange: Dispatch<any | undefined>
}

export const OrganizationRoleSelect = ({
  organization,
  onChange,
}: OrganizationRoleSelectProps) => {
  const classes = useStyles()
  const { token } = useUser()
  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      Authorization: token,
    }),
    [token]
  )

  const [organizationRoles, setOrganizationRoles] = useState<any[]>([])
  const [selectedOrganizationRoleId, setSelectedOrganizationRoleId] =
    useState("")
  const selectedOrganizationRole = useMemo(
    () =>
      organizationRoles.find((_) => _.role_id === selectedOrganizationRoleId),
    [organizationRoles, selectedOrganizationRoleId]
  )

  const { getOrgRolesByOrgId } = useGraphQLService()

  useEffect(() => {
    getOrgRolesByOrgId(organization?.sfid || organization?.sf_account_id).then((data) =>
      setOrganizationRoles(data)
    )
  }, [headers, organization?.sfid, getOrgRolesByOrgId])

  useEffect(() => {
    onChange(selectedOrganizationRole)
  }, [onChange, selectedOrganizationRole])

  const handleChangeOrganizationRole = (e: any) => {
    setSelectedOrganizationRoleId(e.target.value)
  }

  return (
    <FormControl fullWidth>
      <Select
        id="demo-simple-select"
        variant={"outlined"}
        value={selectedOrganizationRoleId}
        placeholder="Role"
        onChange={handleChangeOrganizationRole}
        style={{
          backgroundColor: "#FFF",
          border: "none",
          borderRadius: "5px",
        }}
        className={classes.input}
      >
        {organizationRoles?.map((element: any) => (
          <MenuItem key={element.role_id} value={element.role_id}>
            {element.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      margin: "5px",
      width: "260px",
      "& .MuiInputBase-input": {
        padding: "5px",
      },
    },
  })
)
