import { useContext } from "react"

import { ValidationContext } from "../../../../../providers/ValidationProvider"
import LandChartStatusIconComponent, { LandStatusColor } from "../../ValidationResultsPage/components/LandChartStatusIconComponent"

// type Props = {
//   onClickNarrative: () => void
//   siteName?: string
//   userFullName?: string
//   isCompleted?: boolean
//   narrative?: string
// }

const LandDetailsComponent = () => {
  const { validationState } = useContext(ValidationContext)
  const isCompleted =
    validationState?.selectedValidation?.status === "ReviewCompleted" ||
    validationState?.selectedValidation?.status === "Completed" ||
    validationState?.selectedValidation?.status === "ReviewComplete"

  return (
    <div className="flex select-none flex-row items-center justify-between gap-8 p-4">
      <div className="flex flex-1 flex-col gap-3">
        <label className="text-4xl font-bold text-[#232f64]">{validationState?.selectedSites?.[0]?.label}</label>
        <div className="amInfoContainer">
          <label className="text-sm text-[#686868]">
            <b>Account Manager</b>
            <br />
            {`${validationState?.user?.firstname} ${validationState?.user?.lastname}`}
          </label>
          {isCompleted ? (
            <div className="rounded-md border-2 border-solid border-[#06307b] pb-4 pl-2 pr-8 pt-4">
              <label className="text-lg font-bold text-[#06307b]">Completed</label>
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex flex-1 flex-col gap-3">
        <div className="flex flex-col items-start justify-between gap-4">
          {/* <div className="flex w-1/2 flex-row items-center justify-between gap-6">
            <label className="text-lg">Procured Vendor</label>
            <label className="text-right font-bold">{"Ladiz Washroom"}</label>
          </div>
          <div className="flex w-1/2 flex-row items-center justify-between gap-6">
            <label className="text-lg">Contract Status</label>
            <label className="text-right font-bold">{"Pending"}</label>
          </div> */}

          {/* <div className="detailItem">
            <label>Storm Start</label>
            <label>{stormStart}</label>
          </div>
          <div className="detailItem">
            <label>Storm End</label>
            <label>{stormEnd}</label>
          </div> */}
        </div>
        {/* <div className="columnDetail">
          <div className="detailItem">
            <label>Site info 1</label>
            <label>{"detail"}</label>
          </div>
          <div className="detailItem">
            <label>Site info 2</label>
            <label>{"detail"}</label>
          </div>

          <div
            className="detailItem"
            onClick={() => (narrative ? onClickNarrative() : null)}
            style={{ width: "400px", overflow: "hidden", maxLines: 2 }}
          >
            <label>Site info 3</label>
            <label
              className="narrative"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxLines: 2,
              }}
            >
              {"detail"}
            </label>
          </div>

          <div className="detailItem">
            <label>Site info 4</label>
            <label>{"detail"}</label>
          </div>
        </div> */}
      </div>

      <div className="float-right flex flex-row items-center">
        <div className="mx-4 mt-4 flex flex-row rounded-lg border-2 px-4 py-2">
          <div className=" w-min whitespace-nowrap">
            <p className="font-bold underline">Action Required</p>
            <div className="flex flex-row gap-4">
              <div>
                <div className="mb-2 flex flex-row items-center gap-1">
                  <LandChartStatusIconComponent statusColor={LandStatusColor.Validation} />
                  <label>Validation</label>
                </div>
                <div className="mb-2 flex flex-row items-center gap-1">
                  <LandChartStatusIconComponent statusColor={LandStatusColor.Missing} />
                  <label>Missing</label>
                </div>
              </div>
              <div>
                <div className="mb-2 flex flex-row items-center gap-1">
                  <LandChartStatusIconComponent statusColor={LandStatusColor.Approved} />
                  <label>Approved</label>
                </div>
                <div className="mb-2 flex flex-row items-center gap-1">
                  <LandChartStatusIconComponent statusColor={LandStatusColor.ApprovedRed} />
                  <label>Cancelled</label>
                </div>
              </div>
            </div>
            <div className="mb-2 flex flex-row items-center gap-1">
              <LandChartStatusIconComponent statusColor={LandStatusColor.ReminderBang} />
              <p>Reminder Exceeded</p>
            </div>
          </div>
          <div className="mr-10">
            <p className="font-bold">&nbsp;</p>
          </div>
          <div className="mr-10">
            <p className="font-bold underline">Reviewed</p>
            <div className="mb-2 flex flex-row items-center gap-1">
              <LandChartStatusIconComponent statusColor={LandStatusColor.GreenStar} />
              <label>Approved</label>
            </div>
            <div className="mb-2 flex flex-row items-center gap-1">
              <LandChartStatusIconComponent statusColor={LandStatusColor.RedStar} />
              <label>Cancelled</label>
            </div>
            <div className="mb-2 flex flex-row items-center gap-1">
              <LandChartStatusIconComponent statusColor={LandStatusColor.RedOutlinedStar} />
              <label>Missing</label>
            </div>
          </div>
          <div>
            <p className="font-bold underline">Informational</p>
            <p className="text-center font-bold"># Actual / # Expected</p>

            <div className="my-2 mb-2 flex flex-row items-center gap-1">
              <LandChartStatusIconComponent statusColor={LandStatusColor.Reminder} />
              <p>Reminder</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandDetailsComponent
