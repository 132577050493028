import React, { useEffect, useState } from "react"
import {
  makeStyles,
  createStyles,
  Grid,
  MenuItem,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core"
import { ReactComponent as SearchIcon } from "./../../../assets/search.svg"
import { Filter } from "../../common/Filter"
import useI18n from "../../../hooks/useI18n"

export const BidsFilters = (
  { setFilter, filter, defaultFilter, setLoadingFilter }: any,
  props: any
) => {
  const classes = useStyles()
  const { i18n } = useI18n();
  const [searchText, setSearchText] = useState("")
  const [typeSelected, setTypeSelected] = useState("All")
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(true)
  const [types] = useState<any>([
    { id: "All", value: i18n.t("bids.filterTypes.all") },
    { id: "Awarded", value: i18n.t("bids.filterTypes.awardedBids") },
    { id: "Not Awarded", value: i18n.t("bids.filterTypes.notAwardedBids")},
    { id: "Declined", value: i18n.t("bids.filterTypes.declinedBids")},
    { id: "Accepted", value: i18n.t("bids.filterTypes.acceptedBids")},
    { id: "No Response", value: i18n.t("bids.filterTypes.openBids")},
    { id: "Counter", value: i18n.t("bids.filterTypes.counterBids")},
    { id: "Accepted,Counter", value: i18n.t("bids.filterTypes.pendingBids")},
  ])

  useEffect(() => {
    if (defaultFilter) {
      setTypeSelected(defaultFilter)
      setFilter({ filter: defaultFilter })
      setLoadingFilter(false)
      setLoading(false)
    } else {
      setLoading(false)
      setLoadingFilter(false)
    }
  }, [defaultFilter])

  useEffect(() => {
    if (search !== null) {
      setFilter({ ...filter, search: search })
    } else {
      setFilter({ ...filter, search: undefined })
    }
  }, [search])

  const typeChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
    const f = event.target.value as string
    setFilter({ filter: f })
    setTypeSelected(f)
  }

  const searchChange = (event: any) => {
    setSearchText(event.target.value as string)
  }

  const searchByText = () => {
    setSearch(searchText)
  }

  return (
    <div className="w-full p-2.5">
      <div className="w-full p-2.5 rounded pl-4 h-fit">
        <Grid container className="flex-row items-center">
          <Grid item xs={4} className="flex flex-row items-center">
            <TextField
              size="small"
              id="input-with-icon-textfield"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className={classes.searchInput + " w-full"}
              placeholder={i18n.t("sites.searchBySiteName")}
              onChange={(e) => searchChange(e)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchByText()
                }
              }}
            />
            <Button
              variant="outlined"
              size="small"
              className={classes.buttonFilter}
              onClick={() => searchByText()}
            >
              {i18n.t("common.search")}
            </Button>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            {!loading && (
              <Filter
                id="filter"
                value={typeSelected}
                onChange={typeChanged}
                labelWidth={0}
                width={"500px"}
              >
                {types.map((filter: any, index: number) => {
                  return (
                    <MenuItem
                      key={`filter_${index}`}
                      value={filter.id}
                      selected={typeSelected === filter.id}
                    >
                      {filter.value}
                    </MenuItem>
                  )
                })}
              </Filter>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    searchInput: {
      backgroundColor: "white",
    },
    buttonFilter: {
      backgroundColor: "#f9f9f9",
      height: "40px",
      minWidth: "60px",
      fontSize: "10px",
    },
  })
)
