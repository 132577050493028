import { useCallback, useEffect, useState } from "react"
import { makeStyles, createStyles, Container, Grid, Typography, Button } from "@material-ui/core"
import Box from "@mui/material/Box"
import { ReactComponent as RightIcon } from "./../../../assets/right.svg"
import { useNavigate } from "react-router-dom"
import ProfileTab from "./tabs/ProfileTab"
import EquipmentTab from "./tabs/EquipmentTab"
import ServicesTab from "./tabs/ServicesTab"
import { useActiveUser } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"
import useI18n from "../../../hooks/useI18n"

function ProfileDesktop(props: any) {
  const navigate = useNavigate()
  const classes = useStyles()
  const { i18n } = useI18n()
  const [value, setValue] = useState(0)
  const { sfId: sfid } = useActiveUser()
  const [partner, setPartner] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const graphqlService = useGraphQLService()

  const getPartnerData = useCallback(
    (signal?: AbortSignal) => {
      setLoading(true)
      graphqlService
        .getPartnerBySfid({ sfid }, signal)
        .then((response) => {
          setLoading(false)
          setPartner(response)
        })
        .catch(() => {
          setLoading(false)
        })
    },
    [graphqlService, sfid]
  )

  useEffect(() => {
    const controller = new AbortController()

    getPartnerData(controller.signal)

    return () => controller.abort()
  }, [getPartnerData])

  return (
    <>
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <div className={classes.showTitle}>
                  <div className="mb-4">
                    <Typography noWrap className="flex flex-row items-center">
                      <span className="cursor-pointer font-bold text-breadcrumb-blue" onClick={() => navigate("/")}>
                        {i18n.t("breadcrumbs.home")}
                      </span>
                      <RightIcon className="ml-3 mr-3 w-3.5" />
                      <span className="font-bold">{i18n.t("breadcrumbs.profile")}</span>
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Grid container className="flex-row items-center">
                <Grid item xs={12}>
                  <div className={classes.secondaryTitle}>
                    <Typography noWrap className={classes.cardTitle}>
                      {partner?.name}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <div className="w-full">
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      borderColor: "divider",
                    }}
                  >
                    <div className="mb-3 h-8 w-full">
                      <div className="mt-2">
                        <div className="float-left h-6 w-1/3 text-center">
                          <Button
                            variant="text"
                            className={classes.buttonTab + " w-full " + (value === 0 ? classes.selectedTab : "")}
                            onClick={() => setValue(0)}
                          >
                            {i18n.t("breadcrumbs.profile")}
                          </Button>
                        </div>
                        <div className="float-left h-6 w-1/3 text-center">
                          <Button
                            variant="text"
                            className={classes.buttonTab + " w-full " + (value === 1 ? classes.selectedTab : "")}
                            onClick={() => setValue(1)}
                          >
                            {i18n.t("profile.equipment")}
                          </Button>
                        </div>
                        <div className="float-left h-6 w-1/3 text-center">
                          <Button
                            variant="text"
                            className={classes.buttonTab + " w-full " + (value === 2 ? classes.selectedTab : "")}
                            onClick={() => setValue(2)}
                          >
                            {i18n.t("profile.services")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Box>
                  {value === 0 && (
                    <div>
                      <ProfileTab partner={partner} />
                    </div>
                  )}
                  {value === 1 && (
                    <div>
                      <EquipmentTab partner={partner} getPartnerData={getPartnerData} loading={loading} />
                    </div>
                  )}
                  {value === 2 && (
                    <div>
                      <ServicesTab partner={partner} />
                    </div>
                  )}
                </Box>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    showTitle: {
      width: "auto",
      marginTop: "8px",
      marginLeft: "10px",
    },
    secondaryTitle: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "15px",
    },
    titleFont: {
      fontSize: "44px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "40px",
      marginTop: "21px",
    },
    cardTitle: {
      fontSize: "40px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "black",
      letterSpacing: "-1px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#FFFFFF",
    },
    container: {
      maxWidth: "100%",
    },
    buttonTab: {
      color: "#0F2150",
      fontWeight: "bold",
      backgroundColor: "white",
    },
    selectedTab: {
      borderBottom: "2px solid #0F2150",
    },
  })
)

export default ProfileDesktop
