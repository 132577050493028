import {
  Button,
  Container,
  Grid,
  InputAdornment,
  Paper,
  styled,
  TextField,
  Typography,
} from "@material-ui/core"
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  IconButton,
  Tooltip,
} from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { useActiveUser, useAuth } from "../../providers/AuthProvider"
import { usePartnersService } from "../../services/partnersService"
import { Loader } from "../common/Loader"
import Pagination from "../common/Pagination"
import { useAdminStyles } from "./styles"
import { useDebouncedCallback } from "use-debounce"
import { ReactComponent as SearchIcon } from "./../../assets/search.svg"
import { ReactComponent as UpIcon } from "./../../assets/up.svg"
import { ReactComponent as DownIcon } from "./../../assets/down.svg"
import ActionMenu from "../Team/desktop/ActionMenu"
import UpdateUserModal from "../Team/common/UpdateUserModal"
import UpdateRoleModal from "../Team/common/UpdateRoleModal"
import DeleteUserModal from "../Team/common/DeleteUserModal"
import InviteUserModal from "../Team/common/InviteUserModal"
import { formatDate, getFistLogin, getLastLogin } from "../../utils/Util"
import UpdateOrgModal from "../Team/common/UpdateOrgModal"

export const MasqueradeCard = () => {
  const classes = useAdminStyles()
  const { stopMasquerade, isMasquerading } = useAuth()
  const { userId } = useActiveUser()

  return (
    <Container className={classes.container}>
      <Grid container spacing={2}>
        {isMasquerading ? (
          <>
            <Grid item xs={12} sm={8} lg={12}>
              <Typography noWrap>Masquerading as {userId}</Typography>
            </Grid>
            <Grid item xs={12} sm={8} lg={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  stopMasquerade()
                  window.location.reload()
                }}
                className={classes.button}
              >
                END MASQUERADE
              </Button>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sm={12} lg={12}>
            <UserList />
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f9f9f9",
    color: "#232F64",
    fontSize: "20px",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}))

const UserList = () => {
  const classes = useAdminStyles()
  const { masqueradeAs } = useAuth()
  const [loading, setLoading] = useState(false)
  const [inFlight, setInFlight] = useState(false)
  const [data, setData] = useState<any[]>([])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [total, setTotal] = useState(0)
  const [searchInput, setSearchInput] = useState("")
  const [search, setSearch] = useState<string>()
  const partnersService = usePartnersService()
  const [sort, setSort] = useState({
    field: "email",
    order: "ASC",
  })

  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [openUpdateRoleModal, setOpenUpdateRoleModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openInviteModal, setOpenInviteModal] = useState(false)
  const [openEditOrgModal, setOpenEditOrgModal] = useState(false)

  const [selectedUser, setSelectedUser] = useState<any>({})

  const getUsers = useDebouncedCallback(
    async (
      page: number,
      perPage: number,
      search?: string,
      sort?: any,
      signal?: AbortSignal
    ) => {
      setLoading(true)
      const response = await partnersService.getUsers(
        (page - 1) * perPage,
        perPage,
        [
          "Service Partner Owner",
          "Service Partner Admin",
          "Service Technician",
          "Case Admin",
          "Case Super Admin",
        ],
        search,
        sort?.field,
        sort?.order,
        signal
      )
      setData(response?.data?.rows ?? [])
      setTotal(response?.data?.count ?? 0)
      setLoading(false)
    },
    500
  )

  useEffect(() => {
    const controller = new AbortController()
    getUsers(page, perPage, search, sort, controller.signal)
    return () => controller.abort()
  }, [getUsers, page, perPage, search, sort])

  const sortHandler = (by: any) => {
    if (by === sort.field) {
      if (sort.order === "ASC") {
        setSort({ field: sort.field, order: "DESC" })
      } else {
        setSort({ field: sort.field, order: "ASC" })
      }
    } else {
      setSort({ field: by, order: sort.order })
    }
  }

  const handleSearch = useCallback(() => {
    setSearch(searchInput || undefined)
    setPage(1)
  }, [searchInput])

  const handleMasqueradeClick = useCallback(
    async (id: string) => {
      if (!inFlight) {
        setInFlight(true)
        await masqueradeAs(id)
        setInFlight(false)
        window.location.reload()
      }
    },
    [inFlight, masqueradeAs]
  )

  const openEdit = (user: any) => {
    setSelectedUser(user)
    setOpenUpdateModal(true)
  }

  const closeUpdateModal = () => {
    setOpenUpdateModal(false)
  }

  const openEditRole = (user: any) => {
    setSelectedUser(user)
    setOpenUpdateRoleModal(true)
  }

  const closeUpdateRoleModal = () => {
    setOpenUpdateRoleModal(false)
  }

  const openDelete = (user: any) => {
    setSelectedUser(user)
    setOpenDeleteModal(true)
  }

  const closeDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const openEditOrg = (user: any) => {
    setSelectedUser(user)
    setOpenEditOrgModal(true)
  }

  const closeEditOrgModal = () => {
    setOpenEditOrgModal(false)
  }

  const closeInviteModal = () => {
    setOpenInviteModal(false)
  }

  const reload = () => {
    setPage(1)
    const controller = new AbortController()
    getUsers(1, perPage, search, controller.signal)
    return () => controller.abort()
  }

  const checkTechnicianRole = (orgRoles: any[]) => {
    return orgRoles.every((_) => _.name === "Service Technician")
  }

  return (
    <div>
      <InviteUserModal
        user={null}
        openModal={openInviteModal}
        closeModal={closeInviteModal}
        reload={reload}
      />
      <UpdateUserModal
        user={selectedUser}
        openModal={openUpdateModal}
        closeModal={closeUpdateModal}
        reload={reload}
      />
      <UpdateRoleModal
        user={selectedUser}
        openModal={openUpdateRoleModal}
        closeModal={closeUpdateRoleModal}
        reload={reload}
        orgId={
          selectedUser?.organization_roles?.find((r: any) => r?.organization)
            ?.organization?.id
        }
      />
      <UpdateOrgModal
        user={selectedUser}
        openModal={openEditOrgModal}
        closeModal={closeEditOrgModal}
        reload={reload}
      />
      <DeleteUserModal
        user={selectedUser}
        openModal={openDeleteModal}
        closeModal={closeDeleteModal}
        reload={reload}
        isAdmin={true}
      />
      <Grid container className="flex-row items-center pb-2.5">
        <Grid item xs={4} className="flex flex-row items-center">
          <TextField
            size="small"
            id="input-with-icon-textfield"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            placeholder={"Search by email/phone number"}
            value={searchInput}
            onChange={(_) => setSearchInput(_.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch()
              }
            }}
            className={classes.searchInput + " w-full"}
          />
          <Button
            variant="outlined"
            size="small"
            className={classes.buttonFilter}
            onClick={() => handleSearch()}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={6} className="flex flex-row items-center"></Grid>
        <Grid item xs={2} className="flex flex-row items-center">
          <Button
            onClick={() => setOpenInviteModal(true)}
            style={{
              marginTop: "80px",
              margin: "auto",
              marginBottom: "10px",
            }}
            className={classes.actionButton + " w-40"}
          >
            Invite User
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                onClick={() => sortHandler("email")}
                style={{ cursor: "pointer" }}
              >
                <div className="flex flex-row items-center">
                  {sort.field === "email" && sort.order === "DESC" && (
                    <DownIcon />
                  )}
                  {sort.field === "email" && sort.order === "ASC" && <UpIcon />}
                  <span>Email</span>
                </div>
              </StyledTableCell>
              <StyledTableCell
                onClick={() => sortHandler("phone_number")}
                style={{ cursor: "pointer", minWidth: "120px" }}
              >
                <div className="flex flex-row items-center min-w-fit">
                  {sort.field === "phone_number" && sort.order === "DESC" && (
                    <DownIcon />
                  )}
                  {sort.field === "phone_number" && sort.order === "ASC" && (
                    <UpIcon />
                  )}
                  <span>Phone Number</span>
                </div>
              </StyledTableCell>
              <StyledTableCell>
                <div className="flex flex-row items-center min-w-fit">
                  <span>Organization</span>
                </div>
              </StyledTableCell>
              <StyledTableCell>
                <div className="flex flex-row items-center min-w-fit">
                  <span>Role(s)</span>
                </div>
              </StyledTableCell>
              <StyledTableCell>
                <div className="flex flex-row items-center min-w-fit">
                  <span>First Login</span>
                </div>
              </StyledTableCell>
              <StyledTableCell>
                <div className="flex flex-row items-center min-w-fit">
                  <span>Last Login</span>
                </div>
              </StyledTableCell>
              <StyledTableCell>
                <div className="flex flex-row items-center min-w-fit">
                  <span>Actions</span>
                </div>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {!loading && !!data.length && (
            <TableBody>
              {data.map((row) => {
                const orgRole = row.organization_roles.find(
                  ({ organization }: any) => organization
                )

                return (
                  <StyledTableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <StyledTableCell>{row.email || "-"}</StyledTableCell>
                    <StyledTableCell>{row.phone_number || "-"}</StyledTableCell>
                    <StyledTableCell>
                      {orgRole?.organization?.name}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.organization_roles
                        .map(({ name }: { name: string }) => name)
                        .filter(
                          (v: string, i: number, s: string[]) =>
                            s.indexOf(v) === i
                        )
                        .join(", ")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {getFistLogin(row.authorizations || [])}
                    </StyledTableCell>
                    <StyledTableCell>
                      {getLastLogin(row.authorizations || [])}
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className="flex flex-row items-center">
                        <IconButton
                          disabled={
                            inFlight ||
                            !orgRole ||
                            checkTechnicianRole(row?.organization_roles)
                          }
                          onClick={async () =>
                            await handleMasqueradeClick(row.id)
                          }
                        >
                          <Tooltip title="Masquerade">
                            <AdminPanelSettingsIcon />
                          </Tooltip>
                        </IconButton>

                        <ActionMenu
                          openEdit={openEdit}
                          openEditRole={openEditRole}
                          openDelete={openDelete}
                          openEditOrg={openEditOrg}
                          selectedUser={row}
                          isAdmin={true}
                        />
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {loading && <Loader />}
      {!loading && !data.length && (
        <div className="text-center">
          <div className="inline-block">
            <p>EMPTY</p>
          </div>
        </div>
      )}

      <Pagination
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        total={total}
      />
    </div>
  )
}
