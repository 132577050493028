import React, { useCallback, useEffect, useRef, useState } from "react"
import { makeStyles, createStyles, Button, Tooltip } from "@material-ui/core"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import Collapse from "@mui/material/Collapse"
import TranslateIcon from "@mui/icons-material/Translate"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { styled } from "@mui/material/styles"
import Pagination from "../../common/Pagination"
import { Loader } from "../../common/Loader"
import { formatDate, getMinFormat } from "../../../utils/Util"
import { ReactComponent as UpIcon } from "./../../../assets/up.svg"
import { ReactComponent as DownIcon } from "./../../../assets/down.svg"
import { ReactComponent as ErrorIcon } from "./../../../assets/circle-red.svg"
import { ReactComponent as CloseIcon } from "./../../../assets/close-w.svg"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Carousel from "react-material-ui-carousel"
import { useActiveUser } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"
import { useFetchImages } from "../../../utils/ImageHelper"
import { DateTime } from "luxon"
import useI18n from "../../../hooks/useI18n"
import WorkOrderDetailsGrid from "./WorkOrderDetailsGrid"
import { useCloudTranslation } from "../../../hooks/useCloudTranslation"

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const WorkOrdersLists = ({ filter, ...props }: any) => {
  const classes = useStyles()
  const { i18n } = useI18n()
  const graphqlService = useGraphQLService()
  const imageHelper = useFetchImages()
  const [data, setData] = useState<any[]>([])
  const [sort, setSort] = useState({ field: "service_date", order: "DESC" })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [showDetailIndex, setShowDetailIndex] = useState(-1)
  const [details, setDetails] = useState<any>({})
  const [loadingDetails, setLoadingDetails] = useState(false)
  const { sfId: sfid } = useActiveUser()
  const [openImg, setOpenImg] = useState(false)
  const prevFilter = usePrevious({ filter, perPage })
  const [loadingImges, setLoadingImges] = useState(false)
  const [images, setImages] = useState<any[]>([])
  const abortController = new AbortController()
  const { translateText } = useCloudTranslation()

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f9f9f9",
      color: "#232F64",
      fontSize: "20px",
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }))

  useEffect(() => {
    const controller = new AbortController()
    if ((filter !== prevFilter?.filter || perPage !== prevFilter?.perPage) && page !== 1) {
      setPage(1)
    } else {
      setLoading(true)
      setDetails({})
      setShowDetailIndex(-1)
      graphqlService
        .getWorkOrders(
          {
            providerId: sfid,
            limit: perPage,
            offset: (page - 1) * perPage,
            sort: sort.field,
            order: sort.order,
            status: "Approved",
            search: filter?.search,
          },
          controller.signal
        )
        .then((response) => {
          setData(response.worklogs || [])
          setTotal(response.pagination.found_rows)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })

      return () => controller.abort()
    }
  }, [graphqlService, page, perPage, filter, sfid, sort])

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  const sortHandler = (by: any) => {
    if (by == sort.field) {
      if (sort.order === "ASC") {
        setSort({ field: sort.field, order: "DESC" })
      } else {
        setSort({ field: sort.field, order: "ASC" })
      }
    } else {
      setSort({ field: by, order: sort.order })
    }
  }

  const showDetail = useCallback(
    (sfid: any, index: any) => {
      if (index >= 0) {
        setDetails({})
        setLoadingDetails(true)
        setLoadingImges(true)
        setShowDetailIndex(index)
        graphqlService
          .getWorkLogDetails({
            sfid,
          })
          .then((response) => {
            setDetails(response)
            setLoadingDetails(false)
            getImageData(response)
          })
          .catch(() => {
            setLoading(false)
            setLoadingImges(false)
          })
      } else {
        setShowDetailIndex(index)
        setDetails({})
      }
    },
    [graphqlService]
  )

  const openImage = () => {
    setOpenImg(true)
  }

  const getImageData = useCallback(
    async (response: any) => {
      let totalImg = response?.attachments?.length
      if (!totalImg) {
        setLoadingImges(false)
        setImages([])
        return
      }
      const sfids = response?.attachments.map((a: any) => a.sfid)
      const imagesData = await imageHelper.getImages(sfids, undefined, abortController.signal)
      setImages(imagesData)
      setLoadingImges(false)
    },
    [abortController.signal, imageHelper]
  )

  if (error) {
    return <p>ERROR: {error}</p>
  } else {
    return (
      <>
        <Modal open={openImg} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" onClose={() => setOpenImg(false)}>
          <Box className={classes.styleImg}>
            <div className={classes.iframeBlock}>
              <div className="p-8">
                <Carousel
                  animation={"slide"}
                  autoPlay={false}
                  navButtonsAlwaysVisible={true}
                  navButtonsProps={{
                    style: {},
                  }}
                >
                  {!!images?.length &&
                    images.map((img: any, i: any) => (
                      <div key={i} className="flex flex-col items-center">
                        {loadingImges ? <Loader /> : <img className={classes.carouselImg} alt="photo" src={img} />}
                      </div>
                    ))}
                </Carousel>
              </div>

              <Button variant="text" onClick={() => setOpenImg(false)} className={classes.imgButton}>
                <CloseIcon />
              </Button>
            </div>
          </Box>
        </Modal>
        <div className={classes.listContainer}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell onClick={() => sortHandler("name")} style={{ cursor: "pointer" }}>
                    <div className="flex flex-row items-center">
                      {sort.field === "name" && sort.order === "DESC" && <DownIcon />}
                      {sort.field === "name" && sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.workOrderNo")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell onClick={() => sortHandler("location_name")} style={{ cursor: "pointer" }}>
                    <div className="flex flex-row items-center">
                      {sort.field === "location_name" && sort.order === "DESC" && <DownIcon />}
                      {sort.field === "location_name" && sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.site")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell onClick={() => sortHandler("service_summary")} style={{ cursor: "pointer" }}>
                    <div className="flex flex-row items-center">
                      {sort.field === "service_summary" && sort.order === "DESC" && <DownIcon />}
                      {sort.field === "service_summary" && sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.serviceSummary")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell onClick={() => sortHandler("service_date")} style={{ cursor: "pointer" }}>
                    <div className="flex flex-row items-center">
                      {sort.field === "service_date" && sort.order === "DESC" && <DownIcon />}
                      {sort.field === "service_date" && sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.dateReported")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell onClick={() => sortHandler("service_date")} style={{ cursor: "pointer" }}>
                    <div className="flex flex-row items-center">
                      {sort.field === "service_date" && sort.order === "DESC" && <DownIcon />}
                      {sort.field === "service_date" && sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.checkinTime")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell onClick={() => sortHandler("time_taken_minutes")} style={{ cursor: "pointer" }}>
                    <div className="flex flex-row items-center">
                      {sort.field === "time_taken_minutes" && sort.order === "DESC" && <DownIcon />}
                      {sort.field === "time_taken_minutes" && sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.timeTakenOnSite")}</span>
                    </div>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {!loading && !!data.length && (
                <TableBody>
                  {data.map((row: any, index: any) => (
                    <React.Fragment key={index}>
                      <StyledTableRow
                        hover
                        key={row.sfid}
                        onClick={() => showDetail(row.sfid, index === showDetailIndex ? -1 : index)}
                        style={{ cursor: "pointer" }}
                      >
                        <StyledTableCell>
                          <div className="flex flex-row items-center">
                            <span className={classes.paddingRight}>{row.name}</span>
                            <span>{row.cancelled ? <ErrorIcon className="w-2" /> : ""}</span>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>{row.location_name}</StyledTableCell>
                        <StyledTableCell>
                          <div className="flex flex-row items-center justify-between gap-2">
                            {row.service_summary}
                            {i18n.locale !== "en" && (
                              <Tooltip title={i18n.t("common.translate")} placement="top-start">
                                <TranslateIcon
                                  className={`transform cursor-pointer text-right transition duration-75 ease-in-out hover:scale-125 ${
                                    row.wasTranslated ? "cursor-default text-slate-300 hover:scale-100" : "text-black"
                                  }`}
                                  onClick={async (e) => {
                                    e.stopPropagation()

                                    if (row.wasTranslated) return

                                    const translation = await translateText(row.service_summary)

                                    if (translation) {
                                      row.service_summary = translation || row.service_summary
                                      row.wasTranslated = true
                                    }
                                  }}
                                />
                              </Tooltip>
                            )}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>{row.service_date ? formatDate(row.service_date) : "-"}</StyledTableCell>
                        <StyledTableCell>{row.service_date ? DateTime.fromISO(row?.service_date).toFormat("t") : "-"}</StyledTableCell>
                        <StyledTableCell>{row?.time_taken_minutes ? getMinFormat(row?.time_taken_minutes) : "-"}</StyledTableCell>
                      </StyledTableRow>
                      <TableRow>
                        <TableCell style={{ padding: 0 }} colSpan={12}>
                          <Collapse in={index === showDetailIndex} timeout={100} unmountOnExit>
                            {loadingDetails ? (
                              <Loader />
                            ) : (
                              <WorkOrderDetailsGrid details={details} images={images} loadingImges={loadingImges} openImage={openImage} />
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {loading && <Loader />}
          {!loading && !data.length && (
            <div className="text-center">
              <div className="inline-block">
                <p>{i18n.t("common.empty")}</p>
              </div>
            </div>
          )}

          <Pagination page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} total={total} />
        </div>
      </>
    )
  }
}
const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      width: "auto",
      marginLeft: 0,
      padding: "10px",
    },
    location: {
      fontWeight: "bold",
      color: "#1695CB",
    },
    greenStatus: {
      backgroundColor: "#d7f5e3",
      color: "#4e996c",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "10px",
    },
    redStatus: {
      backgroundColor: "#fed5d5",
      color: "#ff3b5f",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "10px",
    },
    yellowStatus: {
      backgroundColor: "#ffd978",
      color: "#665730",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "10px",
    },
    detailsGrid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#f5f7ff",
    },
    showTitle: {
      width: "auto",
      marginTop: "10px",
      marginLeft: "10px",
      marginBottom: "20px",
    },
    titleFont: {
      fontSize: "22px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#000",
      letterSpacing: "-1px",
      marginBottom: "20px",
    },
    infoFont: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 20,
      marginLeft: "30px",
    },
    commentsTitle: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 15,
      marginLeft: "0px",
    },
    comments: {
      fontSize: 15,
      marginLeft: "10px",
    },
    infoTitle: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
      marginBottom: "10px",
      marginLeft: "30px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      padding: "5px",
      background: "#FFFFFF",
    },
    container: {
      maxWidth: "100%",
    },
    image: {
      maxHeight: "100px",
    },
    imgContainer: {
      position: "relative",
      maxWidth: "150px",
      maxHeight: "100px",
      margin: "0px 5px 5px 0px",
      minWidth: "fit-content",
    },
    imgButton: {
      background: "red",
      top: 0,
      right: 0,
      border: "none",
      cursor: "pointer",
      position: "absolute",
      textAlign: "center",
      borderRadius: "5px",
      height: "25px",
      minWidth: "25px",
    },
    scrollableImg: {
      width: "500px",
      overflowY: "hidden",
    },
    styleImg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    iframeBlock: {
      width: "60%",
      margin: "5px",
      backgroundColor: "white",
      position: "absolute",
    },
    gridHeight: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      padding: "5px",
      background: "#FFFFFF",
      minHeight: "95.5%",
    },
    carouselImg: {
      maxHeight: "85vh",
    },
    paddingRight: {
      paddingRight: "1px",
    },
  })
)

export default WorkOrdersLists
