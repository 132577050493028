import { useCallback, useContext, useState } from "react"

import { ValidationContext } from "../../../providers/ValidationProvider"
import { useGraphQLService } from "../../../services/graphqlService"
import { Validation } from "../interfaces"

export const useValidationNav = () => {
  const { validationState, setValidation } = useContext(ValidationContext)
  const [isLoading, setLoading] = useState(false)
  const { getValidations } = useGraphQLService()

  const fetchValidationsFromServer = useCallback(
    async ({ limit = 10, nextPageToken = "" }: { limit?: number; nextPageToken?: string | null }) => {
      try {
        const response = await getValidations(
          validationState?.user?.sfid ?? "",
          validationState?.startDate ?? "",
          validationState?.endDate ?? "",
          validationState?.currentFilter?.status ?? "ReviewPending",
          validationState?.snowSort ? `${validationState.snowSort.by}:${validationState.snowSort.order.toLowerCase()}` : "status:desc",
          limit,
          validationState?.currentFilter?.siteId,
          nextPageToken
        )

        return response
      } catch (error) {
        console.log("ERROR FETCHING VALIDATIONS FROM SERVER", error)
      }
    },
    [
      getValidations,
      validationState?.currentFilter,
      validationState?.endDate,
      validationState.snowSort,
      validationState?.startDate,
      validationState?.user?.sfid,
    ]
  )

  const getCurrentValidationIndex = useCallback(() => {
    return (validationState?.validations as Validation[])?.findIndex((v) => v.name === (validationState?.selectedValidation as Validation)?.name)
  }, [validationState?.selectedValidation, validationState?.validations])

  const navigateTo = useCallback(
    async (to: "next" | "previous") => {
      const currentIndex = getCurrentValidationIndex()
      const newValidationState = validationState
      let newPage = validationState.pageWithStorm ?? 1

      if (currentIndex === undefined) return

      let nextIndex = to === "next" ? currentIndex + 1 : currentIndex - 1

      let validationArray = (newValidationState?.validations as Validation[]) ?? []
      let pageTokenList = validationState.snowPageTokenList ?? []
      let nextResponse:
        | {
            items: Validation[]
            nextPageToken: any
            totalCount: number
          }
        | undefined

      try {
        if (to === "next" && currentIndex >= validationArray.length - 1) {
          nextIndex = 0

          setLoading(true)

          nextResponse = await fetchValidationsFromServer({
            nextPageToken: newValidationState?.snowNextPageToken,
          })

          newPage = nextResponse?.items ? newPage + 1 : newValidationState.pageWithStorm ?? newPage

          newValidationState.snowNextPageToken = nextResponse?.nextPageToken
          validationArray = nextResponse?.items ? nextResponse?.items : validationArray
          newValidationState.validations = validationArray

          if (nextResponse) {
            pageTokenList = nextResponse ? [...pageTokenList, nextResponse.nextPageToken] : pageTokenList
          }
        } else if (to === "previous" && currentIndex === 0) {
          setLoading(true)

          nextResponse = await fetchValidationsFromServer({
            nextPageToken: pageTokenList[pageTokenList.length - 1],
          })

          pageTokenList = pageTokenList.slice(0, pageTokenList.length - 1)

          newPage = nextResponse?.items ? newPage - 1 : newValidationState.pageWithStorm ?? newPage

          nextIndex = nextResponse?.items ? nextResponse?.items?.length - 1 : 0

          newValidationState.snowNextPageToken = nextResponse?.nextPageToken
          validationArray = nextResponse?.items ? nextResponse?.items : validationArray
          newValidationState.validations = validationArray
        }
      } catch (error) {
        console.log("ERROR ON NAV", error)
      } finally {
        setLoading(false)
      }

      const newValidation = validationArray[nextIndex]

      if (!newValidation) return

      setValidation({
        ...newValidationState,
        snowPageTokenList: pageTokenList,
        pageWithStorm: newPage,
        selectedValidation: newValidation,
      })
    },
    [fetchValidationsFromServer, getCurrentValidationIndex, setValidation, validationState]
  )

  return {
    navigateTo,
    getCurrentValidationIndex,
    isLoading,
    setLoading,
  }
}
