import appStoreLogo from "./../../assets/app-store.png"
import googlePlayLogo from "./../../assets/google-play.png"
const googlePlayLink = process.env?.REACT_APP_GOOGLE_PLAY!
const appStoreLink = process.env?.REACT_APP_APP_STORE!

const Stores = () => {
  const openLink = (url: string) => {
    window.open(url, "_blank")
  }

  return (
    <>
      <div>
        <h2 className="text-l text-center mb-8" style={{ color: "white" }}>
          The page you are attempting to access is only available on the CASE
          FMS mobile app.
        </h2>
        <h2 className="text-sm text-center mb-8" style={{ color: "white" }}>
          Please download the appropriate app and use your smartphone/tablet to
          follow the link in the invitation email you received.
        </h2>
      </div>
      <div
        className="h-6 w-1/2 text-center float-left cursor-pointer p-2"
        onClick={() => openLink(googlePlayLink)}
      >
        <img alt={"google-play"} src={googlePlayLogo} />
      </div>
      <div
        className="h-6 w-1/2 text-center float-left cursor-pointer p-2"
        onClick={() => openLink(appStoreLink)}
      >
        <img alt={"app-store"} src={appStoreLogo} />
      </div>
    </>
  )
}

export default Stores
