import * as React from "react"
import { useState } from "react"

import { Button, Toolbar, Typography, Menu, MenuItem, makeStyles, createStyles } from "@material-ui/core"

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { ReactComponent as RightIcon } from "./../../assets/right-b.svg"
import { ReactComponent as LeftIcon } from "./../../assets/left-b.svg"
import { ReactComponent as RightDoubleIcon } from "./../../assets/right-double-b.svg"
import { ReactComponent as LeftDoubleIcon } from "./../../assets/left-double-b.svg"
import { ReactComponent as DRightIcon } from "./../../assets/right-dis.svg"
import { ReactComponent as DLeftIcon } from "./../../assets/left-dis.svg"
import { ReactComponent as DRightDoubleIcon } from "./../../assets/right-double-dis.svg"
import { ReactComponent as DLeftDoubleIcon } from "./../../assets/left-double-dis.svg"
import useI18n from "../../hooks/useI18n"

const useStyles = makeStyles(() =>
  createStyles({
    toolbarRoot: {
      display: "flex",
      justifyContent: "center",
      width: "auto",
      position: "relative",
    },
    pageNotes: {
      position: "absolute",
      left: "24px",
      fontFamily: "Manrope",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "22px",
      color: "#5F5F5F",
    },
    pageNumbers: {
      fontFamily: "Manrope",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "22px",
      color: "#5F5F5F",
    },
    paginationPerpage: {
      position: "absolute",
      right: "0px",
      display: "flex",
    },
    rowsPerPage: {
      position: "relative",
      top: "5px",
      fontFamily: "Manrope",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 500,
      color: "#5F5F5F",
    },
    rowsPerPageBtnRoot: {
      position: "relative",
      top: "-4px",
    },
    calculatingContainer: {
      display: "flex",
      justifyContent: "center",
      width: "auto",
      marginTop: "134px",
      marginBottom: "535px",
    },
    calculatingTxt: {
      fontWeight: 500,
      color: "#5F5F5F",
    },
  })
)

/**
 * Renders pagination UI
 * @param props
 * @constructor
 */
const Pagination = (props: any) => {
  const classes = useStyles()
  const { i18n } = useI18n()

  let page = props.page
  let perPage = props.perPage
  let total = props.total
  let setPage = props.setPage
  let setPerPage = props.setPerPage
  let loading = props.loading

  const nbPages = Math.ceil(total / perPage) || 1
  const perPagesArr = [10, 20, 50]

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [anchorElPerPagesMenu, setAanchorElPerPagesMenu] = useState<null | HTMLElement>(null)

  React.useEffect(() => {
    if (props.setPerPage) {
      props.setPerPage(perPage)
    } else {
      setPerPage(perPage)
    }

    const consumersSearchResultsNode = document.getElementById("consumers_search_results")
    if (consumersSearchResultsNode) {
      consumersSearchResultsNode.innerHTML = "Search Results (" + total + ")"
    }

    if (props.setTotal) {
      props.setTotal(total)
    }

    if (props.defaultPerPage) {
      let defaultSelectedIdx = 0
      perPagesArr.forEach((page: number, index: number) => {
        if (props.defaultPerPage === page) {
          defaultSelectedIdx = index
        }
      })
      setSelectedIndex(defaultSelectedIdx)
    }
  }, [total])

  const perPageBtnClicked = (event: React.MouseEvent<HTMLElement>) => {
    setAanchorElPerPagesMenu(event.currentTarget)
  }

  const perPagesMenuClosed = () => {
    setAanchorElPerPagesMenu(null)
  }

  const perPagesMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index)
    setPerPage(perPagesArr[index])
    if (props.setPerPage) props.setPerPage(perPagesArr[index])
    setAanchorElPerPagesMenu(null)
  }

  if (loading && total === 0) {
    return (
      <Toolbar
        classes={{
          root: classes.calculatingContainer,
        }}
      >
        <Typography className={classes.calculatingTxt}>Calculating...</Typography>
      </Toolbar>
    )
  }

  return (
    <Toolbar
      classes={{
        root: classes.toolbarRoot,
      }}
    >
      <Typography className={classes.pageNotes}>
        {i18n.t("common.pagination.showingCount", { actual: perPage * page < total ? perPage * page : total, total: total })}
      </Typography>

      <Button
        color="primary"
        disabled={page <= 1}
        onClick={() => {
          setPage(1)
        }}
      >
        {page <= 1 ? <DLeftDoubleIcon /> : <LeftDoubleIcon />}
      </Button>

      <Button
        color="primary"
        disabled={page <= 1}
        onClick={() => {
          setPage(page - 1)
        }}
      >
        {page <= 1 ? <DLeftIcon /> : <LeftIcon />}
      </Button>

      <Typography className={classes.pageNumbers}>{total > 0 ? page + " of " + nbPages : page}</Typography>

      <Button
        color="primary"
        disabled={total > 0 ? page >= nbPages : false}
        onClick={() => {
          setPage(page + 1)
        }}
      >
        {total > 0 && page >= nbPages ? <DRightIcon /> : <RightIcon />}
      </Button>

      <Button
        color="primary"
        disabled={total > 0 ? page >= nbPages : false}
        onClick={() => {
          setPage(nbPages)
        }}
      >
        {total > 0 && page >= nbPages ? <DRightDoubleIcon /> : <RightDoubleIcon />}
      </Button>

      <div className={classes.paginationPerpage}>
        <Typography className={classes.rowsPerPage}>{i18n.t("common.pagination.show")}:</Typography>

        <Button
          color="inherit"
          endIcon={<ArrowDropDownIcon />}
          onClick={perPageBtnClicked}
          classes={{
            root: classes.rowsPerPageBtnRoot,
          }}
        >
          {perPagesArr[selectedIndex]}
        </Button>
        <Menu id="events_rooms_menu" anchorEl={anchorElPerPagesMenu} keepMounted open={Boolean(anchorElPerPagesMenu)} onClose={perPagesMenuClosed}>
          {perPagesArr.map((perPage: any, index: number) => (
            <MenuItem key={index} selected={index === selectedIndex} onClick={(event) => perPagesMenuItemClick(event, index)}>
              {perPage}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </Toolbar>
  )
}

export default Pagination
