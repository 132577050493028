import React, { useEffect, useState } from "react"
import {
  makeStyles,
  createStyles,
  Grid,
  MenuItem,
  Button,
} from "@material-ui/core"
import { Filter } from "./../../common/Filter"
import { ReactComponent as SearchIcon } from "./../../../assets/search.svg"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import useI18n from "../../../hooks/useI18n"

export const SitesFilters = ({ setFilter, filter }: any, props: any) => {
  const classes = useStyles()
  const { i18n } = useI18n()
  const [searchText, setSearchText] = useState("")
  const [typeSelected, setTypeSelected] = useState("all")
  const [search, setSearch] = useState("")
  const [types, setTypes] = useState<any>([
    { id: "all", value: i18n.t("sites.filterTypes.allStatuses") },
    { id: "Active", value: i18n.t("sites.filterTypes.active") },
    { id: "Inactive", value: i18n.t("sites.filterTypes.inactive") },
  ])

  useEffect(() => {}, [])

  useEffect(() => {
    if (search !== null) {
      setFilter({ ...filter, search: search })
    } else {
      setFilter({ ...filter, search: undefined })
    }
  }, [search])

  const typeChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
    const f = event.target.value as string
    setFilter({ filter: f })
    setTypeSelected(f)
  }

  const searchChange = (event: any) => {
    setSearchText(event.target.value as string)
  }

  const searchByText = () => {
    setSearch(searchText)
  }

  return (
    <div className="w-full p-2.5">
      <div className="w-full p-2.5 rounded pl-4 h-fit">
        <Grid container className="flex-row items-center">
          <Grid item xs={4} className="flex flex-row items-center">
            <TextField
              size="small"
              id="input-with-icon-textfield"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className={classes.searchInput + " w-full"}
              placeholder={i18n.t("sites.searchBySiteName")}
              onChange={(e) => searchChange(e)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchByText()
                }
              }}
            />
            <Button
              variant="outlined"
              size="small"
              className={classes.buttonFilter}
              onClick={() => searchByText()}
            >
              {i18n.t("common.search")}
            </Button>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Filter
              id="filter"
              value={typeSelected}
              onChange={typeChanged}
              labelWidth={0}
              width={"500px"}
            >
              {types.map((filter: any, index: number) => {
                return (
                  <MenuItem
                    key={`filter_${index}`}
                    value={filter.id}
                    selected={typeSelected === filter.id}
                  >
                    {filter.value}
                  </MenuItem>
                )
              })}
            </Filter>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    searchInput: {
      backgroundColor: "white",
    },
    buttonFilter: {
      backgroundColor: "#f9f9f9",
      height: "40px",
      minWidth: "60px",
      fontSize: "10px",
    },
  })
)
