import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"

import { DateTime, Duration, Interval } from "luxon"
import { IconButton, Tooltip } from "@material-ui/core"
import ArrowForward from "@mui/icons-material/ArrowForward"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

import "../../validations.css"
import { LandValidation, LandValidationListing, LandWorklog, ServicePeriod } from "../../../interfaces"
import { MIN_DISTANCE_FROM_SITE, getDateByMonthAndWeekNumber, getNumberOfWeeksPerMonth, getWeekNumber, weekOptions } from "../../../../../utils/Util"
import PeriodCardComponent from "../../SelectedValidationPage/components/PeriodCardComponent"
import { ValidationContext } from "../../../../../providers/ValidationProvider"
import LandChartStatusIconComponent, { LandStatusColor } from "./LandChartStatusIconComponent"
import { Loader } from "../../../../common/Loader"
import { useGraphQLService } from "../../../../../services/graphqlService"

type Props = {
  monthFilteringLength: number
  onListingNav: (
    data: {
      service: {
        periodIndex: number
        siteId: string
        serviceKey: string
        sfid: string
        weekNumber?: number | undefined
        monthName: string
        year: number
      }
      item: LandValidationListing
    },
    newListingListInfo?: {
      nextPageToken?: string
      listings: LandValidationListing[]
    }
  ) => void
  onReviewCompleted: ({
    sfid,
    week,
    value,
  }: {
    sfid: string
    week: any
    value: boolean
    selectedService?: {
      periodIndex: number
      serviceKey: string
      sfid: string
      weekNumber?: number
    } | null
  }) => Promise<void>
  data: LandValidation[]
  selectedService?: {
    periodIndex: number
    serviceKey: string
    sfid: string
    weekNumber?: number
  } | null
}

type QuickNavSettings = {
  sfid: string
  serviceKey: string
  siteId: string
  data:
    | {
        reviewComplete: boolean
        periodIndex: number
        weekNumber: number
        monthName: string
        year: number
      }[]
}
const currentDate = DateTime.now()

const LandValidationTable = ({ data, onReviewCompleted, onListingNav, selectedService: propSelectedService = null, monthFilteringLength }: Props) => {
  const currentMonthRef = useRef<any>(null)
  const [dates, setDates] = useState<{ start: DateTime; end: DateTime; months: { name: string; year: number }[] }>()
  const [landValidations, setLandValidations] = useState([] as LandValidation[])
  const [isLoading, setLoading] = useState(false)
  const [selectedService, setSelectedService] = useState<{
    periodIndex: number
    serviceKey: string
    sfid: string
    weekNumber?: number
    year?: number
    monthName?: string
  } | null>(propSelectedService)
  const [quickNavSettings, setQuickNavSettings] = useState<QuickNavSettings[]>([])
  const { validationState, setValidation } = useContext(ValidationContext)
  const { getLandValidationListing } = useGraphQLService()

  const handleReviewComplete = useCallback(
    async (period?: ServicePeriod, sfid?: string, week?: number) => {
      let periodIndex =
        week ??
        validationState.selectedValidation?.servicePeriods?.findIndex(
          (p: ServicePeriod) => period?.serviceKey === p.serviceKey && period?.startDate === p.startDate && period?.endDate === p.endDate
        )

      if (periodIndex >= 0 && selectedService) {
        await onReviewCompleted({
          sfid: sfid ?? selectedService.sfid,
          week: periodIndex,
          value: !validationState.selectedValidation?.servicePeriods?.[periodIndex]?.reviewComplete ?? false,
          selectedService,
        })
      } else {
        console.log("Error on index: ", periodIndex)
      }
    },
    [onReviewCompleted, selectedService, validationState.selectedValidation?.servicePeriods]
  )

  const setDateRange = useCallback(
    (changeToActual: boolean = false) => {
      if (landValidations) {
        const startDate = currentDate.minus({ month: monthFilteringLength })
        const endDate = currentDate.plus({ month: monthFilteringLength })

        const monthNames = []
        let currentMonth = startDate

        while (currentMonth <= endDate) {
          monthNames.push({ name: currentMonth.toLocaleString({ month: "short" }), year: currentMonth.year })
          currentMonth = currentMonth.plus({ months: 1 })
        }

        const mergedArray = [...monthNames]

        setDates((previous) => {
          const actual = { start: startDate, end: endDate, months: mergedArray }

          return changeToActual ? actual : previous ?? actual
        })
      }
    },
    [landValidations, monthFilteringLength]
  )

  const handleNav = (isBackwards: boolean) => {
    window.getSelection()?.removeAllRanges()

    if (!selectedService || !dates || !validationState?.selectedValidation) return

    let selectedValidation = validationState.selectedValidation as LandValidation
    const { periodIndex: selectedPeriodIndex, weekNumber: selectedWeekNumber } = selectedService

    const resultIndex = quickNavSettings.findIndex((v) => v.serviceKey === selectedValidation?.serviceKey && v.sfid === selectedValidation?.sfid)

    if (resultIndex < 0) return

    let result: QuickNavSettings | undefined = quickNavSettings[resultIndex]

    if (result && !result.data?.length) {
      if (isBackwards) {
        const newSettings = quickNavSettings.slice(0, resultIndex)

        if (!newSettings.length) return

        const tempIndex = newSettings.reverse().findIndex((d) => d.data.length)
        const newIndex = tempIndex < 0 ? 0 : tempIndex

        result = newSettings[newIndex]
      } else {
        const tempIndex = quickNavSettings.findIndex(
          (d, i) => i > resultIndex && d.serviceKey !== result?.serviceKey && d.sfid !== result?.sfid && d.data.length
        )
        const newIndex = tempIndex < 0 ? 0 : tempIndex

        result = quickNavSettings[newIndex]
      }
    }

    let dataIndex = result.data.findIndex((d) => d.periodIndex === selectedPeriodIndex && d.weekNumber === selectedWeekNumber)

    if (dataIndex < 0 && selectedPeriodIndex < 0) return

    let hasPendings = selectedValidation.servicePeriods.some((p) => !p.reviewComplete)

    if (isBackwards) {
      if (selectedPeriodIndex >= 0 && dataIndex < 0) {
        const tempIndex = result.data.findIndex((d) => d.periodIndex === selectedPeriodIndex)

        if (tempIndex === 0) {
          dataIndex = result.data.length - 1
        } else {
          for (let index = result.data.length - 1; index >= 0 && index < result.data.length; index--) {
            const data = result.data[index]

            if (index === 0 && data.periodIndex > selectedPeriodIndex) {
              dataIndex = result.data.length - 1
              break
            }

            if (data.periodIndex < selectedPeriodIndex) {
              const tempIndex = result.data.findIndex((d) => d.periodIndex === data.periodIndex)
              dataIndex = tempIndex
              break
            }
          }
        }
      } else if (dataIndex - 1 >= 0) {
        dataIndex--
      } else if (dataIndex - 1 < 0) {
        dataIndex = result.data.length - 1
      } else {
        dataIndex = 0
      }

      hasPendings = selectedValidation.servicePeriods.some((p) => !p.reviewComplete && result?.data?.some((d) => d.periodIndex < selectedPeriodIndex))
    } else {
      if (selectedPeriodIndex >= 0 && dataIndex < 0) {
        const tempIndex = result.data.findIndex((d) => d.periodIndex > selectedPeriodIndex)
        dataIndex = tempIndex < 0 ? 0 : tempIndex
      } else if (dataIndex + 1 >= 0 && dataIndex + 1 <= result.data.length - 1) {
        dataIndex++
      } else {
        dataIndex = 0
      }

      hasPendings = selectedValidation.servicePeriods.some((p) => !p.reviewComplete && result?.data?.some((d) => d.periodIndex > selectedPeriodIndex))
    }

    if (!result?.data?.length && !hasPendings) {
      return navigateToNewValidation(isBackwards)
    }

    if (!result?.data?.length && hasPendings) {
      let settingIndex = quickNavSettings.findIndex((s) => s.serviceKey === selectedValidation.serviceKey && s.sfid === selectedValidation.sfid)

      if (settingIndex < 0) return

      settingIndex =
        settingIndex === quickNavSettings.length - 1 ? (isBackwards ? settingIndex : 0) : isBackwards && settingIndex === 0 ? 0 : settingIndex

      result = undefined

      if (!isBackwards) {
        for (let index = settingIndex; index < quickNavSettings.length; index++) {
          const setting = quickNavSettings[index]

          if (setting.data.length) {
            result = setting
            break
          }
        }
      } else {
        for (let index = settingIndex; index >= 0 && index < quickNavSettings.length; index--) {
          const setting = quickNavSettings[index]

          if (setting.data.length) {
            result = setting
            break
          }
        }
      }

      if (!result) return

      // console.log("VA A SETEAR SERVICES LOCAL:", {
      //   ...selectedService,
      //   sfid: result.sfid,
      //   serviceKey: result.serviceKey,
      //   periodIndex: result.data[dataIndex].periodIndex,
      //   weekNumber: result.data[dataIndex].weekNumber,
      //   monthName: result.data[dataIndex].monthName,
      //   year: result.data[dataIndex].year,
      // })

      setSelectedService({
        ...selectedService,
        sfid: result.sfid,
        serviceKey: result.serviceKey,
        periodIndex: result.data[dataIndex].periodIndex,
        weekNumber: result.data[dataIndex].weekNumber,
        monthName: result.data[dataIndex].monthName,
        year: result.data[dataIndex].year,
      })

      return
    }

    if (dataIndex === 0 && !isBackwards && !hasPendings) {
      const nextIndex = quickNavSettings.findIndex((s, index) => index > resultIndex && s.data.length)

      if (nextIndex >= 0) {
        const next = quickNavSettings[nextIndex]

        result = next
      }
    } else if (dataIndex === result.data.length - 1 && isBackwards) {
      let nextIndex = -1

      for (let index = resultIndex - 1; index >= 0; index--) {
        const setting = quickNavSettings[index]

        if (setting.data.length) {
          nextIndex = index
          break
        }
      }

      if (nextIndex >= 0) {
        const next = quickNavSettings[nextIndex]

        result = next
        dataIndex = next.data.length - 1
      }
    }

    if (dataIndex === 0 && !isBackwards && quickNavSettings.findIndex((s, index) => index > resultIndex && s.data.length) < 0) {
      return navigateToNewValidation(isBackwards)
    }

    if (selectedValidation.serviceKey !== result.serviceKey || selectedValidation.sfid !== result.sfid) {
      selectedValidation = landValidations.find((v) => v.serviceKey === result?.serviceKey && v.sfid === result?.sfid) ?? selectedValidation
    }

    const previousHasPending = quickNavSettings[resultIndex - 1]?.data?.some((d) => !d.reviewComplete) ?? false

    if (
      dataIndex === result.data.length - 1 &&
      isBackwards &&
      result.serviceKey === selectedValidation.serviceKey &&
      result.sfid === selectedValidation.sfid &&
      !previousHasPending
    ) {
      return navigateToNewValidation(isBackwards)
    }

    const exists = validationState?.validations?.find((v) => v.sfid === result?.sfid)

    setSelectedService({
      ...selectedService,
      sfid: result.sfid,
      serviceKey: result.serviceKey,
      periodIndex: result.data[dataIndex]?.periodIndex,
      weekNumber: result.data[dataIndex]?.weekNumber,
      monthName: result.data[dataIndex]?.monthName,
      year: result.data[dataIndex]?.year,
    })
    setValidation({
      ...validationState,
      selectedValidation: undefined,
    })
    setTimeout(() => {
      setValidation({
        ...validationState,
        selectedWeeks: [{ id: result?.data[dataIndex]?.weekNumber ?? 0, label: "" }],
        selectedValidation,
        selectedService: exists
          ? {
              ...selectedService,
              sfid: result?.sfid ?? "",
              serviceKey: result?.serviceKey ?? "",
              periodIndex: result?.data[dataIndex]?.periodIndex ?? 0,
              weekNumber: result?.data[dataIndex]?.weekNumber ?? 0,
              monthName: result?.data[dataIndex]?.monthName ?? "",
              year: result?.data[dataIndex]?.year ?? 0,
              siteId: result?.siteId,
            }
          : validationState.selectedService,
      })
    }, 200)

    if (isBackwards && resultIndex === 0 && dataIndex === result.data.length - 1 && !previousHasPending) {
      return navigateToNewValidation(isBackwards)
    } else if (!isBackwards && resultIndex === quickNavSettings.length - 1 && dataIndex === 0) {
      return navigateToNewValidation(isBackwards)
    }
  }

  const navigateToNewValidation = async (isBackwards: boolean) => {
    if (!validationState?.selectedValidation) return

    let selectedValidation = validationState.selectedValidation as LandValidation
    let listings = [...(validationState?.validations ?? [])] as LandValidationListing[]
    const selectedListingIndex = listings.findIndex((listing) => listing.siteId === selectedValidation.siteId)
    let newListingListInfo:
      | {
          nextPageToken?: string
          listings: LandValidationListing[]
        }
      | undefined

    let newListing: LandValidationListing | undefined
    let periodIndex: number | undefined
    let weekNumber = -1

    if (isBackwards) {
      const initialIndex = selectedListingIndex === 0 ? listings.length - 1 : selectedListingIndex

      for (let i = initialIndex; i >= 0; i--) {
        const listing = listings[i]
        const selectedListing = listings[selectedListingIndex]
        const isSpecial =
          listing.serviceFrequency.toLowerCase() === "quarterly" ||
          listing.serviceFrequency.toLowerCase() === "monthly" ||
          listing.serviceFrequency.toLowerCase() === "annual"

        if (listing.siteId !== selectedListing.siteId) {
          const hasPendings = listing.servicePeriods.some((p) => {
            const periodReminderDate = DateTime.fromISO(p.reminderDate)
            const isPeriodCompleted =
              p.reviewComplete && !p.worklogs.some((w) => w.service?.approval_status?.toLowerCase() === "pending" && !w.duplicateWorklog)

            const sundayReminder = periodReminderDate.weekday === 7 ? periodReminderDate.plus({ day: 1 }) : periodReminderDate
            const sundayCurrent = currentDate.weekday === 7 ? currentDate.plus({ day: 1 }) : currentDate

            return (
              !isPeriodCompleted &&
              sundayReminder.weekNumber < sundayCurrent.weekNumber &&
              sundayReminder.year === sundayCurrent.year &&
              (p.expected || p.worklogs.length) &&
              sundayReminder > currentDate.minus({ months: monthFilteringLength }).startOf("month").startOf("week").minus({ day: 1 })
            )
          })

          if (!hasPendings) {
            continue
          }

          newListing = listing

          for (let pI = listing.servicePeriods.length - 1; pI >= 0; pI--) {
            const period = listing.servicePeriods[pI]
            const periodReminderDate = DateTime.fromISO(period.reminderDate)
            const isPeriodCompleted =
              period.reviewComplete && !period.worklogs.some((w) => w.service?.approval_status?.toLowerCase() === "pending" && !w.duplicateWorklog)
            const sundayReminder = periodReminderDate.weekday === 7 ? periodReminderDate.plus({ day: 1 }) : periodReminderDate
            const sundayCurrent = currentDate.weekday === 7 ? currentDate.plus({ day: 1 }) : currentDate

            if (sundayReminder < currentDate.minus({ months: monthFilteringLength }).startOf("month").startOf("week").minus({ day: 1 })) {
              continue
            }

            if (
              !isPeriodCompleted &&
              sundayReminder.weekNumber < sundayCurrent.weekNumber &&
              sundayReminder.year === sundayCurrent.year &&
              (period.expected || period.worklogs.length)
            ) {
              weekNumber = sundayReminder.weekNumber

              if (isSpecial && period.worklogs.length) {
                const currentDateWorklog = period.worklogs.find((w) => {
                  const date = DateTime.fromISO(w.backDatedTo ?? w.workLogEnd ?? "")

                  if (!date) return false

                  if (date.monthShort === sundayReminder.monthShort) {
                    return true
                  }

                  return false
                })

                if (currentDateWorklog) {
                  const date = DateTime.fromISO(currentDateWorklog.backDatedTo ?? currentDateWorklog.workLogEnd ?? "")

                  weekNumber = (date.weekday === 7 ? date.plus({ day: 1 }) : date).weekNumber
                }
              }

              periodIndex = pI
              break
            }
          }

          break
        }
      }
    } else {
      let initialIndex = selectedListingIndex + 1 > listings.length - 1 ? 0 : selectedListingIndex + 1

      const hasDifferentSiteNext = listings.some((l, i) => {
        const hasPendings = l.servicePeriods.some((p) => {
          const periodReminderDate = DateTime.fromISO(p.reminderDate)
          const isPeriodCompleted =
            p.reviewComplete && !p.worklogs.some((w) => w.service?.approval_status?.toLowerCase() === "pending" && !w.duplicateWorklog)

          const sundayReminder = periodReminderDate.weekday === 7 ? periodReminderDate.plus({ day: 1 }) : periodReminderDate
          const sundayCurrent = currentDate.weekday === 7 ? currentDate.plus({ day: 1 }) : currentDate

          return (
            !isPeriodCompleted &&
            sundayReminder.weekNumber < sundayCurrent.weekNumber &&
            sundayReminder.year === sundayCurrent.year &&
            (p.expected || p.worklogs.length) &&
            sundayReminder > currentDate.minus({ months: monthFilteringLength }).startOf("month").startOf("week").minus({ day: 1 })
          )
        })

        return i >= initialIndex && l.siteId !== listings[selectedListingIndex].siteId && hasPendings
      })

      initialIndex = hasDifferentSiteNext ? initialIndex : 0

      if (initialIndex === 0 && validationState?.landNextPageToken) {
        setLoading(true)

        const customer =
          validationState?.currentFilter?.customer === "all"
            ? undefined
            : validationState.customerList?.find((s) => s.label === validationState?.currentFilter?.customer)?.id
        const servicePartner =
          validationState?.currentFilter?.servicePartner === "all"
            ? undefined
            : validationState.servicePartnerList?.find((s) => s.label === validationState?.currentFilter?.servicePartner)?.id
        const siteId =
          validationState?.currentFilter?.siteId?.toLowerCase() === "all"
            ? undefined
            : validationState.siteList?.find((s) => s.label === validationState?.currentFilter?.siteId)?.id

        const result = await getLandValidationListing(
          validationState?.user?.sfid,
          servicePartner,
          customer,
          siteId,
          validationState?.currentFilter?.showOnlyRequiresReview,
          validationState?.landSort?.by,
          validationState?.landSort?.order,
          20,
          validationState?.landNextPageToken
        )
        setLoading(false)

        initialIndex = listings.length

        listings.push(...(result?.items ?? []))

        newListingListInfo = { nextPageToken: result?.nextPageToken ?? undefined, listings }
      }

      for (let i = initialIndex; i <= listings.length - 1; i++) {
        const listing = listings[i]
        const selectedListing = listings[selectedListingIndex]
        const hasPendings = listing.servicePeriods.some((p) => {
          const periodReminderDate = DateTime.fromISO(p.reminderDate)
          const isPeriodCompleted =
            p.reviewComplete && !p.worklogs.some((w) => w.service?.approval_status?.toLowerCase() === "pending" && !w.duplicateWorklog)

          const sundayReminder = periodReminderDate.weekday === 7 ? periodReminderDate.plus({ day: 1 }) : periodReminderDate
          const sundayCurrent = currentDate.weekday === 7 ? currentDate.plus({ day: 1 }) : currentDate

          return (
            !isPeriodCompleted &&
            sundayReminder.weekNumber < sundayCurrent.weekNumber &&
            sundayReminder.year === sundayCurrent.year &&
            (p.expected || p.worklogs.length) &&
            sundayReminder > currentDate.minus({ months: monthFilteringLength }).startOf("month").startOf("week").minus({ day: 1 })
          )
        })
        const isSpecial =
          listing.serviceFrequency.toLowerCase() === "quarterly" ||
          listing.serviceFrequency.toLowerCase() === "monthly" ||
          listing.serviceFrequency.toLowerCase() === "annual"

        if (listing.siteId !== selectedListing.siteId && hasPendings) {
          newListing = listing

          for (let pI = 0; pI <= listing.servicePeriods.length - 1; pI++) {
            const period = listing.servicePeriods[pI]
            const periodReminderDate = DateTime.fromISO(period.reminderDate)
            const isPeriodCompleted =
              period.reviewComplete && !period.worklogs.some((w) => w.service?.approval_status?.toLowerCase() === "pending" && !w.duplicateWorklog)
            const sundayReminder = periodReminderDate.weekday === 7 ? periodReminderDate.plus({ day: 1 }) : periodReminderDate
            const sundayCurrent = currentDate.weekday === 7 ? currentDate.plus({ day: 1 }) : currentDate

            if (
              isSpecial &&
              !period.worklogs?.some((w) => {
                const date = DateTime.fromISO(w.backDatedTo ?? w.workLogEnd ?? "")
                const curDate = currentDate.minus({ months: monthFilteringLength }).startOf("month").startOf("week").minus({ day: 1 })

                return date?.monthShort === curDate.monthShort && date?.year === curDate.year
              })
            ) {
              continue
            }

            if (
              !isSpecial &&
              sundayReminder < currentDate.minus({ months: monthFilteringLength }).startOf("month").startOf("week").minus({ day: 1 })
            ) {
              continue
            }

            if (
              !isPeriodCompleted &&
              sundayReminder.weekNumber < sundayCurrent.weekNumber &&
              sundayReminder.year === sundayCurrent.year &&
              (period.expected || period.worklogs.length)
            ) {
              weekNumber = sundayReminder.weekNumber

              if (isSpecial && period.worklogs.length) {
                const currentDateWorklog = period.worklogs.find((w) => {
                  const date = DateTime.fromISO(w.backDatedTo ?? w.workLogEnd ?? "")

                  if (!date) return false

                  return date.monthShort === sundayReminder.monthShort
                })

                if (currentDateWorklog) {
                  const date = DateTime.fromISO(currentDateWorklog.backDatedTo ?? currentDateWorklog.workLogEnd ?? "")

                  weekNumber = (date.weekday === 7 ? date.plus({ day: 1 }) : date).weekNumber
                }
              }

              periodIndex = pI
              break
            }
          }

          break
        }
      }
    }

    if (!newListing || periodIndex === undefined || periodIndex < 0) return

    const period = newListing.servicePeriods[periodIndex]
    const { siteId, serviceKey, sfid } = newListing
    const reminderDate = DateTime.fromISO(period?.reminderDate ?? "")
    const monthName = reminderDate?.monthShort ?? ""
    const year = reminderDate?.year ?? currentDate.year

    const service = {
      periodIndex,
      siteId,
      serviceKey,
      sfid,
      weekNumber,
      monthName,
      year,
    }

    onListingNav({ item: newListing, service }, newListingListInfo)
  }

  useEffect(() => {
    setLandValidations(data)
  }, [data])

  useEffect(() => {
    setDateRange()
    // document.getElementById("mainHeader")?.focus()
  }, [landValidations])

  useEffect(() => {
    setDateRange(true)
  }, [monthFilteringLength])

  useEffect(() => {
    if (currentMonthRef.current) {
      currentMonthRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "center",
      })
    }
  }, [selectedService, dates])

  return (
    <div
      tabIndex={0}
      id="mainHeader"
      className="relative flex flex-1 flex-col overflow-auto focus:border-none focus:outline-none"
      onKeyDown={(e) => {
        if (e.key.toLowerCase() === "n") handleNav(false)
        if (e.key.toLowerCase() === "p") handleNav(true)
      }}
    >
      {isLoading && (
        <div className="absolute z-50 flex h-full w-full items-center justify-center bg-case-d-gray bg-opacity-50">
          <Loader />
        </div>
      )}

      {landValidations?.length && selectedService && dates?.months?.length ? (
        <div className="relative mt-2 select-none">
          <div className="mt-2 flex flex-1 flex-col">
            <div className="max-h-[40vh] w-full overflow-auto border">
              <table className="table-auto border-separate border-spacing-0">
                <thead className="sticky top-0 z-20">
                  {/* MONTH NAMES */}
                  <tr>
                    <th className="sticky left-0 z-20 border-b border-r bg-white px-4 text-right text-sm">Month/Year</th>
                    {dates?.months?.map((month, mIndex) => {
                      const monthIndex = DateTime.fromFormat(month.name, "LLL").month - 1
                      let haveWeekNumberConflict = false

                      if (monthIndex + 1 <= dates?.months?.length) {
                        const currentMonthLastWeekNumber = getWeekNumber(monthIndex, getNumberOfWeeksPerMonth(monthIndex, month.year) - 1, month.year)
                        const nextMonthFirstWeekNumber = getWeekNumber(monthIndex + 1, 1, month.year) - 1

                        haveWeekNumberConflict = currentMonthLastWeekNumber === nextMonthFirstWeekNumber
                      }

                      return (
                        <th
                          key={`${monthIndex}-${mIndex}`}
                          colSpan={
                            haveWeekNumberConflict
                              ? getNumberOfWeeksPerMonth(monthIndex, month.year) - 1
                              : getNumberOfWeeksPerMonth(monthIndex, month.year)
                          }
                          className={`border-b-2 border-r border-[#E0E0E0] bg-white px-4 py-2 text-center text-sm`}
                        >
                          {month.name}-{month.year}
                        </th>
                      )
                    })}
                  </tr>
                  {/* WEEK NUMBER */}
                  <tr>
                    <th className="sticky left-0 z-20 border-b border-r bg-white px-4 text-right text-sm">Week #</th>
                    {dates?.months?.map((month, mIndex) => {
                      const monthIndex = DateTime.fromFormat(month.name, "LLL").month - 1
                      let weekLength = [...Array(getNumberOfWeeksPerMonth(monthIndex, month.year)).keys()]

                      return (
                        <React.Fragment key={`${monthIndex}-${mIndex}`}>
                          {weekLength.map((_, weekIndex) => {
                            const weekNumber = getWeekNumber(monthIndex, _, month.year)

                            const isCurrentWeek = currentDate.plus({ day: 1 }).weekNumber === weekNumber && currentDate.year === month.year

                            return (
                              <th
                                key={`${monthIndex}-${weekIndex}`}
                                className={`bg-white text-center text-sm ${
                                  isCurrentWeek ? "border-l-2 border-l-[#FF3333]" : "border-l"
                                } border-b border-[#E0E0E0] px-4 py-2`}
                              >
                                {weekNumber}
                              </th>
                            )
                          })}
                        </React.Fragment>
                      )
                    })}
                  </tr>
                  {/* DATE OF WEEK */}
                  <tr>
                    <th className="sticky left-0 z-20 border-b border-r bg-white px-4 text-right text-sm">Start Date</th>
                    {dates?.months?.map((month, mIndex) => {
                      const monthIndex = DateTime.fromFormat(month.name, "LLL").month - 1
                      let weekLength = [...Array(getNumberOfWeeksPerMonth(monthIndex, month.year)).keys()]

                      return (
                        <React.Fragment key={`${monthIndex}-${mIndex}`}>
                          {weekLength.map((week, weekIndex) => {
                            const weekNumber = getWeekNumber(monthIndex, weekIndex, month.year)

                            const date = getDateByMonthAndWeekNumber(monthIndex, weekIndex, month.year)

                            const isCurrentWeek = currentDate.plus({ day: 1 }).weekNumber === weekNumber && currentDate.year === month.year

                            return (
                              <th
                                key={`${monthIndex}-${weekIndex}`}
                                className={`bg-white text-center text-sm ${
                                  isCurrentWeek ? "border-l-2 border-l-[#FF3333]" : "border-l"
                                }  border-b border-[#E0E0E0] px-4 py-2 font-normal`}
                              >
                                {date.toFormat("D")}
                              </th>
                            )
                          })}
                        </React.Fragment>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  <BodyComponent
                    landValidations={landValidations}
                    dates={dates}
                    selectedService={selectedService}
                    currentMonthRef={currentMonthRef}
                    setQuickNavSettings={(setting) => {
                      const settings = quickNavSettings
                      settings.push(setting)

                      // const uniqueServiceKeys = {} as any

                      // const uniqueArray = [...settings.reverse()].filter((obj) => {
                      //   if (!uniqueServiceKeys[obj.serviceKey]) {
                      //     uniqueServiceKeys[obj.serviceKey] = true
                      //     return true
                      //   }
                      //   return false
                      // })

                      const uniqueArray = settings.filter(
                        (thing, index, self) => index === self.findIndex((t) => t.serviceKey === thing.serviceKey && t.sfid === thing.sfid)
                      )

                      setTimeout(() => {
                        setQuickNavSettings(uniqueArray)
                      }, 200)
                    }}
                    onItemClick={(data) => {
                      const { periodIndex, weekNumber, weekIndex, item, year, monthName } = data
                      const exists = validationState?.validations?.find((v) => v.sfid === item.sfid)

                      setSelectedService({
                        periodIndex: periodIndex >= 0 ? periodIndex : weekIndex,
                        serviceKey: item.serviceKey,
                        sfid: item.sfid,
                        year,
                        monthName,
                        weekNumber,
                      })
                      setValidation({
                        ...validationState,
                        selectedValidation: undefined,
                      })
                      setTimeout(() => {
                        setValidation({
                          ...validationState,
                          selectedWeeks: [{ id: weekNumber, label: "" }],
                          selectedValidation: item,
                          selectedService: exists
                            ? {
                                periodIndex: periodIndex >= 0 ? periodIndex : weekIndex,
                                serviceKey: item.serviceKey,
                                sfid: item.sfid,
                                year,
                                monthName,
                                weekNumber,
                                siteId: item.siteId,
                              }
                            : validationState.selectedService,
                        })
                      }, 200)
                    }}
                  />
                </tbody>
              </table>
            </div>

            {validationState?.selectedValidation && (
              <div className="mt-5 flex flex-col" tabIndex={-1}>
                {
                  <PeriodRenderer
                    selectedService={selectedService}
                    handleReviewComplete={handleReviewComplete}
                    quickNavSettings={quickNavSettings}
                    onPeriodStatusUpdate={(newPeriods) => {
                      const newData = landValidations.map((item) => {
                        if (item.sfid === validationState?.selectedValidation?.sfid) {
                          item.servicePeriods = newPeriods
                        }

                        return item
                      })

                      setLandValidations(newData)
                    }}
                    handleBack={() => handleNav(true)}
                    handleNext={() => handleNav(false)}
                  />
                }
              </div>
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  )
}

const BodyComponent = ({
  landValidations,
  dates,
  selectedService,
  currentMonthRef,
  setQuickNavSettings,
  onItemClick,
}: {
  landValidations: LandValidation[]
  dates: { start: DateTime; end: DateTime; months: { name: string; year: number }[] }
  currentMonthRef: React.MutableRefObject<any>
  selectedService: {
    periodIndex: number
    serviceKey: string
    sfid: string
    weekNumber?: number
    year?: number
    monthName?: string
  } | null
  setQuickNavSettings: (v: QuickNavSettings) => void
  onItemClick: ({
    periodIndex,
    weekNumber,
    item,
    year,
    monthName,
  }: {
    periodIndex: number
    weekNumber: number
    weekIndex: number
    item: LandValidation
    year: number
    monthName: string
  }) => void
}) => {
  const setQuickNavSettingsCallback = useCallback(setQuickNavSettings, [setQuickNavSettings])

  const body = useMemo(
    () =>
      landValidations.map((item) => (
        <DataComponent
          item={item}
          dates={dates}
          selectedService={selectedService}
          currentMonthRef={currentMonthRef}
          setQuickNavSettings={setQuickNavSettingsCallback}
          onItemClick={onItemClick}
          key={item.sfid}
        />
      )),
    [dates, landValidations, selectedService]
  )

  return <>{body}</>
}

const DataComponent = ({
  item,
  dates,
  selectedService,
  currentMonthRef,
  setQuickNavSettings,
  onItemClick,
}: {
  item: LandValidation
  dates: { start: DateTime; end: DateTime; months: { name: string; year: number }[] }
  currentMonthRef: React.MutableRefObject<any>
  selectedService: {
    periodIndex: number
    serviceKey: string
    sfid: string
    weekNumber?: number
  } | null
  setQuickNavSettings: (v: QuickNavSettings) => void
  onItemClick: ({
    periodIndex,
    weekNumber,
    item,
    year,
    monthName,
  }: {
    periodIndex: number
    weekNumber: number
    weekIndex: number
    item: LandValidation
    year: number
    monthName: string
  }) => void
}) => {
  const tableRowElement = () => {
    const quickNavSettingsTemp: QuickNavSettings = { serviceKey: item.serviceKey, sfid: item.sfid, siteId: item.siteId, data: [] }

    const element = (
      <tr className="select-none">
        <th className="sticky left-0 z-10 h-[80px] whitespace-nowrap border-b border-r bg-white px-4 text-start text-sm font-bold text-case-grad-from">
          {item.serviceKey}
          <span className="whitespace-pre-line text-xs font-normal text-[#000000]">{`\n(${item.serviceFrequency})`}</span>
        </th>
        {dates?.months?.map((month, mIndex) => {
          const monthIndex = DateTime.fromFormat(month.name, "LLL").month - 1
          let weekLength = [...Array(getNumberOfWeeksPerMonth(monthIndex, month.year)).keys()]

          return (
            <React.Fragment key={`${monthIndex} - ${mIndex}`}>
              {weekLength.map((week, weekIndex) => {
                const weekNumber = getWeekNumber(monthIndex, weekIndex, month.year)

                const isCurrentWeek = currentDate.plus({ day: 1 }).weekNumber === weekNumber && currentDate.year === month.year

                let periodIndex = -1

                const period = item?.servicePeriods?.find((sp, index) => {
                  const startDate = DateTime.fromISO(sp.startDate)
                  const endDate = DateTime.fromISO(sp.endDate)
                  const interval = Interval.fromDateTimes(startDate, endDate)

                  const oneWeek = Duration.fromObject({
                    weeks: 1,
                  })

                  const weeksArray = interval.splitBy(oneWeek).map((chunk) => {
                    const startOfWeek = chunk.start.startOf("week")
                    const endOfWeek = chunk.end.endOf("week")

                    return { startOfWeek, endOfWeek }
                  })

                  const exists = weeksArray.some(
                    (w) => w.endOfWeek.weekNumber === weekNumber && w.endOfWeek.year === month.year && w.endOfWeek.monthShort === month.name
                  )

                  if (exists) {
                    periodIndex = index
                  }

                  return exists
                })

                const isInContract = period?.expected ?? period?.weekNumber === weekNumber ?? false

                if (!period) {
                  return (
                    <th
                      key={`${monthIndex}-${weekIndex}`}
                      className={`text-center ${isInContract ? "bg-case-grad-from bg-opacity-25" : ""} ${
                        isCurrentWeek ? "border-l-2 border-l-[#FF3333]" : "border-l"
                      }  min-w-[80px] border-b border-[#E0E0E0] px-4`}
                    ></th>
                  )
                }

                const worklogs =
                  period.worklogs?.filter((w) => {
                    const date = w.backDatedTo ?? w.workLogEnd ?? ""
                    let timestamp = DateTime.fromISO(date)

                    if (isNaN(timestamp.weekNumber)) {
                      timestamp = DateTime.fromISO(date?.substring(0, date?.lastIndexOf("-")))
                    }

                    return (
                      (timestamp?.weekday === 7 ? timestamp?.plus({ day: 1 }) : timestamp)?.weekNumber === weekNumber &&
                      !w.duplicateWorklog &&
                      timestamp.year === month.year
                    )
                  }) ?? []

                const isSpecial =
                  item.serviceFrequency.toLowerCase() === "quarterly" ||
                  item.serviceFrequency.toLowerCase() === "monthly" ||
                  item.serviceFrequency.toLowerCase() === "annual"

                let periods: ServicePeriod[] = []

                const getStatusColor = () => {
                  if (isSpecial) {
                    const date = DateTime.fromObject({ weekYear: month.year, weekNumber }).minus({ day: 1 })

                    if (date.day !== 1 && month.name !== date.monthShort) {
                      const result = item.servicePeriods.filter((sp) => sp.worklogs.length)

                      periods = result
                        .map((p) => ({
                          ...p,
                          worklogs:
                            p.worklogs.filter((w) => {
                              const date = DateTime.fromISO(w.backDatedTo ?? w.workLogEnd ?? "")

                              if (!date || w.duplicateWorklog) return false

                              return (date?.weekday === 7 ? date?.plus({ day: 1 }) : date)?.weekNumber === weekNumber
                            }) ?? [],
                        }))
                        .filter((p) => !!p.worklogs.length)

                      if (periods.length > 1) {
                        if (
                          periods.some(
                            (p) =>
                              p.worklogs.some(
                                (w) =>
                                  w.service?.approval_status?.toLowerCase() !== "cancelled" &&
                                  w?.service?.approval_status?.toLowerCase() !== "approved"
                              ) || !p.reviewComplete
                          )
                        ) {
                          return LandStatusColor.Validation
                        }

                        if (
                          periods.some((p) => p.worklogs.some((w) => w.service?.approval_status?.toLowerCase() === "approved") && p.reviewComplete)
                        ) {
                          return LandStatusColor.GreenStar
                        }

                        if (
                          periods.some((p) => !p.worklogs.some((w) => w.service?.approval_status?.toLowerCase() === "approved") && p.reviewComplete)
                        ) {
                          return LandStatusColor.RedStar
                        }
                      }
                    }
                  }

                  if (
                    currentDate > DateTime.fromISO(period?.reminderDate ?? "") &&
                    (period?.actualServiceCount ?? 0) < (period?.expectedServiceCount ?? 0) &&
                    currentDate < DateTime.fromISO(period?.endDate ?? "") &&
                    weekNumber >= currentDate.plus({ day: 1 }).weekNumber &&
                    month.year === currentDate.plus({ day: 1 }).year
                  ) {
                    return LandStatusColor.ReminderBang
                  }

                  if (
                    ((period?.actualServiceCount ?? 0) < (period?.expectedServiceCount ?? 0) ||
                      ((period?.actualServiceCount ?? 0) === 0 && (period?.expectedServiceCount ?? 0) === 0)) &&
                    currentDate < DateTime.fromISO(period?.reminderDate ?? "")
                  ) {
                    if (weekNumber < currentDate.plus({ day: 1 }).weekNumber && month.year === currentDate.plus({ day: 1 }).year) {
                      return LandStatusColor.Missing
                    }

                    return LandStatusColor.Reminder
                  }

                  if (!worklogs.length) {
                    if (period?.reviewComplete) {
                      return LandStatusColor.RedOutlinedStar
                    }

                    if (DateTime.fromISO(period.reminderDate ?? period.endDate) > currentDate) {
                      return LandStatusColor.Reminder
                    }

                    if (currentDate > DateTime.fromISO(period?.reminderDate ?? "") || (period?.expectedServiceCount ?? 0) > 0) {
                      return LandStatusColor.Missing
                    }

                    if (
                      weekNumber > currentDate.weekNumber &&
                      currentDate > DateTime.fromISO(period?.endDate ?? "") &&
                      weekNumber < currentDate.weekNumber
                    ) {
                      return LandStatusColor.Missing
                    }
                  } else {
                    if (
                      !worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "pending") &&
                      worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "approved") &&
                      item.servicePeriods.find((sp) => sp.worklogs?.some((w) => worklogs.some((wl) => wl.sfid === w.sfid)))?.reviewComplete
                    ) {
                      return LandStatusColor.GreenStar
                    }

                    if (
                      !worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "pending") &&
                      !worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "approved") &&
                      !item.servicePeriods.find((sp) => sp.worklogs?.some((w) => worklogs.some((wl) => wl.sfid === w.sfid)))?.reviewComplete
                    ) {
                      return LandStatusColor.ApprovedRed
                    }

                    if (
                      !worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "pending") &&
                      !worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "approved")
                    ) {
                      return LandStatusColor.RedStar
                    }

                    if (
                      !worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "pending") &&
                      worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "approved")
                    ) {
                      return LandStatusColor.Approved
                    }

                    if (!worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "pending")) {
                      return LandStatusColor.Approved
                    }

                    if (
                      worklogs.some(
                        (w) =>
                          DateTime.fromISO(w.backDatedTo ?? w.workLogEnd ?? "") > DateTime.fromISO(period?.reminderDate ?? "") &&
                          w.service?.algorithm_status?.toLowerCase() === "approved" &&
                          (w.distance_from_site ?? 0) < MIN_DISTANCE_FROM_SITE
                      )
                    ) {
                      return worklogs.some((w) => w.service?.approval_status?.toLowerCase() === "cancelled")
                        ? LandStatusColor.ApprovedCancelled
                        : LandStatusColor.ApprovedCheck
                    }
                  }

                  return LandStatusColor.Validation
                }

                const statusColor = getStatusColor()
                const isSelected =
                  selectedService?.periodIndex === (periodIndex >= 0 ? periodIndex : weekIndex) &&
                  selectedService?.serviceKey === item.serviceKey &&
                  selectedService?.weekNumber === weekNumber

                if (!worklogs.length) {
                  const reminder = DateTime.fromISO(period.reminderDate)

                  if (
                    (reminder.weekday === 7 ? reminder.plus({ day: 1 }) : reminder).weekNumber === weekNumber &&
                    reminder.year === month.year &&
                    period.expected &&
                    !period.worklogs?.length
                  ) {
                    if (!period.reviewComplete) {
                      const isReminder =
                        (((period?.actualServiceCount ?? 0) < (period?.expectedServiceCount ?? 0) ||
                          ((period?.actualServiceCount ?? 0) === 0 && (period?.expectedServiceCount ?? 0) === 0)) &&
                          currentDate < DateTime.fromISO(period?.reminderDate ?? "")) ||
                        (currentDate > DateTime.fromISO(period?.reminderDate ?? "") &&
                          (period?.actualServiceCount ?? 0) < (period?.expectedServiceCount ?? 0) &&
                          currentDate < DateTime.fromISO(period?.endDate ?? "") &&
                          weekNumber >= currentDate.plus({ day: 1 }).weekNumber &&
                          month.year === currentDate.plus({ day: 1 }).year)

                      if (!isReminder) {
                        quickNavSettingsTemp.data.push({
                          reviewComplete: period.reviewComplete ?? false,
                          periodIndex,
                          weekNumber,
                          monthName: month.name,
                          year: month.year,
                        })
                      }
                    }

                    return (
                      <th
                        key={`${monthIndex}-${weekIndex}`}
                        ref={isSelected ? currentMonthRef : null}
                        className={`whitespace-nowrap border-b 
          border-[#E0E0E0] px-4 text-center text-sm ${isInContract ? "bg-case-grad-from bg-opacity-25" : ""}
          ${
            isSelected
              ? "border-l border-case-grad-from bg-case-grad-from bg-opacity-100 text-white"
              : isCurrentWeek
              ? "border-l-2 border-l-[#FF3333]"
              : "border-l"
          }`}
                      >
                        <div className="flex flex-col items-center pt-1">
                          <label className="whitespace-nowrap">
                            {isSpecial && periods.length > 1 ? "*" : period?.actualServiceCount ?? 0} /{" "}
                            {isSpecial && periods.length > 1 ? "*" : period?.expectedServiceCount}
                          </label>
                          <Tooltip
                            tabIndex={-1}
                            title={
                              weekNumber >= currentDate.plus({ day: 1 }).weekNumber && period?.reminderDate
                                ? DateTime.fromISO(period?.reminderDate).toFormat("D")
                                : `${period?.actualServiceCount ?? 0} WorkOrders`
                            }
                          >
                            <IconButton
                              onClick={() => onItemClick({ weekIndex, weekNumber, periodIndex, item, year: month.year, monthName: month.name })}
                            >
                              <LandChartStatusIconComponent statusColor={statusColor} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </th>
                    )
                  }

                  if (
                    period.expectedServiceCount > 0 &&
                    period.worklogs.some(
                      (w) =>
                        DateTime.fromISO(w.backDatedTo ?? w.workLogEnd ?? "")?.weekNumber === weekNumber &&
                        DateTime.fromISO(w.backDatedTo ?? w.workLogEnd ?? "")?.year === month.year
                    ) &&
                    !isSpecial
                  ) {
                    quickNavSettingsTemp.data.push({
                      reviewComplete: period.reviewComplete ?? false,
                      periodIndex,
                      weekNumber,
                      monthName: month.name,
                      year: month.year,
                    })

                    return (
                      <th
                        key={`${monthIndex}-${weekIndex}`}
                        ref={isSelected ? currentMonthRef : null}
                        className={`whitespace-nowrap border-b 
      border-[#E0E0E0] px-4 text-center text-sm ${isInContract ? "bg-case-grad-from bg-opacity-25" : ""}
      ${
        isSelected
          ? "border-l border-case-grad-from bg-case-grad-from bg-opacity-100 text-white"
          : isCurrentWeek
          ? "border-l-2 border-l-[#FF3333]"
          : "border-l"
      }`}
                      >
                        <div className="flex flex-col items-center pt-1">
                          <label className="whitespace-nowrap">
                            {isSpecial && periods.length > 1 ? "*" : period?.actualServiceCount ?? 0} /{" "}
                            {isSpecial && periods.length > 1 ? "*" : period?.expectedServiceCount}
                          </label>
                          <Tooltip
                            tabIndex={-1}
                            title={
                              weekNumber >= currentDate.plus({ day: 1 }).weekNumber && period?.reminderDate
                                ? DateTime.fromISO(period?.reminderDate).toFormat("D")
                                : `${period?.actualServiceCount ?? 0} WorkOrders`
                            }
                          >
                            <IconButton
                              onClick={() => onItemClick({ weekIndex, weekNumber, periodIndex, item, year: month.year, monthName: month.name })}
                            >
                              <LandChartStatusIconComponent statusColor={statusColor} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </th>
                    )
                  }

                  if (
                    !isSpecial &&
                    item.serviceFrequency !== "Once" &&
                    (period.expectedServiceCount > 0 ||
                      period.worklogs.some(
                        (w) =>
                          DateTime.fromISO(w.backDatedTo ?? w.workLogEnd ?? "")?.weekNumber === weekNumber &&
                          DateTime.fromISO(w.backDatedTo ?? w.workLogEnd ?? "")?.year === month.year
                      ))
                  ) {
                    return (
                      <th
                        key={`${monthIndex}-${weekIndex}`}
                        ref={isSelected ? currentMonthRef : null}
                        className={`whitespace-nowrap border-b 
    border-[#E0E0E0] px-4 text-center text-sm ${isInContract ? "bg-case-grad-from bg-opacity-25" : ""}
    ${
      isSelected
        ? "border border-case-grad-from bg-case-grad-from bg-opacity-100 text-white"
        : isCurrentWeek
        ? "border-l-2 border-l-[#FF3333]"
        : "border-l"
    }`}
                      >
                        <div className="flex flex-col items-center pt-1">
                          <label className="whitespace-nowrap">
                            {period?.actualServiceCount} / {period?.expectedServiceCount}
                          </label>
                          <Tooltip
                            tabIndex={-1}
                            title={
                              weekNumber >= currentDate.plus({ day: 1 }).weekNumber && period?.reminderDate
                                ? DateTime.fromISO(period?.reminderDate).toFormat("D")
                                : `${period?.actualServiceCount} WorkOrders`
                            }
                          >
                            <IconButton
                              onClick={async () =>
                                await onItemClick({ periodIndex, weekIndex, weekNumber, item, year: month.year, monthName: month.name })
                              }
                            >
                              <LandChartStatusIconComponent statusColor={statusColor} isSelected={isSelected} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </th>
                    )
                  }

                  return (
                    <th
                      key={`${monthIndex}-${weekIndex}`}
                      className={`text-center ${isInContract ? "bg-case-grad-from bg-opacity-25" : ""} ${
                        isCurrentWeek ? "border-l-2 border-l-[#FF3333]" : "border-l"
                      }  min-w-[80px] border-b border-[#E0E0E0] px-4`}
                    ></th>
                  )
                }

                if (
                  !period.reviewComplete ||
                  worklogs.some(
                    (w) =>
                      w?.service?.approval_status?.toLowerCase() === "pending" ||
                      (w?.service?.approval_status?.toLowerCase() !== "approved" && w?.service?.approval_status?.toLowerCase() !== "cancelled")
                  )
                ) {
                  const isReminder =
                    (((period?.actualServiceCount ?? 0) < (period?.expectedServiceCount ?? 0) ||
                      ((period?.actualServiceCount ?? 0) === 0 && (period?.expectedServiceCount ?? 0) === 0)) &&
                      currentDate < DateTime.fromISO(period?.reminderDate ?? "")) ||
                    (currentDate > DateTime.fromISO(period?.reminderDate ?? "") &&
                      (period?.actualServiceCount ?? 0) < (period?.expectedServiceCount ?? 0) &&
                      currentDate < DateTime.fromISO(period?.endDate ?? "") &&
                      weekNumber >= currentDate.plus({ day: 1 }).weekNumber &&
                      month.year === currentDate.plus({ day: 1 }).year)

                  if (!isReminder) {
                    quickNavSettingsTemp.data.push({
                      reviewComplete: period.reviewComplete ?? false,
                      periodIndex,
                      weekNumber,
                      monthName: month.name,
                      year: month.year,
                    })
                  }
                }

                return (
                  <th
                    key={`${monthIndex}-${weekIndex}`}
                    ref={isSelected ? currentMonthRef : null}
                    className={`whitespace-nowrap border-b 
border-[#E0E0E0] px-4 text-center text-sm ${isInContract ? "bg-case-grad-from bg-opacity-25" : ""}
${
  isSelected
    ? "border-l border-case-grad-from bg-case-grad-from bg-opacity-100 text-white"
    : isCurrentWeek
    ? "border-l-2 border-l-[#FF3333]"
    : "border-l"
}`}
                  >
                    <div className="flex flex-col items-center pt-1">
                      {
                        <label className="whitespace-nowrap">
                          {isSpecial && periods.length > 1 ? "*" : period?.actualServiceCount ?? 0} /{" "}
                          {isSpecial && periods.length > 1 ? "*" : period?.expectedServiceCount ?? 0}
                        </label>
                      }
                      <Tooltip
                        tabIndex={-1}
                        title={
                          weekNumber >= currentDate.plus({ day: 1 }).weekNumber && period?.reminderDate
                            ? DateTime.fromISO(period?.reminderDate).toFormat("D")
                            : `${period?.actualServiceCount ?? 0} WorkOrders`
                        }
                      >
                        <IconButton
                          onClick={() => onItemClick({ weekIndex, weekNumber, periodIndex, item, year: month.year, monthName: month.name })}
                        >
                          <LandChartStatusIconComponent statusColor={statusColor} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </th>
                )
              })}
            </React.Fragment>
          )
        })}
      </tr>
    )

    setQuickNavSettings(quickNavSettingsTemp)

    return element
  }

  return tableRowElement()
}

type PeriodProps = {
  selectedService: {
    periodIndex: number
    serviceKey: string
    sfid: string
    weekNumber?: number | undefined
    monthName?: string
    year?: number
  } | null
  quickNavSettings: QuickNavSettings[]
  handleReviewComplete: (period?: ServicePeriod, sfid?: string, week?: number) => void
  handleBack: () => void
  handleNext: () => void
  onPeriodStatusUpdate: ((periods: ServicePeriod[]) => void) | undefined
}

const PeriodRenderer = ({ selectedService, handleReviewComplete, onPeriodStatusUpdate, handleNext, handleBack }: PeriodProps) => {
  const weekNumber = selectedService?.weekNumber ?? 0
  const { validationState } = useContext(ValidationContext)

  const selectedValidation = validationState.selectedValidation as LandValidation

  if (!selectedValidation) return <></>

  const period = selectedValidation.servicePeriods.find((sp) => {
    const interval = Interval.fromDateTimes(DateTime.fromISO(sp.startDate), DateTime.fromISO(sp.endDate))

    const oneWeek = Duration.fromObject({
      weeks: 1,
    })

    const weeksArray = interval.splitBy(oneWeek).map((chunk) => {
      const startOfWeek = chunk.start.startOf("week")
      const endOfWeek = chunk.end.endOf("week")

      return { startOfWeek, endOfWeek }
    })

    return weeksArray.some((w) => w.startOfWeek.weekNumber === weekNumber || w.endOfWeek.weekNumber === weekNumber)
  })

  if (!period) return <></>

  const periodWithWorklogs = {
    ...period,
    worklogs: period?.worklogs?.filter((w) => {
      const timestamp = DateTime.fromISO(w?.backDatedTo ?? w?.workLogEnd ?? "")

      return (timestamp?.weekday === 7 ? timestamp?.plus({ day: 1 }) : timestamp)?.weekNumber === weekNumber && !w.duplicateWorklog
    }),
    // worklogs:
    //   validationState?.selectedValidation?.servicePeriods
    //     ?.filter((sp: ServicePeriod) => sp.worklogs.length)
    //     .map((sp: ServicePeriod) => sp.worklogs)
    //     .flat()
    //     .filter((w: LandWorklog) => {
    //       const date = DateTime.fromISO(w.backDatedTo ?? w.workLogEnd ?? "")

    //       if (!date || w.duplicateWorklog) return false

    //       if (validationState?.selectedValidation?.serviceFrequency?.toLowerCase() === "quarterly") {
    //         return (
    //           date.quarter === period.quarterNumber &&
    //           (selectedService?.monthName === date.monthShort || (date.weekNumber === weekNumber && date.year === selectedService?.year))
    //         )
    //       }

    //       return (date?.weekday === 7 ? date?.plus({ day: 1 }) : date)?.weekNumber === weekNumber
    //     }) ?? [],
  }

  if (periodWithWorklogs) {
    const havePending =
      periodWithWorklogs.worklogs?.some(
        (w: LandWorklog) => w.service?.approval_status?.toLowerCase() !== "cancelled" && w.service?.approval_status?.toLowerCase() !== "approved"
      ) ||
      period.worklogs?.some(
        (w: LandWorklog) => w.service?.approval_status?.toLowerCase() !== "cancelled" && w.service?.approval_status?.toLowerCase() !== "approved"
      )

    if (
      !havePending &&
      !periodWithWorklogs.reviewComplete &&
      periodWithWorklogs.worklogs?.length &&
      periodWithWorklogs.worklogs?.length >= periodWithWorklogs.expectedServiceCount
    ) {
      handleReviewComplete(periodWithWorklogs)

      return <></>
    }

    const isSpecial =
      selectedValidation.serviceFrequency.toLowerCase() === "quarterly" ||
      selectedValidation.serviceFrequency.toLowerCase() === "monthly" ||
      selectedValidation.serviceFrequency.toLowerCase() === "annual"

    if (isSpecial) {
      const frequency =
        selectedValidation.serviceFrequency === "Monthly" ? "Month" : selectedValidation.serviceFrequency === "Quarterly" ? "Quarter" : "Year"

      const date = DateTime.fromObject({ weekYear: selectedService?.year, weekNumber }).minus({ day: 1 })

      if (date.day !== 1 && selectedService?.monthName !== date.monthShort) {
        const result = selectedValidation.servicePeriods.filter((sp) => sp.worklogs.length)

        if (!result) return <></>

        const periods = result
          .map((p) => ({
            ...p,
            worklogs:
              p.worklogs.filter((w) => {
                const date = DateTime.fromISO(w.backDatedTo ?? w.workLogEnd ?? "")

                if (!date || w.duplicateWorklog) return false

                return (date?.weekday === 7 ? date?.plus({ day: 1 }) : date)?.weekNumber === weekNumber
              }) ?? [],
          }))
          .filter((p) => !!p.worklogs.length)

        return (
          <>
            {periods.map((p, i) => {
              const isDisabled = (p.worklogs.some((w) => w.service?.approval_status?.toLowerCase() === "pending") || p.reviewComplete) ?? false

              return (
                <React.Fragment key={i}>
                  <div className={`flex select-none flex-row items-center justify-between ${periods.length > 1 && i !== 0 ? "mt-4" : ""}`}>
                    <div className="flex flex-col gap-4 pl-2 font-bold">
                      {i === 0 && (
                        <div className="flex flex-1 flex-col">
                          <span className="text-start text-lg font-bold text-case-grad-from underline">{selectedService?.serviceKey}</span>
                          <span className="text-start text-base">{weekOptions[weekNumber - 1]?.label}</span>
                        </div>
                      )}

                      {periods.length > 1 && (
                        <span className="w-min whitespace-nowrap rounded-md bg-case-grad-from px-4 text-start text-xl text-white">
                          {/* MISSING YEAR FREQUENCY... BOBO */}
                          {frequency} {frequency === "Quarter" ? p.quarterNumber : p.monthNumber}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-1 flex-row items-center justify-end gap-2">
                      {i === 0 && (
                        <button
                          tabIndex={-1}
                          className="rounded-full bg-case-grad-from p-2 text-white shadow-lg hover:bg-case-grad-mid"
                          onClick={handleBack}
                        >
                          <ArrowBackIcon />
                        </button>
                      )}
                      <button
                        tabIndex={-1}
                        className={`rounded-full ${
                          p.reviewComplete ? "bg-[#39CB73]" : "bg-case-grad-from"
                        } px-4 py-2 font-bold text-white shadow-lg transition duration-200 ease-in-out hover:bg-opacity-90 disabled:bg-gray-bid-dates disabled:text-[#868686] ${
                          i > 0 ? "mx-8" : ""
                        }`}
                        disabled={isDisabled}
                        onClick={() => handleReviewComplete(p)}
                      >
                        {p.reviewComplete ? "Reviewed" : "Mark as Reviewed"}
                      </button>
                      {i === 0 && (
                        <button
                          tabIndex={-1}
                          className="rounded-full bg-case-grad-from p-2 text-white shadow-lg hover:bg-case-grad-mid"
                          onClick={handleNext}
                        >
                          <ArrowForward />
                        </button>
                      )}
                    </div>
                  </div>
                  <PeriodCardComponent selectedService={selectedService} period={p} onStatusUpdate={onPeriodStatusUpdate} />
                </React.Fragment>
              )
            })}
          </>
        )
      }
    }

    const isDisabled =
      (periodWithWorklogs.worklogs.some((w) => w.service?.approval_status?.toLowerCase() === "pending") || periodWithWorklogs.reviewComplete) ?? false

    return (
      <>
        <div className="flex select-none flex-row items-center justify-between">
          <div className="flex flex-1 flex-col pl-2 font-bold">
            <span className="text-start text-lg font-bold text-case-grad-from underline">{selectedService?.serviceKey}</span>
            <span className="text-start text-base">{weekOptions[weekNumber - 1]?.label}</span>
          </div>
          <div className="flex flex-1 flex-row items-center justify-end gap-2">
            <button className="rounded-full bg-case-grad-from p-2 text-white shadow-lg hover:bg-case-grad-mid" onClick={handleBack}>
              <ArrowBackIcon />
            </button>
            <button
              className={`rounded-full ${
                periodWithWorklogs.reviewComplete ? "bg-[#39CB73]" : "bg-case-grad-from"
              } px-4 py-2 font-bold text-white shadow-lg transition duration-200 ease-in-out hover:bg-opacity-90 disabled:bg-gray-bid-dates disabled:text-[#868686]`}
              disabled={isDisabled}
              onClick={() => handleReviewComplete(periodWithWorklogs)}
            >
              {periodWithWorklogs.reviewComplete ? "Reviewed" : "Mark as Reviewed"}
            </button>
            <button className="rounded-full bg-case-grad-from p-2 text-white shadow-lg hover:bg-case-grad-mid" onClick={handleNext}>
              <ArrowForward />
            </button>
          </div>
        </div>
        <PeriodCardComponent selectedService={selectedService} period={periodWithWorklogs} onStatusUpdate={onPeriodStatusUpdate} />
      </>
    )
  }

  return (
    <>
      {/* <div className="flex flex-col pl-2 font-bold">
        <span className="text-start text-lg font-bold text-case-grad-from underline">{selectedService?.serviceKey}</span>
        <div className="flex flex-row items-center justify-between">
          <span className="text-start text-base">{weekOptions[(validationState?.selectedWeeks?.[0]?.id ?? 1) - 1]?.label}</span>
          <div className="flex flex-1 flex-row items-center justify-end gap-10">
            <button
              className={`rounded-full ${
                validationState?.selectedValidation?.servicePeriods?.find(
                  (p: ServicePeriod) => DateTime.fromISO(p.endDate).weekNumber === (validationState?.selectedWeeks?.[0]?.id ?? 1)
                )?.reviewComplete
                  ? "bg-[#39CB73]"
                  : "bg-case-grad-from"
              } px-4 py-2 font-bold text-white shadow-lg transition duration-200 ease-in-out hover:bg-opacity-90 disabled:bg-gray-bid-dates disabled:text-[#868686]`}
              onClick={() => {
                const index = validationState?.selectedValidation?.servicePeriods?.findIndex(
                  (p: ServicePeriod) => DateTime.fromISO(p.endDate).weekNumber === (selectedService?.weekNumber ?? 0)
                )

                handleReviewComplete(undefined, selectedService?.sfid, index >= 0 ? index : selectedService?.periodIndex)
              }}
            >
              Mark as{" "}
              {validationState?.selectedValidation?.servicePeriods?.find(
                (p: ServicePeriod) => DateTime.fromISO(p.endDate).weekNumber === (selectedService?.weekNumber ?? 0)
              )?.reviewComplete
                ? "Pending"
                : "Reviewed"}
            </button>
          </div>
        </div>
      </div>
      <PeriodCardComponent period={{} as any} selectedService={selectedService} /> */}
    </>
  )
}

export default LandValidationTable
