import { useEffect, useState } from "react"
import {
  makeStyles,
  createStyles,
  Container,
  Grid,
  Divider,
} from "@material-ui/core"
import Typography from "@mui/material/Typography"
import Map from "../../../common/googleMap"

function ProfileTab(props: any) {
  const classes = useStyles()
  const [error, setError] = useState("")
  const [partner, setPartner] = useState<any>({})
  const [user, setUSer] = useState<any>({})

  useEffect(() => {
    setPartner(props?.partner)
    setUSer(props?.user)
  }, [props])

  if (error) {
    return <p>ERROR: {error}</p>
  } else {
    return (
      <>
        <div className={classes.listContainer}>
          <div className={classes.grid}>
            <Container className={classes.container}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography>
                    <div className={classes.titleFont}>Profile</div>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography>
                    <div className={classes.subtitleFont}>Personal Info</div>
                  </Typography>
                  <Typography>
                    <div className={classes.subtitleIndicator}>
                      Provide your personal and contact information
                    </div>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.labelFont}>First Name</div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.inputFont}>
                          {partner?.first_name || "-"}
                        </div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.labelFont}>Last Name</div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.inputFont}>
                          {partner?.last_name || "-"}
                        </div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.labelFont}>Phone Number</div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.inputFont}>
                          {partner?.primary_field_poc_cell || "-"}
                        </div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.labelFont}>Email</div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.inputFont}>
                          {partner?.primary_field_poc_email || "-"}
                        </div>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.divider}>
                  <Divider variant="middle" />
                </Grid>

                <Grid item xs={12}>
                  <Typography>
                    <div className={classes.subtitleFont}>Company Info</div>
                  </Typography>
                  <Typography>
                    <div className={classes.subtitleIndicator}>
                      Provide your company information
                    </div>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.labelFont}>Company Name</div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.inputFont}>
                          {partner?.name || "-"}
                        </div>
                      </Typography>
                      {partner?.billinglatitude && (
                        <div className="relative mb-4">
                          <Map
                            center={{
                              lat: partner?.billinglatitude,
                              lng: partner?.billinglongitude,
                            }}
                            principalMarkers={[
                              {
                                id: 0,
                                name: partner?.name,
                                lat: partner?.billinglatitude,
                                lng: partner?.billinglongitude,
                              },
                            ]}
                            siteMarkers={[]}
                            pendingBidMarkers={[]}
                            openBidMarkers={[]}
                            isLoaded={true}
                            maxHeight={"200px"}
                            setMarker={true}
                            defaultMarker={0}
                            zoom={11}
                          />
                        </div>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.labelFont}>Company Address</div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.inputFont}>
                          {partner?.billingstreet}
                          {", "}
                          {partner?.billingcity}
                          {", "}
                          {partner?.billingstate}
                          {", "}
                          {partner?.billingpostalcode}
                        </div>
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.labelFont}>Company Website</div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.inputFont}>
                          {partner?.website || "-"}
                        </div>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </>
    )
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      width: "auto",
      marginLeft: 0,
      padding: "10px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      background: "#FFFFFF",
      cursor: "pointer",
    },
    container: {
      maxWidth: "100%",
      padding: "20px",
    },
    titleFont: {
      fontSize: "25px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#0F2150",
      marginBottom: "20px",
    },
    subtitleFont: {
      fontSize: "20px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#0F2150",
    },
    subtitleIndicator: {
      fontSize: "15px",
      color: "gray",
      letterSpacing: "-1px",
      marginBottom: "20px",
    },
    labelFont: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "113%",
      color: "#0F2150",
    },
    inputFont: {
      marginBottom: "20px",
    },
    divider: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  })
)

export default ProfileTab
