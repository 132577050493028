import { useState } from "react"
import { makeStyles, createStyles, Typography } from "@material-ui/core"
import BidsLists from "./BidsList"
import { BidsFilters } from "./BidsFilter"
import useI18n from "../../../hooks/useI18n"

function BidsMobile(props: any) {
  const classes = useStyles()
  const { i18n } = useI18n();
  const [search, searchInput] = useState("")
  const [filter, setFilter] = useState<any>({})

  return (
    <>
      <div className="h-fit h-min-40 bg-gradient-to-b from-case-grad-mid to-case-grad-to-mobile">
        <div className={classes.secondaryTitleMobile}>
          <Typography className={classes.titleFontMobile}>
            {i18n.t("breadcrumbs.bidListing")}
          </Typography>
        </div>
      </div>
      <div className="bg-gray min-h-screen">
        <BidsFilters filter={filter} setFilter={setFilter} />
        <BidsLists {...props} search={search} filter={filter} />
      </div>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    secondaryTitleMobile: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "100vw",
    },
    titleFontMobile: {
      fontSize: "44px",
      fontWeight: 600,
      lineHeight: "113%",
      color: "white",
      letterSpacing: "-1px",
      marginLeft: "20px",
      marginBottom: "20px",
      marginTop: "21px",
    },
  })
)

export default BidsMobile
