import { useCallback, useEffect, useRef, useState } from "react"
import { makeStyles, createStyles, Grid, Button, Modal, Select, MenuItem } from "@material-ui/core"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import InputAdornment from "@mui/material/InputAdornment"
import AssessmentIcon from "@mui/icons-material/Assessment"
import CloseIcon from "@mui/icons-material/Close"
import Datepicker from "react-tailwindcss-datepicker"
import { CSVLink } from "react-csv"
import { DateTime } from "luxon"
import { Rings } from "react-loader-spinner"
import Autocomplete from "@mui/material/Autocomplete"
import { useDebounce } from "use-debounce"

import { ReactComponent as SearchIcon } from "./../../../assets/search.svg"
import useI18n from "../../../hooks/useI18n"
import { useActiveUser } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"
import { AccountResponse } from "../../Validations/interfaces"
import { formatDate, getMinFormat } from "../../../utils/Util"

// const currentDateTime = DateTime.now()

export const WorkOrdersFilters = ({ setFilter, filter }: any, props: any) => {
  const classes = useStyles()
  const { i18n } = useI18n()

  // =================== START OF WORK ORDERS FILTERS ===================
  const { getAccounts, getWorklogsSet } = useGraphQLService()
  // const { sfId: sfid } = useActiveUser()
  // const [siteId, setSiteId] = useState("")
  // const [siteInputText, setSiteInputText] = useState("")
  // const [value] = useDebounce(siteInputText, 1000)
  // const [inputLoading, setInputLoading] = useState(false)
  // const [isLoading, setLoading] = useState(false)
  // const [isMobile, setMobile] = useState(false)
  // const [reportData, setReportData] = useState([])
  // const [openReportModal, setOpenReportModal] = useState(false)
  // const [selectedStatus, setSelectedStatus] = useState("All")
  const [siteList, setSiteList] = useState<{ label: string; id: string }[]>([])
  // const [dateRange, setDateRange] = useState({
  //   startDate: currentDateTime.set({ day: currentDateTime.startOf("month").day }).toISODate(),
  //   endDate: currentDateTime.toISODate(),
  // })
  // const csvInstance = useRef<any | null>(null)
  // =================== END OF WORK ORDERS FILTERS ===================

  const [searchText, setSearchText] = useState("")
  const [search, setSearch] = useState("")

  // useEffect(() => {
  //   if (window.innerWidth < 1065) {
  //     setMobile(true)
  //   }
  // }, [])

  useEffect(() => {
    if (search !== null) {
      setFilter({ ...filter, search: search })
    } else {
      setFilter({ ...filter, search: undefined })
    }
  }, [search])

  const searchChange = (event: any) => {
    setSearchText(event.target.value as string)
  }

  const searchByText = () => {
    setSearch(searchText)
  }

  // const handleReportGeneration = useCallback(async () => {
  //   try {
  //     setLoading(true)

  //     const resp = await getWorklogsSet(sfid, dateRange.startDate, dateRange.endDate, selectedStatus === "All" ? "Approved" : selectedStatus, siteId)

  //     let data = []

  //     if (!!resp.worklogs?.length) {
  //       data = resp.worklogs.map((w: any) => ({
  //         "Work Order Number": w?.name,
  //         Site: w?.location_name,
  //         "Approval Status": w?.approval_status,
  //         "Service Summary": w?.service_summary,
  //         "Date Reported": w.service_date ? formatDate(w.service_date) : "-",
  //         "Check-In Time": w.service_date ? DateTime.fromISO(w?.service_date).toFormat("t") : "-",
  //         "Time Taken On-Site": w?.time_taken_minutes ? getMinFormat(w?.time_taken_minutes) : "-",
  //       }))
  //     }

  //     setReportData(data)
  //   } catch (error) {
  //     console.log("ERROR GENERATING REPORT", error)
  //   } finally {
  //     setLoading(false)
  //   }
  // }, [dateRange, getWorklogsSet, selectedStatus, sfid, siteId])

  // const getSites = useCallback(
  //   async (signal?: AbortSignal, reset: boolean = false) => {
  //     try {
  //       setInputLoading(true)
  //       let response: AccountResponse | undefined = undefined

  //       if (siteList.length && value.length === 0 && !reset) {
  //         const siteName = siteList.find((s) => s.id === siteId)?.label ?? ""

  //         response = await getAccounts("site", siteName, 50, signal)
  //       } else {
  //         response = await getAccounts("site", value, 50, signal)
  //       }

  //       if (!response) return

  //       const result = response.accounts.map((s) => ({ id: s.sfid, label: s.name })).sort((a, b) => (a.label > b.label ? 1 : -1))

  //       setSiteList(result)
  //     } catch (error) {
  //       console.log("ERROR GETTING SITES", error)
  //     } finally {
  //       setInputLoading(false)
  //     }
  //   },
  //   [getAccounts, siteId, siteList, value]
  // )

  // useEffect(() => {
  //   const abortController = new AbortController()

  //   getSites(abortController.signal)

  //   return () => {
  //     abortController.abort()
  //   }
  // }, [value])

  // useEffect(() => {
  //   if (csvInstance.current) {
  //     if (!!reportData.length) {
  //       csvInstance.current.link.click()
  //     } else {
  //       alert("No data to export.")
  //     }
  //   }
  // }, [reportData])

  return (
    <>
      <div className="w-full p-2.5">
        <div className="flex h-fit w-full flex-row items-center justify-between">
          <Grid container className="flex-row items-center">
            <Grid item xs={4} className="flex flex-row items-center">
              <TextField
                size="small"
                id="input-with-icon-textfield"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                className={classes.searchInput + " w-full"}
                placeholder={i18n.t("workOrders.searchByWorkOrderNumber")}
                onChange={(e) => searchChange(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    searchByText()
                  }
                }}
              />
              <Button variant="outlined" size="small" className={classes.buttonFilter} onClick={() => searchByText()}>
                {i18n.t("common.search")}
              </Button>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}></Grid>
          </Grid>

          {/* {!isMobile && (
            <div className="mx-4 flex flex-col items-center justify-center whitespace-nowrap">
              <span className="transform cursor-pointer transition duration-150 hover:translate-y-[-2px]" onClick={() => setOpenReportModal(true)}>
                <AssessmentIcon />
              </span>
              <p className="text-sm font-semibold">Export .CSV</p>
            </div>
          )} */}
        </div>
      </div>

      {/* <Modal
        open={openReportModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={isLoading ? undefined : () => setOpenReportModal(false)}
      > */}
      {/* <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "1px solid #000",
            borderRadius: "10px",
            boxShadow: 24,
            p: 1,
          }}
        > */}
      {/* <div className="flex w-full flex-col"> */}
      {/* <CloseIcon className="mb-4 cursor-pointer self-end" onClick={isLoading ? undefined : () => setOpenReportModal(false)} /> */}

      {/* <div className="flex flex-col gap-4 px-4 pb-4">
              <div className="flex flex-col gap-2">
                <p className="font-semibold">Date Range</p>
                <Datepicker
                  inputClassName="px-[11px] py-[8px] placeholder-[#CDD1DC] w-full rounded-md border-0 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#AFE023]"
                  containerClassName={
                    "bg-white rounded-md border-0 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#AFE023]"
                  }
                  value={dateRange}
                  configs={{
                    shortcuts: {
                      last7Days: {
                        text: "Last 7 Days",
                        period: {
                          start: currentDateTime.minus({ days: 7 }).toISODate(),
                          end: currentDateTime.toISODate(),
                        },
                      },
                      pastMonth: "Past Month",
                      past3Months: {
                        text: "Past 3 Months",
                        period: {
                          start: currentDateTime.minus({ months: 3 }).toISODate(),
                          end: currentDateTime.toISODate(),
                        },
                      },
                      lastQuarter: {
                        text: "Last Quarter",
                        period: {
                          start: currentDateTime.startOf("quarter").minus({ quarters: 1 }).toISODate(),
                          end: currentDateTime.startOf("quarter").minus({ days: 1 }).toISODate(),
                        },
                      },
                    },
                  }}
                  onChange={(newValue: any) => setDateRange(newValue)}
                  useRange={false}
                  showShortcuts={true}
                  disabled={isLoading}
                  toggleClassName="hidden"
                  placeholder={"Filter by date range"}
                  displayFormat={"MM/DD/YYYY"}
                  separator="-"
                />
              </div> */}

      {/* <div className="flex flex-col gap-2">
                <p className="font-semibold">Approval Status</p>
                <Select
                  variant={"outlined"}
                  value={selectedStatus}
                  placeholder="Status"
                  disabled={isLoading}
                  onChange={(e: any) => setSelectedStatus(e.target.value)}
                  style={{
                    backgroundColor: "#FFF",
                    border: "none",
                    borderRadius: "5px",
                  }}
                  className={classes.input}
                >
                  {["All", "Pending", "Approved", "Cancelled"].map((element: any, index) => (
                    <MenuItem key={index} value={element}>
                      {element}
                    </MenuItem>
                  ))}
                </Select>
              </div> */}

      {/* <div className="flex flex-col gap-2">
                <p className="font-semibold">Site</p>

                <Autocomplete
                  freeSolo
                  size="medium"
                  sx={{ maxWidth: 500, minWidth: 400 }}
                  loading={inputLoading}
                  loadingText="Loading..."
                  value={siteList.find((s) => s.id === siteId)?.label ?? ""}
                  options={siteList}
                  noOptionsText="No sites found"
                  disablePortal={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        ...params.InputLabelProps,
                        shrink: true,
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: { padding: 4 },
                      }}
                      onChange={(e) => setSiteInputText(e.target.value)}
                    />
                  )}
                  onInputChange={(e, value, r) => {
                    if (r === "clear") {
                      setSiteId("")
                      setSiteInputText("")

                      getSites(undefined, true)
                    }
                  }}
                  onChange={(e, value, r, d) => r !== "clear" && setSiteId((value as any).id)}
                />
              </div> */}
      {/* </div> */}

      {/* <span className="m-4">
              <CSVLink
                data={reportData}
                style={{ width: "100%", height: "100%", padding: 8, textAlign: "center", display: "none" }}
                asyncOnClick={true}
                filename="WO_report.csv"
                ref={csvInstance}
              >
                Si
              </CSVLink>
              <button
                className="w-full rounded-md bg-case-grad-mid p-2 font-semibold text-white transition duration-150 ease-in-out hover:bg-case-grad-from hover:shadow-md"
                onClick={handleReportGeneration}
              >
                <div className="flex flex-row items-center justify-center gap-4">
                  Generate Report
                  {isLoading && <Rings color="#4EC7F1" width={24} height={24} />}
                </div>
              </button>
            </span> */}
      {/* </div> */}
      {/* </Box> */}
      {/* </Modal> */}
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    searchInput: {
      backgroundColor: "white",
    },
    buttonFilter: {
      backgroundColor: "#f9f9f9",
      height: "40px",
      minWidth: "60px",
      fontSize: "10px",
    },
    input: {
      "& .MuiInputBase-input": {
        padding: "8px",
      },
    },
  })
)
