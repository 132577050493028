import { useEffect, useState } from "react"
import {
  makeStyles,
  createStyles,
  Container,
  Grid,
  Typography,
} from "@material-ui/core"
import BidsLists from "./BidsList"
import { ReactComponent as RightIcon } from "./../../../assets/right.svg"
import { useNavigate } from "react-router-dom"
import { BidsFilters } from "./BidsFilter"
import { getUrlParam } from "../../../utils/Util"
import useI18n from "../../../hooks/useI18n"

function BidsDesktop(props: any) {
  const navigate = useNavigate()
  const classes = useStyles()
  const { i18n } = useI18n();
  const [filter, setFilter] = useState<any>({})
  const [defaultFilter, setDefaultFilter] = useState("")
  const [loadingFilter, setLoadingFilter] = useState(false)

  useEffect(() => {
    const filter = getUrlParam("filterBy") as string
    if (filter) {
      let f = filter.replace("%20", " ")
      setLoadingFilter(true)
      setDefaultFilter(f)
    }
  }, [])

  const handleLoadingFilter = (state: any) => {
    setLoadingFilter(state)
  }

  return (
    <>
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <div className={classes.showTitle}>
                  <div className="mb-4">
                    <Typography noWrap className="flex flex-row items-center">
                      <span
                        className="cursor-pointer  text-breadcrumb-blue font-bold"
                        onClick={() => navigate("/")}
                      >
                        {i18n.t("breadcrumbs.home")}
                      </span>
                      <RightIcon className="w-3.5 mr-3 ml-3" />
                      <span className="font-bold">{i18n.t("breadcrumbs.bidListing")}</span>
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.grid}>
              <Grid container className="flex-row items-center">
                <Grid item xs={12}>
                  <div className={classes.secondaryTitle}>
                    <Typography noWrap className={classes.cardTitle}>
                      {i18n.t("breadcrumbs.bidListing")}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <BidsFilters
                filter={filter}
                setFilter={setFilter}
                defaultFilter={defaultFilter}
                setLoadingFilter={handleLoadingFilter}
              />
              {!loadingFilter && <BidsLists {...props} filter={filter} />}
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    showTitle: {
      width: "auto",
      marginTop: "8px",
      marginLeft: "10px",
    },
    secondaryTitle: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "15px",
    },
    titleFont: {
      fontSize: "44px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "40px",
      marginTop: "21px",
    },
    cardTitle: {
      fontSize: "40px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "black",
      letterSpacing: "-1px",
      marginLeft: "40px",
      marginTop: "21px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#FFFFFF",
    },
    container: {
      maxWidth: "100%",
    },
    searchInput: {
      backgroundColor: "white",
      borderRadius: "5px",
      width: "95%",
    },
  })
)

export default BidsDesktop
