import { useCallback, useState } from "react"
import {
  makeStyles,
  createStyles,
  Container,
  Grid,
  Typography,
} from "@material-ui/core"
import SitesLists from "./SitesList"
import { SitesFilters } from "./SitesFilter"
import { useNavigate } from "react-router-dom"
import { ReactComponent as RightIcon } from "./../../../assets/right.svg"
import { useActiveUser } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"
import useI18n from "../../../hooks/useI18n"

function SitesDesktop(props: any) {
  const navigate = useNavigate()
  const classes = useStyles()
  const { i18n } = useI18n();
  const graphqlService = useGraphQLService()
  const [filter, setFilter] = useState<any>({})
  const { sfId: sfid } = useActiveUser()
  const [activeSites, setActiveSites] = useState(0)
  const [workOrders, setWorkOrders] = useState(0)
  const [loadingSites, setLoadingSites] = useState(false)
  const [fetchedAll, setFetchedAll] = useState(false)

  const getSitesLocation = useCallback(
    (signal?: AbortSignal) => {
      if (!loadingSites) {
        setLoadingSites(true)
        graphqlService
          .getSitesLocations(
            {
              sfid,
            },
            signal
          )
          .then((response) => {
            setLoadingSites(false)
            setActiveSites(response?.pagination?.found_rows || 0)
            setWorkOrders(response?.total_worklogs || 0)
            setFetchedAll(true)
          })
          .catch(() => {
            setLoadingSites(false)
            setFetchedAll(true)
          })
      }
    },
    [graphqlService, sfid]
  )

  const fetchedList = () => {
    const controller = new AbortController()

    getSitesLocation(controller.signal)

    return () => controller.abort()
  }

  return (
    <>
      <div>
        <Container className={classes.container}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={8}>
                  <div className={classes.showTitle}>
                    <div className="mb-4">
                      <Typography noWrap className="flex flex-row items-center">
                        <span
                          className="cursor-pointer text-breadcrumb-blue font-bold"
                          onClick={() => navigate("/")}
                        >
                          {i18n.t("breadcrumbs.home")}
                        </span>
                        <RightIcon className="w-3.5 mr-3 ml-3" />
                        <span className="font-bold">{i18n.t("breadcrumbs.siteListing")}</span>
                      </Typography>
                    </div>
                    <div>
                      <Typography noWrap className={classes.titleFont}>
                        {i18n.t("breadcrumbs.siteListing")}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4} className="w-full">
                  <div className="w-1/2 text-left float-left ">
                    <div className="h-38 m-2 rounded  bg-primary-sky-blue flex flex-col items-center pt-8 pb-8">
                      <span className="text-4xl font-bold text-card-blue mb-3.5">
                        {activeSites}
                      </span>
                      <span className="text-xl text-card-blue">
                        {i18n.t("sites.totalActiveSites")}
                      </span>
                    </div>
                  </div>
                  <div className="w-1/2 text-left float-left ">
                    <div className="h-38 m-2 rounded  bg-primary-sky-blue flex flex-col items-center pt-8 pb-8">
                      <span className="text-4xl font-bold text-card-blue mb-3.5">
                        {workOrders}
                      </span>
                      <span className="text-xl text-card-blue ">
                        {i18n.t("sites.wosCompleted")}
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className={classes.grid}>
                <SitesFilters filter={filter} setFilter={setFilter} />
                <SitesLists
                  {...props}
                  filter={filter}
                  fetchedList={fetchedList}
                  fetchedAll={fetchedAll}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    showTitle: {
      width: "auto",
      marginTop: "10px",
      marginLeft: "10px",
    },
    titleFont: {
      fontSize: "44px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#000",
      letterSpacing: "-1px",
    },
    secondaryTitleFont: {
      fontSize: "22px",
      fontWeight: 700,
      lineHeight: "113%",
      color: "#000",
      letterSpacing: "-1px",
      marginTop: "10px",
    },
    cardTitle: {
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "40px",
      marginTop: "21px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#FFFFFF",
    },
    container: {
      maxWidth: "100%",
    },
  })
)

export default SitesDesktop
