import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"

import { IconButton, Modal, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip } from "@material-ui/core"
import Box from "@mui/material/Box/Box"
import TableCell from "@mui/material/TableCell"
import { DateTime, Duration, Interval } from "luxon"

import { getDateByMonthAndWeekNumber, getNumberOfWeeksPerMonth, getWeekNumber } from "../../../../../utils/Util"
import LandChartStatusIconComponent, { LandStatusColor } from "./LandChartStatusIconComponent"
import { useGraphQLService } from "../../../../../services/graphqlService"
import { ValidationContext } from "../../../../../providers/ValidationProvider"
import { LandValidationListing, ServicePeriod } from "../../../interfaces"
import { Loader } from "../../../../common/Loader"

const currentDate = DateTime.now()

type Props = {
  monthFilteringLength: number
  isCached: boolean
  selectedService: {
    periodIndex: number
    siteId?: string
    serviceKey: string
    sfid: string
    weekNumber?: number
  } | null
  onItemClick: ({
    periodIndex,
    weekIndex,
    weekNumber,
    monthName,
    year,
    item,
  }: {
    periodIndex: number
    weekIndex: number
    weekNumber: number
    year: number
    monthName: string
    item: LandValidationListing
  }) => Promise<void>
}

const LandSummaryTableComponent = ({ selectedService, onItemClick, monthFilteringLength, isCached }: Props) => {
  const [isLoading, setLoading] = useState(false)
  // const [sort, setSort] = useState<{ by: "siteName" | "customerName" | "servicePartnerName"; order: "asc" | "desc" }>()
  const [isLoadingNewPage, setLoadingNewPage] = useState(false)
  const [listingData, setListingData] = useState<LandValidationListing[]>()
  const [dates, setDates] = useState<{ start: DateTime; end: DateTime; months: { name: string; year: number }[] }>()
  const chartRef = useRef<HTMLElement>(null)
  const currentMonthRef = useRef<HTMLElement>(null)
  const periodRef = useRef<HTMLElement>(null)
  const col1Ref = useRef<HTMLElement>(null)
  const col2Ref = useRef<HTMLElement>(null)
  const col3Ref = useRef<HTMLElement>(null)
  const col4Ref = useRef<HTMLElement>(null)
  const col5Ref = useRef<HTMLElement>(null)
  const { validationState, setValidation } = useContext(ValidationContext)

  const { getAccounts, getLandValidationListing } = useGraphQLService()

  const getListingData = useCallback(
    async (paginated: boolean = false) => {
      if (paginated && !validationState?.landNextPageToken) {
        return
      }

      if (!paginated) {
        setLoading(true)
      } else setLoadingNewPage(true)

      let filteredSites: {
        id: string
        label: string
      }[] = []
      let customerList = validationState?.customerList
      let servicePartnerList = validationState?.servicePartnerList

      if (validationState?.currentFilter?.siteId) {
        const response = await getAccounts("site", validationState?.currentFilter?.siteId)

        filteredSites = response?.accounts?.map((s) => ({ id: s.sfid, label: s.name })).sort((a, b) => (a.label > b.label ? 1 : -1))
      }

      if (!customerList) {
        const response = await getAccounts("customer")

        customerList = response?.accounts?.map((s) => ({ id: s.sfid, label: s.name })).sort((a, b) => (a.label > b.label ? 1 : -1))
      }

      if (!servicePartnerList) {
        const response = await getAccounts("service_partner")

        servicePartnerList = response?.accounts?.map((s) => ({ id: s.sfid, label: s.name })).sort((a, b) => (a.label > b.label ? 1 : -1))
      }

      const customer =
        validationState?.currentFilter?.customer === "all"
          ? undefined
          : customerList?.find((s) => s.label === validationState?.currentFilter?.customer)?.id
      const servicePartner =
        validationState?.currentFilter?.servicePartner === "all"
          ? undefined
          : servicePartnerList?.find((s) => s.label === validationState?.currentFilter?.servicePartner)?.id
      const siteId =
        validationState?.currentFilter?.siteId?.toLowerCase() === "all"
          ? undefined
          : filteredSites?.find((s) => s.label === validationState?.currentFilter?.siteId)?.id

      const result = await getLandValidationListing(
        validationState?.user?.sfid,
        servicePartner,
        customer,
        siteId,
        validationState?.currentFilter?.showOnlyRequiresReview,
        validationState?.landSort?.by,
        validationState?.landSort?.order,
        20,
        paginated ? validationState?.landNextPageToken : undefined
      )

      const data = result?.items?.map((v) => ({ ...v, servicePeriods: v.servicePeriods.filter((sp) => sp !== null) }))

      console.log(data, "lov mike")

      if (!paginated) {
        setListingData(data)
      } else {
        setListingData((previous) => {
          const actual = [...(previous ?? []), ...(data ?? [])]

          return actual
        })
        setLoadingNewPage(false)
      }

      setValidation((previous) => {
        const actual = {
          ...previous,
          servicePartnerList,
          customerList,
          // currentFilter: {
          //   showOnlyRequiresReview: data?.some((v) => v.servicePeriods.some((sp) => !sp.reviewComplete && sp.actualServiceCount > 0)) ?? false,
          // },
          // siteList,
          validations: paginated ? [...(validationState?.validations ?? []), ...(data ?? [])] : data,
          nextPageToken: result?.nextPageToken ?? undefined,
        }

        return actual
      })

      if (!paginated) {
        setLoading(false)
      }
    },
    [validationState, listingData, getLandValidationListing, setValidation, getAccounts]
  )

  const handleScroll = useCallback(async () => {
    const tableContainer = chartRef.current

    if (!tableContainer) return

    const { scrollHeight, scrollTop, clientHeight } = tableContainer

    const isTableScrolledToBottom = scrollHeight - scrollTop - clientHeight < 300

    if (isTableScrolledToBottom && !isLoadingNewPage) {
      await getListingData(true)
    }
  }, [getListingData, isLoadingNewPage])

  const setDateRange = useCallback(
    (changeToActual: boolean = false) => {
      if (listingData) {
        const startDate = currentDate.minus({ month: monthFilteringLength })
        const endDate = currentDate.plus({ month: monthFilteringLength })

        const monthNames = []
        let currentMonth = startDate

        while (currentMonth <= endDate) {
          monthNames.push({ name: currentMonth.toLocaleString({ month: "short" }), year: currentMonth.year })
          currentMonth = currentMonth.plus({ months: 1 })
        }

        const mergedArray = [...monthNames]

        setDates((previous) => {
          const actual = { start: startDate, end: endDate, months: mergedArray }

          return changeToActual ? actual : previous ?? actual
        })
      }
    },
    [listingData, monthFilteringLength]
  )

  // const handleSort = useCallback(
  //   (by: "siteName" | "customerName" | "servicePartnerName") => {
  //     // setSort((prev) => ({ by, order: prev?.by === by ? (prev?.order === "asc" ? "desc" : "asc") : "asc" }))
  //     setValidation({
  //       ...validationState,
  //       sort: { by, order: validationState?.sort?.by === by ? (validationState?.sort?.order === "asc" ? "desc" : "asc") : "asc" },
  //     })
  //   },
  //   [setValidation, validationState]
  // )

  // useEffect(() => {
  //   if (validationState?.sort) {
  //     getListingData(false)
  //   }
  // }, [validationState?.sort])

  useEffect(() => {
    if ((!isCached ? !selectedService : true) && !validationState?.validations?.length) {
      // setValidation({
      //   ...validationState,
      //   sort: { by: "siteName", order: "asc" },
      // })
      getListingData(false)

      return
    }

    if (validationState?.validations) {
      setListingData(validationState.validations)
    }
  }, [selectedService, validationState?.currentFilter])

  useEffect(() => {
    setDateRange()
  }, [listingData, setDateRange])

  useEffect(() => {
    setDateRange(true)
  }, [monthFilteringLength, setDateRange])

  useEffect(() => {
    if (col1Ref.current && col2Ref.current && col3Ref.current && col4Ref.current && col5Ref.current && dates?.months?.length && !isLoading) {
      const col1Width = col1Ref.current.offsetWidth
      const col2Width = col2Ref.current.offsetWidth
      const col3Width = col3Ref.current.offsetWidth
      const col4Width = col4Ref.current.offsetWidth
      const col5Width = col4Ref.current.offsetWidth
      col2Ref.current.style.left = `${col1Width}px`
      col3Ref.current.style.left = `${col1Width + col2Width}px`
      col4Ref.current.style.left = `${col1Width + col2Width + col3Width}px`
      col5Ref.current.style.left = `${col1Width + col2Width + col3Width + col4Width}px`

      document.documentElement.style.setProperty("--col1-width", `${col1Width}px`)
      document.documentElement.style.setProperty("--col2-width", `${col2Width}px`)
      document.documentElement.style.setProperty("--col3-width", `${col3Width}px`)
      document.documentElement.style.setProperty("--col4-width", `${col4Width}px`)
      document.documentElement.style.setProperty("--col5-width", `${col5Width}px`)
    }
  }, [chartRef, dates?.months?.length, isLoading])

  useEffect(() => {
    if (currentMonthRef.current && chartRef.current && !isLoading && dates?.months?.length && !isCached) {
      const scrollToPercentage = monthFilteringLength === 3 ? 0.4 : monthFilteringLength === 6 ? 0.6 : 0.8

      chartRef.current.scrollTo({
        left: currentMonthRef.current?.offsetLeft * scrollToPercentage,
        behavior: "smooth",
      })

      return
    }

    if (periodRef?.current && chartRef.current && !isLoading && !isLoadingNewPage && listingData?.length && isCached) {
      periodRef.current!.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
    }
  }, [isLoading, dates, selectedService, isLoadingNewPage, monthFilteringLength, listingData, isCached])

  if (isLoading || !dates?.months?.length) return <Loader />

  if (!listingData?.length)
    return (
      <div className="text-center">
        <div className="inline-block">
          <p>EMPTY</p>
        </div>
      </div>
    )

  return (
    <div className="relative mt-2">
      <Modal open={isLoadingNewPage}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Loader />
        </Box>
      </Modal>

      <TableContainer component={Paper} ref={chartRef} className="max-h-[70vh] border-separate border-spacing-0" onScroll={handleScroll}>
        <Table stickyHeader>
          <TableHead className="sticky top-0 z-20 bg-white">
            <TableRow>
              <TableCell
                ref={col1Ref}
                sx={{
                  position: "sticky",
                  width: "100%",
                  left: 0,
                  background: "white",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                }}
                className="border border-white"
              >
                {/* <div className="flex flex-row items-center" onClick={() => handleSort("customerName")}>
                  {validationState?.sort?.by === "customerName" && validationState?.sort?.order === "desc" && <DownIcon />}
                  {validationState?.sort?.by === "customerName" && validationState?.sort?.order === "asc" && <UpIcon />}
                  Customer
                </div> */}
                Customer
              </TableCell>
              <TableCell
                ref={col2Ref}
                sx={{
                  position: "sticky",
                  left: "var(--col1-width, 0)",
                  background: "white",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                }}
                className="border-white"
              >
                {/* <div className="flex flex-row items-center" onClick={() => handleSort("servicePartnerName")}>
                  {validationState?.sort?.by === "servicePartnerName" && validationState?.sort?.order === "desc" && <DownIcon />}
                  {validationState?.sort?.by === "servicePartnerName" && validationState?.sort?.order === "asc" && <UpIcon />}
                  Assigned SP
                </div> */}
                Assigned SP
              </TableCell>
              <TableCell
                ref={col3Ref}
                sx={{
                  position: "sticky",
                  left: "calc(var(--col1-width, 0) + var(--col2-width, 0))",
                  background: "white",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                }}
                className="border-white"
              >
                {/* <div className="flex flex-row items-center" onClick={() => handleSort("siteName")}>
                  {validationState?.sort?.by === "siteName" && validationState?.sort?.order === "desc" && <DownIcon />}
                  {validationState?.sort?.by === "siteName" && validationState?.sort?.order === "asc" && <UpIcon />}
                  Site
                </div> */}
                Site (Procurement Bid Name)
              </TableCell>
              <TableCell
                ref={col4Ref}
                sx={{
                  position: "sticky",
                  left: "calc(var(--col1-width, 0) + var(--col2-width, 0) + var(--col3-width, 0))",
                  background: "white",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                }}
                className="border border-white"
              >
                Service Key
              </TableCell>
              <TableCell
                ref={col5Ref}
                sx={{
                  position: "sticky",
                  left: "calc(var(--col1-width, 0) + var(--col2-width, 0) + var(--col3-width, 0) + var(--col4-width, 0))",
                  background: "white",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                  "&::after": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    width: "2px",
                    background: "black",
                  },
                }}
                className="border-b border-l border-t border-b-white border-l-white border-t-white"
              >
                Frequency
              </TableCell>
              {dates?.months?.map((month, mIndex) => {
                const monthIndex = DateTime.fromFormat(month.name, "LLL").month - 1
                let weekLength = [...Array(getNumberOfWeeksPerMonth(monthIndex, month.year)).keys()]

                return (
                  <React.Fragment key={`${monthIndex}-${mIndex}`}>
                    {weekLength.map((week, weekIndex) => {
                      const weekNumber = getWeekNumber(monthIndex, week, month.year)

                      // if (weekNumber === currentDate.weeksInWeekYear && monthIndex === 0 && week === 0) {
                      //   return <React.Fragment key={`${monthIndex}-${weekIndex}`} />
                      // }

                      const isCurrentWeek = currentDate.plus({ day: 1 }).weekNumber === weekNumber && currentDate.year === month.year

                      const date = getDateByMonthAndWeekNumber(monthIndex, weekIndex, month.year)

                      return (
                        <TableCell
                          key={`${monthIndex}-${weekIndex}`}
                          ref={currentDate.month - 1 === monthIndex && currentDate.year === month.year ? currentMonthRef : null}
                          sx={{ fontWeight: "bold" }}
                          className={`text-center
              ${isCurrentWeek ? "border-l-2 border-l-[#FF3333]" : ""} 
              border-b border-[#E0E0E0] px-4 py-2`}
                        >
                          <div className="flex flex-col items-center justify-center">
                            <span className="text-sm">{weekNumber}</span>
                            <span className="text-sm font-normal">{date.toFormat("D")}</span>
                          </div>
                        </TableCell>
                      )
                    })}
                  </React.Fragment>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <ListingBodyComponent
              listingData={listingData}
              dates={dates}
              selectedService={selectedService}
              onItemClick={onItemClick}
              periodRef={periodRef}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const DataComponent = ({
  item,
  dates,
  selectedService,
  onItemClick,
  periodRef,
}: {
  item: LandValidationListing
  dates: {
    start: DateTime
    end: DateTime
    months: {
      name: string
      year: number
    }[]
  }
  selectedService: {
    periodIndex: number
    siteId?: string
    serviceKey: string
    sfid: string
    weekNumber?: number | undefined
  } | null
  periodRef: React.MutableRefObject<any>
  onItemClick: ({
    periodIndex,
    weekIndex,
    weekNumber,
    item,
    monthName,
    year,
  }: {
    periodIndex: number
    weekIndex: number
    weekNumber: number
    year: number
    monthName: string
    item: LandValidationListing
  }) => Promise<void>
}) => (
  <TableRow key={item.sfid} className="h-[80px]">
    <TableCell
      sx={{
        position: "sticky",
        left: 0,
        background: "white",
        minWidth: "150px",
        zIndex: 10,
      }}
      className="border border-white"
    >
      {item.customerName ?? "-"}
    </TableCell>
    <TableCell
      sx={{
        position: "sticky",
        left: "var(--col1-width, 0)",
        background: "white",
        minWidth: "100px",
        zIndex: 10,
      }}
      className="border border-white"
    >
      {item.servicePartnerName ?? "-"}
    </TableCell>
    <TableCell
      sx={{
        position: "sticky",
        left: "calc(var(--col1-width, 0) + var(--col2-width, 0))",
        background: "white",
        minWidth: "200px",
        zIndex: 10,
      }}
      className="border border-white"
    >
      {item.siteName}
    </TableCell>
    <TableCell
      sx={{
        position: "sticky",
        left: "calc(var(--col1-width, 0) + var(--col2-width, 0) + var(--col3-width, 0))",
        background: "white",
        minWidth: "100px",
        fontWeight: 500,
        zIndex: 10,
      }}
      className="border border-white"
    >
      {item.serviceKey}
    </TableCell>
    <TableCell
      sx={{
        position: "sticky",
        left: "calc(var(--col1-width, 0) + var(--col2-width, 0) + var(--col3-width, 0) + var(--col4-width, 0))",
        background: "white",
        minWidth: "100px",
        zIndex: 10,
        "&::after": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          width: "2px",
          background: "black",
        },
      }}
      className="border-b border-l border-t border-b-white border-l-white border-t-white"
    >
      {item.serviceFrequency}
    </TableCell>
    {dates?.months?.map((month, mIndex) => {
      const monthIndex = DateTime.fromFormat(month.name, "LLL").month - 1
      let weekLength = [...Array(getNumberOfWeeksPerMonth(monthIndex, month.year)).keys()]

      return (
        <React.Fragment key={`${monthIndex}-${mIndex}`}>
          {weekLength.map((week, weekIndex) => {
            const weekNumber = getWeekNumber(monthIndex, weekIndex, month.year)

            const isCurrentWeek = currentDate.plus({ day: 1 }).weekNumber === weekNumber && currentDate.year === month.year
            let periodIndex = -1

            const period = item?.servicePeriods?.find((sp, index) => {
              if (!sp) return false

              const startDate = DateTime.fromISO(sp.startDate)
              const endDate = DateTime.fromISO(sp.endDate)
              const interval = Interval.fromDateTimes(startDate, endDate)

              const oneWeek = Duration.fromObject({
                weeks: 1,
              })

              const weeksArray = interval.splitBy(oneWeek).map((chunk) => {
                const startOfWeek = chunk.start.startOf("week")
                const endOfWeek = chunk.end.endOf("week")

                return { startOfWeek, endOfWeek }
              })

              const exists = weeksArray.some(
                (w) => w.endOfWeek.weekNumber === weekNumber && w.endOfWeek.year === month.year && w.endOfWeek.monthShort === month.name
              )

              if (exists) {
                periodIndex = index
              }

              return exists
            })

            const isInContract = period?.expected ?? (period?.weekNumber === weekNumber && currentDate.year === month.year) ?? false

            if (!period) {
              return (
                <th
                  key={`${monthIndex}-${weekIndex}`}
                  className={`text-center ${isInContract ? "bg-case-grad-from bg-opacity-25" : ""} ${
                    isCurrentWeek ? "border-l-2 border-l-[#FF3333]" : "border-l"
                  }  min-w-[80px] border-b border-[#E0E0E0] px-4`}
                ></th>
              )
            }

            const worklogs =
              period?.worklogs?.filter((w) => {
                const date = w.backDatedTo ?? w.workLogEnd ?? ""
                let timestamp = DateTime.fromISO(date)

                if (isNaN(timestamp.weekNumber)) {
                  timestamp = DateTime.fromISO(date?.substring(0, date?.lastIndexOf("-")))
                }

                return (
                  (timestamp?.weekday === 7 ? timestamp?.plus({ day: 1 }) : timestamp)?.weekNumber === weekNumber &&
                  !w.duplicateWorklog &&
                  timestamp.year === month.year
                )
              }) ?? []

            let periods: ServicePeriod[] = []
            const isSpecial =
              item.serviceFrequency.toLowerCase() === "quarterly" ||
              item.serviceFrequency.toLowerCase() === "monthly" ||
              item.serviceFrequency.toLowerCase() === "annual"

            const getStatusColor = () => {
              if (isSpecial) {
                const date = DateTime.fromObject({ weekYear: month.year, weekNumber }).minus({ day: 1 })

                if (date.day !== 1 && month.name !== date.monthShort) {
                  const result = item.servicePeriods.filter((sp) => sp.worklogs?.length)

                  periods = result
                    .map((p) => ({
                      ...p,
                      worklogs:
                        p.worklogs.filter((w) => {
                          const date = DateTime.fromISO(w.backDatedTo ?? w.workLogEnd ?? "")

                          if (!date || w.duplicateWorklog) return false

                          return (date?.weekday === 7 ? date?.plus({ day: 1 }) : date)?.weekNumber === weekNumber
                        }) ?? [],
                    }))
                    .filter((p) => !!p.worklogs.length)

                  if (periods.length > 1) {
                    if (
                      periods.some(
                        (p) =>
                          p.worklogs.some(
                            (w) =>
                              w.service?.approval_status?.toLowerCase() !== "cancelled" && w?.service?.approval_status?.toLowerCase() !== "approved"
                          ) || !p.reviewComplete
                      )
                    ) {
                      return LandStatusColor.Validation
                    }

                    if (periods.some((p) => p.worklogs.some((w) => w.service?.approval_status?.toLowerCase() === "approved") && p.reviewComplete)) {
                      return LandStatusColor.GreenStar
                    }

                    if (periods.some((p) => !p.worklogs.some((w) => w.service?.approval_status?.toLowerCase() === "approved") && p.reviewComplete)) {
                      return LandStatusColor.RedStar
                    }
                  }
                }
              }

              if (
                currentDate > DateTime.fromISO(period?.reminderDate ?? "") &&
                (period?.actualServiceCount ?? 0) < (period?.expectedServiceCount ?? 0) &&
                currentDate < DateTime.fromISO(period?.endDate ?? "") &&
                weekNumber >= currentDate.plus({ day: 1 }).weekNumber &&
                month.year === currentDate.plus({ day: 1 }).year
              ) {
                return LandStatusColor.ReminderBang
              }

              if (
                ((period?.actualServiceCount ?? 0) < (period?.expectedServiceCount ?? 0) ||
                  ((period?.actualServiceCount ?? 0) === 0 && (period?.expectedServiceCount ?? 0) === 0)) &&
                currentDate < DateTime.fromISO(period?.reminderDate ?? "")
              ) {
                if (weekNumber < currentDate.plus({ day: 1 }).weekNumber && month.year === currentDate.plus({ day: 1 }).year) {
                  return LandStatusColor.Missing
                }

                return LandStatusColor.Reminder
              }

              if (!worklogs.length) {
                if (period?.reviewComplete) {
                  return LandStatusColor.BlackStar
                }

                if (DateTime.fromISO(period.reminderDate ?? period.endDate) > currentDate) {
                  return LandStatusColor.Reminder
                }

                if (currentDate > DateTime.fromISO(period?.reminderDate ?? "") || (period?.expectedServiceCount ?? 0) > 0) {
                  return LandStatusColor.Missing
                }

                if (
                  weekNumber > currentDate.weekNumber &&
                  currentDate > DateTime.fromISO(period?.endDate ?? "") &&
                  weekNumber < currentDate.weekNumber
                ) {
                  return LandStatusColor.Missing
                }
              } else {
                if (
                  !worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "pending") &&
                  worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "approved") &&
                  item.servicePeriods?.find((sp) => sp?.worklogs?.some((w) => worklogs.some((wl) => wl.service?.sfid === w.service?.sfid)))
                    ?.reviewComplete
                ) {
                  return LandStatusColor.BlackStar
                }

                if (
                  !worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "pending") &&
                  !worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "approved") &&
                  !item.servicePeriods.find((sp) => sp.worklogs?.some((w) => worklogs.some((wl) => wl.service?.sfid === w.service?.sfid)))
                    ?.reviewComplete
                ) {
                  return LandStatusColor.Validation
                }

                if (
                  !worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "pending") &&
                  !worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "approved")
                ) {
                  return LandStatusColor.BlackStar
                }

                if (
                  !worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "pending") &&
                  worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "approved")
                ) {
                  return LandStatusColor.Approved
                }

                if (!worklogs.some((w) => w?.service?.approval_status?.toLowerCase() === "pending")) {
                  return LandStatusColor.Approved
                }
              }

              return LandStatusColor.Validation
            }

            const statusColor = getStatusColor()

            const isSelected =
              selectedService?.periodIndex === (periodIndex >= 0 ? periodIndex : weekIndex) &&
              selectedService?.serviceKey === item.serviceKey &&
              selectedService?.siteId === item.siteId &&
              selectedService?.weekNumber === weekNumber

            if (!worklogs.length) {
              const reminder = DateTime.fromISO(period.reminderDate)

              if (
                (reminder.weekday === 7 ? reminder.plus({ day: 1 }) : reminder).weekNumber === weekNumber &&
                reminder.year === month.year &&
                period.expected &&
                !period.worklogs?.length
              ) {
                return (
                  <th
                    key={`${monthIndex}-${weekIndex}`}
                    ref={isSelected ? periodRef : null}
                    className={`whitespace-nowrap border-b 
border-[#E0E0E0] px-4 text-center text-sm ${isInContract ? "bg-case-grad-from bg-opacity-25" : ""}
${
  isSelected
    ? "border border-case-grad-from bg-case-grad-from bg-opacity-100 text-white"
    : isCurrentWeek
    ? "border-l-2 border-l-[#FF3333]"
    : "border-l"
}`}
                  >
                    <div className="flex flex-col items-center pt-1">
                      <label className="whitespace-nowrap">
                        {isSpecial && periods.length > 1 ? "*" : period?.actualServiceCount ?? 0} /{" "}
                        {isSpecial && periods.length > 1 ? "*" : period?.expectedServiceCount}
                      </label>
                      <Tooltip
                        title={
                          weekNumber >= currentDate.plus({ day: 1 }).weekNumber && period?.reminderDate
                            ? DateTime.fromISO(period?.reminderDate).toFormat("D")
                            : `${period?.actualServiceCount} WorkOrders`
                        }
                      >
                        <IconButton
                          onClick={async () =>
                            await onItemClick({ periodIndex, weekIndex, weekNumber, item, year: month.year, monthName: month.name })
                          }
                        >
                          <LandChartStatusIconComponent statusColor={statusColor} isSelected={isSelected} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </th>
                )
              }

              if (
                period?.expectedServiceCount > 0 &&
                period?.worklogs?.some(
                  (w) =>
                    DateTime.fromISO(w.backDatedTo ?? w.workLogEnd ?? "")?.weekNumber === weekNumber &&
                    DateTime.fromISO(w.backDatedTo ?? w.workLogEnd ?? "")?.year === month.year
                ) &&
                !isSpecial
              ) {
                return (
                  <th
                    key={`${monthIndex}-${weekIndex}`}
                    ref={isSelected ? periodRef : null}
                    className={`whitespace-nowrap border-b 
  border-[#E0E0E0] px-4 text-center text-sm ${isInContract ? "bg-case-grad-from bg-opacity-25" : ""}
  ${
    isSelected
      ? "border-l border-case-grad-from bg-case-grad-from bg-opacity-100 text-white"
      : isCurrentWeek
      ? "border-l-2 border-l-[#FF3333]"
      : "border-l"
  }`}
                  >
                    <div className="flex flex-col items-center pt-1">
                      <label className="whitespace-nowrap">
                        {isSpecial && periods.length > 1 ? "*" : period?.actualServiceCount ?? 0} /{" "}
                        {isSpecial && periods.length > 1 ? "*" : period?.expectedServiceCount}
                      </label>
                      <Tooltip
                        title={
                          weekNumber >= currentDate.plus({ day: 1 }).weekNumber && period?.reminderDate
                            ? DateTime.fromISO(period?.reminderDate).toFormat("D")
                            : `${period?.actualServiceCount ?? 0} WorkOrders`
                        }
                      >
                        <IconButton
                          onClick={() => onItemClick({ weekIndex, weekNumber, periodIndex, item, year: month.year, monthName: month.name })}
                        >
                          <LandChartStatusIconComponent statusColor={statusColor} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </th>
                )
              }

              if (
                !isSpecial &&
                item.serviceFrequency !== "Once" &&
                (period.expectedServiceCount > 0 ||
                  period.worklogs.some(
                    (w) =>
                      DateTime.fromISO(w.backDatedTo ?? w.workLogEnd ?? "")?.weekNumber === weekNumber &&
                      DateTime.fromISO(w.backDatedTo ?? w.workLogEnd ?? "")?.year === month.year
                  ))
              ) {
                return (
                  <th
                    key={`${monthIndex}-${weekIndex}`}
                    ref={isSelected ? periodRef : null}
                    className={`whitespace-nowrap border-b 
border-[#E0E0E0] px-4 text-center text-sm ${isInContract ? "bg-case-grad-from bg-opacity-25" : ""}
${
  isSelected
    ? "border border-case-grad-from bg-case-grad-from bg-opacity-100 text-white"
    : isCurrentWeek
    ? "border-l-2 border-l-[#FF3333]"
    : "border-l"
}`}
                  >
                    <div className="flex flex-col items-center pt-1">
                      <label className="whitespace-nowrap">
                        {period?.actualServiceCount} / {period?.expectedServiceCount}
                      </label>
                      <Tooltip
                        title={
                          weekNumber >= currentDate.plus({ day: 1 }).weekNumber && period?.reminderDate
                            ? DateTime.fromISO(period?.reminderDate).toFormat("D")
                            : `${period?.actualServiceCount} WorkOrders`
                        }
                      >
                        <IconButton
                          onClick={async () =>
                            await onItemClick({ periodIndex, weekIndex, weekNumber, item, year: month.year, monthName: month.name })
                          }
                        >
                          <LandChartStatusIconComponent statusColor={statusColor} isSelected={isSelected} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </th>
                )
              }

              return (
                <th
                  key={`${monthIndex}-${weekIndex}`}
                  className={`text-center ${isInContract ? "bg-case-grad-from bg-opacity-25" : ""} ${
                    isCurrentWeek ? "border-l-2 border-l-[#FF3333]" : "border-l"
                  }  min-w-[80px] border-b border-[#E0E0E0] px-4`}
                ></th>
              )
            }

            return (
              <th
                key={`${monthIndex}-${weekIndex}`}
                ref={isSelected ? periodRef : null}
                className={`whitespace-nowrap border-b 
border-[#E0E0E0] px-4 text-center text-sm ${isInContract ? "bg-case-grad-from bg-opacity-25" : ""}
${
  isSelected
    ? "border-l border-case-grad-from bg-case-grad-from bg-opacity-100 text-white"
    : isCurrentWeek
    ? "border-l-2 border-l-[#FF3333]"
    : "border-l"
}`}
              >
                <div className="flex flex-col items-center pt-1">
                  {
                    <label className="whitespace-nowrap">
                      {isSpecial && periods.length > 1 ? "*" : period?.actualServiceCount ?? 0} /{" "}
                      {isSpecial && periods.length > 1 ? "*" : period?.expectedServiceCount ?? 0}
                    </label>
                  }
                  <Tooltip
                    title={
                      weekNumber >= currentDate.plus({ day: 1 }).weekNumber && period?.reminderDate
                        ? DateTime.fromISO(period?.reminderDate).toFormat("D")
                        : `${period?.actualServiceCount ?? 0} WorkOrders`
                    }
                  >
                    <IconButton
                      onClick={async () => await onItemClick({ periodIndex, weekIndex, weekNumber, item, year: month.year, monthName: month.name })}
                    >
                      <LandChartStatusIconComponent statusColor={statusColor} isSelected={isSelected} />
                    </IconButton>
                  </Tooltip>
                </div>
              </th>
            )
          })}
        </React.Fragment>
      )
    })}
  </TableRow>
)

const ListingBodyComponent = ({
  listingData,
  dates,
  selectedService,
  periodRef,
  onItemClick,
}: {
  listingData: LandValidationListing[]
  periodRef: React.MutableRefObject<any>
  dates: {
    start: DateTime
    end: DateTime
    months: {
      name: string
      year: number
    }[]
  }
  selectedService: {
    periodIndex: number
    siteId?: string
    serviceKey: string
    sfid: string
    weekNumber?: number | undefined
  } | null
  onItemClick: ({
    periodIndex,
    weekIndex,
    weekNumber,
    year,
    monthName,
    item,
  }: {
    periodIndex: number
    weekIndex: number
    weekNumber: number
    year: number
    monthName: string
    item: LandValidationListing
  }) => Promise<void>
}) => {
  const body = useMemo(
    () =>
      listingData.map((item) => (
        <DataComponent item={item} dates={dates} selectedService={selectedService} onItemClick={onItemClick} key={item.sfid} periodRef={periodRef} />
      )),
    [dates, listingData, onItemClick, periodRef, selectedService]
  )

  return <>{body}</>
}

export default LandSummaryTableComponent
