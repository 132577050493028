import { useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { ReactComponent as AnnotationCheck } from "./../../assets/annotation-check.svg"
import { ReactComponent as AdminIcon } from "./../../assets/users-edit.svg"
import { ReactComponent as WorkordenIcon } from "./../../assets/menu-orders.svg"
import { ReactComponent as BidIcon } from "./../../assets/menu-bids.svg"
import { ReactComponent as MarkerIcon } from "./../../assets/menu-sites.svg"
import { ReactComponent as UsersIcon } from "./../../assets/users.svg"
import { ReactComponent as LogoutIcon } from "./../../assets/log-out.svg"
import { ReactComponent as MenuIcon } from "./../../assets/menu.svg"
import { ReactComponent as BackIcon } from "./../../assets/back.svg"
import { ReactComponent as LogoIcon } from "./../../assets/logo.svg"
import { ReactComponent as CloseIcon } from "./../../assets/close-w.svg"
import { ReactComponent as UserIcon } from "./../../assets/user.svg"
import { ReactComponent as PhoneIcon } from "./../../assets/phone-w.svg"
import { useAuth, useActiveUser } from "../../providers/AuthProvider"
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import useI18n from "../../hooks/useI18n"

const Navbar = ({ children }: any) => {
  const navigate = useNavigate()
  const { isCanada, logout } = useAuth()
  const { i18n } = useI18n()
  const { permissions } = useActiveUser()
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    const element = document.getElementById("navBarMenu")

    if (!isOpen) {
      if (!!element) {
        element.style.display = "block"
      }
    } else {
      if (!!element) {
        setTimeout(() => {
          element.style.display = "none"
        }, 500)
      }
    }

    setIsOpen(!isOpen)
  }

  const handleLogout = () => logout()

  const isDashboard = () => {
    return window.location.pathname !== "/"
  }

  const menuItem = [
    {
      path: "/bids",
      name: i18n.t("common.menuBar.bids"),
      icon: <BidIcon />,
    },
    {
      path: "/sites",
      name: i18n.t("common.menuBar.sites"),
      icon: <MarkerIcon />,
    },
    {
      path: "/workorders",
      name: i18n.t("common.menuBar.workOrders"),
      icon: <WorkordenIcon />,
    },
    {
      path: "/admin",
      name: i18n.t("common.menuBar.admin"),
      icon: <AdminIcon />,
    },
    {
      path: "/validation",
      name: i18n.t("common.menuBar.validation"),
      icon: <FactCheckOutlinedIcon />,
    },
    {
      path: "/compliance",
      name: i18n.t("common.menuBar.compliance"),
      icon: <AnnotationCheck />,
    },
  ]

  const menuItemLogout = [
    {
      path: "/login",
      name: i18n.t("common.menuBar.logOut"),
      icon: <LogoutIcon />,
    },
  ]

  const menuItemBottom = [
    {
      path: "/profile",
      name: i18n.t("common.menuBar.profile"),
      icon: <UserIcon />,
    },
    {
      path: "/team",
      name: i18n.t("common.menuBar.team"),
      icon: <UsersIcon />,
    },
    {
      path: "/contact-us",
      name: i18n.t("common.menuBar.contactUs"),
      icon: <PhoneIcon />,
    },
    !isCanada
      ? {
          path: "https://go.paymode.com/CaseFMS/G-NB6MHN5KV",
          isHyperLink: true,
          name: i18n.t("common.menuBar.signUpForDirectPayment"),
          icon: <OpenInNewIcon />,
        }
      : null,
  ]

  return (
    <div className="flex flex-col">
      <div
        id="navBarMenu"
        className="navbar-menu flex w-full flex-1 overflow-y-auto"
        style={{
          display: "none",
          transform: isOpen ? "translateY(0)" : "translateY(-100%)",
          transition: "all 0.5s",
        }}
      >
        <CloseIcon className="absolute right-0 mt-2 h-6" onClick={toggle} />
        <br />
        <br />
        <div>
          {menuItem.map(
            (item, index) =>
              permissions?.some((p) => `/${p}` === item.path.toLowerCase()) && (
                <NavLink to={item.path} key={index} className="link" onClick={toggle}>
                  <div className="icon">{item.icon}</div>
                  <div
                    style={{
                      display: "block",
                      transition: "all 0.5s",
                    }}
                    className="link_text"
                  >
                    {item.name}
                  </div>
                </NavLink>
              )
          )}
          <br />
          {menuItemBottom.map((item, index) =>
            !item ? null : item.isHyperLink ? (
              <a href={item.path} key={index} className="link" target="_blank" rel="noreferrer">
                <MenuChildrenComponent icon={item.icon} name={item.name} />
              </a>
            ) : (
              <NavLink to={item.path} key={index} className="link" onClick={toggle}>
                <MenuChildrenComponent icon={item.icon} name={item.name} />
              </NavLink>
            )
          )}
          {menuItemLogout.map((item, index) => (
            <NavLink to={item.path} key={index} className="link" onClick={handleLogout}>
              <div className="icon">{item.icon}</div>
              <div className="link_text">{item.name}</div>
            </NavLink>
          ))}
        </div>
      </div>
      <div className="navbar-container">
        <div className="navbar h-1/4 w-full pb-2">
          <div className="top_section">
            <div className="h-8 w-full ">
              <div className="mt-2">
                {isDashboard() ? (
                  <BackIcon className="float-left h-6 w-1/5 text-left" onClick={() => navigate(-1)} />
                ) : (
                  <div className="float-left h-6 w-1/5 text-left"></div>
                )}
                <LogoIcon className="float-left h-6 w-3/5 text-center" />
                <MenuIcon className="float-left h-6 w-1/5 text-right" onClick={toggle} />
              </div>
            </div>
          </div>
        </div>
        {/* Example for header on screens*/}
        {/* <div className="h-40 bg-gradient-to-b from-case-grad-mid to-case-grad-to-mobile"></div> */}
        <main>{children}</main>
      </div>
    </div>
  )
}

const MenuChildrenComponent = ({ icon, name }: { icon: JSX.Element; name: string }) => (
  <>
    <div className="icon">{icon}</div>
    <div className="link_text">{name}</div>
  </>
)

export default Navbar
