import { useCallback, useEffect, useState } from "react"
import {
  makeStyles,
  createStyles,
  Container,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core"
import { ReactComponent as RightIcon } from "./../../../assets/right.svg"
import { useNavigate } from "react-router-dom"
import { ReactComponent as UserIcon } from "./../../../assets/user-b.svg"
import { ReactComponent as MessageIcon } from "./../../../assets/message-b.svg"
import { ReactComponent as Phonecon } from "./../../../assets/phone.svg"
import { ReactComponent as MarkerIcon } from "./../../../assets/marker-pin.svg"
import { ReactComponent as MailIcon } from "./../../../assets/mail.svg"
import { useActiveUser } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"
import useI18n from "../../../hooks/useI18n"

function ContactUsDesktop(props: any) {
  const navigate = useNavigate()
  const classes = useStyles()
  const { i18n } = useI18n()
  const { sfId: sfid } = useActiveUser()
  const [partner, setPartner] = useState<any>({})
  const graphqlService = useGraphQLService()

  const getPartnerData = useCallback(
    (signal?: AbortSignal) => {
      graphqlService
        .getPartnerBySfid({ sfid }, signal)
        .then((response) => {
          setPartner(response)
        })
        .catch(() => {})
    },
    [graphqlService, sfid]
  )

  useEffect(() => {
    const controller = new AbortController()

    getPartnerData(controller.signal)

    return () => controller.abort()
  }, [getPartnerData])

  return (
    <>
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <div className={classes.showTitle}>
                  <div className="mb-4">
                    <Typography noWrap className="flex flex-row items-center">
                      <span
                        className="cursor-pointer text-breadcrumb-blue font-bold"
                        onClick={() => navigate("/")}
                      >
                        {i18n.t("breadcrumbs.home")}
                      </span>
                      <RightIcon className="w-3.5 mr-3 ml-3" />
                      <span className="font-bold">{i18n.t("breadcrumbs.contactUs")}</span>
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container className="flex-row items-center">
              <Grid item xs={12}>
                <div className={classes.secondaryTitle}>
                  <Typography className={classes.cardTitle}>
                    {i18n.t("breadcrumbs.contactUs")}
                  </Typography>
                </div>

                <Typography className={classes.cardSubtitle}>
                  {i18n.t("contactUs.supportOrJoinCaseTeam")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container className="flex-row items-center">
              <Grid item xs={12}>
                <div className={classes.secondaryTitle}>
                  <Typography className={classes.subtitle}>
                    {i18n.t("contactUs.caseContacts")}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <div className={classes.listContainer}>
                <div className={classes.grid}>
                  {!!partner?.contacts?.length &&
                    partner?.contacts.map((contact: any, i: any) => (
                      <Container key={i} className={classes.container}>
                        <Grid container>
                          <Grid
                            item
                            xs={3}
                            className="flex flex-row items-center"
                          >
                            <UserIcon className="mr-3" />
                            <div>
                              <span className={classes.infoFont}>
                                {contact.name}
                              </span>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className="flex flex-row items-center"
                          >
                            <div>
                              <Typography noWrap className={classes.infoTitle}>
                                {contact.title || "-"}
                              </Typography>
                            </div>
                          </Grid>

                          <Grid item xs={3}>
                            <div className="w-full">
                              <div className="float-right">
                                <div className="flex flex-row items-center w-72">
                                  <MessageIcon className="mr-3" />
                                  <div className={classes.contactFont}>
                                    {contact.mobilephone || "No Phone Number"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>

                          <Grid item xs={3}>
                            <div className="w-full">
                              <div className="float-right">
                                <div className="flex flex-row items-center w-72">
                                  <div className={classes.contactFont}>
                                    {contact.email || "No Email"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider
                              variant="middle"
                              className={classes.divider}
                            />
                          </Grid>
                        </Grid>
                      </Container>
                    ))}
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <div className={classes.listContainer}>
                <div className={classes.grid}>
                  <Container className={classes.container}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={4}>
                            <div className={classes.gridContact}>
                              <div className=" m-2 rounded flex flex-col items-center pt-9 pb-9">
                                <span className={classes.circleOpenBids}>
                                  <Phonecon />
                                </span>
                                <span className={classes.fontContactUs}>
                                  {i18n.t("contactUs.callUs")}
                                </span>
                                <span>(888) 978-7669</span>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <div className={classes.gridContact}>
                              <div className=" m-2 rounded flex flex-col items-center pt-9 pb-9">
                                <span className={classes.circleOpenBids}>
                                  <MailIcon />
                                </span>
                                <span className={classes.fontContactUs}>
                                  {i18n.t("contactUs.emailUs")}
                                </span>
                                <span>mail@casefms.com</span>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <div className={classes.gridContact}>
                              <div className=" m-2 rounded flex flex-col items-center pt-9 pb-9">
                                <span className={classes.circleOpenBids}>
                                  <MarkerIcon />
                                </span>
                                <span className={classes.fontContactUs}>
                                  {i18n.t("contactUs.visitUs")}
                                </span>
                                <span>356 John L Dietsch Blvd.</span>
                                <span>North Attleborough, MA 02763</span>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    showTitle: {
      width: "auto",
      marginTop: "8px",
    },
    secondaryTitle: {
      display: "flex",
      justifyContent: "space-between",
    },
    titleFont: {
      fontSize: "44px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "40px",
      marginTop: "21px",
    },
    cardTitle: {
      fontSize: "40px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "black",
      letterSpacing: "-1px",
    },
    cardSubtitle: {
      marginTop: "10px",
      fontSize: "18px",
      fontWeight: 800,
      color: "black",
      marginBottom: "20px",
    },
    subtitle: {
      fontSize: "25px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      background: "#FFFFFF",
      padding: "10px 0px",
    },
    gridContact: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      background: "#FFFFFF",
      margin: "10px",
      minHeight: "217px",
    },
    container: {
      maxWidth: "100%",
      padding: "10px 20px",
    },
    listContainer: {
      width: "auto",
      marginLeft: 0,
      paddingTop: "10px",
    },
    infoFont: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 15,
    },
    contactFont: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 13,
    },
    infoTitle: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
      marginBottom: "10px",
    },
    divider: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    circleOpenBids: {
      backgroundColor: "#232F64",
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "5px",
      fontSize: "20px",
      color: "white",
      fontWeight: "bold",
    },
    fontContactUs: {
      fontWeight: "bold",
      color: "#232F64",
    },
  })
)

export default ContactUsDesktop
