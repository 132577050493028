import { useEffect, useState } from "react"

import { Container, Grid, Typography } from "@material-ui/core"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { useAdminStyles } from "../Admin/styles"
import { ReactComponent as RightIcon } from "./../../assets/right.svg"
import { ValidationProvider } from "../../providers/ValidationProvider"

const ValidationsPage = () => {
  const classes = useAdminStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState<string[] | null>(null)

  useEffect(() => {
    if (location?.pathname?.includes("details")) {
      setCurrentPage(["Results", "Details"])
    } else if (location?.pathname?.includes("results")) {
      setCurrentPage(["Results"])
    } else setCurrentPage(null)
  }, [location?.pathname])

  return (
    <ValidationProvider>
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.showTitle}>
                  <div className="mb-4">
                    <Typography noWrap className="flex flex-row items-center">
                      <span className="cursor-pointer  font-bold text-breadcrumb-blue" onClick={() => navigate("/")}>
                        Home
                      </span>
                      <RightIcon className="ml-3 mr-3 w-3.5" />
                      {currentPage ? (
                        <>
                          <span className="cursor-pointer font-bold text-breadcrumb-blue" onClick={() => navigate("/validation")}>
                            Search
                          </span>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            {currentPage.map((item, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <RightIcon className="ml-3 mr-3 w-3.5" />
                                <span
                                  onClick={index === 0 && currentPage.length > 1 ? () => navigate(-1) : undefined}
                                  className={index === 0 && currentPage.length > 1 ? "cursor-pointer font-bold text-breadcrumb-blue" : "font-bold"}
                                >
                                  {item}
                                </span>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <span className="font-bold">Search</span>
                        </>
                      )}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.grid}>
              <Outlet />
            </div>
          </Grid>
        </Grid>
      </Container>
    </ValidationProvider>
  )
}

export default ValidationsPage
