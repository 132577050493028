import { useCallback, useEffect, useState } from "react"
import { makeStyles, createStyles, Typography, Button } from "@material-ui/core"
import { useGraphQLService } from "../../../services/graphqlService"
import { ReactComponent as MarkerIcon } from "./../../../assets/marker.svg"
import Box from "@mui/material/Box"
import ProfileTab from "./tabs/ProfileTab"
import EquipmentTab from "./tabs/EquipmentTab"
import ServicesTab from "./tabs/ServicesTab"
import { useActiveUser } from "../../../providers/AuthProvider"
import useI18n from "../../../hooks/useI18n"

function ProfileMobile(props: any) {
  const classes = useStyles()
  const { i18n } = useI18n()
  const [value, setValue] = useState(0)
  const { sfId: sfid } = useActiveUser()
  const [partner, setPartner] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const graphqlService = useGraphQLService()

  const getPartnerData = useCallback((signal?: AbortSignal) => {
    setLoading(true)
    graphqlService
      .getPartnerBySfid({ sfid }, signal)
      .then((response) => {
        setLoading(false)
        setPartner(response)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [graphqlService, sfid])

  useEffect(() => {
    const controller = new AbortController()

    getPartnerData(controller.signal)

    return () => controller.abort()
  }, [getPartnerData])

  return (
    <>
      <div className="h-fit h-min-40 bg-gradient-to-b from-case-grad-mid to-case-grad-to-mobile pb-4">
        <div className={classes.secondaryTitleMobile}>
          <Typography noWrap className={classes.titleFontMobile}>
            {partner?.name}
          </Typography>
        </div>
        <div
          className={classes.subtitleFontMobile + " flex flex-row items-center"}
        >
          <MarkerIcon className="w-4 mr-1.5" />
          <Typography noWrap>
            {partner?.billingstreet}
            {", "}
            {partner?.billingcity}
            {", "}
            {partner?.billingstate}
            {", "}
            {partner?.billingpostalcode}
          </Typography>
        </div>
      </div>
      <div className="bg-case-gray h-full">
        <div className="w-full">
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderColor: "divider",
              }}
            >
              <div className="w-full h-8 mb-3">
                <div className="h-6 w-1/3 text-center float-left">
                  <Button
                    variant="text"
                    className={
                      classes.buttonTab +
                      " w-full " +
                      (value === 0 ? classes.selectedTab : "")
                    }
                    onClick={() => setValue(0)}
                  >
                    {i18n.t("breadcrumbs.profile")}
                  </Button>
                </div>
                <div className="h-6 w-1/3 text-center float-left">
                  <Button
                    variant="text"
                    className={
                      classes.buttonTab +
                      " w-full " +
                      (value === 1 ? classes.selectedTab : "")
                    }
                    onClick={() => setValue(1)}
                  >
                    {i18n.t("profile.equipment")}
                  </Button>
                </div>
                <div className="h-6 w-1/3 text-center float-left">
                  <Button
                    variant="text"
                    className={
                      classes.buttonTab +
                      " w-full " +
                      (value === 2 ? classes.selectedTab : "")
                    }
                    onClick={() => setValue(2)}
                  >
                    {i18n.t("profile.services")}
                  </Button>
                </div>
              </div>
            </Box>
            {value === 0 && (
              <div>
                <ProfileTab partner={partner} />
              </div>
            )}
            {value === 1 && (
              <div>
                <EquipmentTab
                  partner={partner}
                  getPartnerData={getPartnerData}
                  loading={loading}
                />
              </div>
            )}
            {value === 2 && (
              <div>
                <ServicesTab partner={partner} />
              </div>
            )}
          </Box>
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    secondaryTitleMobile: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "100vw",
    },
    titleFontMobile: {
      fontSize: "30px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "white",
      letterSpacing: "-1px",
      marginLeft: "20px",
      marginTop: "21px",
    },
    subtitleFontMobile: {
      fontSize: "12px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "white",
      letterSpacing: "-1px",
      marginLeft: "20px",
      marginBottom: "0px",
    },
    buttonTab: {
      color: "#0F2150",
      fontWeight: "bold",
      backgroundColor: "white",
    },
    selectedTab: {
      borderBottom: "2px solid #0F2150",
    },
  })
)

export default ProfileMobile
