type Props = {
  type: "Approved" | "Cancelled"
}

const ApproveCancelBoxIconComponent = ({ type }: Props) => {
  return (
    <div
      className={`flex h-6 w-6 justify-center rounded-sm ${
        type === "Cancelled" ? "bg-[#FF3333]" : "bg-[#3ED229]"
      } text-lg font-bold text-white`}
    >
      {type === "Cancelled" ? <>&#10005;</> : <>&#10003;</>}
    </div>
  )
}

export default ApproveCancelBoxIconComponent
