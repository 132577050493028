import { useCallback, useEffect, useState } from "react"
import {
  makeStyles,
  createStyles,
  Container,
  Grid,
  TextField,
  Button,
} from "@material-ui/core"
import Typography from "@mui/material/Typography"
import { useNavigate } from "react-router-dom"
import { Loader } from "../../../common/Loader"
import { useGraphQLService } from "../../../../services/graphqlService"

function EquipmentTab(props: any) {
  const classes = useStyles()
  const navigate = useNavigate()
  const graphqlService = useGraphQLService()
  const [error, setError] = useState("")
  const [partner, setPartner] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [editableValues, setEditableValues] = useState<any>({
    trucks_in_fleet: 0,
    number_of_trucks_dedicated_to_case_sites: 0,
    sander_de_icer_s_in_fleet: 0,
    loaders_in_fleet: 0,
    skidsteers_in_fleet: 0,
    pushers_in_fleet: 0,
  })

  useEffect(() => {
    setPartner(props?.partner)
    setEditableValues({
      trucks_in_fleet: props?.partner?.trucks_in_fleet || 0,
      number_of_trucks_dedicated_to_case_sites:
        props?.partner?.number_of_trucks_dedicated_to_case_sites || 0,
      sander_de_icer_s_in_fleet: props?.partner?.sander_de_icer_s_in_fleet || 0,
      loaders_in_fleet: props?.partner?.loaders_in_fleet || 0,
      skidsteers_in_fleet: props?.partner?.skidsteers_in_fleet || 0,
      pushers_in_fleet: props?.partner?.pushers_in_fleet || 0,
    })
    setLoading(props?.loading)
  }, [props])

  const inputChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    let removedText = e.target.value.replace(/\D+/g, "")
    setEditableValues({ ...editableValues, [key]: Number(removedText) })
  }

  const cancel = () => {
    setEditMode(false)
    setEditableValues({
      trucks_in_fleet: partner?.trucks_in_fleet || 0,
      number_of_trucks_dedicated_to_case_sites:
        partner?.number_of_trucks_dedicated_to_case_sites || 0,
      sander_de_icer_s_in_fleet: partner?.sander_de_icer_s_in_fleet || 0,
      loaders_in_fleet: partner?.loaders_in_fleet || 0,
      skidsteers_in_fleet: partner?.skidsteers_in_fleet || 0,
      pushers_in_fleet: partner?.pushers_in_fleet || 0,
    })
  }

  const handleEdit = useCallback(() => {
    setEditMode(false)
    setLoading(true)
    graphqlService
      .updateServicePartnerEquipment({
        sfid: partner?.sfid,
        values: editableValues,
      })
      .then(() => {
        props?.getPartnerData()
      })
      .catch(() => {
        setLoading(false)
      })
  }, [editableValues, graphqlService, partner?.sfid, props])

  if (error) {
    return <p>ERROR: {error}</p>
  } else {
    return (
      <>
        <div className={classes.listContainer}>
          <div className={classes.grid}>
            <Container className={classes.container}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography>
                    <div className={classes.titleFont}>Equipment</div>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography>
                    <div className={classes.subtitleFont}>
                      Fleet Information
                    </div>
                  </Typography>
                  <Typography>
                    <div className={classes.subtitleIndicator}>
                      Provide the number of each piece of equipment you have in
                      your fleet
                    </div>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.labelFont}>Truck(s)</div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {editMode ? (
                        <TextField
                          id={"outlined-helperText"}
                          variant={"outlined"}
                          size="small"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputChanged(e, "trucks_in_fleet")
                          }
                          value={editableValues.trucks_in_fleet}
                          type="text"
                          style={{
                            backgroundColor: "#FFF",
                            border: "none",
                            borderRadius: "5px",
                          }}
                          className={classes.input}
                        />
                      ) : (
                        <Typography>
                          <div className={classes.inputFont}>
                            {partner?.trucks_in_fleet || "0"}
                          </div>
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.labelFont}>
                          Sander/De-Icer(s)
                        </div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {editMode ? (
                        <TextField
                          id={"outlined-helperText"}
                          variant={"outlined"}
                          size="small"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputChanged(e, "sander_de_icer_s_in_fleet")
                          }
                          value={editableValues.sander_de_icer_s_in_fleet}
                          type="text"
                          style={{
                            backgroundColor: "#FFF",
                            border: "none",
                            borderRadius: "5px",
                          }}
                          className={classes.input}
                        />
                      ) : (
                        <Typography>
                          <div className={classes.inputFont}>
                            {partner?.sander_de_icer_s_in_fleet || "0"}
                          </div>
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.labelFont}>Loader(s)</div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {editMode ? (
                        <TextField
                          id={"outlined-helperText"}
                          variant={"outlined"}
                          size="small"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputChanged(e, "loaders_in_fleet")
                          }
                          value={editableValues.loaders_in_fleet}
                          type="text"
                          style={{
                            backgroundColor: "#FFF",
                            border: "none",
                            borderRadius: "5px",
                          }}
                          className={classes.input}
                        />
                      ) : (
                        <Typography>
                          <div className={classes.inputFont}>
                            {partner?.loaders_in_fleet || "0"}
                          </div>
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.labelFont}>Skid Steer(s)</div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {editMode ? (
                        <TextField
                          id={"outlined-helperText"}
                          variant={"outlined"}
                          size="small"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputChanged(e, "skidsteers_in_fleet")
                          }
                          value={editableValues.skidsteers_in_fleet}
                          type="text"
                          style={{
                            backgroundColor: "#FFF",
                            border: "none",
                            borderRadius: "5px",
                          }}
                          className={classes.input}
                        />
                      ) : (
                        <Typography>
                          <div className={classes.inputFont}>
                            {partner?.skidsteers_in_fleet || "0"}
                          </div>
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>
                        <div className={classes.labelFont}>Pusher(s)</div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {editMode ? (
                        <TextField
                          id={"outlined-helperText"}
                          variant={"outlined"}
                          size="small"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inputChanged(e, "pushers_in_fleet")
                          }
                          value={editableValues.pushers_in_fleet}
                          type="text"
                          style={{
                            backgroundColor: "#FFF",
                            border: "none",
                            borderRadius: "5px",
                          }}
                          className={classes.input}
                        />
                      ) : (
                        <Typography>
                          <div className={classes.inputFont}>
                            {partner?.pushers_in_fleet || "0"}
                          </div>
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      {loading && (
                        <div className={classes.buttons}>
                          <Loader />
                        </div>
                      )}
                      {!loading && (
                        <div className={classes.buttons}>
                          {editMode && (
                            <Button
                              onClick={cancel}
                              style={{
                                marginRight: "10px",
                              }}
                              className={classes.declineButton + " w-40"}
                              disabled={false}
                            >
                              Cancel
                            </Button>
                          )}
                          {editMode && (
                            <Button
                              onClick={handleEdit}
                              className={classes.actionButton + " w-40"}
                              disabled={false}
                            >
                              Save
                            </Button>
                          )}
                          {!editMode && (
                            <Button
                              onClick={() => setEditMode(true)}
                              className={classes.actionButton + " w-40"}
                              disabled={false}
                            >
                              Edit
                            </Button>
                          )}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </>
    )
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      width: "auto",
      marginLeft: 0,
      padding: "10px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      background: "#FFFFFF",
    },
    container: {
      maxWidth: "100%",
      padding: "20px",
    },
    titleFont: {
      fontSize: "25px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#0F2150",
      marginBottom: "20px",
    },
    subtitleFont: {
      fontSize: "20px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#0F2150",
    },
    subtitleIndicator: {
      fontSize: "15px",
      color: "gray",
      letterSpacing: "-1px",
      marginBottom: "20px",
    },
    labelFont: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "113%",
      color: "#0F2150",
    },
    inputFont: {
      marginBottom: "20px",
    },
    divider: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    input: {
      margin: "5px",
      width: "80px",
      "& .MuiInputBase-input": {
        padding: "5px",
      },
    },
    buttons: {
      display: "flex",
      justifyContent: "end",
      marginTop: "20px",
      marginBottom: "20px",
      marginRight: "10px",
    },
    actionButton: {
      border: "1px solid #0F2150",
      borderRadius: "100px",
      backgroundColor: "#0F2150",
      color: "#fff",
      "&.Mui-disabled": {
        border: "1px solid #e2e5ea",
        backgroundColor: "#e2e5ea",
        color: "#b2b7c6",
      },
      "&:hover": {
        backgroundColor: "#1b3a8c",
      },
    },
    declineButton: {
      border: "1px solid #0F2150",
      borderRadius: "100px",
      backgroundColor: "#fff",
      color: "#0F2150",
      "&.Mui-disabled": {
        border: "1px solid #e2e5ea",
        backgroundColor: "#e2e5ea",
        color: "#b2b7c6",
      },
    },
  })
)

export default EquipmentTab
