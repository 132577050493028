import { useContext, useState } from "react"

import { DateTime } from "luxon"
import { CircularProgress, Container, Modal } from "@material-ui/core"

import { weekOptions } from "../../../../utils/Util"
import { useAdminStyles } from "../../../Admin/styles"
import { ValidationContext } from "../../../../providers/ValidationProvider"
import { LandValidation } from "../../interfaces"
import PeriodCardComponent from "../SelectedValidationPage/components/PeriodCardComponent"
import LandDetailsComponent from "../SelectedValidationPage/components/LandDetailsComponent"

const LandValidationDetailsPage = () => {
  const classes = useAdminStyles()

  const [isLoading, setLoading] = useState(false)
  const { validationState } = useContext(ValidationContext)

  return (
    <>
      <Modal open={isLoading} className="loadingModalContainer">
        <CircularProgress />
      </Modal>

      <Container className={classes.container}>
        <LandDetailsComponent />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: 16,
          }}
        >
          {(validationState.selectedValidation as LandValidation)?.servicePeriods?.map((period, index) => {
            const weekNumber = DateTime.fromSQL(period.startDate).get("weekNumber")

            // const exists = validationState?.selectedWeeks?.find(
            //   (sw) => sw.id - 1 === weekNumber
            // )

            // console.log(weekNumber, period, exists)

            if (period.worklogs?.length) {
              console.log(
                // "Period week: ",
                // weekNumber,
                "\nPeriod start/end date: ",
                period.startDate + " / " + period.endDate,
                "\nWOs length ",
                period.worklogs?.length,
                "\nWOs: ",
                period.worklogs
              )

              return (
                <div key={index}>
                  <span className="pl-2 font-bold">{weekOptions[weekNumber]?.label}</span>
                  <PeriodCardComponent period={period} selectedService={null} />
                </div>
              )
            }
            // else {
            //   return (
            //     <div key={index} className="text-center">
            //       <div className="inline-block">
            //         <p>No records for the selected week and validation.</p>
            //       </div>
            //     </div>
            //   )
            // }
            // // else return <React.Fragment key={index}>{index}</React.Fragment>
          })}
        </div>
      </Container>
    </>
  )
}

export default LandValidationDetailsPage
