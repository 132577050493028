import { useCallback, useContext, useEffect, useState } from "react"

import { Snackbar, Typography } from "@material-ui/core"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Alert, ToggleButton, ToggleButtonGroup } from "@mui/material"

import { Loader } from "../../../../common/Loader"
import { useGraphQLService } from "../../../../../services/graphqlService"
import { ValidationContext } from "../../../../../providers/ValidationProvider"
import { LandValidation, LandValidationListing } from "../../../interfaces"
import LandValidationTable from "./LandValidationTable"
import LandSummaryTableComponent from "./LandSummaryTableComponent"

const LandMainPageComponent = () => {
  const [isLoading, setLoading] = useState(false)
  const [isCached, setCached] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [monthFilteringLength, setMonthFilteringLength] = useState(3)
  const [landData, setLandData] = useState<LandValidation[]>([])
  const [selectedService, setSelectedService] = useState<{
    periodIndex: number
    siteId?: string
    serviceKey: string
    sfid: string
    weekNumber?: number
    monthName: string
    year: number
  } | null>(null)
  const { validationState, setValidation } = useContext(ValidationContext)

  const { getLandValidations, updateServicePeriodCompletion } = useGraphQLService()

  const onItemClick = useCallback(
    async (data: { periodIndex: number; weekIndex: number; weekNumber: number; year: number; monthName: string; item: LandValidationListing }) => {
      const { periodIndex, weekIndex, weekNumber, item, year, monthName } = data
      const index = periodIndex < 0 ? weekIndex : periodIndex

      try {
        setLoading(true)
        setCached(false)
        const result = await getLandValidations(undefined, 2000, item.siteId ?? "", undefined, "serviceStart", "desc")
        setLoading(false)
        if (result) {
          const selectedValidation = result.find((v) => v.sfid === item.sfid)
          setLandData(result)
          setSelectedService({
            periodIndex: index,
            siteId: item.siteId,
            serviceKey: item.serviceKey,
            sfid: item.sfid,
            year,
            weekNumber,
            monthName,
          })
          setValidation((previous) => {
            const actual = {
              ...previous,
              selectedSites: [
                {
                  label: item.siteName,
                  id: item.siteId,
                },
              ],
              selectedWeeks: [{ id: weekNumber, label: "" }],
              selectedService: {
                periodIndex: index,
                siteId: item.siteId,
                serviceKey: item.serviceKey,
                sfid: item.sfid,
                year,
                weekNumber,
                monthName,
              },
              selectedValidation,
            }

            return actual
          })
        }
      } catch (error) {
        console.log("INNER EXCEPTION:", error)
      }
    },
    [getLandValidations, setValidation]
  )

  const handleLengthChange = useCallback((event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    if (newAlignment) {
      setMonthFilteringLength(+newAlignment)
    }
  }, [])

  useEffect(() => {
    if (!selectedService && validationState?.selectedService) {
      setCached(true)
      const exists = validationState?.validations?.find((v) => v.sfid === validationState?.selectedService?.sfid)

      if (exists) {
        setSelectedService(validationState?.selectedService)
      }
    }
  }, [selectedService])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div tabIndex={-1} className="mb-2 flex w-min select-none flex-row items-center gap-4 whitespace-nowrap">
            <label className="underline">Additional Months:</label>
            <ToggleButtonGroup
              tabIndex={-1}
              className="bg-case-d-gray bg-opacity-10"
              color="primary"
              size="small"
              value={`${monthFilteringLength}`}
              exclusive
              onChange={handleLengthChange}
            >
              <ToggleButton tabIndex={-1} value="3">
                <div className="p-4">3</div>
              </ToggleButton>
              <ToggleButton tabIndex={-1} value="6">
                <div className="p-4">6</div>
              </ToggleButton>
              <ToggleButton tabIndex={-1} value="12">
                <div className="p-4">12</div>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          {selectedService?.siteId && !isCached && (
            <div
              className="flex max-w-min cursor-pointer select-none flex-row items-center gap-4"
              tabIndex={-1}
              onClick={() => {
                window.getSelection()?.removeAllRanges()
                setSelectedService(null)
                setValidation({
                  ...validationState,
                  selectedValidation: null,
                })
              }}
            >
              <ArrowBackIcon />
              <Typography className="searchValidationTitle" noWrap>
                Go back to listings
              </Typography>
            </div>
          )}

          {selectedService?.siteId && landData && !isCached ? (
            <div className="mt-4" tabIndex={-1}>
              <LandValidationTable
                data={landData}
                monthFilteringLength={monthFilteringLength}
                selectedService={{ ...selectedService }}
                onListingNav={async (
                  data: {
                    service: {
                      periodIndex: number
                      siteId: string
                      serviceKey: string
                      sfid: string
                      weekNumber?: number | undefined
                      monthName: string
                      year: number
                    }
                    item: LandValidationListing
                  },
                  newListingListInfo?: {
                    nextPageToken?: string
                    listings: LandValidationListing[]
                  }
                ) => {
                  try {
                    setLoading(true)

                    const result = await getLandValidations(undefined, 20, data?.item?.siteId ?? "", undefined, "serviceStart", "desc")

                    if (!result) return

                    setLandData(result)

                    setSelectedService({
                      ...data?.service,
                      periodIndex: data?.service?.periodIndex ?? selectedService.periodIndex,
                      serviceKey: data?.service?.serviceKey ?? selectedService.serviceKey,
                      sfid: data?.service?.sfid ?? selectedService.sfid,
                      weekNumber: data?.service?.weekNumber,
                    })

                    const newItem = result.find((v) => v.sfid === data?.item?.sfid)

                    setValidation({
                      ...validationState,
                      validations: newListingListInfo?.listings ?? validationState.validations,
                      selectedValidation: newItem ?? data?.item,
                      selectedSites: [{ label: (newItem ?? data?.item)?.siteName, id: data?.service?.siteId }],
                      selectedService: {
                        ...data?.service,
                        periodIndex: data?.service?.periodIndex ?? selectedService.periodIndex,
                        serviceKey: data?.service?.serviceKey ?? selectedService.serviceKey,
                        sfid: data?.service?.sfid ?? selectedService.sfid,
                        weekNumber: data?.service?.weekNumber,
                      },
                      landNextPageToken: newListingListInfo ? newListingListInfo?.nextPageToken : validationState.landNextPageToken,
                    })
                  } catch (error) {
                    console.log("ERROR ON VALIDATIONAV", error)
                  } finally {
                    setLoading(false)
                  }
                }}
                onReviewCompleted={async ({ sfid, week, value, selectedService: newSelectedService }) => {
                  try {
                    setLoading(true)
                    setIsVisible(true)

                    const resp = await updateServicePeriodCompletion({
                      sfid,
                      week,
                      value,
                    })

                    if (resp) {
                      const result = await getLandValidations(
                        undefined,
                        20,
                        validationState?.selectedSites?.[0].id ?? "",
                        undefined,
                        "serviceStart",
                        "desc"
                      )
                      setLoading(false)
                      if (result) {
                        setLandData(result)

                        const updatedSelectedService = {
                          ...selectedService,
                          periodIndex: newSelectedService?.periodIndex ?? selectedService.periodIndex,
                          serviceKey: newSelectedService?.serviceKey ?? selectedService.serviceKey,
                          sfid: newSelectedService?.sfid ?? selectedService.sfid,
                          weekNumber: newSelectedService?.weekNumber,
                        }

                        setSelectedService(updatedSelectedService)

                        const newVals = (validationState.validations ?? []) as LandValidationListing[]
                        const validationIndex = newVals.findIndex((v: LandValidationListing) => v.sfid === sfid)

                        if (validationIndex >= 0) {
                          newVals[validationIndex].servicePeriods[week].reviewComplete = value

                          if (
                            !newVals[validationIndex].servicePeriods.some((p) => !p.reviewComplete) &&
                            validationState.currentFilter?.showOnlyRequiresReview
                          ) {
                            newVals.splice(validationIndex, 1)
                          }
                        }

                        setValidation({
                          ...validationState,
                          selectedValidation: result.find((v) => v.sfid === updatedSelectedService?.sfid),
                          validations: newVals,
                        })
                      }
                    }
                  } catch (error) {
                    console.log("Error", error)
                  } finally {
                    setLoading(false)
                    setIsVisible(false)
                  }
                }}
              />
            </div>
          ) : (
            <LandSummaryTableComponent
              selectedService={selectedService}
              onItemClick={onItemClick}
              monthFilteringLength={monthFilteringLength}
              isCached={isCached}
            />
          )}
        </>
      )}

      {isVisible && (
        <Snackbar
          open={isVisible}
          autoHideDuration={3000}
          onClose={() => setIsVisible(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setIsVisible(false)}
            severity="info"
            sx={{ width: "100%" }}
            style={{
              backgroundColor: "#0F2150",
              color: "white",
              fontSize: 18,
              alignItems: "center",
            }}
          >
            Completing review...
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default LandMainPageComponent
