import {
  makeStyles,
  createStyles,
  Typography,
  Container,
  Grid,
  Button,
  Modal,
  Box,
} from "@material-ui/core"
import { ReactComponent as UserIcon } from "./../../../../assets/user-b.svg"
import { ReactComponent as MessageIcon } from "./../../../../assets/message-b.svg"
import { ReactComponent as CloseIcon } from "./../../../../assets/close-w.svg"
import Divider from "@mui/material/Divider"
import { useState } from "react"
import Carousel from "react-material-ui-carousel"

export const SiteDetailsCard = (props: any) => {
  const classes = useStyles()
  const [openImg, setOpenImg] = useState(false)

  const openImage = () => {
    setOpenImg(true)
  }

  return (
    <>
      {openImg && (
        <Modal
          open={openImg}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onClose={() => setOpenImg(false)}
        >
          <Box className={classes.styleImg}>
            <div className={classes.iframeBlock}>
              <div className="p-8">
                <Carousel
                  animation={"slide"}
                  autoPlay={false}
                  navButtonsAlwaysVisible={true}
                >
                  {!!props?.images?.length &&
                    props?.images.map((img: any, i: any) => (
                      <div key={i} className="flex flex-col items-center">
                        <img
                          className={classes.carouselImg}
                          alt="photo"
                          src={img}
                        />
                      </div>
                    ))}
                </Carousel>
              </div>

              <Button
                variant="text"
                onClick={() => setOpenImg(false)}
                className={classes.imgButtonClose}
              >
                <CloseIcon />
              </Button>
            </div>
          </Box>
        </Modal>
      )}
      <div>
        <div>
          <Typography className={classes.titleFont}>Case Contacts</Typography>
        </div>
        <div>
          {!!props?.site?.site_contacts?.length &&
            props?.site?.site_contacts.map((contact: any) => (
              <>
                {(contact.title == "Account Associate" ||
                  contact.title == "Field Supervisor" ||
                  contact.title == "Regional Field Manager" ||
                  contact.title == "Senior Account Manager") && (
                  <Container className={classes.container}>
                    <Grid container>
                      <Grid item xs={12} className="flex flex-row items-center">
                        <UserIcon className="mr-3" />
                        <div>
                          <span className={classes.infoFont}>
                            {contact.name}
                          </span>
                          <Typography noWrap className={classes.infoTitle}>
                            {contact.title || "-"}
                          </Typography>
                        </div>
                      </Grid>

                      <Grid item xs={12} className="flex flex-row items-center">
                        <MessageIcon className="mr-3" />
                        <div>
                          <div className={classes.contactFont}>
                            {contact.mobilephone || "No Phone Number"}
                          </div>
                          <div className={classes.contactFont}>
                            {contact.email || "No Email"}
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} className={classes.divider}>
                        <Divider variant="middle" />
                      </Grid>
                    </Grid>
                  </Container>
                )}
              </>
            ))}
        </div>
        <Divider variant="middle" className={classes.divider} />
        <div>
          <Typography className={classes.titleFont}>Photos</Typography>
        </div>

        <div className="flex flex-col items-center">
          {!!props?.images?.length &&
            props?.images.map((img: any, index: any) => (
              <div key={index} className={classes.imgContainer}>
                <img
                  className={classes.image + " cursor-pointer"}
                  onClick={() => openImage()}
                  alt="photo"
                  src={img}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
const useStyles = makeStyles(() =>
  createStyles({
    titleFont: {
      fontSize: "30px",
      "font-weight": "800",
      lineHeight: "113%",
      color: "#0F2150",
      letterSpacing: "-1px",
      marginLeft: "10px",
      marginTop: "20px",
      marginBottom: "20px",
    },
    subTitleFont: {
      fontSize: "30px",
      "font-weight": "800",
      lineHeight: "113%",
      color: "#0F2150",
      letterSpacing: "-1px",
      marginLeft: "10px",
      marginTop: "20px",
      marginBottom: "20px",
    },
    divider: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    container: {
      maxWidth: "100%",
    },
    infoFont: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 15,
    },
    contactFont: {
      color: "#0F2150",
      fontSize: 13,
    },
    infoTitle: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
      marginBottom: "10px",
    },
    image: {
      width: "100%",
      height: "auto",
    },
    imgContainer: {
      position: "relative",
      margin: "10px 10px",
    },
    imgButtonClose: {
      background: "red",
      top: 0,
      right: 0,
      border: "none",
      cursor: "pointer",
      position: "absolute",
      textAlign: "center",
      borderRadius: "5px",
      height: "25px",
      minWidth: "25px",
    },
    styleImg: {},
    iframeBlock: {
      width: "100%",
      margin: "5px",
      backgroundColor: "white",
      position: "absolute",
    },
    carouselImg: {
      maxHeight: "85vh",
    },
  })
)

export default SiteDetailsCard
