import { Container, Grid, Typography } from "@material-ui/core"
import { useNavigate } from "react-router-dom"

import { useAdminStyles } from "../Admin/styles"
import { ReactComponent as RightIcon } from "./../../assets/right.svg"
import ComplianceDashboard from "./components/complianceDashboard"
import useI18n from "../../hooks/useI18n"

const CompliancePage = () => {
  const classes = useAdminStyles()
  const navigate = useNavigate()
  const { i18n } = useI18n()

  return (
    <Container className={classes.container}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.showTitle}>
                <div className="mb-4">
                  <Typography noWrap className="flex flex-row items-center">
                    <span className="cursor-pointer  font-bold text-breadcrumb-blue" onClick={() => navigate("/")}>
                      {i18n.t("breadcrumbs.home")}
                    </span>
                    <RightIcon className="ml-3 mr-3 w-3.5" />
                    <span className="font-bold">{i18n.t("breadcrumbs.complianceDashboard")}</span>
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={`${classes.grid} p-4`}>
            <ComplianceDashboard />
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default CompliancePage
