import React, { useEffect, useState } from "react"
import { makeStyles, createStyles, Grid, Button } from "@material-ui/core"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import { ReactComponent as FilterIcon } from "./../../../../assets/arrow-right.svg"
import { ReactComponent as SearchIcon } from "./../../../../assets/search.svg"

export const DetailsFilter = ({ setFilter, filter }: any, props: any) => {
  const classes = useStyles()
  const [searchText, setSearchText] = useState("")
  const [search, setSearch] = useState("")

  useEffect(() => {}, [])

  useEffect(() => {
    if (search !== null) {
      setFilter({ ...filter, search: search })
    } else {
      setFilter({ ...filter, search: undefined })
    }
  }, [search])

  const searchChange = (event: any) => {
    setSearchText(event.target.value as string)
  }

  const searchByText = () => {
    setSearch(searchText)
  }

  return (
    <div className="w-full p-2.5">
      <div className="w-full p-2.5 rounded pl-4 h-fit">
        <Grid container className="flex-row items-center">
          <Grid item xs={12} className="flex flex-row items-center">
            <div className="w-full">
              <TextField
                size="small"
                id="input-with-icon-textfield"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                className={classes.searchInput}
                onChange={(e) => searchChange(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    searchByText()
                  }
                }}
                placeholder={"Search by work order number"}
              />
              <Button
                variant="outlined"
                size="small"
                className={classes.buttonFilter}
                onClick={() => searchByText()}
              >
                <FilterIcon className="w-3.5" />
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    searchInput: {
      backgroundColor: "white",
      borderRadius: "5px",
      width: "calc(100% - 40px)",
    },
    buttonFilter: {
      backgroundColor: "white",
      height: "40px",
      minWidth: "40px",
    },
  })
)
