import React, { useCallback, useEffect, useState } from "react"
import { Loader } from "../../common/Loader"
import { makeStyles, createStyles, Container, Grid, Button, TextField, InputAdornment } from "@material-ui/core"
import { ReactComponent as DownIcon } from "./../../../assets/down.svg"
import { ReactComponent as SearchIcon } from "./../../../assets/search.svg"
import { ReactComponent as AddUserIcon } from "./../../../assets/user-plus-w.svg"
import { useActiveUser } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import { styled } from "@mui/material/styles"
import TableRow from "@mui/material/TableRow"
import { useDebouncedCallback } from "use-debounce"
import ActionMenu from "../desktop/ActionMenu"
import InviteUserModal from "../common/InviteUserModal"
import UpdateUserModal from "../common/UpdateUserModal"
import UpdateRoleModal from "../common/UpdateRoleModal"
import DeleteUserModal from "../common/DeleteUserModal"

export const TeamLists = (props: any) => {
  const classes = useStyles()
  const [data, setData] = useState<any[]>([])
  const [sort, setSort] = useState({
    field: "email",
    order: "ASC",
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const user = useActiveUser()
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [openUpdateRoleModal, setOpenUpdateRoleModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openInviteModal, setOpenInviteModal] = useState(false)
  const [search, setSearch] = useState<string>()
  const [searchInput, setSearchInput] = useState("")

  const [selectedUser, setSelectedUser] = useState<any>({})
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f9f9f9",
      color: "#232F64",
      fontSize: "20px",
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }))

  const { getOrgUsers } = useGraphQLService()
  const [loadingMore, setLoadingMore] = useState(false)

  const getUsers = useDebouncedCallback(async (page: number, perPage: number, search?: string, sort?: any, signal?: AbortSignal) => {
    if (page === 1) {
      setLoading(true)
    } else {
      setLoadingMore(true)
    }
    const response = await getOrgUsers(
      {
        orgId: user.organizationId,
        limit: perPage,
        offset: (page - 1) * perPage,
        sort: sort?.field,
        searchText: search,
        sortOrder: sort?.order,
      },
      signal
    )

    if (page === 1) {
      setData(response?.rows ?? [])
      setTotal(response?.pagination?.found_rows ?? 0)
      setLoading(false)
      setLoadingMore(false)
    } else {
      setData((d) => [...d, ...(response?.rows ?? [])])
      setLoading(false)
      setLoadingMore(false)
    }
  }, 500)

  useEffect(() => {
    const controller = new AbortController()
    getUsers(page, perPage, search, sort, controller.signal)
    return () => controller.abort()
  }, [getUsers, page, perPage, search, sort])

  const openEdit = (user: any) => {
    setSelectedUser(user)
    setOpenUpdateModal(true)
  }

  const closeUpdateModal = () => {
    setOpenUpdateModal(false)
  }

  const openEditRole = (user: any) => {
    setSelectedUser(user)
    setOpenUpdateRoleModal(true)
  }

  const closeUpdateRoleModal = () => {
    setOpenUpdateRoleModal(false)
  }

  const openDelete = (user: any) => {
    setSelectedUser(user)
    setOpenDeleteModal(true)
  }

  const closeDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const reload = () => {
    setPage(1)
    getUsers(1, perPage, search, sort)
  }

  const handleSearch = useCallback(() => {
    setSearch(searchInput || undefined)
    setPage(1)
  }, [searchInput])

  const closeInviteModal = () => {
    setOpenInviteModal(false)
  }

  if (error) {
    return <p>ERROR: {error}</p>
  } else {
    return (
      <>
        {loading && (
          <div className={classes.listContainer}>
            <Loader />
          </div>
        )}

        {!loading && !data.length && (
          <div className={classes.listContainer}>
            <div>EMPTY</div>
          </div>
        )}

        {!loading && !!data.length && (
          <div>
            <InviteUserModal user={user} openModal={openInviteModal} closeModal={closeInviteModal} reload={reload} />
            <UpdateUserModal user={selectedUser} openModal={openUpdateModal} closeModal={closeUpdateModal} reload={reload} />
            <UpdateRoleModal
              user={selectedUser}
              openModal={openUpdateRoleModal}
              closeModal={closeUpdateRoleModal}
              reload={reload}
              orgId={user.organizationId}
            />
            <DeleteUserModal user={selectedUser} openModal={openDeleteModal} closeModal={closeDeleteModal} reload={reload} />
            <Container className={classes.container}>
              <Grid container>
                <Grid item xs={12} className="flex flex-row items-center pt-2.5">
                  <TextField
                    size="small"
                    id="input-with-icon-textfield"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    placeholder={"Search by email/phone number"}
                    value={searchInput}
                    onChange={(_) => setSearchInput(_.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch()
                      }
                    }}
                    className={classes.searchInput + " w-full"}
                  />
                  <Button variant="outlined" size="small" className={classes.buttonFilter} onClick={() => handleSearch()}>
                    Search
                  </Button>

                  <Button
                    onClick={() => setOpenInviteModal(true)}
                    variant="text"
                    style={{
                      marginTop: "80px",
                      margin: "auto",
                      marginBottom: "10px",
                    }}
                    className={classes.actionButton + ""}
                  >
                    <AddUserIcon />
                  </Button>
                </Grid>
              </Grid>
            </Container>
            <Container className={classes.container}>
              {!!data.length &&
                data.map((row: any, index: any) => {
                  const profile = row.profiles?.find((p: any) => p.partner_id === user.organizationId)

                  return (
                    <Grid key={index} container>
                      <Grid item xs={12}>
                        <div className={classes.grid}>
                          <div className={classes.secondaryTitle}>
                            <p>
                              Email: <span className={classes.cardTitle}>{row?.email || "-"}</span>
                            </p>
                          </div>
                          <div className={classes.secondaryTitle}>
                            <p>
                              Phone Number: <span className={classes.cardTitle}>{row?.phone_number || "-"}</span>
                            </p>
                          </div>
                          <div className={classes.secondaryTitle}>
                            <p>
                              User Name: <span className={classes.cardTitle}>{profile ? `${profile?.name_first} ${profile?.name_last}` : "-"}</span>
                            </p>
                          </div>
                          <div className={classes.secondaryTitle}>
                            <p>
                              Roles:
                              <span className={classes.cardDetails}>
                                {row.organization_roles
                                  .map(({ name }: { name: string }) => name)
                                  .filter((v: string, i: number, s: string[]) => s.indexOf(v) === i)
                                  .join(", ")}
                              </span>
                            </p>
                          </div>
                          <div className={classes.secondaryTitle}>
                            <ActionMenu openEdit={openEdit} openEditRole={openEditRole} openDelete={openDelete} selectedUser={row} />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  )
                })}
              {loadingMore ? (
                <Loader />
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <div className="align-items flex w-full flex-col">
                      <Button variant="text" size="small" onClick={() => setPage(page + 1)}>
                        <DownIcon />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}
            </Container>
          </div>
        )}
      </>
    )
  }
}
const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      width: "auto",
      marginLeft: 0,
      padding: "10px",
    },
    location: {
      fontWeight: "bold",
      color: "#1695CB",
    },
    cardTitle: {
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "10px",
      marginTop: "10px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#FFFFFF",
      padding: "5px",
    },
    container: {
      maxWidth: "100%",
    },
    secondaryTitle: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "15px",
    },
    data: {
      marginLeft: "10px",
      paddingBottom: "20px",
    },
    active: {
      backgroundColor: "#d7f5e3",
      color: "#4e996c",
      borderRadius: "20px",
      padding: "2px 11px",
      height: "30px",
      marginRight: "5px",
      marginTop: "15px",
    },
    inactive: {
      backgroundColor: "#fed5d5",
      color: "#ff3b5f",
      borderRadius: "20px",
      padding: "2px 5px",
      height: "30px",
      marginRight: "5px",
      marginTop: "15px",
    },
    cardDetails: {
      fontWeight: "bold",
      color: "#0F2150",
    },
    searchInput: {
      backgroundColor: "white",
    },
    buttonFilter: {
      backgroundColor: "#f9f9f9",
      height: "40px",
      minWidth: "60px",
      fontSize: "10px",
    },
    actionButton: {
      position: "absolute",
      right: "35px",
      top: "65px",
      borderRadius: "5px",
      color: "#fff",
    },
  })
)

export default TeamLists
