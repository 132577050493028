import { Select, InputLabel, FormControl } from "@material-ui/core"
import { makeStyles, createStyles } from "@material-ui/core"
import clsx from "clsx"

export const Filter = (props: any) => {
  const classes = useStyles()

  return (
    <FormControl
      variant="outlined"
      size="small"
      className={clsx(classes.eventsFilterItem, classes.filterWidth)}
    >
      <InputLabel id={props.id} classes={{ formControl: classes.label }}>
        {props.label}
      </InputLabel>
      <Select
        labelWidth={props.labelWidth}
        labelId={props.id}
        value={props.value}
        onOpen={props.onOpen}
        onChange={props.onChange}
        classes={{
          root: classes.inputSelectRoot,
          outlined: classes.inputSelectOutlined,
          select: classes.inputSelectSelected,
        }}
      >
        {props.children}
      </Select>
    </FormControl>
  )
}

const useStyles = makeStyles(() => {
  return createStyles({
    eventsFilterItem: {
      height: "48px",
      "& label.MuiFormLabel-root.Mui-focused": {
        color: "#1695CB",
        fontWeight: "bold",
      },
      "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
          borderColor: "#1695CB",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#1695CB",
        },
      },
    },
    reasonWidth: {
      minWidth: "277px",
    },
    filterWidth: {
      width: "100%",
      minWidth: "165px",
    },
    paymentTypeWidth: {
      minWidth: "185px",
    },
    label: {
      fontSize: "16px",
      lineHeight: "22px",
      fontFamily: "Manrope",
      fontStyle: "normal",
      fontWeight: "bold",
      color: "#969696",
    },
    inputSelectRoot: {
      padding: "12px 16px",
      backgroundColor: "white",
    },
    inputSelectOutlined: {
      borderRadius: "8px",
      "&.MuiSelect-outlined": {
        paddingRight: "48px",
      },
    },
    inputSelectSelected: {
      "&:focus": {
        background: "#F2F4FB",
      },
    },
  })
})
