import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Modal,
  TextField,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useDebouncedCallback } from "use-debounce"
import { usePartnersService } from "../../../services/partnersService"
import {
  transformPhoneNumber,
  validateEmail,
  validatePhoneNumber,
} from "../../../utils/Util"
import { Loader } from "../../common/Loader"
import { TeamModalProps } from "../desktop/TeamDesktop"
import { ReactComponent as CloseIcon } from "./../../../assets/close-w.svg"
import { useActiveUser } from "../../../providers/AuthProvider"
import useI18n from "../../../hooks/useI18n"

interface UserInfo {
  email?: string
  phoneNumber?: string
  name_first?: string
  name_last?: string
}

export default function UpdateUserModal({
  user,
  openModal,
  closeModal,
  reload,
  showProfileInfo,
}: TeamModalProps) {
  const partnersService = usePartnersService()
  const classes = useStyles()
  const { i18n } = useI18n()
  const activeUser = useActiveUser()
  const [invalidPhone, setInvalidPhone] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [newUserInfo, setNewUserInfo] = useState<UserInfo | null>(null)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<string[]>([])

  const updateUser = useDebouncedCallback(async (signal?: AbortSignal) => {
    let phone = newUserInfo?.phoneNumber
    if (phone && !phone.startsWith("+")) {
      phone = "+1" + phone
    }
    if (!newUserInfo?.email && !phone) {
      setErrors([i18n.t("common.modal.updateUser.mustProvideEmailOrPhone")])
      return
    }

    let vEmail = validateEmail(newUserInfo?.email ?? "")
    let vPhone = validatePhoneNumber(phone ?? "")

    if ((vEmail || !newUserInfo?.email) && (vPhone || !phone)) {
      setLoading(true)

      const profile = user.profiles?.find(
        (p: any) => p.partner_id === activeUser.organizationId
      )

      if (
        profile?.name_first !== newUserInfo?.name_first ||
        profile?.name_last !== newUserInfo?.name_last
      ) {
        const response = await partnersService.updateUserProfile(
          {
            id: profile?.id,
            user_id: profile?.user_id ?? user?.id,
            partner_id: profile?.partner_id ?? activeUser.organizationId,
            name_first: newUserInfo?.name_first,
            name_last: newUserInfo?.name_last,
          },
          signal
        )

        if (response?.status !== 200) {
          setLoading(false)
          setErrors(["Something went wrong, please try again"])
          return
        }

        handleClose()
        reload()
      }

      if (
        newUserInfo?.phoneNumber !== user.phone_number ||
        newUserInfo?.email !== user.email
      ) {
        const response = await partnersService.updateUser(
          {
            userId: user.id,
            email: newUserInfo?.email,
            phoneNumber: transformPhoneNumber(phone),
          },
          signal
        )
        if (response && response.status === 200) {
          handleClose()
          reload()
        } else if (response && response.status === 400) {
          setErrors(response.data?.message)
        } else {
          setErrors(["Something went wrong, please try again"])
        }
      }

      setLoading(false)
    } else {
      setInvalidPhone(!vPhone)
      setInvalidEmail(!vEmail)
    }
  }, 500)

  const handleClose = () => {
    setInvalidEmail(false)
    setInvalidPhone(false)
    setNewUserInfo(null)
    setErrors([])
    closeModal()
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewUserInfo({ ...newUserInfo, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    const profile = user.profiles?.find(
      (p: any) => p.partner_id === activeUser.organizationId
    )

    /*
              (!newUserInfo?.phoneNumber && !newUserInfo?.email) ||
              (!newUserInfo?.name_first && !newUserInfo?.name_last)
    */

    setNewUserInfo({
      email: user.email,
      phoneNumber: user.phone_number,
      name_first: profile?.name_first,
      name_last: profile?.name_last,
    })
  }, [
    activeUser.organizationId,
    user.email,
    user.phoneNumber,
    user.phone_number,
    user.profiles,
  ])

  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Box className={classes.style}>
        <div className={classes.header}>{i18n.t("common.modal.updateUser.updateUser")}</div>
        <div className={classes.body}>
          {loading ? (
            <Loader />
          ) : (
            <div>
              {showProfileInfo && (
                <>
                  <p>{i18n.t("common.modal.updateUser.firstName")}</p>
                  <TextField
                    id={"outlined-helperText"}
                    variant={"outlined"}
                    size="small"
                    name="name_first"
                    onChange={handleInputChange}
                    value={newUserInfo?.name_first}
                    type="text"
                    style={{
                      backgroundColor: "#FFF",
                      border: "none",
                      borderRadius: "5px",
                    }}
                    className={classes.input}
                  />

                  <p>{i18n.t("common.modal.updateUser.lastName")}</p>
                  <TextField
                    id={"outlined-helperText"}
                    variant={"outlined"}
                    size="small"
                    name="name_last"
                    onChange={handleInputChange}
                    value={newUserInfo?.name_last}
                    type="text"
                    style={{
                      backgroundColor: "#FFF",
                      border: "none",
                      borderRadius: "5px",
                    }}
                    className={classes.input}
                  />
                </>
              )}

              <p>{i18n.t("common.modal.updateUser.email")}</p>
              <TextField
                id={"outlined-helperText"}
                variant={"outlined"}
                size="small"
                name="email"
                onChange={handleInputChange}
                value={newUserInfo?.email}
                type="text"
                style={{
                  backgroundColor: "#FFF",
                  border: "none",
                  borderRadius: "5px",
                }}
                className={classes.input}
              />

              {invalidEmail && newUserInfo?.email !== "" && (
                <p className="text-red text-sm" style={{ color: "red" }}>
                  {i18n.t("common.modal.updateUser.invalidEmail")}
                </p>
              )}

              <p>{i18n.t("common.modal.updateUser.phoneNumber")}</p>
              <TextField
                id={"outlined-helperText"}
                variant={"outlined"}
                size="small"
                name="phoneNumber"
                onChange={handleInputChange}
                value={newUserInfo?.phoneNumber}
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                style={{
                  backgroundColor: "#FFF",
                  border: "none",
                  borderRadius: "5px",
                }}
                className={classes.input}
              />

              {invalidPhone && newUserInfo?.phoneNumber !== "" && (
                <p className="text-red text-sm" style={{ color: "red" }}>
                  {i18n.t("common.modal.updateUser.invalidPhone")}
                </p>
              )}
              {errors &&
                errors.length > 0 &&
                errors.map((e, i) => (
                  <React.Fragment key={i}>
                    <p
                      className="text-red text-sm mt-3"
                      style={{ color: "red" }}
                    >
                      {e}
                    </p>
                  </React.Fragment>
                ))}
            </div>
          )}
        </div>
        <div className="flex flex-col items-center">
          <Button
            onClick={() => updateUser()}
            style={{
              marginTop: "80px",
              margin: "auto",
              marginBottom: "10px",
            }}
            className={classes.actionButton + " w-40"}
            disabled={
              (!newUserInfo?.phoneNumber && !newUserInfo?.email) ||
              (showProfileInfo &&
                !newUserInfo?.name_first &&
                !newUserInfo?.name_last)
            }
          >
            {i18n.t("common.update")}
          </Button>
        </div>

        <Button
          variant="text"
          onClick={handleClose}
          className={classes.imgButton}
        >
          <CloseIcon />
        </Button>
      </Box>
    </Modal>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      padding: "15px",
    },
    style: {
      position: "absolute" as "absolute",
      left: "50%",
      transform: "translate(-50%, 30vh)",
      backgroundColor: "white",
      borderRadius: "5px",
      width: "300px",
      minWidth: "100px",
    },
    actionButton: {
      border: "1px solid #0F2150",
      borderRadius: "100px",
      backgroundColor: "#0F2150",
      color: "#fff",
      "&.Mui-disabled": {
        border: "1px solid #e2e5ea",
        backgroundColor: "#e2e5ea",
        color: "#b2b7c6",
      },
      "&:hover": {
        backgroundColor: "#1b3a8c",
      },
    },
    input: {
      margin: "5px",
      width: "260px",
      "& .MuiInputBase-input": {
        padding: "5px",
      },
    },
    imgButton: {
      background: "red",
      top: 0,
      right: 0,
      border: "none",
      cursor: "pointer",
      position: "absolute",
      textAlign: "center",
      borderRadius: "5px",
      height: "25px",
      minWidth: "25px",
      "&:hover": {
        backgroundColor: "#eb5c52",
      },
    },
    header: {
      padding: "1px",
      paddingLeft: "10px",
      backgroundColor: "lightgray",
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
      fontWeight: "bold",
      color: "#0F2150",
    },
  })
)
