import { Fragment, useCallback, useEffect, useState } from "react"

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Collapse,
  Grid,
} from "@mui/material"
import { Paper, createStyles, makeStyles, styled } from "@material-ui/core"
import { Rings } from "react-loader-spinner"
import CheckIcon from "@mui/icons-material/Check"
import ClearIcon from "@mui/icons-material/Clear"
import EditIcon from "@mui/icons-material/Edit"
import CloseIcon from "@material-ui/icons/Close"
import Carousel from "react-material-ui-carousel"

import { SnowWorklog, SortField } from "../../../interfaces"
import { useGraphQLService } from "../../../../../services/graphqlService"
import { useFetchImages } from "../../../../../utils/ImageHelper"
import { Loader } from "../../../../common/Loader"

type Props = {
  data: SnowWorklog[]
  sort:
    | {
        by: SortField
        order: "desc" | "asc" | "DESC" | "ASC"
      }
    | undefined
  sortHandler: (type: SortField) => void
  handleStatusChange: (status: "Approved" | "Cancelled" | "Edit", wlItem: SnowWorklog) => void
  handleServiceStatusChange: (status: "Cancelled" | "Approved", serviceItem: any, row: SnowWorklog) => void
  onNotesUpdate: (note: string, workLogId: string) => void
}

const SnowValidationWithoutWeatherTable = ({ data, sort, sortHandler, handleServiceStatusChange, handleStatusChange, onNotesUpdate }: Props) => {
  const [showActions, setShowActions] = useState(false)
  const [showNotesModal, setShowNotesModal] = useState(false)
  const [loadingDetails, setLoadingDetails] = useState(false)
  const [loadingNoteUpdate, setLoadingNoteUpdate] = useState(false)
  const [loadingImages, setLoadingImages] = useState(false)
  const [openImg, setOpenImg] = useState(false)
  const [showDetailIndex, setShowDetailIndex] = useState(-1)
  const [selectedWorklog, setSelectedWorklog] = useState<SnowWorklog>()
  const [note, setNote] = useState("")
  const [images, setImages] = useState<any[]>([])
  const [details, setDetails] = useState<any>({})

  const abortController = new AbortController()

  const { getWorkLogDetails, updateWorkOrderNotes } = useGraphQLService()
  const imageHelper = useFetchImages()
  const classes = useStyles()

  const updateWLNotes = useCallback(
    async (wlID: string, notes: string) => {
      try {
        const response = await updateWorkOrderNotes({
          work_log_id: wlID,
          service_partner_notes: notes,
          work_log_event: {
            type: "update",
          },
        })

        return response
      } catch (error) {
        console.log(error, "ERROR UPDATING NOTES")
      }
    },
    [updateWorkOrderNotes]
  )

  const handleEdit = useCallback(
    async (wlItem: SnowWorklog, index: number) => {
      setShowDetailIndex(index)
      setShowActions(false)
      setDetails({})

      try {
        setLoadingImages(true)

        const response: any = await getWorkLogDetails({ sfid: wlItem.workLogId }, undefined, true)

        setDetails(response)

        const sfids = response?.attachments.map((a: any) => a.sfid)

        const imagesData = await imageHelper.getImages(sfids, undefined, abortController.signal)

        setImages(imagesData)
      } catch (error) {
        console.log(error, "piip", wlItem)
      } finally {
        setLoadingImages(false)
      }
    },
    [abortController.signal, getWorkLogDetails, imageHelper]
  )

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f9f9f9",
      color: "#232F64",
      fontSize: "20px",
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
      width: "min-content",
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }))

  const renderRowDetails = useCallback(
    async (row: SnowWorklog, index: number) => {
      if (showDetailIndex === index) {
        setShowDetailIndex(-1)
        setImages([])
        setDetails({})
      } else {
        setShowDetailIndex(index)

        try {
          setLoadingImages(true)
          setLoadingDetails(true)

          const response: any = (await getWorkLogDetails({ sfid: row.workLogId }, undefined, true)) ?? {}
          setLoadingDetails(false)

          setDetails(response)

          const sfids = response?.attachments.map((a: any) => a.sfid)

          const imagesData = await imageHelper.getImages(sfids, undefined, abortController.signal)

          setImages(imagesData)
        } catch (error) {
          console.log(error, "piip", row)
        } finally {
          setLoadingImages(false)
          setLoadingDetails(false)
        }
      }
    },
    [abortController.signal, getWorkLogDetails, imageHelper, showDetailIndex]
  )

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  return (
    <>
      <Modal open={openImg} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" onClose={() => setOpenImg(false)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "60%",
              margin: "5px",
              backgroundColor: "white",
              position: "absolute",
            }}
          >
            <div className="p-8">
              <Carousel
                animation={"slide"}
                autoPlay={false}
                navButtonsAlwaysVisible={true}
                navButtonsProps={{
                  style: {},
                }}
              >
                {!!images?.length &&
                  images.map((img: any, i: any) => (
                    <div key={i} className="flex flex-col items-center">
                      {loadingImages ? <Loader /> : <img style={{ maxHeight: "85vh" }} alt="workorder_photo" src={img} />}
                    </div>
                  ))}
              </Carousel>
            </div>

            <Button
              variant="text"
              onClick={() => setOpenImg(false)}
              style={{
                background: "red",
                top: 0,
                right: 0,
                border: "none",
                cursor: "pointer",
                position: "absolute",
                textAlign: "center",
                borderRadius: "5px",
                height: "25px",
                minWidth: "25px",
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </Box>
      </Modal>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell onClick={() => sortHandler("workOrder")}>
                <div className="clickable flex w-min flex-row items-center whitespace-nowrap">
                  <span>Work Order #</span>
                  <div className="flex flex-col pl-4">
                    <span className={`${sort?.by === "workOrder" && sort?.order === "ASC" ? "text-case-grad-from" : "text-gray-bid-dates"}`}>
                      &#9206;
                    </span>
                    <span className={`${sort?.by === "workOrder" && sort?.order === "DESC" ? "text-case-grad-from" : "text-gray-bid-dates"}`}>
                      &#9207;
                    </span>
                  </div>
                </div>
              </StyledTableCell>
              <StyledTableCell onClick={() => sortHandler("siteName")}>
                <div className="clickable flex flex-row items-center">
                  <span>Site</span>
                  <div className="flex flex-col pl-4">
                    <span className={`${sort?.by === "siteName" && sort?.order === "ASC" ? "text-case-grad-from" : "text-gray-bid-dates"}`}>
                      &#9206;
                    </span>
                    <span className={`${sort?.by === "siteName" && sort?.order === "DESC" ? "text-case-grad-from" : "text-gray-bid-dates"}`}>
                      &#9207;
                    </span>
                  </div>
                </div>
              </StyledTableCell>
              <StyledTableCell>
                <div className="flex flex-row items-center">
                  <span>Service Summary</span>
                </div>
              </StyledTableCell>
              <StyledTableCell onClick={() => sortHandler("serviceDate")}>
                <div className="clickable flex flex-row items-center">
                  <span>Date</span>
                  <div className="flex flex-col pl-4">
                    <span className={`${sort?.by === "serviceDate" && sort?.order === "ASC" ? "text-case-grad-from" : "text-gray-bid-dates"}`}>
                      &#9206;
                    </span>
                    <span className={`${sort?.by === "serviceDate" && sort?.order === "DESC" ? "text-case-grad-from" : "text-gray-bid-dates"}`}>
                      &#9207;
                    </span>
                  </div>
                </div>
              </StyledTableCell>
              <StyledTableCell />
              <StyledTableCell width="10%">
                <div className="flex flex-row items-center whitespace-nowrap">
                  <span>WO Approval</span>
                </div>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {!!data.length ? (
            <TableBody>
              {data.map((worklog, index) => (
                <Fragment key={index}>
                  <StyledTableRow
                    className={`rowItem clickable ${
                      worklog.workOrderStatus.toLowerCase() === "pending" || !worklog.isReviewed ? "nonCompleted" : "completedValidationRow"
                    }${index === showDetailIndex ? " selectedRow" : ""}`}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    onClick={() => renderRowDetails(worklog, index)}
                  >
                    <StyledTableCell
                      className={
                        index === showDetailIndex && details?.sfid
                          ? "whitespace-nowrap hover:cursor-pointer hover:font-bold hover:text-case-grad-mid hover:underline"
                          : ""
                      }
                      onClick={
                        index === showDetailIndex && details?.sfid
                          ? (e) => {
                              e.stopPropagation()
                              window.open(`${process.env.REACT_APP_SF_LINK}/lightning/r/Work_Order__c/${details.sfid}/view`, "_blank")
                            }
                          : undefined
                      }
                    >
                      {worklog.workOrderName}
                    </StyledTableCell>
                    <StyledTableCell>{worklog.siteName}</StyledTableCell>
                    <StyledTableCell>{worklog.serviceSummary ?? ""}</StyledTableCell>
                    <StyledTableCell>{new Date(worklog.updatedAt).toLocaleDateString()}</StyledTableCell>
                    <StyledTableCell>
                      <button
                        className="rounded-md bg-case-grad-from px-4 py-2 font-bold text-white hover:scale-105 hover:cursor-pointer hover:bg-case-grad-mid hover:shadow-lg"
                        onClick={(e) => {
                          e.stopPropagation()
                          setNote(worklog.notes)
                          setSelectedWorklog(worklog)
                          setShowNotesModal(true)
                        }}
                      >
                        Notes
                      </button>
                    </StyledTableCell>
                    <TableCell
                      width={"10%"}
                      onMouseEnter={() => {
                        if (showDetailIndex !== index) {
                          setShowDetailIndex(index)
                          // setSelectedWorklog(row)
                          setShowActions(true)
                        }
                      }}
                      onMouseLeave={() => {
                        if (showDetailIndex === index && showActions) {
                          setShowDetailIndex(-1)
                          setImages([])
                          setShowActions(false)
                        }
                      }}
                    >
                      {showDetailIndex !== index ? (
                        <div className={`stormStatus ${worklog.workOrderStatus?.toLowerCase()}`}>{worklog.workOrderStatus}</div>
                      ) : !showActions ? (
                        <></>
                      ) : (
                        <div className="flex w-full flex-row items-center justify-center gap-2">
                          <div
                            className="cursor-pointer rounded-full bg-[#39CB73] p-[6px] text-white transition-all duration-150 ease-in-out hover:scale-110"
                            onClick={(e) => {
                              e.stopPropagation()

                              handleStatusChange("Approved", worklog)
                            }}
                          >
                            <CheckIcon fontSize="small" />
                          </div>
                          <div
                            className="cursor-pointer rounded-full bg-[#FF1744] p-[6px] text-white transition-all duration-150 ease-in-out hover:scale-110"
                            onClick={(e) => {
                              e.stopPropagation()

                              handleStatusChange("Cancelled", worklog)
                            }}
                          >
                            <ClearIcon fontSize="small" />
                          </div>
                          <div
                            className="cursor-pointer rounded-full bg-[#4EC7F1] p-[6px] text-white transition-all duration-150 ease-in-out hover:scale-110"
                            onClick={(e) => {
                              e.stopPropagation()

                              handleEdit(worklog, index)
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </div>
                        </div>
                      )}
                    </TableCell>
                  </StyledTableRow>

                  <TableRow>
                    <TableCell style={{ padding: 0, margin: 0 }} colSpan={6}>
                      <Collapse in={index === showDetailIndex && !showActions} timeout={100} unmountOnExit className="subContent bg-case-gray">
                        {loadingDetails || !details ? (
                          <Loader />
                        ) : (
                          <div className="relative flex flex-col overflow-hidden">
                            {details.services?.map((service: any, serviceIndex: number) => {
                              return (
                                <TableRow
                                  key={serviceIndex}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell style={{ width: "100%", maxWidth: "53%" }}></TableCell>
                                  <TableCell style={{ width: "100%", maxWidth: "44%" }}>
                                    {service?.service_name ?? "-"} {service?.status ?? ""}
                                  </TableCell>
                                  <TableCell style={{ width: "100%", maxWidth: "3%" }}>
                                    {service && (
                                      <div className="flex flex-row items-center justify-center gap-4">
                                        <div
                                          className={`statusActionIcon transition-all duration-150 ease-in-out ${
                                            service.approval_status === "Pending"
                                              ? "hover:scale-110"
                                              : service.approval_status === "Approved"
                                              ? "scale-100 border-2 border-case-grad-from"
                                              : "opacity-50 hover:scale-110 hover:opacity-100"
                                          }`}
                                          style={{ backgroundColor: "#39CB73" }}
                                          onClick={() => {
                                            handleServiceStatusChange("Approved", service, worklog)
                                          }}
                                        >
                                          <CheckIcon fontSize="small" />
                                        </div>
                                        <div
                                          className={`statusActionIcon transition-all duration-150 ease-in-out ${
                                            service.approval_status === "Pending"
                                              ? "hover:scale-110"
                                              : service.approval_status === "Cancelled"
                                              ? "scale-100 border-2 border-case-grad-from"
                                              : "opacity-50 hover:scale-110 hover:opacity-100"
                                          }`}
                                          style={{ backgroundColor: "#FF1744" }}
                                          onClick={() => {
                                            handleServiceStatusChange("Cancelled", service, worklog)
                                          }}
                                        >
                                          <ClearIcon fontSize="small" />
                                        </div>
                                      </div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              )
                            })}

                            {showDetailIndex >= 0 && !showActions && (
                              <Grid item xs={12}>
                                <div className={classes.grid}>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography className={classes.titleFont}>Photos</Typography>
                                    </Grid>

                                    <Grid item xs={12} className={classes.scrollableImg}>
                                      <div className="flex flex-row items-center">
                                        {loadingImages ? (
                                          <Loader />
                                        ) : (
                                          <>
                                            {!!images?.length ? (
                                              images.map((img: any, index: any) => (
                                                <div key={index} className={classes.imgContainer}>
                                                  <img
                                                    className={classes.image + " cursor-pointer"}
                                                    alt="workorder_photo"
                                                    onClick={() => setOpenImg(true)}
                                                    src={img}
                                                  />
                                                </div>
                                              ))
                                            ) : (
                                              <div className="text-lg">No images found</div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </Grid>
                            )}
                          </div>
                        )}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={6}>
                  EMPTY
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <br />

      <Modal
        open={showNotesModal}
        onClose={() => setShowNotesModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            maxHeight: 500,
            overflow: "auto",
            bgcolor: "background.paper",
            backgroundColor: "#fff",
            border: "1px solid #000",
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Notes for {selectedWorklog?.workOrderName}
            </Typography>
            <br />
            <TextField
              id="outlined-multiline-static"
              label="Add Notes"
              multiline
              minRows={4}
              value={note}
              fullWidth
              disabled={loadingNoteUpdate}
              onChange={(e) => setNote(e.target.value)}
              variant="standard"
            />

            <div className="modalButtonsContainer">
              {loadingNoteUpdate && <Rings color="#4EC7F1" width={50} height={50} />}
              <Button variant="contained" color="secondary" onClick={() => setShowNotesModal(false)} style={{ backgroundColor: "#ff1744" }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={async () => {
                  if (selectedWorklog?.notes !== note && selectedWorklog?.workLogId) {
                    try {
                      setLoadingNoteUpdate(true)
                      const resp = await updateWLNotes(selectedWorklog?.workLogId, note)

                      if (resp?.message?.includes("successfully")) {
                        onNotesUpdate(note, selectedWorklog?.workLogId)
                        setShowNotesModal(false)
                      }
                    } catch (e) {
                      console.log(e)
                    } finally {
                      setLoadingNoteUpdate(false)
                    }
                  }
                }}
                style={{ backgroundColor: "#0e2151" }}
              >
                Save
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    titleFont: {
      fontSize: "22px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#000",
      letterSpacing: "-1px",
      marginBottom: "20px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      padding: "8px",
      background: "#FFFFFF",
    },
    image: {
      maxHeight: "100px",
      borderRadius: "5px",
    },
    imgContainer: {
      position: "relative",
      maxWidth: "150px",
      maxHeight: "100px",
      margin: "0px 5px 5px 0px",
      minWidth: "fit-content",
    },
    scrollableImg: {
      width: "500px",
      overflowY: "hidden",
    },
  })
)

export default SnowValidationWithoutWeatherTable
