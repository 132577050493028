import { useCallback, useEffect, useState } from "react"
import { makeStyles, createStyles, TextField, InputAdornment, Button, Grid } from "@material-ui/core"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { styled } from "@mui/material/styles"
import Pagination from "../../common/Pagination"
import { Loader } from "../../common/Loader"
import { ReactComponent as UpIcon } from "./../../../assets/up.svg"
import { ReactComponent as DownIcon } from "./../../../assets/down.svg"
import { useActiveUser } from "../../../providers/AuthProvider"
import { useDebouncedCallback } from "use-debounce"
import ActionMenu from "./ActionMenu"
import UpdateUserModal from "../common/UpdateUserModal"
import UpdateRoleModal from "../common/UpdateRoleModal"
import DeleteUserModal from "../common/DeleteUserModal"
import InviteUserModal from "../common/InviteUserModal"
import { ReactComponent as SearchIcon } from "./../../../assets/search.svg"
import { getFistLogin, getLastLogin } from "../../../utils/Util"
import useI18n from "../../../hooks/useI18n"
import { useGraphQLService } from "../../../services/graphqlService"

export const TeamLists = () => {
  const classes = useStyles()
  const { i18n } = useI18n()
  const [data, setData] = useState<any[]>([])
  const [sort, setSort] = useState({
    field: "email",
    order: "ASC",
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const user = useActiveUser()
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [openUpdateRoleModal, setOpenUpdateRoleModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openInviteModal, setOpenInviteModal] = useState(false)
  const [search, setSearch] = useState<string>()
  const [searchInput, setSearchInput] = useState("")
  const { getOrgUsers } = useGraphQLService()

  const [selectedUser, setSelectedUser] = useState<any>({})

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f9f9f9",
      color: "#232F64",
      fontSize: "20px",
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }))

  const getUsers = useDebouncedCallback(async (page: number, perPage: number, search?: string, sort?: any, signal?: AbortSignal) => {
    setLoading(true)

    const response = await getOrgUsers(
      {
        orgId: user.organizationId,
        limit: perPage,
        offset: (page - 1) * perPage,
        sort: sort.field,
        searchText: search,
        sortOrder: sort.order,
      },
      signal
    )

    setData(response?.rows ?? [])
    setTotal(response?.pagination?.found_rows ?? 0)
    setLoading(false)
  }, 500)

  useEffect(() => {
    const controller = new AbortController()
    getUsers(page, perPage, search, sort, controller.signal)
    return () => controller.abort()
  }, [getUsers, page, perPage, sort, search])

  const sortHandler = (by: any) => {
    if (by === sort.field) {
      if (sort.order === "ASC") {
        setSort({ field: sort.field, order: "DESC" })
      } else {
        setSort({ field: sort.field, order: "ASC" })
      }
    } else {
      setSort({ field: by, order: sort.order })
    }
  }

  const openEdit = (user: any) => {
    setSelectedUser(user)
    setOpenUpdateModal(true)
  }

  const closeUpdateModal = () => {
    setOpenUpdateModal(false)
  }

  const openEditRole = (user: any) => {
    setSelectedUser(user)
    setOpenUpdateRoleModal(true)
  }

  const closeUpdateRoleModal = () => {
    setOpenUpdateRoleModal(false)
  }

  const openDelete = (user: any) => {
    setSelectedUser(user)
    setOpenDeleteModal(true)
  }

  const closeDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const reload = () => {
    getUsers(page, perPage, search, sort)
  }

  const handleSearch = useCallback(() => {
    setSearch(searchInput || undefined)
    setPage(1)
  }, [searchInput])

  const closeInviteModal = () => {
    setOpenInviteModal(false)
  }

  if (error) {
    return <p>ERROR: {error}</p>
  } else {
    return (
      <>
        <InviteUserModal user={user} openModal={openInviteModal} closeModal={closeInviteModal} reload={reload} />
        <UpdateUserModal user={selectedUser} openModal={openUpdateModal} closeModal={closeUpdateModal} reload={reload} showProfileInfo={true} />
        <UpdateRoleModal
          user={selectedUser}
          openModal={openUpdateRoleModal}
          closeModal={closeUpdateRoleModal}
          reload={reload}
          orgId={user.organizationId}
        />
        <DeleteUserModal user={selectedUser} openModal={openDeleteModal} closeModal={closeDeleteModal} reload={reload} />
        <div className={classes.listContainer}>
          <Grid container className="flex-row items-center pb-2.5">
            <Grid item xs={4} className="flex flex-row items-center">
              <TextField
                size="small"
                id="input-with-icon-textfield"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                placeholder={"Search by email/phone number"}
                value={searchInput}
                onChange={(_) => setSearchInput(_.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch()
                  }
                }}
                className={classes.searchInput + " w-full"}
              />
              <Button variant="outlined" size="small" className={classes.buttonFilter} onClick={() => handleSearch()}>
                {i18n.t("common.search")}
              </Button>
            </Grid>
            <Grid item xs={6} className="flex flex-row items-center"></Grid>
            <Grid item xs={2} className="flex flex-row items-center">
              <Button
                onClick={() => setOpenInviteModal(true)}
                style={{
                  marginTop: "80px",
                  margin: "auto",
                  marginBottom: "10px",
                }}
                className={classes.actionButton + " w-40"}
              >
                {i18n.t("common.modal.inviteUser.inviteUser")}
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell onClick={() => sortHandler("email")} style={{ cursor: "pointer" }}>
                    <div className="flex flex-row items-center">
                      {sort.field === "email" && sort.order === "DESC" && <DownIcon />}
                      {sort.field === "email" && sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.modal.updateUser.email")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell onClick={() => sortHandler("phone_number")} style={{ cursor: "pointer", minWidth: "120px" }}>
                    <div className="flex min-w-fit flex-row items-center">
                      {sort.field === "phone_number" && sort.order === "DESC" && <DownIcon />}
                      {sort.field === "phone_number" && sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.modal.updateUser.phoneNumber")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="flex min-w-fit flex-row items-center">
                      <span>{i18n.t("common.tableHeaders.name")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="flex min-w-fit flex-row items-center">
                      {sort.field === "role_name" && sort.order === "DESC" && <DownIcon />}
                      {sort.field === "role_name" && sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.roles")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="flex min-w-fit flex-row items-center">
                      <span>{i18n.t("common.tableHeaders.firstLogin")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="flex min-w-fit flex-row items-center">
                      <span>{i18n.t("common.tableHeaders.lastLogin")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="flex min-w-fit flex-row items-center">
                      <span>{i18n.t("common.tableHeaders.action")}</span>
                    </div>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {!loading && !!data.length && (
                <TableBody>
                  {data.map((row: any, i: any) => {
                    const profile = row.profiles?.find((p: any) => p.partner_id === user.organizationId)

                    return (
                      <StyledTableRow
                        key={`${i}_${row.sfid}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <StyledTableCell>{row?.email || "-"}</StyledTableCell>
                        <StyledTableCell>{row?.phone_number || "-"}</StyledTableCell>
                        <StyledTableCell>{profile ? `${profile?.name_first} ${profile?.name_last}` : "-"}</StyledTableCell>
                        <StyledTableCell>
                          {row.organization_roles
                            .map(({ name }: { name: string }) => name)
                            .filter((v: string, i: number, s: string[]) => s.indexOf(v) === i)
                            .join(", ")}
                        </StyledTableCell>
                        <StyledTableCell>{getFistLogin(row.authorizations || [])}</StyledTableCell>
                        <StyledTableCell>{getLastLogin(row.authorizations || [])}</StyledTableCell>
                        <StyledTableCell>
                          <ActionMenu openEdit={openEdit} openEditRole={openEditRole} openDelete={openDelete} selectedUser={row} />
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {loading && <Loader />}
          {!loading && !data.length && (
            <div className="text-center">
              <div className="inline-block">
                <p>{i18n.t("common.empty")}</p>
              </div>
            </div>
          )}

          <Pagination page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} total={total} />
        </div>
      </>
    )
  }
}
const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      width: "auto",
      marginLeft: 0,
      padding: "30px",
    },
    menu: {
      position: "relative",
    },
    searchInput: {
      backgroundColor: "white",
    },
    buttonFilter: {
      backgroundColor: "#f9f9f9",
      height: "40px",
      minWidth: "60px",
      fontSize: "10px",
    },
    actionButton: {
      border: "1px solid #0F2150",
      borderRadius: "5px",
      backgroundColor: "#0F2150",
      color: "#fff",
      "&.Mui-disabled": {
        border: "1px solid #e2e5ea",
        backgroundColor: "#e2e5ea",
        color: "#b2b7c6",
      },
      "&:hover": {
        backgroundColor: "#1b3a8c",
      },
    },
  })
)

export default TeamLists
