import React, { useCallback, useContext, useEffect, useState } from "react"

import { Paper, Modal, TextField, Grid, Container, makeStyles, Tooltip } from "@material-ui/core"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import ClearIcon from "@mui/icons-material/Clear"
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined"
import Box from "@mui/system/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Carousel from "react-material-ui-carousel"
import CloseIcon from "@material-ui/icons/Close"
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined"
import CircularProgress from "@mui/material/CircularProgress"
import { createStyles } from "@material-ui/core"
import { DateTime } from "luxon"

import "./SeasonCardComponent.css"
import { Loader } from "../../../../common/Loader"
import { LandValidation, LandWorklog, ServicePeriod } from "../../../interfaces"
import { ValidationContext } from "../../../../../providers/ValidationProvider"
import { useGraphQLService } from "../../../../../services/graphqlService"
import { useFetchImages } from "../../../../../utils/ImageHelper"
import { ReactComponent as WorkordenIcon } from "../../../../../assets/menu-orders-b.svg"
import { ReactComponent as CalendarIcon } from "../../../../../assets/calendar-b.svg"
import { ReactComponent as ClockIcon } from "../../../../../assets/clock-b.svg"
import { ReactComponent as HourglassIcon } from "../../../../../assets/hourglass-b.svg"
import { ReactComponent as MessageIcon } from "../../../../../assets/message-text.svg"
import { MIN_DISTANCE_FROM_SITE, getMinFormat } from "../../../../../utils/Util"

interface Props {
  period: ServicePeriod
  selectedService: {
    periodIndex: number
    serviceKey: string
    sfid: string
    weekNumber?: number | undefined
  } | null
  onStatusUpdate?: (periods: ServicePeriod[]) => void
}

const PeriodCardComponent = ({ period, selectedService: rootSelectedService, onStatusUpdate }: Props) => {
  const [loading, setLoading] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const [showNotesModal, setShowNotesModal] = useState(false)
  const [selectedService, setSelectedService] = useState({
    sfid: "",
    wlIndex: -1,
  })
  const [showWODetailsModal, setShowWODetailsModal] = useState(false)
  const [loadingImages, setLoadingImages] = useState(false)
  const [openImg, setOpenImg] = useState(false)
  const [note, setNote] = useState("")
  const [periodData, setPeriodData] = useState<ServicePeriod>()
  const [showDetailIndex, setShowDetailIndex] = useState(-1)
  const [images, setImages] = useState<any[]>([])
  const [details, setDetails] = useState<any>({})

  const { validationState, setValidation } = useContext(ValidationContext)
  const { getWorkLogDetails, updateWorkOrderService, updateServicePeriodCompletion } = useGraphQLService()
  const imageHelper = useFetchImages()
  const classes = useStyles()

  useEffect(() => {
    setPeriodData(period)
  }, [period])

  const handleStatusChange = useCallback(
    async (status: "Approved" | "Cancelled", wlItem: LandWorklog) => {
      if (!validationState?.selectedValidation) return

      const selectedValidation = validationState.selectedValidation as LandValidation

      setLoading(true)

      try {
        const resp = await updateWorkOrderService({
          approval_notes: note.length ? note : wlItem.service?.approval_notes ?? "",
          approval_status: status,
          service_id: wlItem.service?.sfid ?? "",
        })

        if (resp) {
          let newPeriods = selectedValidation.servicePeriods?.map((sp) => {
            const newWorklogs = sp.worklogs.map((wl) => {
              if (wl.worklogId === wlItem.worklogId) {
                return {
                  ...wl,
                  service: {
                    ...wl.service,
                    approval_status: status,
                    approval_notes: note,
                  },
                }
              }

              return wl
            })

            return {
              ...sp,
              worklogs: newWorklogs,
            } as ServicePeriod
          })

          if (periodData?.reviewComplete) {
            const result = await updateServicePeriodCompletion({
              sfid: rootSelectedService?.sfid ?? "",
              week: rootSelectedService?.periodIndex ?? 0,
              value: !periodData.reviewComplete,
            })

            if (result) {
              newPeriods[rootSelectedService?.periodIndex ?? 0].reviewComplete = !periodData.reviewComplete
              periodData.reviewComplete = !periodData.reviewComplete
            }
          }

          setValidation({
            ...validationState,
            selectedValidation: {
              ...selectedValidation,
              servicePeriods: newPeriods,
            },
          })

          setShowDetailIndex(-1)
          setImages([])
          setShowActions(false)
          onStatusUpdate && onStatusUpdate(newPeriods)
        }
      } catch (error) {
        alert("An error occurs trying to update WOS Approval Status, please try again.")
      } finally {
        setLoading(false)
      }
    },
    [note, onStatusUpdate, periodData, rootSelectedService, setValidation, updateWorkOrderService, updateServicePeriodCompletion, validationState]
  )

  const handleNotes = useCallback(async () => {
    try {
      if (!validationState?.selectedValidation) return

      setShowNotesModal(false)

      setLoading(true)

      const resp = await updateWorkOrderService({
        service_id: selectedService.sfid,
        approval_notes: note?.length ? note : " ",
      })

      if (resp && periodData) {
        const wls = periodData?.worklogs.map((w, index) => {
          if (index === selectedService.wlIndex && w.service) {
            w.service.approval_notes = note
          }

          return w
        })

        const newPeriodData = {
          ...periodData,
          worklogs: wls,
        }

        setPeriodData(newPeriodData)
      }
    } catch (error) {
      console.log("Error updating notes", error)
    } finally {
      setLoading(false)
    }
  }, [note, periodData, selectedService, updateWorkOrderService, validationState?.selectedValidation])

  const fetchWorklogDetails = useCallback(
    async (sfid: string | null) => {
      if (sfid === null) return

      try {
        // const landResponse = await getLandWorklog(
        //   sfid
        // )
        const woResponse = await getWorkLogDetails({ sfid }, undefined, true)

        setDetails(woResponse)

        return woResponse
      } catch (error) {
        console.log(error, "error on WO click", sfid)
        return null
      }
    },
    [getWorkLogDetails]
  )

  return (
    <>
      <Modal open={openImg} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" onClose={() => setOpenImg(false)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "60%",
              margin: "5px",
              backgroundColor: "white",
              position: "absolute",
            }}
          >
            <div className="p-8">
              <Carousel
                animation={"slide"}
                autoPlay={false}
                navButtonsAlwaysVisible={true}
                navButtonsProps={{
                  style: {},
                }}
              >
                {!!images?.length &&
                  images.map((img: any, i: any) => (
                    <div key={i} className="flex flex-col items-center">
                      {loadingImages ? <Loader /> : <img style={{ maxHeight: "85vh" }} alt="workorder_photo" src={img} />}
                    </div>
                  ))}
              </Carousel>
            </div>

            <Button
              variant="text"
              onClick={() => setOpenImg(false)}
              style={{
                background: "red",
                top: 0,
                right: 0,
                border: "none",
                cursor: "pointer",
                position: "absolute",
                textAlign: "center",
                borderRadius: "5px",
                height: "25px",
                minWidth: "25px",
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </Box>
      </Modal>

      <div className="containerItem">
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead style={{ backgroundColor: "#DDDEE4" }}>
              <TableRow>
                <TableCell width="7%" onClick={() => {}}>
                  <div className="flex flex-row items-center">
                    <span>Work Order #</span>
                  </div>
                </TableCell>
                <TableCell width="10%" onClick={() => {}}>
                  <div className="flex flex-row items-center">
                    <span>Reported Completion Date</span>
                  </div>
                </TableCell>
                <TableCell width="10%" onClick={() => {}}>
                  <div className="flex flex-row items-center">
                    <span>Service Provider</span>
                  </div>
                </TableCell>
                <TableCell width="10%" onClick={() => {}}>
                  <div className="flex flex-row items-center">
                    <span>Reported Distance from Site</span>
                  </div>
                </TableCell>
                <TableCell width="10%" onClick={() => {}}>
                  <div className="flex flex-row items-center">
                    <span>WOMS WO</span>
                  </div>
                </TableCell>
                <TableCell width="26%" onClick={() => {}}>
                  <div className="flex flex-row items-center">
                    <span>WOS Algorithm Reasons</span>
                  </div>
                </TableCell>
                <TableCell width="7%" onClick={() => {}}>
                  <div className="flex flex-row items-center">
                    <span>WOS Algorithm Status</span>
                  </div>
                </TableCell>
                <TableCell width="10%" onClick={() => {}}>
                  <div className="flex flex-row items-center">
                    <span>WOS Approval Status</span>
                  </div>
                </TableCell>
                {/* <TableCell width="10%" onClick={() => {}}>
                  <div className="flex flex-row items-center">
                    <span>Associated Cases</span>
                  </div>
                </TableCell> */}
              </TableRow>
            </TableHead>
            {!loading ? (
              <TableBody>
                {periodData?.worklogs?.map((row, index) => (
                  <React.Fragment key={row.worklogId}>
                    <TableRow
                      className={`rowItem clickable ${index === showDetailIndex ? "selectedRow" : ""}`}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      onClick={async () => {
                        if (showDetailIndex === index && !showWODetailsModal) {
                          setShowDetailIndex(-1)
                          setImages([])
                          setDetails({})
                        } else {
                          setShowDetailIndex(index)

                          setLoadingImages(true)

                          const woResponse = await fetchWorklogDetails(row.worklogId)

                          const sfids = woResponse?.attachments.map((a: any) => a.sfid)

                          if (!sfids) {
                            setImages([])

                            return
                          }

                          const imagesData = await imageHelper.getImages(sfids)

                          setImages(imagesData)
                          setLoadingImages(false)
                        }
                      }}
                    >
                      <TableCell width="7%">
                        {showDetailIndex === index ? (
                          <span
                            className="z-50 mr-2 cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation()
                              setShowWODetailsModal(true)
                            }}
                          >
                            <LaunchOutlinedIcon />
                          </span>
                        ) : null}
                        <span
                          className="whitespace-nowrap hover:cursor-pointer hover:font-bold hover:text-case-grad-mid hover:underline"
                          onClick={(e) => {
                            e.stopPropagation()
                            window.open(`${process.env.REACT_APP_SF_LINK}/lightning/r/Work_Order__c/${row.sfid}/view`, "_blank")
                          }}
                        >
                          {row.work_order_name}
                        </span>
                      </TableCell>
                      <TableCell width="10%">
                        {(isNaN(DateTime.fromSQL(row?.backDatedTo ?? row?.workLogEnd ?? "")?.weekNumber)
                          ? DateTime.fromISO(
                              (row?.backDatedTo ?? row?.workLogEnd ?? "")?.substring(0, (row?.backDatedTo ?? row?.workLogEnd ?? "")?.lastIndexOf("-"))
                            )
                          : DateTime.fromSQL(row?.backDatedTo ?? row?.workLogEnd ?? "")
                        ).toFormat("D")}
                        {row.backDatedTo ? <label className="font-bold text-case-grad-from">(B)</label> : ""}
                      </TableCell>
                      <TableCell width="10%">{row.service_provider}</TableCell>
                      <TableCell width="10%">
                        {row.distance_from_site ? (
                          <label className={`${row.distance_from_site > MIN_DISTANCE_FROM_SITE ? "font-bold text-[#FF3333]" : ""}`}>
                            {row.distance_from_site}mi
                          </label>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell width="10%">{row.woms_wo ?? "-"}</TableCell>
                      <TableCell width="26%">
                        <span style={{ whiteSpace: "pre-line" }}>{row.reasons.map((r) => `- ${r}`).join("\n")}</span>
                      </TableCell>
                      <TableCell width="7%">{row.service?.algorithm_status ?? "-"}</TableCell>
                      <TableCell
                        width="10%"
                        onMouseEnter={() => {
                          if (showDetailIndex !== index) {
                            setShowDetailIndex(index)
                            // setSelectedWorklog(row)
                            setShowActions(true)
                          }
                        }}
                        onMouseLeave={() => {
                          setShowDetailIndex(-1)
                          setImages([])
                          setShowActions(false)
                        }}
                      >
                        {showDetailIndex !== index ? (
                          <div className={`stormStatus ${row.service?.approval_status?.toLowerCase()}`}>{row.service?.approval_status ?? "-"}</div>
                        ) : (
                          <div className="statusActionIconsContainer">
                            <div
                              className="statusActionIcon"
                              style={{ backgroundColor: "#39CB73" }}
                              onClick={() => {
                                setNote(row.service?.approval_notes ?? "")
                                handleStatusChange("Approved", row)
                              }}
                            >
                              <CheckIcon fontSize="small" />
                            </div>
                            <div
                              className="statusActionIcon"
                              style={{ backgroundColor: "#FF1744" }}
                              onClick={() => {
                                setNote(row.service?.approval_notes ?? "")
                                handleStatusChange("Cancelled", row)
                              }}
                            >
                              <ClearIcon fontSize="small" />
                            </div>
                            <div
                              className="statusActionIcon"
                              style={{
                                backgroundColor: row.service?.approval_notes ? "#FFE033" : "#808080",
                              }}
                              onClick={async () => {
                                setShowNotesModal(true)
                                setShowDetailIndex(index)
                                setSelectedService({
                                  sfid: row.service?.sfid ?? "",
                                  wlIndex: index,
                                })
                                setNote(row.service?.approval_notes ?? "")

                                await fetchWorklogDetails(row.worklogId)
                              }}
                            >
                              {row.service?.approval_notes ? (
                                <Tooltip title={row.service.approval_notes}>
                                  <NoteAddOutlinedIcon fontSize="small" />
                                </Tooltip>
                              ) : (
                                <NoteAddOutlinedIcon fontSize="small" />
                              )}
                            </div>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
                {!periodData?.worklogs?.length && (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div className="text-center">
                        <div className="inline-block">
                          <p>EMPTY</p>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <Loader />
            )}
          </Table>
        </TableContainer>

        {showDetailIndex >= 0 && !showActions && (
          <div
            style={{
              padding: 8,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <label
              style={{
                fontSize: 35,
                fontWeight: "bold",
                color: "#232f64",
              }}
            >
              Photos
            </label>
            <div
              className="flex flex-row"
              style={{
                width: "100%",
                overflowY: "hidden",
                gap: "10px",
              }}
            >
              {!loadingImages ? (
                !!images?.length ? (
                  images.map((img: any, i: any) => (
                    <div
                      key={i}
                      style={{
                        position: "relative",
                        // width: "100%",
                        margin: "10px 0px",
                      }}
                    >
                      <img
                        style={{
                          minWidth: "150px",
                          maxHeight: "200px",
                          // backgroundColor: 'black',
                          cursor: "pointer",
                          // objectFit: 'contain'
                        }}
                        alt="workorder_photo"
                        onClick={() => setOpenImg(true)}
                        src={img}
                      />
                    </div>
                  ))
                ) : (
                  <Typography>No photos available</Typography>
                )
              ) : (
                <Loader />
              )}
            </div>
          </div>
        )}

        <Modal
          open={showNotesModal}
          onClose={() => {
            setShowNotesModal(false)
            setSelectedService({
              sfid: "",
              wlIndex: -1,
            })
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              maxHeight: 500,
              overflow: "auto",
              bgcolor: "background.paper",
              backgroundColor: "#fff",
              border: "1px solid #000",
              borderRadius: 4,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Notes for {details?.name ?? <CircularProgress size={20} className="mx-4" />}
              </Typography>
              <br />
              <TextField
                id="outlined-multiline-static"
                label="Add Notes"
                multiline
                minRows={4}
                value={note}
                fullWidth
                onChange={(e) => setNote(e.target.value)}
                variant="standard"
              />

              <div className="modalButtonsContainer">
                <Button
                  variant="contained"
                  onClick={() => {
                    setShowNotesModal(false)
                    setSelectedService({
                      sfid: "",
                      wlIndex: -1,
                    })
                  }}
                  style={{
                    backgroundColor: "#ff1744",
                    color: "white",
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingLeft: 8,
                    paddingRight: 8,
                    borderRadius: 5,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNotes}
                  style={{
                    backgroundColor: "#0e2151",
                    color: "white",
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingLeft: 8,
                    paddingRight: 8,
                    borderRadius: 5,
                  }}
                >
                  Save
                </Button>
              </div>
            </Typography>
          </Box>
        </Modal>

        <Modal
          open={showWODetailsModal}
          onClose={() => setShowWODetailsModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              // width: 1000,
              maxHeight: 1000,
              overflow: "auto",
              bgcolor: "background.paper",
              backgroundColor: "#fff",
              border: "1px solid #000",
              borderRadius: 4,
              boxShadow: 24,
              p: 4,
            }}
          >
            <div className="bg-case-gray">
              <Container className={classes.container}>
                <Grid container>
                  <Grid item xs={6}>
                    <div className={classes.gridHeight}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.titleFont}>Work Orders Details</Typography>
                        </Grid>
                        <Grid item xs={12} className="mt-8 flex flex-row items-center">
                          <WorkordenIcon className="mr-3" />
                          <div>
                            <span className={classes.infoFont}>{details?.name}</span>
                            <Typography noWrap className={classes.infoTitle}>
                              Work Order #
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} className="mt-8 flex flex-row items-center">
                          <CalendarIcon className="mr-3" />
                          <div>
                            <span className={classes.infoFont}>
                              {details?.service_date ? DateTime.fromISO(details?.service_date).toFormat("D") : "-"}
                            </span>
                            <Typography noWrap className={classes.infoTitle}>
                              Service Date
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={6} className="mt-8 flex flex-row items-center">
                          <ClockIcon className="mr-3" />
                          <div>
                            <span className={classes.infoFont}>
                              {details?.service_date ? DateTime.fromISO(details?.service_date).toFormat("t") : "-"}
                            </span>
                            <Typography noWrap className={classes.infoTitle}>
                              Service Time
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={6} className="mt-8 flex flex-row items-center">
                          <HourglassIcon className="mr-3" />
                          <div>
                            <span className={classes.infoFont}>{details?.time_taken_minutes ? getMinFormat(details?.time_taken_minutes) : "-"}</span>
                            <Typography noWrap className={classes.infoTitle}>
                              Duration on-site
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.gridHeight}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.titleFont}>Services Logged</Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <div className=" m-3">
                            {!!details?.services?.length &&
                              details?.services.map((service: any, index: any) => (
                                <div key={index} className="flex flex-row items-center font-bold">
                                  <div className="w-full">
                                    {service?.service_key || "-"}
                                    {" / "}
                                    {service?.status}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </Grid>
                        <Grid item xs={12} className="mt-8 flex flex-row items-center">
                          <MessageIcon className="ml-3" />
                          <div>
                            <span className={classes.commentsTitle}>Comments:</span>
                          </div>
                        </Grid>

                        <Grid item xs={12} className="mt-8 flex flex-row items-center">
                          <div>
                            <span className={classes.comments}>{details?.comments || "-"}</span>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    titleFont: {
      fontSize: "22px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#000",
      letterSpacing: "-1px",
      marginBottom: "20px",
    },
    infoFont: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 20,
      marginLeft: "30px",
    },
    commentsTitle: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 15,
      marginLeft: "0px",
    },
    comments: {
      fontSize: 15,
      marginLeft: "10px",
    },
    infoTitle: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
      marginBottom: "10px",
      marginLeft: "30px",
    },
    container: {
      maxWidth: "100%",
    },
    gridHeight: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      padding: "5px",
      background: "#FFFFFF",
      minHeight: "95.5%",
    },
  })
)

export default PeriodCardComponent
