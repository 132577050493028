import { Rings } from "react-loader-spinner"

export const Loader = (props: any) => {
  return (
    <div className="text-center">
      <div className="inline-block">
        <Rings color="#4EC7F1" />
      </div>
    </div>
  )
}
