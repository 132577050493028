import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Modal,
  TextField,
} from "@material-ui/core"
import axios from "axios"
import React, { useCallback, useMemo, useState } from "react"
import { useUser } from "../../../providers/AuthProvider"
import {
  transformPhoneNumber,
  validateEmail,
  validatePhoneNumber,
} from "../../../utils/Util"
import { OrganizationRoleSelect } from "../../Admin/OrganizationRoleSelect"
import { OrganizationSelect } from "../../Admin/OrganizationSelect"
import { Loader } from "../../common/Loader"
import { TeamModalProps } from "../desktop/TeamDesktop"
import { ReactComponent as CloseIcon } from "./../../../assets/close-w.svg"
import useI18n from "../../../hooks/useI18n"
const case_auth = process.env?.REACT_APP_SERVER_URL

export default function InviteUserModal({
  user,
  openModal,
  closeModal,
  reload,
}: TeamModalProps) {
  const classes = useStyles()
  const { i18n } = useI18n()
  const [input, setInput] = useState("")
  const [validInput, setValidInput] = useState<Boolean>(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [currentOrganization, setCurrentOrganization] = useState<any>()
  const [currentOrganizationRole, setCurrentOrganizationRole] = useState<any>()
  const { token } = useUser()
  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      Authorization: token,
    }),
    [token]
  )

  const handleClose = () => {
    setLoading(false)
    setError("")
    setValidInput(true)
    setInput("")
    closeModal()
  }

  const inputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("")
    setValidInput(true)
    setInput(event.target.value.toLocaleLowerCase())
  }

  const handleInvite = useCallback(
    async (e: any) => {
      e.preventDefault()

      const vemail = validateEmail(input)
      const vphone = validatePhoneNumber(input)
      setValidInput(vemail || vphone)

      if (vemail) {
        setLoading(true)
        handleInviteEmail()
        return
      }

      if (vphone) {
        let phone = transformPhoneNumber(input)
        setLoading(true)
        handleInvitePhone(phone)
      }
    },
    [
      input,
      setLoading,
      setValidInput,
      validateEmail,
      validatePhoneNumber,
      transformPhoneNumber,
    ]
  )

  const handleInvitePhone = useCallback(
    async (phone: any) => {
      if (
        currentOrganizationRole?.role_id &&
        (user || currentOrganization?.id) &&
        phone
      ) {
        try {
          const response = await axios({
            method: "POST",
            url: `${case_auth}/users/invites/salesforce-org/sms`,
            data: {
              phone_number: phone,
              role_id: currentOrganizationRole?.role_id,
              organization_sf_id: user?.sfId || currentOrganization?.sfid,
              organization_name: user?.partnerName || currentOrganization?.name,
            },
            headers,
          })
          if (response && response.status === 200) {
            handleClose()
            reload()
          } else {
            setLoading(false)
            setError(
              response?.data?.message ?? i18n.t("common.modal.inviteUser.somethingWentWrong")
            )
          }
        } catch (error: any) {
          setLoading(false)
          setError(
            error?.response?.data?.message ??
              i18n.t("common.modal.inviteUser.somethingWentWrong")
          )
        }
      }

      setLoading(false)
    },
    [
      currentOrganizationRole?.id,
      user,
      currentOrganization?.id,
      currentOrganization?.sfid,
      currentOrganization?.name,
      headers,
      handleClose,
      reload,
    ]
  )

  const handleInviteEmail = useCallback(async () => {
    if (
      currentOrganizationRole?.role_id &&
      (user || currentOrganization?.id) &&
      input
    ) {
      try {
        const response = await axios({
          method: "POST",
          url: `${case_auth}/users/invites/salesforce-org/email`,
          data: {
            email: input,
            role_id: currentOrganizationRole?.role_id,
            organization_sf_id: user?.sfId || currentOrganization?.sfid,
            organization_name: user?.partnerName || currentOrganization?.name,
          },
          headers,
        })
        if (response && response.status === 200) {
          handleClose()
          reload()
        } else {
          setLoading(false)
          setError(
            response?.data?.message ?? i18n.t("common.modal.inviteUser.somethingWentWrong")
          )
        }
      } catch (error: any) {
        setLoading(false)
        setError(
          error?.response?.data?.message ??
            i18n.t("common.modal.inviteUser.somethingWentWrong")
        )
      }
    }
  }, [
    currentOrganization?.id,
    currentOrganizationRole?.id,
    input,
    headers,
    user,
    handleClose,
    reload
  ])

  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Box className={classes.style}>
        <div className={classes.header}>{i18n.t("common.modal.inviteUser.inviteUser")}</div>
        <div className={classes.body}>
          {loading ? (
            <Loader />
          ) : (
            <div>
              {!user && (
                <>
                  <p>{i18n.t("common.modal.inviteUser.organization")}</p>
                  <OrganizationSelect
                    onChange={(_) => setCurrentOrganization(_)}
                  />
                </>
              )}
              {((!user && currentOrganization && currentOrganization?.id) ||
                user) && (
                <>
                  <p>Role</p>
                  <OrganizationRoleSelect
                    organization={
                      user ? { sf_account_id: user.sfId } : currentOrganization
                    }
                    onChange={(_) => setCurrentOrganizationRole(_)}
                  />
                </>
              )}

              {!!currentOrganizationRole && (
                <>
                  <p>{i18n.t("common.modal.inviteUser.emailPhone")}</p>
                  <TextField
                    id={"outlined-helperText"}
                    variant={"outlined"}
                    size="small"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      inputChanged(e)
                    }
                    value={input}
                    type="text"
                    style={{
                      backgroundColor: "#FFF",
                      border: "none",
                      borderRadius: "5px",
                    }}
                    className={classes.input}
                  />
                </>
              )}

              {!validInput && (
                <p className="text-red" style={{ color: "red" }}>
                  {i18n.t("common.modal.inviteUser.provideEmailOrPhone")}
                </p>
              )}

              {error && (
                <React.Fragment>
                  <p className="text-red text-sm" style={{ color: "red" }}>
                    {error}
                  </p>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col items-center">
          <Button
            onClick={(e) => handleInvite(e)}
            style={{
              marginTop: "80px",
              margin: "auto",
              marginBottom: "10px",
            }}
            className={classes.actionButton + " w-40"}
            disabled={
              input === "" || !validInput || !currentOrganizationRole || loading
            }
          >
            Invite
          </Button>
        </div>

        <Button
          variant="text"
          onClick={handleClose}
          className={classes.imgButton}
        >
          <CloseIcon />
        </Button>
      </Box>
    </Modal>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      padding: "15px",
    },
    style: {
      position: "absolute" as "absolute",
      left: "50%",
      transform: "translate(-50%, 30vh)",
      backgroundColor: "white",
      borderRadius: "5px",
      width: "300px",
      minWidth: "100px",
    },
    actionButton: {
      border: "1px solid #0F2150",
      borderRadius: "100px",
      backgroundColor: "#0F2150",
      color: "#fff",
      "&.Mui-disabled": {
        border: "1px solid #e2e5ea",
        backgroundColor: "#e2e5ea",
        color: "#b2b7c6",
      },
      "&:hover": {
        backgroundColor: "#1b3a8c",
      },
    },
    input: {
      margin: "5px",
      width: "260px",
      "& .MuiInputBase-input": {
        padding: "5px",
      },
    },
    imgButton: {
      background: "red",
      top: 0,
      right: 0,
      border: "none",
      cursor: "pointer",
      position: "absolute",
      textAlign: "center",
      borderRadius: "5px",
      height: "25px",
      minWidth: "25px",
      "&:hover": {
        backgroundColor: "#eb5c52",
      },
    },
    header: {
      padding: "1px",
      paddingLeft: "10px",
      backgroundColor: "lightgray",
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
      fontWeight: "bold",
      color: "#0F2150",
    },
  })
)
