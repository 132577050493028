import React, { useEffect, useState } from "react"
import {
  GoogleMap,
  InfoWindowF,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api"
import { Loader } from "./Loader"
import { useNavigate } from "react-router-dom"

function Map(props: any) {
  const navigate = useNavigate()
  const [activeMarker, setActiveMarker] = useState<any>(null)
  const [loadingMarkers, setLoadingMarkers] = useState(false)

  useEffect(() => {
    timerMarkers()
  }, [])

  const handleActiveMarker = (marker: any) => {
    if (marker === activeMarker) {
      return
    }

    setActiveMarker(null)
    setTimeout(function () {
      setActiveMarker(marker)
    }, 50)
  }

  const timerMarkers = () => {
    setTimeout(function () {
      setLoadingMarkers(true)
      if (props.setMarker) {
        setTimeout(function () {
          setActiveMarker(props?.defaultMarker || 0)
        }, 100)
      }
    }, 100)
  }

  const options = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  }

  if (!props.isLoaded) {
    return <Loader />
  } else {
    return (
      <GoogleMap
        // onLoad={handleOnLoad}
        onClick={() => setActiveMarker(null)}
        mapContainerStyle={{
          width: "100vw",
          height: "50vh",
          maxWidth: "100%",
          maxHeight: props.maxHeight,
        }}
        center={{ lat: props.center.lat, lng: props.center.lng }}
        mapTypeId={props.hybrid ? "hybrid" : "roadmap"}
        tilt={0}
        zoom={props.zoom || 10}
      >
        {loadingMarkers && (
          <>
            <MarkerClusterer maxZoom={12} gridSize={30} options={options}>
              {(clusterer) => (
                <>
                  {props.pendingBidMarkers.map((marker: any) => (
                    <Marker
                      clusterer={clusterer}
                      key={marker.id}
                      position={{ lat: marker.lat, lng: marker.lng }}
                      onClick={() => handleActiveMarker(marker.id)}
                      icon={
                        "https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                      }
                    >
                      {activeMarker === marker.id ? (
                        <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                          <div
                            style={{
                              cursor: "pointer",
                              color: "#0F2150",
                              fontWeight: "bold",
                            }}
                            onClick={() =>
                              navigate(`/bid-details?id=${marker.id}`)
                            }
                          >
                            Bid: {marker.name}
                          </div>
                        </InfoWindowF>
                      ) : null}
                    </Marker>
                  ))}

                  {props.openBidMarkers.map((marker: any) => (
                    <Marker
                      clusterer={clusterer}
                      key={marker.id}
                      position={{ lat: marker.lat, lng: marker.lng }}
                      onClick={() => handleActiveMarker(marker.id)}
                      icon={
                        "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
                      }
                    >
                      {activeMarker === marker.id ? (
                        <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                          <div
                            style={{
                              cursor: "pointer",
                              color: "#0F2150",
                              fontWeight: "bold",
                            }}
                            onClick={() =>
                              navigate(`/bid-details?id=${marker.id}`)
                            }
                          >
                            Bid: {marker.name}
                          </div>
                        </InfoWindowF>
                      ) : null}
                    </Marker>
                  ))}

                  {props.principalMarkers.map((marker: any) => (
                    <Marker
                      clusterer={clusterer}
                      key={marker.id}
                      position={{ lat: marker.lat, lng: marker.lng }}
                      onClick={() => handleActiveMarker(marker.id)}
                      icon={
                        "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                      }
                    >
                      {activeMarker === 0 ? (
                        <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                          <div>{marker.name || "Account"}</div>
                        </InfoWindowF>
                      ) : null}
                    </Marker>
                  ))}

                  {props.siteMarkers.map((marker: any) => (
                    <Marker
                      clusterer={clusterer}
                      key={marker.id}
                      position={{ lat: marker.lat, lng: marker.lng }}
                      onClick={() => handleActiveMarker(marker.id)}
                      icon={
                        "https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                      }
                    >
                      {activeMarker === marker.id ? (
                        <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                          <div
                            style={{
                              cursor: "pointer",
                              color: "#0F2150",
                              fontWeight: "bold",
                            }}
                            onClick={() =>
                              navigate(`/site-details?id=${marker.id}`)
                            }
                          >
                            Site: {marker.name}
                          </div>
                        </InfoWindowF>
                      ) : null}
                    </Marker>
                  ))}
                </>
              )}
            </MarkerClusterer>
          </>
        )}
      </GoogleMap>
    )
  }
}

export default Map
