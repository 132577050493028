import {
  Box,
  Button,
  Checkbox,
  createStyles,
  makeStyles,
  Modal,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useDebouncedCallback } from "use-debounce"
import { usePartnersService } from "../../../services/partnersService"
import { Loader } from "../../common/Loader"
import { TeamModalProps } from "../desktop/TeamDesktop"
import { ReactComponent as CloseIcon } from "./../../../assets/close-w.svg"
import { useUser } from "../../../providers/AuthProvider"
import useI18n from "../../../hooks/useI18n"

export default function UpdateRoleModal({
  user,
  openModal,
  closeModal,
  reload,
  orgId,
}: TeamModalProps) {
  const partnersService = usePartnersService()
  const [loading, setLoading] = useState(false)
  // const [error, setError] = useState("")
  const classes = useStyles()
  const { i18n } = useI18n()
  const { isSuperAdmin } = useUser()
  const [newRoles, setNewRoles] = useState<string[]>([])
  const [roles, setRoles] = useState<
    { name: string; id: string; selected: boolean }[]
  >([])

  useEffect(() => {
    if (openModal && !loading) {
      setRoles((values) =>
        values.map((v, i) =>
          user?.organization_roles?.find((r: any) => r.name === v.name)
            ? {
                ...v,
                selected: true,
              }
            : {
                ...v,
                selected: false,
              }
        )
      )
    }
  }, [openModal, user?.organization_roles, loading])

  useEffect(() => {
    if (orgId && openModal) {
      const controller = new AbortController()
      setLoading(true)
      partnersService
        .getOrgRoles({ partnerId: orgId }, controller.signal)
        .then((response: any[]) => {
          const result = response
            .filter(({ name }) => !name?.match(/invited/i))
            .filter(
              ({ name }) =>
                isSuperAdmin || !name?.match(/^case(?: super)? admin$/i)
            )
            .map(({ name, id }) => ({
              name,
              id,
              selected: false,
            }))

          setRoles(result)

          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })

      return () => controller.abort()
    }

    if (!orgId) {
      setRoles([])
    }
  }, [orgId, partnersService, openModal, isSuperAdmin])

  const handleUpdate = () => {
    let newRoles: string[] = []
    roles.map((r) => {
      if (r.selected) {
        newRoles.push(r.id)
      }
    })

    setNewRoles(newRoles)
    updateUser()
  }

  const updateUser = useDebouncedCallback(async (signal?: AbortSignal) => {
    setLoading(true)
    const response = await partnersService.updateUserRole(
      { userId: user.id, orgId: orgId, newRoles: newRoles },
      signal
    )
    if (response) {
      setNewRoles([])
      setLoading(false)
      handleClose()
      reload()
    } else {
      setLoading(false)
      setNewRoles([])
      // setError("Something went wrong")
    }
  }, 500)

  const handleClose = () => {
    closeModal()
  }

  const handleChangeRole = (index: any) => {
    setRoles((values) =>
      values.map((v, i) =>
        index === i
          ? {
              ...v,
              selected: !v.selected,
            }
          : v
      )
    )
  }

  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Box className={classes.style}>
        <div className={classes.header}>{i18n.t("common.modal.updateRole.updateRole")}</div>
        <div className={classes.body}>
          {loading ? (
            <Loader />
          ) : (
            <div>
              {roles.map((role: any, i: any) => (
                <React.Fragment key={i}>
                  <div className="flex flex-row items-center">
                    <Checkbox
                      size="small"
                      checked={roles[i].selected}
                      onChange={() => handleChangeRole(i)}
                      style={{
                        color: "#1b3a8c",
                      }}
                    />
                    <p>{role.name}</p>
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
        <div className="flex flex-col items-center">
          <Button
            onClick={() => handleUpdate()}
            style={{
              marginTop: "80px",
              margin: "auto",
              marginBottom: "10px",
            }}
            className={classes.actionButton + " w-40"}
            disabled={!roles?.some((r) => r.selected) || loading}
          >
            {i18n.t("common.update")}
          </Button>
        </div>

        <Button
          variant="text"
          onClick={handleClose}
          className={classes.imgButton}
        >
          <CloseIcon />
        </Button>
      </Box>
    </Modal>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      marginBottom: "10px",
      padding: "5px",
    },
    style: {
      position: "absolute" as "absolute",
      left: "50%",
      transform: "translate(-50%, 30vh)",
      backgroundColor: "white",
      borderRadius: "5px",
      width: "300px",
      minWidth: "100px",
    },
    actionButton: {
      border: "1px solid #0F2150",
      borderRadius: "100px",
      backgroundColor: "#0F2150",
      color: "#fff",
      "&.Mui-disabled": {
        border: "1px solid #e2e5ea",
        backgroundColor: "#e2e5ea",
        color: "#b2b7c6",
      },
      "&:hover": {
        backgroundColor: "#1b3a8c",
      },
    },
    imgButton: {
      background: "red",
      top: 0,
      right: 0,
      border: "none",
      cursor: "pointer",
      position: "absolute",
      textAlign: "center",
      borderRadius: "5px",
      height: "25px",
      minWidth: "25px",
      "&:hover": {
        backgroundColor: "#eb5c52",
      },
    },
    header: {
      padding: "1px",
      paddingLeft: "10px",
      backgroundColor: "lightgray",
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
      fontWeight: "bold",
      color: "#0F2150",
    },
  })
)
