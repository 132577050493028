import React, { useCallback, useContext, useEffect, useState } from "react"

import { Button, CircularProgress, Container, Typography } from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete/Autocomplete"
import DateRangePicker from "@wojtekmaj/react-daterange-picker"
import AcUnitIcon from "@mui/icons-material/AcUnit"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import moment from "moment"

import { useAdminStyles } from "../../../Admin/styles"
import { useGraphQLService } from "../../../../services/graphqlService"
import { User, Pagination, ValidationTypes } from "../../interfaces"
import { ValidationContext } from "../../../../providers/ValidationProvider"
import { ReactComponent as TreeIcon } from "../../../../assets/tree-g.svg"
import "./SearchValidationPage.css"

interface Props {
  title: string
  component?: React.ReactNode
}

const roles = [
  "Account Manager",
  "Account Associate",
  "Regional Field Manager",
  "Regional Director of Operations",
  "Senior Account Manager",
  "Senior Regional Field Manager",
]

const SearchValidationPage = () => {
  const [selectedAM, setSelectedAM] = useState<string | null>(null)
  const [selectedRoles, setSelectedRoles] = useState<string[]>([])
  const [selectedSites, setSelectedSites] = useState<{
    id: string
    label: string
  } | null>(null)
  const [data, setData] = useState<{
    users: User[]
    pagination: Pagination
    options?: string[]
  }>()
  const { setValidation } = useContext(ValidationContext)
  const [dateRange, setDateRange] = useState([new Date(), new Date()])
  const [validationType, setValidationType] = useState<ValidationTypes>()
  const [isLoading, setLoading] = useState(true)

  const classes = useAdminStyles()
  const navigate = useNavigate()
  const { getUsersByTitle, getLandValidationListing } = useGraphQLService()

  useEffect(() => {
    if (validationType) {
      getAccountManagers()
    }
  }, [validationType, selectedRoles])

  const getAccountManagers = useCallback(async () => {
    try {
      if (validationType === ValidationTypes.Land && selectedRoles.length === 0) return

      setLoading(true)
      setSelectedAM(null)
      setSelectedSites(null)

      const response = await getUsersByTitle(
        selectedRoles.length > 0
          ? selectedRoles.join(",")
          : "Account Manager,Regional Director of Operations,Senior Account Manager,Solution Center Manager",
        data?.pagination?.limit ?? 100,
        data?.pagination?.offset ?? 0
      )

      const options = response.users.map((user) => `${user.firstname} ${user.lastname}`).sort()

      setData({
        ...response,
        options: [...new Set(options)],
      })
    } catch (error) {
      console.log(error, "ERROR FETCHING ACCOUNTS")
    } finally {
      setLoading(false)
    }
  }, [data?.pagination?.limit, data?.pagination?.offset, getUsersByTitle, selectedRoles, validationType])

  const handleSearch = useCallback(async () => {
    const selectedAccountManager = data?.users.find((u) => `${u.firstname} ${u.lastname}` === selectedAM)

    const dayDiff = moment(dateRange[1]).diff(moment(dateRange[0]), "days")

    if (dayDiff > 30) {
      alert("Please select a date range of 31 days or less")

      return
    }

    const formattedStartDate = moment(dateRange[0]).format("YYYY-MM-DD")
    const formattedEndDate = moment(dateRange[1]).format("YYYY-MM-DD")

    const user = selectedAccountManager ?? data?.users[0]
    setLoading(true)
    const initialListing =
      validationType === ValidationTypes.Land
        ? await getLandValidationListing(user?.sfid, undefined, undefined, undefined, true, "siteName", "asc", 20)
        : undefined

    setLoading(false)

    setValidation({
      validationType: validationType!,
      user,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      selectedValidation: null,
      // selectedWeeks: selectedWeek ? [selectedWeek] : [],
      selectedService: null,
      selectedSites: selectedSites ? [selectedSites] : [],
      siteList: [],
      landSort: { by: "siteName", order: "asc" },
      snowSort: { by: "storm.start", order: "DESC" },
      workOrderSort: { by: "siteName", order: "ASC" },
      pageWithStorm: 1,
      pageWithoutStorm: 1,
      perPageWithStorm: 10,
      perPageWithoutStorm: 10,
      landNextPageToken: initialListing?.nextPageToken ?? undefined,
      currentFilter:
        validationType === ValidationTypes.Land
          ? {
              showOnlyRequiresReview: true,
            }
          : {
              status: "ReviewPending",
            },
    })

    if (dateRange) {
      navigate("/validation/results")
    }

    return
  }, [data?.users, dateRange, validationType, getLandValidationListing, setValidation, selectedSites, selectedAM, navigate])

  const SearchFieldItemComponent = ({ title, component }: Props) => {
    return (
      <div className="searchFieldItemComponent">
        <h1>{title}</h1>
        {component}
      </div>
    )
  }

  return (
    <Container className={`${classes.container} container`}>
      {validationType && (
        <div className="flex w-min cursor-pointer flex-row items-center gap-4 pt-4" onClick={() => setValidationType(undefined)}>
          <ArrowBackIcon />
          <Typography className="searchValidationTitle" noWrap>
            Change Validation Type
          </Typography>
        </div>
      )}

      <div className="mainValidationGrid">
        {!validationType ? (
          <>
            <div className="mb-8 text-3xl text-[#0F2150]">Choose a Validation Type</div>
            <div className="flex w-full flex-row items-center justify-center">
              <div
                className="mr-8 flex w-1/6 cursor-pointer flex-col items-center justify-center bg-white px-4 py-5 shadow hover:bg-case-grad-mid hover:text-white hover:opacity-50 sm:rounded-lg sm:p-6"
                onClick={() => setValidationType(ValidationTypes.Snow)}
              >
                <h3 className="text-gray-900 mb-2 text-xl font-semibold leading-6">Snow</h3>
                <AcUnitIcon fontSize="large" style={{ color: "#448ab2" }} />
              </div>
              <div
                className="flex w-1/6 cursor-pointer flex-col items-center justify-center bg-white px-4 py-5 shadow hover:bg-case-grad-mid hover:text-white hover:opacity-50 sm:rounded-lg sm:p-6"
                onClick={() => setValidationType(ValidationTypes.Land)}
              >
                <h3 className="text-gray-900 mb-2 text-xl font-semibold leading-6">Land</h3>
                <TreeIcon className="h-10 w-10" />
              </div>
            </div>
          </>
        ) : (
          <div className="searchValidationContainer">
            <Typography className="searchValidationTitle" noWrap>
              Search for {validationType} Validation
            </Typography>

            <div className="searchValidationsContent">
              {validationType === ValidationTypes.Snow ? (
                <>
                  <SearchFieldItemComponent
                    title="Account Manager"
                    component={
                      <Autocomplete
                        disablePortal
                        size="small"
                        value={selectedAM}
                        loading={isLoading}
                        options={data?.options ?? []}
                        sx={{ width: 300 }}
                        onChange={(e, value, r, d) => setSelectedAM(value)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    }
                  />
                  <SearchFieldItemComponent
                    title="Date Range"
                    component={<DateRangePicker onChange={(v) => setDateRange(v as any)} value={dateRange} />}
                  />
                </>
              ) : (
                <>
                  <SearchFieldItemComponent
                    title="Roles"
                    component={
                      <Autocomplete
                        disablePortal
                        multiple
                        disableCloseOnSelect
                        size="small"
                        value={selectedRoles}
                        loading={isLoading}
                        options={roles ?? []}
                        sx={{ width: 300 }}
                        onChange={(e, value, r, d) => setSelectedRoles(value)}
                        renderInput={(params) => <TextField {...params} label="Select one or multiple roles" />}
                      />
                    }
                  />

                  {selectedRoles.length > 0 && (
                    <>
                      <SearchFieldItemComponent
                        title="User"
                        component={
                          <Autocomplete
                            disablePortal
                            size="small"
                            value={selectedAM}
                            loading={isLoading}
                            options={data?.options ?? []}
                            sx={{ width: 300 }}
                            onChange={(e, value, r, d) => setSelectedAM(value)}
                            renderInput={(params) => <TextField {...params} label="Select an user" />}
                          />
                        }
                      />
                    </>
                  )}
                </>
              )}

              <Button
                style={{
                  marginTop: "80px",
                  margin: "auto",
                  marginBottom: "10px",
                }}
                className={`searchButton ${selectedAM === null || isLoading ? "disabled" : ""}`}
                disabled={selectedAM === null || isLoading}
                onClick={handleSearch}
              >
                {isLoading && <CircularProgress color="inherit" size={20} className="mr-2" />}
                <span>Search</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

export default SearchValidationPage
