import { useCallback, useEffect, useState } from "react"
import { Loader } from "../../common/Loader"
import {
  distanceInMiles,
  formatDate,
  formatDateUTC,
  isPastDate,
} from "../../../utils/Util"
import {
  makeStyles,
  createStyles,
  Container,
  Grid,
  Typography,
  Button,
} from "@material-ui/core"
import { ReactComponent as DownIcon } from "./../../../assets/down.svg"
import { ReactComponent as SnowIcon } from "./../../../assets/cloud-snow-lb.svg"
import { ReactComponent as TreeIcon } from "./../../../assets/tree-g.svg"
import { ReactComponent as SweepingIcon } from "./../../../assets/sweeping-b.svg"
import { ReactComponent as JanitorialIcon } from "./../../../assets/janitoral-b.svg"
import { useNavigate } from "react-router-dom"
import { useActiveUser } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"

export const BidsLists = (props: any) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const graphqlService = useGraphQLService()

  const [search, searchInput] = useState("")
  const [data, setData] = useState<any[]>([])
  const [sort, setSort] = useState({
    field: "bid_status_response",
    order: "ASC",
  })
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [error, setError] = useState("")
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [partner, setPartner] = useState<any>({})
  const { sfId: sfid } = useActiveUser()

  const distance = (site_lat: any, site_lon: any) => {
    if (partner?.billinglatitude && partner?.billinglongitude) {
      let from = {
        lat: site_lat,
        lon: site_lon,
      }
      let to = {
        lat: partner?.billinglatitude,
        lon: partner?.billinglongitude,
      }
      return distanceInMiles(from, to)
    }
    return ""
  }

  const getPartnerData = useCallback(
    (signal?: AbortSignal) => {
      graphqlService
        .getPartnerBySfid({ sfid }, signal)
        .then((response) => {
          setPartner(response)
        })
        .catch(() => {})
    },
    [graphqlService, sfid]
  )

  useEffect(() => {
    const controller = new AbortController()

    getPartnerData(controller.signal)

    return () => controller.abort()
  }, [getPartnerData])

  useEffect(() => {
    const controller = new AbortController()
    if (page === 1) {
      setLoading(true)
    } else {
      setLoadingMore(true)
    }
    graphqlService
      .getBids(
        {
          sfid,
          limit: perPage,
          offset: (page - 1) * perPage,
          sort: sort.field,
          order: sort.order,
          filterBy: typeof props?.filter === "string" ? props?.filter : "All",
          search: props?.filter?.search,
        },
        controller.signal
      )
      .then((response) => {
        if (page === 1) {
          setData(response.bids)
          setLoading(false)
          setLoadingMore(false)
        } else {
          setData((d) => [...d, ...response.bids])
          setLoading(false)
          setLoadingMore(false)
        }
      })
      .catch(() => {
        setLoading(false)
        setLoadingMore(false)
      })

    return () => controller.abort()
  }, [graphqlService, page, perPage, props?.filter, sfid, sort])

  const goToBidDetails = (bid: any) => {
    navigate(`/bid-details?id=${bid.case_bid_id}`)
  }

  if (error) {
    return <p>ERROR: {error}</p>
  } else {
    return (
      <>
        {loading && (
          <div className={classes.listContainer}>
            <Loader />
          </div>
        )}

        {!loading && !data.length && (
          <div className={classes.listContainer}>
            <div>EMPTY</div>
          </div>
        )}

        {!loading && !!data.length && (
          <div>
            <Container className={classes.container}>
              {!!data.length &&
                data.map((row: any, index: any) => (
                  <Grid
                    key={index}
                    container
                    onClick={() => goToBidDetails(row)}
                  >
                    <Grid item xs={12}>
                      <div className={classes.grid}>
                        <div className={classes.secondaryTitle}>
                          <Typography noWrap className={classes.cardTitle}>
                            {row?.bid_location_name}
                          </Typography>
                          <span
                            className={
                              row.bid_status === "Awarded"
                                ? classes.blueStatus
                                : row.bid_status === "Accepted"
                                ? classes.greenStatus
                                : row.bid_status === "Declined" ||
                                  row.bid_status === "Not Awarded" ||
                                  row.bid_status === "Terminated"
                                ? classes.redStatus
                                : row?.bid_status === "No Response" &&
                                  !isPastDate(row?.contract_end_date)
                                ? classes.newBidStatus
                                : row?.bid_status === "No Response" &&
                                  isPastDate(row?.contract_end_date)
                                ? classes.expiredStatus
                                : classes.yellowStatus
                            }
                          >
                            {row?.bid_status === "No Response" &&
                            !isPastDate(row?.contract_end_date)
                              ? "Open Bid"
                              : row?.bid_status === "No Response" &&
                                isPastDate(row?.contract_end_date)
                              ? "Expired"
                              : row?.bid_status}
                          </span>
                        </div>
                        <div className={classes.data}>
                          <div className="flex flex-row items-center">
                            {row?.service_line?.indexOf("Snow") >= 0 ? (
                              <SnowIcon className="w-7 h-7" />
                            ) : (
                              ""
                            )}
                            {row?.service_line?.indexOf("Land") >= 0 ? (
                              <TreeIcon className="w-7 h-7" />
                            ) : (
                              ""
                            )}
                            {row?.service_line?.indexOf("Sweep") >= 0 ? (
                              <SweepingIcon className="w-7 h-7" />
                            ) : (
                              ""
                            )}
                            {row?.service_line?.indexOf("Jani") >= 0 ? (
                              <JanitorialIcon className="w-7 h-7" />
                            ) : (
                              ""
                            )}
                          </div>
                          <div>
                            Distance:
                            <span className={classes.cardDetails}>
                              {distance(row.site_lat, row.site_lon)}
                            </span>
                          </div>
                          <div>
                            Bid Deadline:
                            <span className={classes.cardDetails}>
                              {row.bid_response_deadline
                                ? formatDateUTC(row.bid_response_deadline)
                                : "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                ))}
              {loadingMore ? (
                <Loader />
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <div className="w-full flex flex-col align-items">
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => setPage(page + 1)}
                      >
                        <DownIcon />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}
            </Container>
          </div>
        )}
      </>
    )
  }
}
const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      width: "auto",
      marginLeft: 0,
      padding: "10px",
    },
    location: {
      fontWeight: "bold",
      color: "#1695CB",
    },
    cardTitle: {
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "10px",
      marginTop: "10px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#FFFFFF",
      padding: "5px",
    },
    container: {
      maxWidth: "100%",
    },
    secondaryTitle: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "15px",
    },
    data: {
      marginLeft: "10px",
      paddingBottom: "20px",
    },
    active: {
      backgroundColor: "#d7f5e3",
      color: "#4e996c",
      borderRadius: "20px",
      padding: "2px 11px",
      height: "30px",
      marginRight: "5px",
      marginTop: "15px",
    },
    inactive: {
      backgroundColor: "#fed5d5",
      color: "#ff3b5f",
      borderRadius: "20px",
      padding: "2px 5px",
      height: "30px",
      marginRight: "5px",
      marginTop: "15px",
    },
    cardDetails: {
      fontWeight: "bold",
      color: "#0F2150",
    },
    greenStatus: {
      backgroundColor: "#d7f5e3",
      color: "#4e996c",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "15px",
      margin: "5px",
    },
    redStatus: {
      width: "250px",
      backgroundColor: "#fed5d5",
      color: "#ff3b5f",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "15px",
      margin: "5px",
    },
    yellowStatus: {
      backgroundColor: "#ffd978",
      color: "#665730",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "15px",
      margin: "5px",
    },
    newBidStatus: {
      minWidth: "90px",
      backgroundColor: "#fff",
      color: "#3d7556",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "15px",
      border: "solid 1px #3d7556",
    },
    expiredStatus: {
      backgroundColor: "gray",
      color: "black",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "15px",
    },
    blueStatus: {
      backgroundColor: "#4ec7f1",
      color: "#22586a",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "15px",
    },
  })
)

export default BidsLists
