import axios from "axios"

import useI18n from "./useI18n"
import { useState } from "react"

type TranslationResponse = {
  data: {
    translations: [
      {
        translatedText: string
      }
    ]
  }
}

export const useCloudTranslation = () => {
  const [loadingTranslation, setLoadingTranslation] = useState(false)
  const [translatedText, setTranslatedText] = useState<string | null>(null)
  const API_KEY = process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API_KEY
  const API_URL = `https://translation.googleapis.com/language/translate/v2`

  const i18n = useI18n()

  const translateText = async (text: string) => {
    if (i18n.locale === "en" || !text) return

    try {
      setLoadingTranslation(true)

      const result = await axios.post<TranslationResponse>(
        API_URL,
        {
          q: text,
          source: "en",
          target: i18n.locale,
          format: "text",
        },
        { params: { key: API_KEY } }
      )

      if (result.status !== 200) throw new Error("Request error")

      setTranslatedText(result.data?.data?.translations?.[0]?.translatedText || null)

      return result.data?.data?.translations?.[0]?.translatedText
    } catch (error) {
      throw new Error("Internal request error")
    } finally {
      setLoadingTranslation(false)
    }
  }

  return { loadingTranslation, translatedText, translateText }
}
