export interface ValidationState {
  validationType: ValidationTypes
  validations?: any[]
  filteredValidations?: any[]
  workOrders?: SnowWorklog[]
  selectedValidation?: any
  user?: User
  snowNextPageToken?: string | null
  workOrderNextPageToken?: string | null
  landNextPageToken?: string
  snowPageTokenList?: (string | undefined | null)[]
  workOrderPageTokenList?: (string | undefined | null)[]
  startDate?: string
  endDate?: string
  currentFilter?: ValidationFilter
  landSort?: { by: SortField; order: "asc" | "desc" | "ASC" | "DESC" }
  snowSort?: { by: SortField; order: "asc" | "desc" | "ASC" | "DESC" }
  workOrderSort?: { by: SortField; order: "asc" | "desc" | "ASC" | "DESC" }
  pageWithStorm?: number
  perPageWithStorm?: number
  pageWithoutStorm?: number
  perPageWithoutStorm?: number
  totalWithStorm?: number
  totalWithoutStorm?: number
  selectedService?: {
    periodIndex: number
    siteId?: string
    serviceKey: string
    sfid: string
    weekNumber?: number
    monthName: string
    year: number
  } | null
  siteList?: {
    label: string
    id: string
  }[]
  filteredSiteList?: {
    label: string
    id: string
  }[]
  customerList?: {
    label: string
    id: string
  }[]
  servicePartnerList?: {
    label: string
    id: string
  }[]
  selectedWeeks?: {
    label: string
    id: number
  }[]
  selectedSites?: {
    label: string
    id: string
  }[]
}

export interface ValidationFilter {
  sp?: { selected?: string; all?: string[] }
  dateRange?: [Date, Date]
  acc?: string
  status?: string
  servicePartner?: string
  customer?: string
  siteId?: string
  showOnlyRequiresReview?: boolean
}

export interface ValidationData {
  validationName: string
  worklogId?: string
  action?: string
  status?: string
}

export interface ServiceDataUpdate {
  service_id: string
  approval_status?: string | null
  approval_notes?: string | null
}

export interface ServicePeriodCompletionData {
  sfid: string
  week: number
  value: boolean
}

export interface ValidationWorklogData {
  work_log_id: string
  service_partner_notes: string
  work_log_event: {
    type: string
  }
}

export interface User {
  id?: string
  sfid?: string
  mobilephone?: string
  phone?: string
  email?: string
  firstname?: string
  lastname?: string
  title?: string
}

export interface Pagination {
  found_rows: number
  limit: number
  offset: number
}

export interface Validation {
  schema: string
  schemaVersion: string
  validationRuleSet: string
  name: string
  validations: ValidationsItem[]
  serviceProviders?: string
  algorithmStatus: string
  description: string
  storm: Storm
  validationSettings: string
  createdAt: string
  stormId: string
  siteId: string
  siteName: string
  serviceProviderId: string
  serviceProvider: string
  accountManagerId: string
  status: string
  underover: string
  _metaData: _metaData
}

export interface ValidationsItem {
  period: string
  periodStart: string
  periodEnd: string
  services: ServicesItem[]
  worklogs: WorklogsItem[]
}

export interface WorklogsItem {
  services: ServicesItem[]
  datetime: string
  workLogId: string
  workOrderId: string
  duplicate: boolean
  approvalStatus: string
  algorithmStatus: string
  serviceSummary: string
  servicePartnerNotes: string
  serviceProviderId: string
  serviceProvider: string
}

export interface LandValidation {
  sfid: string
  siteId: string
  siteName: string
  procurementBidId: string
  servicePartnerId: string
  scheduleName: string
  serviceStart: string
  serviceEnd: string
  serviceType: string
  serviceFrequency: string
  serviceLine: string
  servicePartnerName: string | null
  serviceKey: string
  bidAwardedDate: string | null
  servicePeriods: ServicePeriod[]
  _metaData: _metaData
}

export interface ServicePeriod {
  startDate: string
  endDate: string
  endDatePlusOne: string
  reminderDate: string
  serviceKey: string
  serviceType: string
  expectedServiceCount: number
  actualServiceCount: number
  missingServiceCount: number | null
  overServiceCount: number | null
  servicePartnerMismatch: boolean | null
  expected: boolean | null
  reviewComplete: boolean | null
  quarterNumber: number | null
  monthNumber: number | null
  weekNumber: number | null
  worklogs: LandWorklog[]
}

export interface LandWorklog {
  worklogId: string | null
  workLogStart: string | null
  workLogEnd: string | null
  backDatedTo: string | null
  algorithmStatus: string | null
  reasons: string[]
  work_order_name: string | null
  sfid: string | null
  service_summary: string | null
  distance_from_site: number | null
  time_taken_minutes: string | null
  service_provider_account__c: string | null
  service_provider: string | null
  service_partner_notes: string | null
  woms_wo: string | null
  workOrderId?: string | null
  approval_status: string | null
  servicePartnerMismatch: boolean | null
  duplicateWorklog: boolean | null
  service: {
    sfid: string | null
    name: string | null
    service_key: string | null
    service_type: string | null
    value: string | null
    approval_status: string | null
    algorithm_status: string | null
    approval_notes: string | null
  } | null
}

export interface LandWorklogDetails {
  workLogId: string
  createdAt: string | null
  updatedAt: string | null
  status: string
  serviceRequestIds: any[]
  serviceRequests: any[]
  siteDetails: SiteDetails
  serviceData: ServiceData
  services: Service[]
  notes: string
  attachments: Attachment[]
  userDetails: UserDetails
  userDeviceLocation: UserDeviceLocation
  mobileAppDetails: MobileAppDetails
  workOrderId: string
  backdatedTo: string | null
  _metaData: _metaData
  algorithmStatus: string
}

export interface Attachment {
  title: string | null
  sfid: string | null
  s3id: string | null
  createddate: string | null
  contenttype: string | null
  objecttype: string | null
  filetype: string | null
  etag: string
  location: string
  key: string
  bucket: string
}

export interface MobileAppDetails {
  mobileAppVersion: string
  mobileAppBuildNumber: number
  dataSource: string
}

export interface ServiceData {
  startedDate: string
  serviceSubscriptionId: string
  serviceLine: string
}

export interface Service {
  name: string
  serviceKey: string
  serviceDefinition: string
  serviceLine: string
  serviceId: string
  status: string
  scheduleId: string
  periodIndex: number
  valid: boolean
  reasons: string[]
}

export interface SiteDetails {
  siteId: string
  latitude: number
  longitude: number
  timezoneOffset: string
  timezoneId: string
}

export interface UserDetails {
  userId: string
  fullName: string
  phone: string
  servicePartnerId: string
}

export interface UserDeviceLocation {
  timestamp: string
  mocked: boolean
  coords: Coords
}

export interface Coords {
  accuracy: number
  altitude: number
  altitudeAccuracy: number
  heading: number
  latitude: number
  longitude: number
  speed: number
}

export interface LandValidationListing {
  sfid: string
  siteId: string
  reviewerIds: string[]
  siteName: string
  procurementBidId: string
  servicePartnerId: string
  servicePartnerName: string
  customerId: string
  customerName: string
  scheduleName: string
  serviceStart: string
  serviceEnd: string
  serviceType: string
  requiresReview: boolean
  serviceFrequency: string
  serviceLine: string
  serviceKey: string
  serviceDays: any[]
  servicePeriods: ServicePeriod[]
}

export interface Account {
  sfid: string
  name: string
}

export interface Pagination {
  limit: number
  offset: number
  found_rows: number
}

export interface LandValidationListingResponse {
  items: LandValidationListing[]
  nextPageToken?: string | null
  totalCount: number
}
export interface SnowWorklogsResponse {
  worklogs: SnowWorklog[]
  nextPageToken?: string | null
  totalRows: string
}
export interface AccountResponse {
  accounts: Account[]
  pagination: Pagination
}
export interface OrgUsersResponse {
  rows: User[]
  pagination: Pagination
}

export interface OrgUser {
  id: string
  email: string
  phone_number: null
  organization_roles: OrganizationRole[]
  profiles: Profile[]
  authorizations: Authorization[]
}

export interface Authorization {
  id: string
  user_id: string
  created_at: Date
  updated_at: Date
}

export interface OrganizationRole {
  id: string
  role_id: string
  organization_id: string
  organization_type: string
  name: string
  user_organization_roles: UserOrganizationRoles
  organization: Organization
}

export interface Organization {
  id: string
  name: string
  sf_account_id: string
  created_by: null
  updated_by: null
  created_at: Date
  updated_at: Date
}

export interface UserOrganizationRoles {
  user_id: string
  org_role_id: string
}

export interface Profile {
  id: string
  user_id: string
  name_first: string
  name_last: string
  partner_id: string
  title: null
  created_at: Date
  updated_at: Date
}

export interface SortType {
  field: SortField
  order: "asc" | "desc" | "ASC" | "DESC"
}

export interface ServicesItem {
  category?: string
  min?: number
  max?: number
  count: number
  service?: string
  status?: string
  reason?: string
  sfid?: string
  external_id?: string
  service_id: string
  service_key: string | null
  service_type: string | null
}

export interface SnowWorklog {
  workLogId: string
  createdAt: string
  updatedAt: string
  status: string
  notes: string
  workOrderId: string | null
  WOMSWorkLogID: string
  siteDetails: {
    siteId: string
  }
  services: {
    serviceId: string
    serviceManagementId: string
    value: string
  }[]
  serviceData: {
    startedDate: string
    serviceLine: string
    serviceSubscriptionId: string
  }
  siteName: string
  serviceSummary: string | null
  workOrderName: string | null
  workOrderStatus: string
  isReviewed?: boolean
}

export enum ValidationTypes {
  Snow = "Snow",
  Land = "Land",
}

export type SortField =
  | "siteName"
  | "customerName"
  | "servicePartnerName"
  | "site"
  | "sp"
  | "stormId"
  | "accumulation"
  | "storm.total_accumulation"
  | "completedWos"
  | "pendingWos"
  | "startDate"
  | "storm.start"
  | "status"
  | "serviceType"
  | "week"
  | "services"
  | "expected"
  | "completed"
  | "inReview"
  | "workOrder"
  | "serviceDate"

interface Storm {
  id: string
  start: string
  end: string
  locationId: string
  city: string
  state: string
  zipCode: string
  precip_type: string
  total: string
  total_accumulation: string
  total_trace: string | null
  ice: string
  ice_trace: string | null
  note: string
  title: string
  narrative: string
  footnote: string
  stormStart: string
  stormEnd: string
}

interface _metaData {
  id: string
  _rid: string
  _self: string
  _etag: string
  _attachments: string
  _ts: string
}
