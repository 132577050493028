import { useCallback, useEffect, useState } from "react"
import {
  makeStyles,
  createStyles,
  Container,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import { ReactComponent as UserIcon } from "./../../../assets/user-b.svg"
import { ReactComponent as MessageIcon } from "./../../../assets/message-b.svg"
import { ReactComponent as Phonecon } from "./../../../assets/phone.svg"
import { ReactComponent as MarkerIcon } from "./../../../assets/marker-pin.svg"
import { ReactComponent as MailIcon } from "./../../../assets/mail.svg"
import { useActiveUser } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"
import useI18n from "../../../hooks/useI18n"

function ContactUsMobile(props: any) {
  const navigate = useNavigate()
  const classes = useStyles()
  const { i18n } = useI18n()
  const { sfId: sfid } = useActiveUser()
  const [partner, setPartner] = useState<any>({})
  const graphqlService = useGraphQLService()

  const getPartnerData = useCallback(
    (signal?: AbortSignal) => {
      graphqlService
        .getPartnerBySfid({ sfid }, signal)
        .then((response) => {
          setPartner(response)
        })
        .catch(() => {})
    },
    [graphqlService, sfid]
  )

  useEffect(() => {
    const controller = new AbortController()

    getPartnerData(controller.signal)

    return () => controller.abort()
  }, [getPartnerData])

  return (
    <>
      <div className="h-fit h-min-40 bg-gradient-to-b from-case-grad-mid to-case-grad-to-mobile">
        <div className={classes.secondaryTitleMobile}>
          <Typography className={classes.titleFontMobile}>
            {i18n.t("breadcrumbs.contactUs")}
          </Typography>
        </div>
      </div>
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.cardSubtitle}>
              {i18n.t("contactUs.supportOrJoinCaseTeam")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container className="flex-row items-center">
              <Grid item xs={12}>
                <div className={classes.secondaryTitle}>
                  <Typography className={classes.subtitle}>
                    {i18n.t("contactUs.caseContacts")}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <div className={classes.listContainer}>
                <div className={classes.grid}>
                  {!!partner?.contacts?.length &&
                    partner?.contacts.map((contact: any, index: any) => (
                      <Container key={index} className={classes.container}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            className="flex flex-row items-center"
                          >
                            <UserIcon className="mr-3" />
                            <div>
                              <span className={classes.infoFont}>
                                {contact.name}
                              </span>
                              <Typography noWrap className={classes.infoTitle}>
                                {contact.title || "-"}
                              </Typography>
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className="flex flex-row items-center"
                          >
                            <MessageIcon className="mr-3" />
                            <div>
                              <div className={classes.contactFont}>
                                {contact.mobilephone || "No Phone Number"}
                              </div>
                              <div className={classes.contactFont}>
                                {contact.email || "No Email"}
                              </div>
                            </div>
                          </Grid>

                          <Grid item xs={12} className={classes.divider}>
                            <Divider variant="middle" />
                          </Grid>
                        </Grid>
                      </Container>
                    ))}
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.listContainer}>
              <div className={classes.grid}>
                <Grid container>
                  <Grid item xs={4}>
                    <div className={classes.iconContactUs}>
                      <span className={classes.circleOpenBids}>
                        <Phonecon />
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <div>
                      <p className={classes.fontContactUs}>{i18n.t("contactUs.callUs")}</p>
                      <p>(888) 978-7669</p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.listContainer}>
              <div className={classes.grid}>
                <Grid container>
                  <Grid item xs={4}>
                    <div className={classes.iconContactUs}>
                      <span className={classes.circleOpenBids}>
                        <MailIcon />
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <div>
                      <p className={classes.fontContactUs}>{i18n.t("contactUs.emailUs")}</p>
                      <p>mail@casefms.com</p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.listContainer}>
              <div className={classes.grid}>
                <Grid container>
                  <Grid item xs={4}>
                    <div className={classes.iconContactUs}>
                      <span className={classes.circleOpenBids}>
                        <MarkerIcon />
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <div>
                      <p className={classes.fontContactUs}>{i18n.t("contactUs.visitUs")}</p>
                      <p>
                        356 John L Dietsch Blvd. North Attleborough, MA 02763
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    secondaryTitleMobile: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "100vw",
    },
    titleFontMobile: {
      fontSize: "44px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "white",
      letterSpacing: "-1px",
      marginLeft: "20px",
      marginBottom: "20px",
      marginTop: "21px",
    },
    showTitle: {
      width: "auto",
      marginTop: "8px",
    },
    secondaryTitle: {
      display: "flex",
      justifyContent: "space-between",
    },
    titleFont: {
      fontSize: "44px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "40px",
      marginTop: "21px",
    },
    cardTitle: {
      fontSize: "40px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "black",
      letterSpacing: "-1px",
    },
    cardSubtitle: {
      marginTop: "10px",
      fontSize: "18px",
      fontWeight: 800,
      color: "black",
      marginBottom: "20px",
    },
    subtitle: {
      fontSize: "25px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      background: "#FFFFFF",
      padding: "10px 0px",
    },
    gridContact: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      background: "#FFFFFF",
      margin: "10px",
      minHeight: "217px",
    },
    container: {
      maxWidth: "100%",
      padding: "10px 20px",
    },
    listContainer: {
      width: "auto",
      marginLeft: 0,
      paddingTop: "10px",
    },
    infoFont: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 15,
    },
    contactFont: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 13,
    },
    infoTitle: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
      marginBottom: "10px",
    },
    divider: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    circleOpenBids: {
      backgroundColor: "#232F64",
      width: "70px",
      height: "70px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "5px",
      fontSize: "20px",
      color: "white",
      fontWeight: "bold",
    },
    fontContactUs: {
      fontWeight: "bold",
      color: "#232F64",
      fontSize: "25px",
    },
    iconContactUs: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
  })
)

export default ContactUsMobile
