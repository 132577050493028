import { useCallback, useContext, useEffect, useMemo, useState } from "react"

import { CircularProgress, Container, Modal } from "@material-ui/core"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import moment from "moment"
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined"
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined"

import "./SelectedValidationPage.css"
import { useAdminStyles } from "../../../Admin/styles"
import { ValidationContext } from "../../../../providers/ValidationProvider"
import { useGraphQLService } from "../../../../services/graphqlService"
import { useValidationNav } from "../../hooks/useValidationNav"
import { ValidationTypes, ValidationsItem } from "../../interfaces"
import SeasonCardComponent from "./components/SeasonCardComponent"
import StormDetailsComponent from "./components/StormDetailsComponent"
import LandValidationDetailsPage from "../LandValidationDetailsPage/LandValidationDetailsPage"

const SelectedValidationPage = () => {
  const classes = useAdminStyles()
  const [showNarrative, setShowNarrative] = useState(false)

  const { validationState, setValidation } = useContext(ValidationContext)
  const { updateWorkOrderValidation, getWorkLogDetails } = useGraphQLService()
  const { getCurrentValidationIndex, navigateTo, isLoading, setLoading } = useValidationNav()

  const markAsCompleted = useCallback(
    async (signal?: AbortSignal) => {
      if (!validationState?.selectedValidation) return

      try {
        const resp = await updateWorkOrderValidation(
          {
            action: "ReviewCompleted",
            validationName: validationState?.selectedValidation?.name,
          },
          signal
        )

        if (resp === true) {
          setValidation({
            ...validationState,
            selectedValidation: {
              ...validationState?.selectedValidation,
              status: "ReviewCompleted",
            },
          })
        }
      } catch (error) {
        console.log(error, "ERROR")
      }
    },
    [setValidation, updateWorkOrderValidation, validationState]
  )

  const checkAutoApprove = useCallback(
    async (signal?: AbortSignal) => {
      if (!validationState?.selectedValidation || validationState?.selectedValidation?.status === "ReviewCompleted") return

      const validationsWithWorklogs = validationState?.selectedValidation?.validations?.filter((v: ValidationsItem) => v.worklogs.length)
      let hasPendingWorklogs = false

      setLoading(true)

      return await new Promise((resolve, reject) =>
        setTimeout(async () => {
          try {
            setLoading(true)

            for (let vIndex = 0; vIndex < validationsWithWorklogs.length; vIndex++) {
              const validation = validationsWithWorklogs[vIndex] as ValidationsItem

              for (let wIndex = 0; wIndex < validation.worklogs.length; wIndex++) {
                const worklog = validation.worklogs[wIndex]

                const worklogData = await getWorkLogDetails({ sfid: worklog.workLogId }, signal, true)

                if (!["Approved", "Cancelled", "Edit", "ReviewCompleted"].includes(worklogData?.approval_status)) {
                  // console.log("worklog is not in terminal state", worklog.workOrderId, worklogData, worklogData.approval_status)
                  hasPendingWorklogs = true
                  break
                }
              }

              if (hasPendingWorklogs) break
            }

            if (hasPendingWorklogs) {
              return resolve(false)
            }

            await markAsCompleted(signal)
            return resolve(true)
          } catch (error) {
            console.log(error, "ERROR")
          } finally {
            setLoading(false)
          }
        }, 3000)
      )
    },
    [getWorkLogDetails, markAsCompleted, setLoading, validationState?.selectedValidation]
  )

  useEffect(() => {
    const abortController = new AbortController()

    checkAutoApprove(abortController.signal)

    return () => {
      abortController.abort()
    }
  }, [checkAutoApprove, validationState.selectedValidation.validations])

  const beforePeriod = useMemo(
    () => validationState.selectedValidation?.validations?.find((v: ValidationsItem) => v.period === "before" && !!v.worklogs.length),
    [validationState.selectedValidation]
  )

  const preStormPeriod = useMemo(
    () => validationState.selectedValidation?.validations?.find((v: ValidationsItem) => v.period === "prestorm" && !!v.worklogs.length),
    [validationState.selectedValidation]
  )

  const stormPeriod = useMemo(
    () => validationState.selectedValidation?.validations?.find((v: ValidationsItem) => v.period === "storm" && !!v.worklogs.length),
    [validationState.selectedValidation]
  )

  const postStormPeriod = useMemo(
    () => validationState.selectedValidation?.validations?.find((v: ValidationsItem) => v.period === "poststorm" && !!v.worklogs.length),
    [validationState.selectedValidation]
  )

  const afterPeriod = useMemo(
    () => validationState.selectedValidation?.validations?.find((v: ValidationsItem) => v.period === "after" && !!v.worklogs.length),
    [validationState.selectedValidation]
  )

  if (validationState?.validationType === ValidationTypes.Land) return <LandValidationDetailsPage />

  return (
    <>
      <div className="navigationRow">
        {validationState?.validations?.[(getCurrentValidationIndex() ?? 0) - 1] || (validationState?.snowPageTokenList?.length ?? 0) > 1 ? (
          <div className="navItem select-none" onClick={async () => await navigateTo("previous")}>
            <ArrowBackOutlinedIcon fontSize="small" />
            <Typography noWrap className="navLabel">
              Previous Validation
            </Typography>
          </div>
        ) : (
          <Typography noWrap className="navLabel">
            <b>Start of Page</b>
          </Typography>
        )}
        {validationState?.validations?.[(getCurrentValidationIndex() ?? 0) + 1] || validationState?.snowNextPageToken ? (
          <div className="navItem select-none" onClick={async () => await navigateTo("next")}>
            <Typography noWrap className="navLabel">
              Next Validation
            </Typography>
            <ArrowForwardOutlinedIcon fontSize="small" />
          </div>
        ) : (
          <Typography noWrap className="navLabel font-bold">
            <b>End of Page</b>
          </Typography>
        )}
      </div>

      <Modal open={isLoading} className="loadingModalContainer">
        <CircularProgress />
      </Modal>

      <Container className={classes.container}>
        <>
          <StormDetailsComponent
            onClickNarrative={() => setShowNarrative(!showNarrative)}
            siteName={validationState?.selectedValidation?.siteName}
            userFullName={`${validationState?.user?.firstname} ${validationState?.user?.lastname}`}
            isCompleted={
              validationState?.selectedValidation?.status === "ReviewCompleted" ||
              validationState?.selectedValidation?.status === "Completed" ||
              validationState?.selectedValidation?.status === "ReviewComplete"
            }
            stormId={validationState?.selectedValidation?.storm?.id}
            stormType={validationState?.selectedValidation?.storm?.precip_type}
            stormStart={moment(validationState?.selectedValidation?.storm?.start).format("MM/DD/YYYY LTS")}
            stormEnd={moment(validationState?.selectedValidation?.storm?.end).format("MM/DD/YYYY LTS")}
            totalAcc={validationState?.selectedValidation?.storm?.total_accumulation}
            iceAcc={validationState?.selectedValidation?.storm?.ice}
            narrative={validationState?.selectedValidation?.storm?.narrative}
            validationSettings={validationState?.selectedValidation?.validationSettings}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginBottom: 16,
            }}
          >
            {beforePeriod && <SeasonCardComponent data={beforePeriod} />}
            {preStormPeriod && <SeasonCardComponent data={preStormPeriod} />}
            {stormPeriod && <SeasonCardComponent data={stormPeriod} />}
            {postStormPeriod && <SeasonCardComponent data={postStormPeriod} />}
            {afterPeriod && <SeasonCardComponent data={afterPeriod} />}
          </div>
        </>

        <br />

        <Modal
          open={showNarrative}
          onClose={() => setShowNarrative(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              height: 300,
              overflow: "auto",
              bgcolor: "background.paper",
              border: "1px solid #000",
              borderRadius: 4,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Narrative
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {validationState?.selectedValidation?.storm?.narrative}
            </Typography>
          </Box>
        </Modal>
      </Container>
    </>
  )
}

export default SelectedValidationPage
