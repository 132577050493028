import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { AuthProvider } from "./providers/AuthProvider"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import HttpsRedirect from "react-https-redirect"

// Sentry.init({
//   dsn: "https://cd5770f8269e4be2a380c9290af3c572@o1431248.ingest.sentry.io/4503894383394817",
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
// })

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <HttpsRedirect>
    <AuthProvider>
      <App />
    </AuthProvider>
  </HttpsRedirect>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
