import {
  createStyles,
  FormControl,
  makeStyles,
  TextField,
} from "@material-ui/core"
import { Autocomplete } from "@mui/material"
import axios from "axios"
import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useUser } from "../../providers/AuthProvider"
import { useGraphQLService } from "../../services/graphqlService"
import { Loader } from "../common/Loader"

export type OrganizationSelectProps = {
  onChange: Dispatch<any | undefined>
}

export const OrganizationSelect = ({ onChange }: OrganizationSelectProps) => {
  const classes = useStyles()
  const graphqlService = useGraphQLService()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<any[]>([])
  const [selectedOrganization, setSelectedOrganization] = useState<any>({})
  const [searchText, setSearchText] = useState("")

  const getPartnersOptions = useCallback(
    async (searchText: string, signal?: AbortSignal) => {
      setLoading(true)
      const response = await graphqlService.searchPartners(
        { name: searchText },
        signal
      )
      setOptions(response)
      setLoading(false)
    },
    [graphqlService]
  )

  useEffect(() => {
    onChange(selectedOrganization)
  }, [onChange, selectedOrganization])

  const handleSelectOrganization = (e: any, value: any) => {
    setSelectedOrganization(value)
  }

  useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const searchOrganizations = (e: any) => {
    setSearchText(e.target.value)
  }

  useEffect(() => {
    const controller = new AbortController()
    getPartnersOptions(searchText, controller.signal)
    return () => controller.abort()
  }, [searchText])

  return (
    <FormControl fullWidth>
      <Autocomplete
        id="asynchronous-demo"
        onChange={(e, value) => {
          handleSelectOrganization(e, value)
        }}
        sx={{ width: 300 }}
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => `${option.name} - ${option.billingcity ?? ''} ${option.billingstate ?? ''}`}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search Organization"
            style={{
              backgroundColor: "#FFF",
              border: "none",
              borderRadius: "5px",
            }}
            onChange={(e) => searchOrganizations(e)}
            className={classes.input}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <Loader /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      margin: "5px",
      width: "260px",
      "& .MuiInputBase-input": {
        padding: "5px",
      },
    },
  })
)
