import { useMemo } from "react"
import { useUser } from "../providers/AuthProvider"
const url = process.env.REACT_APP_API_URL!

export const useImageService = () => {
  const { token } = useUser()

  return useMemo(() => makeImageService(token), [token])
}

export const makeImageService = (token: string) => {
  return {
    getLocationImg: (data: any, signal?: AbortSignal) => {
      return fetch(
        `${url}/rest/v1/locations/${data?.siteId}/images/${data.sfid}`,
        {
          headers: { Authorization: token },
          signal
        }
      )
        .then((res) => {
          if (res.status === 200) {
            return res.blob()
          }
          return null
        })
        .then((blob) => {
          if (blob) {
            return URL.createObjectURL(blob)
          }
          return null
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getAttachmentImg: (data: any, signal?: AbortSignal) => {
      return fetch(`${url}/rest/v1/attachments/${data.sfid}`, {
        headers: { Authorization: token },
        signal
      })
        .then((res) => {
          if (res.status === 200) {
            return res.blob()
          }
          return null
        })
        .then((blob) => {
          if (blob) {
            return URL.createObjectURL(blob)
          }
          return null
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getPDF: (data: any) => {
      return fetch(`${url}/rest/v1/documents/${data.sfid}`, {
        headers: { Authorization: token },
      })
        .then((res) => res.blob())
        .then((blob) => {
          return URL.createObjectURL(blob)
        })
        .catch(() => {
          return Promise.reject()
        })
    },
  }
}
