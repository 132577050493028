import { useState } from "react"
import { makeStyles, createStyles, Typography } from "@material-ui/core"
import { WorkOrdersFilters } from "./WorkOrdersFilter"
import WorkOrdersLists from "./WorkOrdersList"

function WorkOrdersMobile(props: any) {
  const classes = useStyles()
  const [filter, setFilter] = useState<any>({})

  return (
    <>
      <div className="h-fit h-min-40 bg-gradient-to-b from-case-grad-mid to-case-grad-to-mobile">
        <div className={classes.secondaryTitleMobile}>
          <Typography noWrap className={classes.titleFontMobile}>
            Work Orders
          </Typography>
        </div>
      </div>
      <div className="bg-gray min-h-screen">
        <WorkOrdersFilters filter={filter} setFilter={setFilter} />
        <WorkOrdersLists {...props} filter={filter} />
      </div>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    secondaryTitleMobile: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "100vw",
    },
    titleFontMobile: {
      fontSize: "44px",
      fontWeight: 600,
      lineHeight: "113%",
      color: "white",
      letterSpacing: "-1px",
      marginLeft: "20px",
      marginBottom: "20px",
      marginTop: "21px",
    },
  })
)

export default WorkOrdersMobile
