import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

interface PaginationComponentProps {
  total: number
  perPage: number
  page: number
  quantity: number
  loading?: boolean
  onNextPage?: () => void
  onPrevPage?: () => void
  onPageSizeChange?: (pageSize: number) => void
}

const PaginationComponent = ({ page, quantity, perPage, total, loading, onNextPage, onPrevPage, onPageSizeChange }: PaginationComponentProps) => {
  return (
    <div className="mb-6 h-[16px] w-full py-6 ">
      <div className="flex h-full w-full flex-row items-center justify-between">
        <p>
          Showing {quantity} of {total}
        </p>
        <div className="flex flex-row items-center gap-4">
          <ChevronLeftIcon
            className={`${page === 1 ? "" : "cursor-pointer"}`}
            color={page === 1 ? "disabled" : undefined}
            onClick={page !== 1 ? () => onPrevPage && onPrevPage() : undefined}
          />
          <label>
            {!loading ? page : "-"} of {Math.ceil(total / perPage) || 1}
          </label>
          <ChevronRightIcon
            className={`${page === (Math.ceil(total / perPage) || 0) ? "" : "cursor-pointer"}`}
            color={page === (Math.ceil(total / perPage) || 0) ? "disabled" : undefined}
            onClick={page !== (Math.ceil(total / perPage) || 0) ? () => onNextPage && onNextPage() : undefined}
          />
        </div>
        <div className="flex flex-row items-center gap-4">
          <label>Show: </label>
          <select
            id="location"
            name="location"
            className="border-gray-600 block rounded-md py-2 pl-3 pr-3 text-base font-semibold shadow-lg sm:text-sm"
            onChange={(e) => onPageSizeChange && onPageSizeChange(+e.target.value)}
          >
            <option value="10" selected={perPage === 10}>
              10
            </option>
            <option value="20" selected={perPage === 20}>
              20
            </option>
            {/* <option value="50">50</option> */}
          </select>
        </div>
      </div>
    </div>
  )
}

export default PaginationComponent
