import { Box, Button, createStyles, makeStyles, Modal } from "@material-ui/core"
import { ReactComponent as CloseIcon } from "./../../../assets/close-w.svg"
import React, { useCallback, useState } from "react"
import { usePartnersService } from "../../../services/partnersService"
import { OrganizationRoleSelect } from "../../Admin/OrganizationRoleSelect"
import { OrganizationSelect } from "../../Admin/OrganizationSelect"
import { TeamModalProps } from "../desktop/TeamDesktop"
import { Loader } from "../../common/Loader"

export default function UpdateOrgModal({
  user,
  openModal,
  closeModal,
  reload,
}: TeamModalProps) {
  const partnersService = usePartnersService()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [currentOrganization, setCurrentOrganization] = useState<any>()
  const [currentOrganizationRole, setCurrentOrganizationRole] = useState<any>()

  const handleClose = () => {
    setLoading(false)
    setError("")
    closeModal()
  }

  const handleUpdate = useCallback(
    async (e: any) => {
      e.preventDefault()
      setLoading(true)
      const controller = new AbortController()
      const orgRoles = user.organization_roles
      const org = orgRoles.find((_: any) => !!_?.organization_id)

      const data = {
        user_id: user.id,
        organization_id: org?.organization_id,
        new_sf_account_id: currentOrganization.sfid,
        new_organization_name: currentOrganization.name,
        role_id: currentOrganizationRole.role_id,
      }

      const response = await partnersService.updateAssociation(
        data,
        controller.signal
      )

      if (response && response?.status === 200 && response?.data) {
        setLoading(false)
        handleClose()
        reload()
      } else {
        setLoading(false)
        setError("Something went wrong")
      }
      return () => controller.abort()
    },
    [user, currentOrganization, currentOrganizationRole, setLoading]
  )

  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Box className={classes.style}>
        <div className={classes.header}>Update Organization</div>
        <div className={classes.body}>
          {loading ? (
            <Loader />
          ) : (
            <div>
              <>
                <p>Organization</p>
                <OrganizationSelect
                  onChange={(_) => setCurrentOrganization(_)}
                />
              </>
              {currentOrganization && currentOrganization?.id && (
                <>
                  <p>Role</p>
                  <OrganizationRoleSelect
                    organization={currentOrganization}
                    onChange={(_) => setCurrentOrganizationRole(_)}
                  />
                </>
              )}

              {error && (
                <React.Fragment>
                  <p className="text-red text-sm" style={{ color: "red" }}>
                    {error}
                  </p>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col items-center">
          <Button
            onClick={(e) => handleUpdate(e)}
            style={{
              marginTop: "80px",
              margin: "auto",
              marginBottom: "10px",
            }}
            className={classes.actionButton + " w-40"}
            disabled={!currentOrganizationRole || loading}
          >
            Update
          </Button>
        </div>

        <Button
          variant="text"
          onClick={handleClose}
          className={classes.imgButton}
        >
          <CloseIcon />
        </Button>
      </Box>
    </Modal>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      padding: "15px",
    },
    style: {
      position: "absolute" as "absolute",
      left: "50%",
      transform: "translate(-50%, 30vh)",
      backgroundColor: "white",
      borderRadius: "5px",
      width: "300px",
      minWidth: "100px",
    },
    actionButton: {
      border: "1px solid #0F2150",
      borderRadius: "100px",
      backgroundColor: "#0F2150",
      color: "#fff",
      "&.Mui-disabled": {
        border: "1px solid #e2e5ea",
        backgroundColor: "#e2e5ea",
        color: "#b2b7c6",
      },
      "&:hover": {
        backgroundColor: "#1b3a8c",
      },
    },
    input: {
      margin: "5px",
      width: "260px",
      "& .MuiInputBase-input": {
        padding: "5px",
      },
    },
    imgButton: {
      background: "red",
      top: 0,
      right: 0,
      border: "none",
      cursor: "pointer",
      position: "absolute",
      textAlign: "center",
      borderRadius: "5px",
      height: "25px",
      minWidth: "25px",
      "&:hover": {
        backgroundColor: "#eb5c52",
      },
    },
    header: {
      padding: "1px",
      paddingLeft: "10px",
      backgroundColor: "lightgray",
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
      fontWeight: "bold",
      color: "#0F2150",
    },
  })
)
