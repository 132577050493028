import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"

import { Paper, Collapse, Modal, TextField, Grid, Container, makeStyles } from "@material-ui/core"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import ClearIcon from "@mui/icons-material/Clear"
import EditIcon from "@mui/icons-material/Edit"
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined"
import Box from "@mui/system/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import moment from "moment"
import Carousel from "react-material-ui-carousel"
import CloseIcon from "@material-ui/icons/Close"
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined"
import { createStyles } from "@material-ui/core"
import { DateTime } from "luxon"

import { Loader } from "../../../../common/Loader"
import { ReactComponent as UnderIcon } from "../../../../../assets/under_icon.svg"
import { ReactComponent as OverIcon } from "../../../../../assets/over_icon.svg"
import { ServicesItem, ValidationsItem, WorklogsItem } from "../../../interfaces"
import { ValidationContext } from "../../../../../providers/ValidationProvider"
import { useGraphQLService } from "../../../../../services/graphqlService"
import "./SeasonCardComponent.css"
import { useFetchImages } from "../../../../../utils/ImageHelper"
import { ReactComponent as WorkordenIcon } from "../../../../../assets/menu-orders-b.svg"
import { ReactComponent as CalendarIcon } from "../../../../../assets/calendar-b.svg"
import { ReactComponent as ClockIcon } from "../../../../../assets/clock-b.svg"
import { ReactComponent as HourglassIcon } from "../../../../../assets/hourglass-b.svg"
import { ReactComponent as MessageIcon } from "../../../../../assets/message-text.svg"
import { getMinFormat } from "../../../../../utils/Util"
import { useActiveUser } from "../../../../../providers/AuthProvider"

interface Props {
  data: ValidationsItem
}

const SeasonCardComponent = ({ data }: Props) => {
  const [loading, setLoading] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const [showNotesModal, setShowNotesModal] = useState(false)
  const [showWODetailsModal, setShowWODetailsModal] = useState(false)
  const [loadingImages, setLoadingImages] = useState(false)
  const [openImg, setOpenImg] = useState(false)
  const [note, setNote] = useState("")
  const [selectedWorklog, setSelectedWorklog] = useState<WorklogsItem>()
  const [validationData, setValidationData] = useState<ValidationsItem>()
  const [showDetailIndex, setShowDetailIndex] = useState(-1)
  const [images, setImages] = useState<any[]>([])
  const [details, setDetails] = useState<any>({})

  const { validationState, setValidation } = useContext(ValidationContext)
  const { updateWorkOrderNotes, getWorkLogDetails, updateWorkOrderService } = useGraphQLService()
  const imageHelper = useFetchImages()
  const { name_full } = useActiveUser()
  const classes = useStyles()

  useEffect(() => {
    setShowDetailIndex(-1)
    setValidationData(data)
  }, [data])

  const updateWLNotes = useCallback(
    async (wlID: string, notes: string) => {
      try {
        const response = await updateWorkOrderNotes({
          work_log_id: wlID,
          service_partner_notes: notes,
          work_log_event: {
            type: "update",
          },
        })

        return response
      } catch (error) {
        console.log(error, "ERROR UPDATING NOTES")
      }
    },
    [updateWorkOrderNotes]
  )

  const generateAutoNote = useCallback(
    (status: "Cancelled" | "Approved" | "Edit") =>
      `${name_full ? `- ${name_full} ` : ""}- Work order marked as ${status} as part of Storm ID (${
        validationState?.selectedValidation?.stormId
      }) on ${moment().format("MM/DD/YYYY LTS")}\n`,
    [name_full, validationState?.selectedValidation?.stormId]
  )

  const getCounts = useMemo(() => {
    const res = data?.services?.filter((s) => s.count > 0)

    const result = res.map((r) => ({
      category: r.category,
      min: r.min,
      max: r.max,
      count: r.count,
    }))

    return result
  }, [data?.services])

  const handleStatusChange = useCallback(
    async (status: "Approved" | "Cancelled" | "Edit", wlItem: WorklogsItem) => {
      if (!validationState?.selectedValidation) return

      try {
        setLoading(true)

        // const resp = await updateWorkOrderValidation({
        //   status: status,
        //   validationName: validationState?.selectedValidation?.name,
        //   worklogId: wlItem?.workLogId,
        // })

        // if (resp) {
        if (status !== "Edit") {
          const response: any = await getWorkLogDetails({ sfid: wlItem.workLogId }, undefined, true)

          if (response) {
            for (let i = 0; i < response.services.length; i++) {
              const service = response.services[i]

              await updateWorkOrderService({
                approval_status: status,
                service_id: service?.external_id ?? "",
              })
            }
          }
        }

        let newValidations: ValidationsItem[] = validationState.selectedValidation?.validations.map((v: ValidationsItem) => {
          const newWorklogs = v.worklogs.map((wl) => {
            if (wl.workOrderId === wlItem.workOrderId && v.period === validationData?.period) {
              return {
                ...wl,
                approvalStatus: status,
              }
            }
            return wl
          })

          return {
            ...v,
            worklogs: newWorklogs,
          }
        })

        const v = newValidations.find((v) => v.period === data.period)

        const autoNote = generateAutoNote(status)

        if (
          v?.worklogs &&
          (!wlItem?.servicePartnerNotes?.includes("marked as Edit") || (wlItem?.servicePartnerNotes?.includes("marked as Edit") && status !== "Edit"))
        ) {
          // for (let index = 0; index < v.worklogs.length; index++) {
          // const WL = v.worklogs[index]

          const updatedNote = `${wlItem.servicePartnerNotes || ""}${autoNote}`

          const resp = await updateWLNotes(wlItem.workLogId, updatedNote.length > 255 ? updatedNote.substring(0, 255) : updatedNote)

          if (resp) {
            const updatedValidations: ValidationsItem[] = newValidations.map((v) => {
              const newWorklogs = v.worklogs.map((wl) => {
                if (wl.workLogId === wlItem.workLogId) {
                  return {
                    ...wl,
                    servicePartnerNotes: updatedNote.length > 255 ? updatedNote.substring(0, 255) : updatedNote,
                  }
                }

                return wl
              })

              return {
                ...v,
                worklogs: newWorklogs,
              }
            })

            newValidations = updatedValidations

            // setValidation({
            //   ...validationState,
            //   selectedValidation: {
            //     ...validationState.selectedValidation,
            //     validations: newValidations,
            //   },
            // })
          }
        }
        // }

        v && setValidationData(v)

        setValidation({
          ...validationState,
          validations: validationState.validations?.map((v) => {
            if (v.name === validationState.selectedValidation!.name) {
              return {
                ...v,
                validations: newValidations,
              }
            }

            return v
          }),
          selectedValidation: {
            ...validationState.selectedValidation,
            validations: newValidations,
          },
        })

        setShowDetailIndex(-1)
        setImages([])
        setShowActions(false)
        // }
      } catch (error) {
        alert("An error occurs trying to update WOS Approval Status, please try again.")
      } finally {
        setLoading(false)
      }
    },
    [data.period, generateAutoNote, getWorkLogDetails, setValidation, updateWLNotes, updateWorkOrderService, validationData?.period, validationState]
  )

  const handleServiceStatusChange = useCallback(
    async (status: "Cancelled" | "Approved", serviceItem: ServicesItem, row: WorklogsItem) => {
      if (!details?.services?.length) return

      // const service = details.services.find((s: any) => s.service_key === serviceItem.service)

      // if (!service) return

      setLoading(true)

      try {
        const resp = await updateWorkOrderService({
          approval_status: status,
          service_id: serviceItem?.external_id ?? "",
        })

        if (resp) {
          await handleStatusChange("Edit", row)
        }
      } catch (error) {
        alert("An error occurs trying to update WOS Approval Status, please try again.")
      } finally {
        setLoading(false)
      }
    },
    [details?.services, handleStatusChange, updateWorkOrderService]
  )

  const renderRowDetails = useCallback(
    async (row: WorklogsItem, index: number) => {
      if (showDetailIndex === index && !showWODetailsModal) {
        setShowDetailIndex(-1)
        setImages([])
        setDetails({})
      } else {
        setShowDetailIndex(index)

        try {
          setLoadingImages(true)

          const response: any = await getWorkLogDetails({ sfid: row.workLogId }, undefined, true)

          setDetails(response)

          const sfids = response?.attachments.map((a: any) => a.sfid)

          const imagesData = await imageHelper.getImages(sfids)

          setImages(imagesData)
        } catch (error) {
          console.log(error, "piip", row)
        } finally {
          setLoadingImages(false)
        }
      }
    },
    [getWorkLogDetails, imageHelper, showDetailIndex, showWODetailsModal]
  )

  const handleEdit = useCallback(
    async (wlItem: WorklogsItem, index: number) => {
      setShowDetailIndex(index)
      setShowActions(false)

      try {
        setLoadingImages(true)

        const response: any = await getWorkLogDetails({ sfid: wlItem.workLogId }, undefined, true)

        setDetails(response)

        const sfids = response?.attachments.map((a: any) => a.sfid)

        const imagesData = await imageHelper.getImages(sfids)

        setImages(imagesData)
      } catch (error) {
        console.log(error, "piip", wlItem)
      } finally {
        setLoadingImages(false)
      }
    },
    [getWorkLogDetails, imageHelper]
  )

  const handleNotes = useCallback(async () => {
    if (!selectedWorklog) return

    if (!validationState?.selectedValidation) return

    setLoading(true)

    try {
      const resp = await updateWLNotes(selectedWorklog.workLogId, note.length > 255 ? note.substring(0, 255) : note)

      if (resp) {
        setShowNotesModal(false)

        const newValidations: ValidationsItem[] = validationState.selectedValidation?.validations.map((v: ValidationsItem) => {
          const newWorklogs = v.worklogs.map((wl) => {
            if (wl.workLogId === selectedWorklog.workLogId) {
              return {
                ...wl,
                servicePartnerNotes: note.length > 255 ? note.substring(0, 255) : note,
              }
            }

            return wl
          })

          return {
            ...v,
            worklogs: newWorklogs,
          }
        })

        setValidation({
          ...validationState,
          selectedValidation: {
            ...validationState.selectedValidation,
            validations: newValidations,
          },
        })

        const updatedValidation = newValidations.find((v) => v.period === validationData?.period)

        updatedValidation && setValidationData(updatedValidation)
      }
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }, [note, selectedWorklog, setValidation, updateWLNotes, validationData?.period, validationState])

  const updateAll = useCallback(
    async (status: "Cancelled" | "Approved") => {
      if (!validationState?.selectedValidation) return

      if (validationData?.worklogs) {
        try {
          setShowDetailIndex(-1)
          setImages([])
          setDetails({})
          setLoading(true)

          for (let i = 0; i < validationData?.worklogs?.length; i++) {
            const workLog = validationData?.worklogs[i]

            // const resp = await updateWorkOrderValidation({
            //   status: status,
            //   validationName: validationState?.selectedValidation?.name,
            //   worklogId: workLog?.workLogId,
            // })

            // if (resp) {
            workLog.approvalStatus = status

            const response: any = await getWorkLogDetails({ sfid: workLog.workLogId }, undefined, true)

            if (response) {
              for (let i = 0; i < response.services.length; i++) {
                const service = response.services[i]

                await updateWorkOrderService({
                  approval_status: status,
                  service_id: service?.external_id ?? "",
                })
              }

              const autoNote = generateAutoNote(status)
              const updatedNote = `${workLog.servicePartnerNotes || ""}${autoNote}`
              await updateWLNotes(workLog.workLogId, updatedNote.length > 255 ? updatedNote.substring(0, 255) : updatedNote)
            }
            // }
          }
        } catch (error) {
          console.log(error, "ERROR")
        } finally {
          setLoading(false)
        }
      }
    },
    [generateAutoNote, getWorkLogDetails, updateWLNotes, updateWorkOrderService, validationData?.worklogs, validationState?.selectedValidation]
  )

  return (
    <>
      <Modal open={openImg} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" onClose={() => setOpenImg(false)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "60%",
              margin: "5px",
              backgroundColor: "white",
              position: "absolute",
            }}
          >
            <div className="p-8">
              <Carousel
                animation={"slide"}
                autoPlay={false}
                navButtonsAlwaysVisible={true}
                navButtonsProps={{
                  style: {},
                }}
              >
                {!!images?.length &&
                  images.map((img: any, i: any) => (
                    <div key={i} className="flex flex-col items-center">
                      {loadingImages ? <Loader /> : <img style={{ maxHeight: "85vh" }} alt="workorder_photo" src={img} />}
                    </div>
                  ))}
              </Carousel>
            </div>

            <Button
              variant="text"
              onClick={() => setOpenImg(false)}
              style={{
                background: "red",
                top: 0,
                right: 0,
                border: "none",
                cursor: "pointer",
                position: "absolute",
                textAlign: "center",
                borderRadius: "5px",
                height: "25px",
                minWidth: "25px",
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </Box>
      </Modal>

      <div className="containerItem">
        <div className="summaryAndOptionsContainer">
          <div className="headContent">
            <label className="itemTitle">{validationData?.period}</label>
            <div className="periodTimestampContainer">
              {validationData?.periodStart && (
                <div>
                  <label className="periodTimestampLabel">
                    <b>Begin:</b>{" "}
                  </label>
                  <label>{moment(validationData?.periodStart).format("MM/DD/YYYY LTS")}</label>
                </div>
              )}
              {validationData?.periodEnd && (
                <div>
                  <label className="periodTimestampLabel">
                    <b>End:</b>{" "}
                  </label>
                  <label>{moment(validationData?.periodEnd).format("MM/DD/YYYY LTS")}</label>
                </div>
              )}
            </div>
            <div className="counterAndActionsContainer">
              <div className="counter">
                {getCounts.map(
                  (c, i) =>
                    c?.count > 0 && (
                      <div key={i} className="counterItem">
                        <label className="counterCategory">
                          <b>{c.category ?? "Uncategorized"}:</b>
                        </label>
                        <label className="counterMin">Min: {c.min}</label>
                        <label className="counterMax">Max: {c.max}</label>
                        <div className="counterIconRow">
                          <label className="counterCount">Count: {c.count} </label>
                          <label
                            style={{
                              color:
                                c.count > (c.max ?? 0)
                                  ? "#FF1744"
                                  : c.count < (c.min ?? 0)
                                  ? "#CCAE60"
                                  : c.count >= (c.max ?? 0) || c.count <= (c.min ?? 0)
                                  ? "#2F7D31"
                                  : "#2F7D31",
                            }}
                          >
                            {`${
                              c.count > (c.max ?? 0)
                                ? `(Over)`
                                : c.count < (c.min ?? 0)
                                ? `(Under)`
                                : c.count >= (c.max ?? 0) || c.count <= (c.min ?? 0)
                                ? `(Okay)`
                                : `(Okay)`
                            }`}
                          </label>
                          {c.count > (c.max ?? 0) ? (
                            <OverIcon />
                          ) : c.count < (c.min ?? 0) ? (
                            <UnderIcon />
                          ) : c.count >= (c.max ?? 0) || c.count <= (c.min ?? 0) ? (
                            <CheckBoxOutlinedIcon color="success" fontSize="medium" />
                          ) : (
                            <CheckBoxOutlinedIcon color="success" fontSize="medium" />
                          )}
                        </div>
                      </div>
                    )
                )}
              </div>

              {validationData && validationData.worklogs.length > 1 && (
                <div className="buttonActionGroupContainer">
                  <button className="buttonAction decline" onClick={() => updateAll("Cancelled")}>
                    Decline All
                  </button>
                  <button className="buttonAction accept" onClick={() => updateAll("Approved")}>
                    Accept All
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead style={{ backgroundColor: "#DDDEE4" }}>
              <TableRow>
                <TableCell width="10%" onClick={() => {}}>
                  <div className="flex flex-row items-center">
                    <span>Work Order #</span>
                  </div>
                </TableCell>
                <TableCell width="10%" onClick={() => {}}>
                  <div className="flex flex-row items-center">
                    <span>Service Summary</span>
                  </div>
                </TableCell>
                <TableCell width="10%" onClick={() => {}}>
                  <div className="flex flex-row items-center">
                    <span>Date</span>
                  </div>
                </TableCell>
                <TableCell width="10%" onClick={() => {}}>
                  <div className="flex flex-row items-center">
                    <span>Algorithm Status</span>
                  </div>
                </TableCell>
                <TableCell width="10%" onClick={() => {}}>
                  <div className="flex flex-row items-center">
                    <span>Algorithm Reason</span>
                  </div>
                </TableCell>
                <TableCell width="10%" onClick={() => {}}>
                  <div className="flex flex-row items-center">
                    <span></span>
                  </div>
                </TableCell>
                <TableCell width="10%" onClick={() => {}}>
                  <div className="flex flex-row items-center">
                    <span>WO Approval</span>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            {!loading ? (
              <TableBody>
                {validationData?.worklogs.map((row, index) => (
                  <React.Fragment key={row.workOrderId}>
                    <TableRow
                      className={`rowItem clickable ${index === showDetailIndex ? "selectedRow" : ""}`}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      onClick={() => renderRowDetails(row, index)}
                    >
                      <TableCell
                        width="10%"
                        className={
                          index === showDetailIndex && details?.sfid
                            ? "whitespace-nowrap hover:cursor-pointer hover:font-bold hover:text-case-grad-mid hover:underline"
                            : ""
                        }
                        onClick={
                          index === showDetailIndex && details?.sfid
                            ? (e) => {
                                e.stopPropagation()
                                window.open(`${process.env.REACT_APP_SF_LINK}/lightning/r/Work_Order__c/${details.sfid}/view`, "_blank")
                              }
                            : undefined
                        }
                      >
                        {showDetailIndex === index && details?.sfid ? (
                          <span
                            className="z-50 mr-2 cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation()
                              setShowWODetailsModal(true)
                            }}
                          >
                            <LaunchOutlinedIcon />
                          </span>
                        ) : null}
                        {row.workOrderId}
                        {row.duplicate ? " (Duplicate)" : ""}
                      </TableCell>
                      <TableCell width="10%">{row.serviceSummary}</TableCell>
                      <TableCell width="10%">{moment(row.datetime).format("MM/DD/YYYY LT")}</TableCell>
                      <TableCell width="10%"></TableCell>
                      <TableCell width="10%"></TableCell>
                      <TableCell width="10%">
                        <TableCell width="10%" sx={{ border: 0 }}>
                          <button
                            className="rounded-md bg-case-grad-from px-4 py-2 font-bold text-white hover:scale-105 hover:cursor-pointer hover:bg-case-grad-mid hover:shadow-lg"
                            onClick={(e) => {
                              e.stopPropagation()
                              setNote(row?.servicePartnerNotes)
                              setSelectedWorklog(row)
                              setShowNotesModal(true)
                            }}
                          >
                            Notes
                          </button>
                        </TableCell>
                      </TableCell>
                      <TableCell
                        width="10%"
                        onMouseEnter={() => {
                          if (showDetailIndex !== index) {
                            setShowDetailIndex(index)
                            // setSelectedWorklog(row)
                            setShowActions(true)
                          }
                        }}
                        onMouseLeave={() => {
                          if (showDetailIndex === index && showActions) {
                            setShowDetailIndex(-1)
                            setImages([])
                            setShowActions(false)
                          }
                        }}
                      >
                        {showDetailIndex !== index ? (
                          <div className={`stormStatus ${row?.approvalStatus?.toLowerCase()}`}>
                            {/* {validationState.selectedValidation?.status} */}
                            {row?.approvalStatus}
                          </div>
                        ) : !showActions ? (
                          <></>
                        ) : (
                          <div className="statusActionIconsContainer">
                            <div
                              className="statusActionIcon transition-all duration-150 ease-in-out hover:scale-110"
                              style={{ backgroundColor: "#39CB73" }}
                              onClick={(e) => {
                                e.stopPropagation()

                                handleStatusChange("Approved", row)
                              }}
                            >
                              <CheckIcon fontSize="small" />
                            </div>
                            <div
                              className="statusActionIcon transition-all duration-150 ease-in-out hover:scale-110"
                              style={{ backgroundColor: "#FF1744" }}
                              onClick={(e) => {
                                e.stopPropagation()

                                handleStatusChange("Cancelled", row)
                              }}
                            >
                              <ClearIcon fontSize="small" />
                            </div>
                            <div
                              className="statusActionIcon transition-all duration-150 ease-in-out hover:scale-110"
                              style={{ backgroundColor: "#4EC7F1" }}
                              onClick={(e) => {
                                e.stopPropagation()

                                handleEdit(row, index)
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </div>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ padding: 0, margin: 0 }} colSpan={12}>
                        <Collapse in={index === showDetailIndex && !showActions} timeout={100} unmountOnExit className="subContent bg-case-gray">
                          {loadingImages ? (
                            <Loader />
                          ) : (
                            row.services.map((serviceItem, index) => {
                              const service = details?.services
                                ? details?.services?.find((s: any) => s.service_key === serviceItem.service)
                                : serviceItem

                              if (!service) return null

                              return (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell width="9%">{serviceItem.service}</TableCell>
                                  <TableCell width="9%">-</TableCell>
                                  <TableCell width="9%">{moment(row.datetime).format("MM/DD/YYYY LT")}</TableCell>
                                  <TableCell width="9%">{service?.approval_status}</TableCell>
                                  <TableCell width="16%">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <p>{serviceItem.reason}</p>
                                    </div>
                                  </TableCell>
                                  <TableCell width="9%">
                                    <div className="flex flex-row items-center justify-center gap-4">
                                      <div
                                        className={`statusActionIcon transition-all duration-150 ease-in-out ${
                                          service.approval_status === "Pending"
                                            ? "hover:scale-110"
                                            : service.approval_status === "Approved"
                                            ? "scale-100 border-2 border-case-grad-from"
                                            : "opacity-50 hover:scale-110 hover:opacity-100"
                                        }`}
                                        style={{ backgroundColor: "#39CB73" }}
                                        onClick={() => handleServiceStatusChange("Approved", service, row)}
                                      >
                                        <CheckIcon fontSize="small" />
                                      </div>
                                      <div
                                        className={`statusActionIcon transition-all duration-150 ease-in-out ${
                                          service.approval_status === "Pending"
                                            ? "hover:scale-110"
                                            : service.approval_status === "Cancelled"
                                            ? "scale-100 border-2 border-case-grad-from"
                                            : "opacity-50 hover:scale-110 hover:opacity-100"
                                        }`}
                                        style={{ backgroundColor: "#FF1744" }}
                                        onClick={() => handleServiceStatusChange("Cancelled", service, row)}
                                      >
                                        <ClearIcon fontSize="small" />
                                      </div>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              )
                            })
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            ) : (
              <Loader />
            )}
          </Table>
        </TableContainer>

        {showDetailIndex >= 0 && !showActions && (
          <div
            style={{
              padding: 8,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <label
              style={{
                fontSize: 35,
                fontWeight: "bold",
                color: "#232f64",
              }}
            >
              Photos
            </label>
            <div
              className="flex flex-row"
              style={{
                width: "100%",
                overflowY: "hidden",
                gap: "10px",
              }}
            >
              {!loadingImages ? (
                !!images?.length ? (
                  images.map((img: any, i: any) => (
                    <div
                      key={i}
                      style={{
                        position: "relative",
                        // width: "100%",
                        margin: "10px 0px",
                      }}
                    >
                      <img
                        style={{
                          // width: "100%",
                          minWidth: "150px",
                          maxHeight: "200px",
                          cursor: "pointer",
                        }}
                        alt="workorder_photo"
                        onClick={() => setOpenImg(true)}
                        src={img}
                      />
                    </div>
                  ))
                ) : (
                  <Typography>Empty</Typography>
                )
              ) : (
                <Loader />
              )}
            </div>
          </div>
        )}

        <Modal
          open={showNotesModal}
          onClose={() => setShowNotesModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              maxHeight: 500,
              overflow: "auto",
              bgcolor: "background.paper",
              backgroundColor: "#fff",
              border: "1px solid #000",
              borderRadius: 4,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Notes for {selectedWorklog?.workOrderId}
              </Typography>
              <br />
              <TextField
                id="outlined-multiline-static"
                label="Add Notes"
                multiline
                minRows={4}
                value={note}
                fullWidth
                onChange={(e) => setNote(e.target.value)}
                variant="standard"
              />

              <div className="modalButtonsContainer">
                <Button variant="contained" color="secondary" onClick={() => setShowNotesModal(false)} style={{ backgroundColor: "#ff1744" }}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleNotes} style={{ backgroundColor: "#0e2151" }}>
                  Save
                </Button>
              </div>
            </Typography>
          </Box>
        </Modal>

        <Modal
          open={showWODetailsModal}
          onClose={() => setShowWODetailsModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              // width: 1000,
              maxHeight: 1000,
              overflow: "auto",
              bgcolor: "background.paper",
              backgroundColor: "#fff",
              border: "1px solid #000",
              borderRadius: 4,
              boxShadow: 24,
              p: 4,
            }}
          >
            <div className="bg-case-gray">
              <Container className={classes.container}>
                <Grid container>
                  <Grid item xs={6}>
                    <div className={classes.gridHeight}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.titleFont}>Work Orders Details</Typography>
                        </Grid>
                        <Grid item xs={12} className="mt-8 flex flex-row items-center">
                          <WorkordenIcon className="mr-3" />
                          <div>
                            <span className={classes.infoFont}>{details?.name}</span>
                            <Typography noWrap className={classes.infoTitle}>
                              Work Order #
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} className="mt-8 flex flex-row items-center">
                          <CalendarIcon className="mr-3" />
                          <div>
                            <span className={classes.infoFont}>
                              {details?.service_date ? DateTime.fromISO(details?.service_date).toFormat("D") : "-"}
                            </span>
                            <Typography noWrap className={classes.infoTitle}>
                              Service Date
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={6} className="mt-8 flex flex-row items-center">
                          <ClockIcon className="mr-3" />
                          <div>
                            <span className={classes.infoFont}>
                              {details?.service_date ? DateTime.fromISO(details?.service_date).toFormat("t") : "-"}
                            </span>
                            <Typography noWrap className={classes.infoTitle}>
                              Service Time
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={6} className="mt-8 flex flex-row items-center">
                          <HourglassIcon className="mr-3" />
                          <div>
                            <span className={classes.infoFont}>{details?.time_taken_minutes ? getMinFormat(details?.time_taken_minutes) : "-"}</span>
                            <Typography noWrap className={classes.infoTitle}>
                              Duration on-site
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.gridHeight}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.titleFont}>Services Logged</Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <div className=" m-3">
                            {!!details?.services?.length &&
                              details?.services.map((service: any, index: any) => (
                                <div key={index} className="flex flex-row items-center font-bold">
                                  <div className="w-full">
                                    {service?.service_key || "-"}
                                    {" / "}
                                    {service?.status}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </Grid>
                        <Grid item xs={12} className="mt-8 flex flex-row items-center">
                          <MessageIcon className="ml-3" />
                          <div>
                            <span className={classes.commentsTitle}>Comments:</span>
                          </div>
                        </Grid>

                        <Grid item xs={12} className="mt-8 flex flex-row items-center">
                          <div>
                            <span className={classes.comments}>{details?.comments || "-"}</span>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    titleFont: {
      fontSize: "22px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#000",
      letterSpacing: "-1px",
      marginBottom: "20px",
    },
    infoFont: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 20,
      marginLeft: "30px",
    },
    commentsTitle: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 15,
      marginLeft: "0px",
    },
    comments: {
      fontSize: 15,
      marginLeft: "10px",
    },
    infoTitle: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
      marginBottom: "10px",
      marginLeft: "30px",
    },
    container: {
      maxWidth: "100%",
    },
    gridHeight: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      padding: "5px",
      background: "#FFFFFF",
      minHeight: "95.5%",
    },
  })
)

export default SeasonCardComponent
