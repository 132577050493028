import { createContext, PropsWithChildren, useCallback, useEffect, useState } from "react"

import { LOCALE_KEY } from "../utils/Util"

export interface I18nCustomContextProps {
  locale: string
  saveLocale: (locale: string) => void
}

export const i18nCustomContext = createContext<I18nCustomContextProps>({} as I18nCustomContextProps)

export const I18nCustomProvider = ({ children }: PropsWithChildren<any>) => {
  const [locale, setLocale] = useState("en")

  const saveLocale = useCallback(
    (newLocale: string) => {
      newLocale = newLocale.toLowerCase().includes("us") ? "en" : newLocale

      console.log("PERSISTING LOCALE ==>\n", "NEW :", newLocale, "PREVIOUS: ", locale, "\n<== PERSISTING LOCALE")

      localStorage.setItem(LOCALE_KEY, newLocale)

      setLocale(newLocale)
    },
    [locale]
  )

  const getLocaleFromLocalStorage = useCallback(() => {
    const currentLocale = localStorage.getItem(LOCALE_KEY)

    if (!!currentLocale) {
      setLocale(currentLocale)
    } else saveLocale("en")
  }, [saveLocale])

  useEffect(() => {
    getLocaleFromLocalStorage()
  }, [getLocaleFromLocalStorage])

  return <i18nCustomContext.Provider value={{ locale, saveLocale }}>{children}</i18nCustomContext.Provider>
}
