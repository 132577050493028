import React from "react"

const ReminderBangIconComponent = () => {
  return (
    <div className="diamond-container">
      <div className="diamond">
        <div className="bang">!</div>
      </div>
    </div>
  )
}

export default ReminderBangIconComponent
