import React, { useEffect, useState } from "react"
import { makeStyles, createStyles, Grid } from "@material-ui/core"
import { formatDateUTC } from "../../../utils/Util"
import useI18n from "../../../hooks/useI18n"

export const BidServiceDetails = (props: any) => {
  const classes = useStyles()
  const { i18n } = useI18n()
  const [services, setServices] = useState<any[]>([])

  useEffect(() => {
    setServices(props?.details?.groupedKeys)
  }, [props])

  return (
    <div className={classes.grid}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.title}>{i18n.t("bids.details.serviceDetails.title")}</div>
        </Grid>
        {services?.map((service: any, i: any) => (
          <React.Fragment key={i}>
            <Grid item xs={12}>
              <div className={classes.subTitle}>{service?.name}</div>
            </Grid>
            {service?.details?.map((d: any, j: any) => (
              <React.Fragment key={`${i}_${j}`}>
                <Grid item xs={4}>
                  <div className={classes.label}>{i18n.t("bids.details.serviceDetails.serviceTimeline")}:</div>
                </Grid>
                <Grid item xs={8}>
                  <div className={classes.data}>
                    {d?.service_start_date
                      ? formatDateUTC(d?.service_start_date)
                      : "N/A"}{" "}
                    -{" "}
                    {d?.service_end_date
                      ? formatDateUTC(d?.service_end_date)
                      : "N/A"}
                  </div>
                </Grid>
                {d?.service_frequency && (
                  <>
                    <Grid item xs={4}>
                      <div className={classes.label}>{i18n.t("common.tableheaders.frequency")}:</div>
                    </Grid>
                    <Grid item xs={8}>
                      <div className={classes.data}>{d?.service_frequency}</div>
                    </Grid>
                  </>
                )}
                {d?.expected_service_days && (
                  <>
                    <Grid item xs={4}>
                      <div className={classes.label}>{i18n.t("bids.details.serviceDetails.expectedDays")}:</div>
                    </Grid>
                    <Grid item xs={8}>
                      <div className={classes.data}>
                        {d?.expected_service_days}
                      </div>
                    </Grid>
                  </>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(() => {
  return createStyles({
    grid: {
      borderRadius: "5px",
      background: "#FFFFFF",
      boxShadow: "0px 0px 3px 1px rgb(0 0 0 / 20%)",
      padding: "5px",
    },
    title: {
      fontWeight: "bold",
      fontSize: "25px",
      color: "#0F2150",
    },
    subTitle: {
      fontWeight: "bold",
      fontSize: "15px",
      color: "#0F2150",
    },
    label: {
      fontSize: "10px",
      color: "#0F2150",
    },
    data: {
      fontSize: "10px",
      color: "#0F2150",
    },
  })
})
