import * as React from "react"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { ReactComponent as EditIcon } from "./../../../assets/edit.svg"
import { useActiveUser } from "../../../providers/AuthProvider"
import useI18n from "../../../hooks/useI18n"

export default function ActionMenu({
  openEdit,
  openEditRole,
  openDelete,
  selectedUser,
  openEditOrg,
  isAdmin = false,
}: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const user = useActiveUser()
  const { i18n } = useI18n()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = () => {
    handleClose()
    openEdit(selectedUser)
  }

  const handleRole = () => {
    handleClose()
    openEditRole(selectedUser)
  }

  const handleDelete = () => {
    handleClose()
    openDelete(selectedUser)
  }

  const handleEditOrg = () => {
    handleClose()
    openEditOrg(selectedUser)
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <EditIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            padding: 6,
          },
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleEdit}>{i18n.t("common.actionMenu.editProfile")}</MenuItem>
        <br />
        {isAdmin && (
          <div>
            <MenuItem onClick={handleEditOrg}>{i18n.t("common.actionMenu.editOrg")}</MenuItem>
            <br />
          </div>
        )}
        <MenuItem
          onClick={handleRole}
          disabled={
            selectedUser?.id === user?.userId ||
            selectedUser?.organization_roles?.length === 0
          }
        >
          {i18n.t("common.actionMenu.editRole")}
        </MenuItem>
        <br />
        <MenuItem
          onClick={handleDelete}
          disabled={selectedUser?.id === user?.userId}
        >
          {i18n.t("common.delete")}
        </MenuItem>
      </Menu>
    </div>
  )
}
