import { useEffect, useState } from "react"
import { makeStyles, createStyles } from "@material-ui/core"

function ServicesTab(props: any) {
  const classes = useStyles()
  const [error, setError] = useState("")
  const [partner, setPartner] = useState<any>({})

  useEffect(() => {
    setPartner(props?.partner)
  }, [props])

  if (error) {
    return <p>ERROR: {error}</p>
  } else {
    return <></>
  }
}

const useStyles = makeStyles(() => createStyles({}))

export default ServicesTab
