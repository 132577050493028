import React, { useEffect, useState } from "react"
import {
  makeStyles,
  createStyles,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core"
import { Filter } from "../../../common/Filter"
import { ReactComponent as SearchIcon } from "./../../../../assets/search.svg"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import useI18n from "../../../../hooks/useI18n"

export const DetailsFilter = ({ setFilter, filter }: any, props: any) => {
  const classes = useStyles()
  const [searchText, setSearchText] = useState("")
  const [search, setSearch] = useState("")
  const { i18n } = useI18n()

  useEffect(() => {
    if (search !== null) {
      setFilter({ ...filter, search: search })
    } else {
      setFilter({ ...filter, search: undefined })
    }
  }, [search])

  const searchChange = (event: any) => {
    setSearchText(event.target.value as string)
  }

  const searchByText = () => {
    setSearch(searchText)
  }

  return (
    <div className="w-full p-2.5">
      <div className="w-full h-fit">
        <Grid container className="flex-row items-center">
          <Grid item xs={4} className="flex flex-row items-center">
            <TextField
              size="small"
              id="input-with-icon-textfield"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className={classes.searchInput + " w-full"}
              placeholder={i18n.t("workOrders.searchByWorkOrderNumber")}
              onChange={(e) => searchChange(e)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchByText()
                }
              }}
            />
            <Button
              variant="outlined"
              size="small"
              className={classes.buttonFilter}
              onClick={() => searchByText()}
            >
              {i18n.t("common.search")}
            </Button>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </div>
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    searchInput: {
      backgroundColor: "white",
    },
    buttonFilter: {
      backgroundColor: "#f9f9f9",
      height: "40px",
      minWidth: "60px",
      fontSize: "10px",
    },
  })
)
