import { makeStyles, createStyles } from "@material-ui/core"

export const useAdminStyles = makeStyles(() =>
  createStyles({
    showTitle: {
      width: "auto",
      marginTop: "8px",
      marginLeft: "10px",
    },
    secondaryTitle: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "15px",
    },
    titleFont: {
      fontSize: "44px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "40px",
      marginTop: "21px",
    },
    cardTitle: {
      fontSize: "40px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "black",
      letterSpacing: "-1px",
      marginLeft: "40px",
      marginTop: "21px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#FFFFFF",
    },
    container: {
      maxWidth: "100%"
    },
    button: {
      marginBottom: "2rem",
    },
    searchInput: {
      backgroundColor: "white",
    },
    buttonFilter: {
      backgroundColor: "#f9f9f9",
      height: "40px",
      minWidth: "60px",
      fontSize: "10px",
    },
    actionButton: {
      border: "1px solid #0F2150",
      borderRadius: "5px",
      backgroundColor: "#0F2150",
      color: "#fff",
      "&.Mui-disabled": {
        border: "1px solid #e2e5ea",
        backgroundColor: "#e2e5ea",
        color: "#b2b7c6",
      },
      "&:hover": {
        backgroundColor: "#1b3a8c",
      },
    },
  })
)
