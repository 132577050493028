import { useCallback, useEffect, useState } from "react"
import {
  makeStyles,
  createStyles,
  Typography,
  Grid,
  Button,
  Divider,
  Modal,
  Box,
} from "@material-ui/core"
import { ReactComponent as MarkerIcon } from "./../../../assets/marker.svg"
import { ReactComponent as CalendarIcon } from "./../../../assets/calendar-b.svg"
import { ReactComponent as ClockIcon } from "./../../../assets/clock-b.svg"
import { ReactComponent as HourglassIcon } from "./../../../assets/hourglass-b.svg"
import { ReactComponent as WorkordenIcon } from "./../../../assets/menu-orders-b.svg"
import { ReactComponent as MessageIcon } from "./../../../assets/message-text.svg"
import { ReactComponent as CloseIcon } from "./../../../assets/close-w.svg"
import { useNavigate } from "react-router-dom"
import { Loader } from "../../common/Loader"
import {
  formatDate,
  getMinFormat,
  getTime,
  getUrlParam,
} from "../../../utils/Util"
import Carousel from "react-material-ui-carousel"
import { useGraphQLService } from "../../../services/graphqlService"
import { useFetchImages } from "../../../utils/ImageHelper"
import { DateTime } from "luxon"

function WorkOrderDetails(props: any) {
  const classes = useStyles()
  const imageHelper = useFetchImages()
  const navigate = useNavigate()
  const graphqlService = useGraphQLService()
  const [details, setDetails] = useState<any>({})
  const [loadingDetails, setLoadingDetails] = useState(false)
  const [openImg, setOpenImg] = useState(false)
  const [loadingImges, setLoadingImges] = useState(false)
  const [images, setImages] = useState<any[]>([])

  const getWorkOrderDetails = useCallback(
    (sfid: any, signal?: AbortSignal) => {
      setLoadingDetails(true)
      setLoadingImges(true)
      graphqlService
        .getWorkLogDetails(
          {
            sfid,
          },
          signal
        )
        .then((response) => {
          setDetails(response)
          setLoadingDetails(false)
          getImageData(response)
        })
        .catch(() => {
          setLoadingDetails(false)
          setLoadingImges(false)
        })
    },
    [graphqlService]
  )

  useEffect(() => {
    const controller = new AbortController()

    const id = getUrlParam("id") as string
    if (id) {
      getWorkOrderDetails(id, controller.signal)
    } else {
      navigate("/workorders")
    }

    return () => controller.abort()
  }, [getWorkOrderDetails, navigate])

  const openImage = () => {
    setOpenImg(true)
  }

  const getImageData = useCallback(async (response: any) => {
    let totalImg = response?.attachments?.length
    if (!totalImg) {
      setLoadingImges(false)
      setImages([])
      return
    }
    const sfids = response?.attachments.map((a: any) => a.sfid)
    const imagesData = await imageHelper.getImages(sfids)
    setImages(imagesData)
    setLoadingImges(false)
  }, [])

  if (loadingDetails) {
    return <Loader />
  } else {
    return (
      <>
        <Modal
          open={openImg}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onClose={() => setOpenImg(false)}
        >
          <Box className={classes.styleImg}>
            <div className={classes.iframeBlock}>
              <div className="p-8">
                <Carousel
                  animation={"slide"}
                  autoPlay={false}
                  navButtonsAlwaysVisible={true}
                >
                  {!!images?.length &&
                    images.map((img: any, i: any) => (
                      <div key={i} className="flex flex-col items-center">
                        {loadingImges ? (
                          <Loader />
                        ) : (
                          <img
                            className={classes.carouselImg}
                            alt="photo"
                            src={img}
                          />
                        )}
                      </div>
                    ))}
                </Carousel>
              </div>

              <Button
                variant="text"
                onClick={() => setOpenImg(false)}
                className={classes.imgButtonClose}
              >
                <CloseIcon />
              </Button>
            </div>
          </Box>
        </Modal>
        <div className="h-fit bg-gradient-to-b from-case-grad-mid to-case-grad-to-mobile">
          <div className={classes.secondaryTitleMobile}>
            <Typography noWrap className={classes.titleFontMobile}>
              <div
                className={classes.accountName + " flex flex-row items-center"}
              >
                <div className={classes.accountName2 + " w-full"}>
                  {details?.name}
                </div>
              </div>
            </Typography>
          </div>
          <Typography className={classes.secondaryTitleFont}>
            <div className="flex flex-row items-center">
              <span>
                <MarkerIcon className="w-4 mr-1.5" />
              </span>
              <span>{details.location_name}</span>
            </div>
          </Typography>
        </div>
        <div className="m-3">
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.titleDetails}>
                Work Orders Details
              </Typography>
            </Grid>

            <Grid item xs={12} className="flex flex-row items-center">
              <WorkordenIcon className="ml-3" />
              <div>
                <span className={classes.infoFontDetails}>{details?.name}</span>
                <Typography noWrap className={classes.infoTitleDetails}>
                  Work Order #
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} className={classes.divider}>
              <Divider variant="middle" />
            </Grid>

            <Grid item xs={12} className="flex flex-row items-center">
              <CalendarIcon className="ml-3" />
              <div>
                <span className={classes.infoFontDetails}>
                  {details?.service_date
                    ? formatDate(details?.service_date)
                    : "-"}
                </span>
                <Typography noWrap className={classes.infoTitleDetails}>
                  Service Date
                </Typography>
              </div>
            </Grid>

            <Grid item xs={6} className="flex flex-row items-center">
              <ClockIcon className="ml-3" />
              <div>
                <span className={classes.infoFontDetails}>
                  {details?.service_date
                    ? DateTime.fromISO(details?.service_date).toFormat("t")
                    : "-"}
                </span>
                <Typography noWrap className={classes.infoTitleDetails}>
                  Service Time
                </Typography>
              </div>
            </Grid>

            <Grid item xs={6} className="flex flex-row items-center">
              <HourglassIcon className="ml-3" />
              <div>
                <span className={classes.infoFontDetails}>
                  {details?.time_taken_minutes
                    ? getMinFormat(details?.time_taken_minutes)
                    : "-"}
                </span>
                <Typography noWrap className={classes.infoTitleDetails}>
                  Duration on-site
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} className={classes.divider}>
              <Divider variant="middle" />
            </Grid>

            <Grid item xs={12}>
              <div className={classes.showTitle}>
                <div>
                  <Typography noWrap className={classes.titleFont}>
                    Services Logged
                  </Typography>
                </div>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className=" m-3">
                {!!details?.services?.length &&
                  details?.services.map((service: any, index: any) => (
                    <div
                      key={index}
                      className="font-bold flex flex-row items-center"
                    >
                      <div className="w-full">
                        {service?.service_key || "-"}
                        {" / "}
                        {service?.status}
                      </div>
                    </div>
                  ))}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="flex flex-row items-center mt-3">
                <MessageIcon className="ml-3 mr-3" />
                <span className={classes.commentsTitle}>Comments:</span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="flex flex-row items-center mt-3">
                <span className={classes.comments}>
                  {details?.comments || "-"}
                </span>
              </div>
            </Grid>

            <Grid item xs={12} className={classes.divider}>
              <Divider variant="middle" />
            </Grid>

            <Grid item xs={12}>
              <div className={classes.showTitle}>
                <div>
                  <Typography noWrap className={classes.titleFont}>
                    Photos
                  </Typography>
                </div>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="m-3">
                <div className="flex flex-col items-center">
                  {loadingImges ? (
                    <Loader />
                  ) : (
                    <>
                      {!!images?.length &&
                        images.map((img: any, index: any) => (
                          <div key={index} className={classes.imgContainer}>
                            <img
                              onClick={() => openImage()}
                              className={classes.image + " cursor-pointer"}
                              alt="photo"
                              src={img}
                            />
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    secondaryTitleMobile: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "100vw",
    },
    titleFontMobile: {
      fontSize: "22px",
      lineHeight: "113%",
      color: "white",
      letterSpacing: "-1px",
      marginLeft: "20px",
      marginTop: "20px",
      marginBottom: "10px",
    },
    titleDetails: {
      fontSize: "22px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#000",
      letterSpacing: "-1px",
      marginBottom: "20px",
    },
    secondaryTitleFont: {
      fontSize: "15px",
      lineHeight: "113%",
      color: "white",
      letterSpacing: "-1px",
      marginLeft: "20px",
      paddingBottom: "10px",
    },
    infoFont: {
      color: "white",
      fontWeight: "bold",
      fontSize: 20,
    },
    infoFontDetails: {
      color: "black",
      fontWeight: "bold",
      fontSize: 20,
      marginLeft: "20px",
    },
    infoTitle: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "white",
      letterSpacing: "-1px",
      marginBottom: "10px",
    },
    infoTitleDetails: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
      marginBottom: "10px",
      marginLeft: "20px",
    },
    accountName: {
      width: "100vw",
    },
    accountName2: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
      fontSize: "30px",
    },
    containerText: {
      width: "auto",
      margin: "20px",
    },
    buttonTab: {
      color: "#0F2150",
      fontWeight: "bold",
      backgroundColor: "white",
    },
    selectedTab: {
      borderBottom: "2px solid #0F2150",
    },
    showTitle: {
      width: "auto",
      marginTop: "8px",
      marginLeft: "10px",
    },
    titleFont: {
      fontSize: "22px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#000",
      letterSpacing: "-1px",
      marginBottom: "20px",
    },
    greenStatus: {
      backgroundColor: "#d7f5e3",
      color: "#4e996c",
      borderRadius: "20px",
      padding: "5px 10px",
    },
    redStatus: {
      backgroundColor: "#fed5d5",
      color: "#ff3b5f",
      borderRadius: "20px",
      padding: "5px 10px",
    },
    yellowStatus: {
      backgroundColor: "#ffd978",
      color: "#665730",
      borderRadius: "20px",
      padding: "5px 10px",
    },
    divider: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    styleImg: {},
    image: {
      width: "100%",
      height: "auto",
    },
    imgContainer: {
      position: "relative",
      width: "100%",
      margin: "10px 0px",
    },
    imgButton: {
      background: "white",
      top: 0,
      right: 0,
      border: "none",
      cursor: "pointer",
      position: "absolute",
      textAlign: "center",
      borderRadius: "5px",
      height: "25px",
      minWidth: "25px",
    },
    commentsTitle: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 15,
      marginLeft: "0px",
    },
    comments: {
      fontSize: 15,
      marginLeft: "20px",
    },
    imgButtonClose: {
      background: "red",
      top: 0,
      right: 0,
      border: "none",
      cursor: "pointer",
      position: "absolute",
      textAlign: "center",
      borderRadius: "5px",
      height: "25px",
      minWidth: "25px",
    },
    iframeBlock: {
      width: "100%",
      margin: "5px",
      backgroundColor: "white",
      position: "absolute",
    },
    carouselImg: {
      maxHeight: "85vh",
    },
  })
)

export default WorkOrderDetails
