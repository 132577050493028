import { useContext } from "react"

import { I18n } from "i18n-js"

import { i18nCustomContext } from "../providers/I18nProvider"
import { translations } from "../localization"

const useI18n = () => {
  const { locale, saveLocale } = useContext(i18nCustomContext)
  const i18n = new I18n(translations, {
    enableFallback: true,
    defaultLocale: "en",
  })

  i18n.locale = locale
  i18n.missingBehavior = "guess"

  return { locale, saveLocale, i18n }
}

export default useI18n
