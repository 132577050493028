import { useCallback, useEffect, useState } from "react"
import { makeStyles, createStyles, Container, Grid, Typography } from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import SitesLists from "./cards/SitesList"
import Map from "../../common/googleMap"
import { Loader } from "../../common/Loader"
import Divider from "@mui/material/Divider"
import { SitesFilters } from "./cards/SitesFilter"
import { useActiveUser, useAuth } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"
import useI18n from "../../../hooks/useI18n"

function DashboardDesktop(props: any) {
  const navigate = useNavigate()
  const classes = useStyles()
  const graphqlService = useGraphQLService()
  const { sfId: sfid } = useActiveUser()
  const { setIsCanada } = useAuth()
  const [data, setData] = useState<any>(null)
  const [markers, setMarkers] = useState<any[]>([])
  const [openBidsMarkers, setOpenBidsMarkers] = useState<any[]>([])
  const [pendingBidsMarkers, setPendingBidsMarkersBids] = useState<any[]>([])
  const [activeSites, setActiveSites] = useState(0)
  const [noResponseBids, setNoResponseBids] = useState(0)
  const [pendingBids, setPendingBids] = useState(0)
  const [awardedBids, setAwardedBids] = useState(0)
  const [filter, setFilter] = useState<any>({})
  const [loadingLocations, setLoadingLocations] = useState(false)
  const [bidMarkersReady, setBidsMarkersReady] = useState(false)
  const [loadingWorkOrders, setLoadingWorkOrders] = useState(true)
  const [workOrders, setWorkOrders] = useState(0)
  const { i18n } = useI18n()

  const getPartnerData = useCallback(
    (signal?: AbortSignal) => {
      let data = sessionStorage.getItem("dashboardData")
      graphqlService
        .getPartnerBySfid({ sfid }, signal)
        .then((response) => {
          setData(response)
          sessionStorage?.setItem("dashboardData", JSON.stringify(response))
          setIsCanada(response?.billingcountry?.toLowerCase().includes("canada") || false)
        })
        .catch(() => {
          setData(data)
        })
    },
    [graphqlService, sfid, setIsCanada]
  )

  const setAllDashboardData = useCallback((response: any) => {
    setActiveSites(response?.active_sites?.pagination?.found_rows)
    setNoResponseBids(response?.open_bids?.pagination?.found_rows)
    setPendingBids(response?.pending_bids?.pagination?.found_rows)
    setAwardedBids(response?.awarded_bids?.pagination?.found_rows)

    let openBids: any[] = []
    let pendingBids: any[] = []
    let sites: any[] = []
    response?.active_sites?.sites.map((s: any) => {
      sites.push({
        id: s.site_id,
        name: s.site_name,
        lat: s.site_lat,
        lng: s.site_lon,
      })
    })

    response?.pending_bids?.bids?.map((b: any) => {
      pendingBids.push({
        id: b.case_bid_id,
        name: b.bid_location_name,
        lat: b.site_lat,
        lng: b.site_lon,
      })
    })

    response?.open_bids?.bids?.map((b: any) => {
      openBids.push({
        id: b.case_bid_id,
        name: b.bid_location_name,
        lat: b.site_lat,
        lng: b.site_lon,
      })
    })
    setOpenBidsMarkers(openBids)
    setPendingBidsMarkersBids(pendingBids)
    setMarkers(sites)
    setLoadingLocations(true)
    setBidsMarkersReady(true)
  }, [])

  const getMarkers = useCallback(
    (signal?: AbortSignal) => {
      let data = sessionStorage.getItem("dashboard")
      if (!!data) {
        setAllDashboardData(JSON.parse(data as string))
      } else {
        graphqlService
          .getDashboardData({ sfid }, signal)
          .then((response) => {
            sessionStorage?.setItem("dashboard", JSON.stringify(response))
            setAllDashboardData(response)
          })
          .catch(() => {})
      }
    },
    [graphqlService, setAllDashboardData, sfid]
  )

  useEffect(() => {
    const controller = new AbortController()

    window.onunload = function () {
      sessionStorage.clear()
    }
    getPartnerData(controller.signal)
    getMarkers(controller.signal)

    return () => controller.abort()
  }, [getMarkers, getPartnerData])

  const setWOs = useCallback((wos: any) => {
    setLoadingWorkOrders(false)
    setWorkOrders(wos)
  }, [])

  return (
    <>
      <div>
        <Container className={classes.container}>
          <Grid container>
            <Grid item xs={12} className="flex flex-row items-center justify-between">
              <div>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.showTitle}>
                      <div>
                        <Typography className={classes.titleFont}>{data?.name}</Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container className="flex-row items-center">
                  <Grid item xs={12}>
                    <div className={classes.secondaryTitle}>
                      <Typography className={classes.direction}>
                        {data?.billingstreet} {data?.billingcity}, {data?.billingstate}, {data?.billingcountry}, {data?.billingpostalcode}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>

              {data?.insurance_status && data?.insurance_status?.toLowerCase() !== "compliant" && (
                <div
                  className={`flex flex-row items-end gap-2 rounded-md ${
                    data?.insurance_status?.toLowerCase() !== "unknown" ? "border-2 border-[#FF1744]" : ""
                  } p-4`}
                >
                  <span className="text-xl">{i18n.t("dashboard.insuranceStatus")}</span>
                  <span className={`text-4xl font-bold ${data?.insurance_status?.toLowerCase() === "unknown" ? "text-[#000000]" : "text-[#FF1744]"}`}>
                    {`${i18n.t(
                      `dashboard.${data?.insurance_status?.toLowerCase() === "unknown" ? "Unknown" : data?.insurance_status?.toLowerCase()}`
                    )}`}
                  </span>
                  <div className="cursor-pointer text-xl underline" onClick={() => navigate("compliance")}>
                    {i18n.t("dashboard.clickHereForMoreDetails")}
                  </div>
                </div>
              )}
            </Grid>

            <Grid item xs={3}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.gridBids}>
                    <div className={classes.secondaryTitle}>
                      <Typography noWrap className={classes.cardTitle}>
                        {i18n.t("dashboard.bids")}
                      </Typography>
                    </div>
                    <div
                      className=" m-2 flex flex-row items-center rounded pt-9"
                      onClick={() => navigate(`/bids?filterBy=No Response`)}
                      style={{ cursor: "pointer" }}
                    >
                      {bidMarkersReady ? <span className={classes.circleOpenBids}>{noResponseBids}</span> : <Loader />}
                      <span className={classes.cardMessage}>{i18n.t("dashboard.openBids")}</span>
                    </div>
                    <div
                      className=" m-2 flex flex-row items-center rounded pt-9"
                      onClick={() => navigate(`/bids?filterBy=Accepted,Counter`)}
                      style={{ cursor: "pointer" }}
                    >
                      {bidMarkersReady ? <span className={classes.circleRecomendedBids}>{pendingBids}</span> : <Loader />}
                      <span className={classes.cardMessage}>{i18n.t("dashboard.pendingBids")}</span>
                    </div>
                    <div
                      className=" m-2 flex flex-row items-center rounded pt-9"
                      onClick={() => navigate(`/bids?filterBy=Awarded`)}
                      style={{ cursor: "pointer" }}
                    >
                      {bidMarkersReady ? <span className={classes.circleNewBids}>{awardedBids}</span> : <Loader />}
                      <span className={classes.cardMessage}>{i18n.t("dashboard.awardedBids")}</span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={9}>
              <div className={classes.gridMap}>
                <Grid container className="flex-row items-center">
                  <Grid item xs={12}>
                    <div>
                      {data && loadingLocations && bidMarkersReady && data?.billinglatitude && data?.billinglongitude ? (
                        <div className="relative">
                          <Map
                            center={{
                              lat: data?.billinglatitude,
                              lng: data?.billinglongitude,
                            }}
                            principalMarkers={[
                              {
                                id: 0,
                                name: data?.name,
                                lat: data?.billinglatitude,
                                lng: data?.billinglongitude,
                              },
                            ]}
                            partnerName={data?.name}
                            siteMarkers={markers}
                            openBidMarkers={openBidsMarkers}
                            pendingBidMarkers={pendingBidsMarkers}
                            isLoaded={true}
                            zoom={10}
                          />
                          <div className={classes.distance}>
                            <div className={classes.spanDistance + " text-s ml-52 mt-3 flex flex-row rounded bg-white px-2 py-1"}>
                              <div className={classes.iframeBlock}>
                                <img className={classes.image} alt="photo" src={`https://maps.google.com/mapfiles/ms/icons/red-dot.png`} />
                              </div>
                              <span className="mr-3">HQ</span>
                              <div className={classes.iframeBlock}>
                                <img className={classes.image} alt="photo" src={`https://maps.google.com/mapfiles/ms/icons/blue-dot.png`} />
                              </div>
                              <span className="mr-3">{i18n.t("dashboard.yourSite")}</span>
                              <div className={classes.iframeBlock}>
                                <img className={classes.image} alt="photo" src={`https://maps.google.com/mapfiles/ms/icons/green-dot.png`} />
                              </div>
                              <span className="mr-3">{i18n.t("dashboard.pendingBids")}</span>
                              <div className={classes.iframeBlock}>
                                <img className={classes.image} alt="photo" src={`https://maps.google.com/mapfiles/ms/icons/yellow-dot.png`} />
                              </div>
                              <span className="mr-3">{i18n.t("dashboard.openBids")}</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <Grid item xs={3}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.gridBids}>
                    <div className={classes.secondaryTitle}>
                      <Typography noWrap className={classes.cardTitle}>
                        {i18n.t("dashboard.performance")}
                      </Typography>
                    </div>
                    <div className="pt-9">
                      <span className={classes.cardMessage}>{i18n.t("dashboard.activeSites")}</span>
                      {loadingLocations ? <span className={classes.performanceNumber}>{activeSites}</span> : <Loader />}
                    </div>
                    <Divider variant="middle" className={classes.divider} />
                    <div className="pt-9">
                      <span className={classes.cardMessage}>{i18n.t("dashboard.completedWorkOrders")}</span>
                      {!loadingWorkOrders ? <span className={classes.performanceNumber}>{workOrders}</span> : <Loader />}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <div className={classes.grid}>
                <Grid container className="flex-row items-center">
                  <Grid item xs={12} onClick={() => navigate("/sites")} style={{ cursor: "pointer" }}>
                    <div className={classes.secondaryTitle}>
                      <Typography noWrap className={classes.cardTitle}>
                        {i18n.t("dashboard.yourSites")}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <SitesFilters filter={filter} setFilter={setFilter} />
                <SitesLists {...props} filter={filter} setWOs={setWOs} />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    showTitle: {
      width: "auto",
      marginTop: "10px",
      marginLeft: "10px",
    },
    titleFont: {
      fontSize: "44px",
      fontWeight: "bold",
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
    },
    secondaryTitleFont: {
      fontSize: "18px",
      lineHeight: "113%",
      color: "#000",
      letterSpacing: "-1px",
      marginBottom: "20px",
    },
    cardTitle: {
      fontSize: "30px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "10px",
    },
    direction: {
      fontSize: "20px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#000",
      letterSpacing: "-1px",
      marginLeft: "10px",
    },
    cardMessage: {
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "10px",
    },
    cardMessageTitle: {
      fontSize: "25px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "10px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#FFFFFF",
      padding: "15px",
      marginTop: "10px",
    },
    gridMap: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#FFFFFF",
      padding: "0px",
      marginTop: "10px",
    },
    gridBids: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      background: "#FFFFFF",
      padding: "15px",
      margin: "10px",
    },
    container: {
      maxWidth: "100%",
    },
    secondaryTitle: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "15px",
    },
    circleNewBids: {
      backgroundColor: "white",
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "5px",
      fontSize: "20px",
      color: "#2eaf7d",
      fontWeight: "bold",
      border: "1px solid #2eaf7d",
    },
    circleRecomendedBids: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "5px",
      fontSize: "20px",
      color: "#232F64",
      fontWeight: "bold",
      border: "1px solid #232F64",
    },
    circleOpenBids: {
      backgroundColor: "#232F64",
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "5px",
      fontSize: "20px",
      color: "white",
      fontWeight: "bold",
    },
    imageMap: {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "cover",
      width: "100%",
    },
    divider: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    performanceNumber: {
      display: "flex",
      backgroundColor: "red",
      backgroundImage: "linear-gradient(0, #4EC7F1, #0F2150)",
      backgroundSize: "100%",
      backgroundRepeat: "repeat",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      fontSize: "50px",
      "-moz-background-clip": "text",
      "-moz-text-fill-color": "transparent",
      fontWeight: "bold",
      marginBottom: "5px",
      marginLeft: "10px",
    },
    distance: {
      color: "black",
    },
    spanDistance: {
      top: 0,
      left: 0,
      border: "none",
      position: "absolute",
    },
    iframeBlock: {},
    image: {
      width: "20px",
      height: "auto",
    },
  })
)

export default DashboardDesktop
