import { useCallback, useEffect, useRef, useState } from "react"
import { makeStyles, createStyles } from "@material-ui/core"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { styled } from "@mui/material/styles"
import Pagination from "../../common/Pagination"
import { Loader } from "../../common/Loader"
import {
  distanceInMiles,
  formatDate,
  formatDateUTC,
  isPastDate,
} from "../../../utils/Util"
import { ReactComponent as UpIcon } from "./../../../assets/up.svg"
import { ReactComponent as DownIcon } from "./../../../assets/down.svg"
import { ReactComponent as SnowIcon } from "./../../../assets/cloud-snow-lb.svg"
import { ReactComponent as TreeIcon } from "./../../../assets/tree-g.svg"
import { ReactComponent as SweepingIcon } from "./../../../assets/sweeping-b.svg"
import { ReactComponent as JanitorialIcon } from "./../../../assets/janitoral-b.svg"
import { useNavigate } from "react-router-dom"
import { useActiveUser } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"
import useI18n from "../../../hooks/useI18n"

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const BidsLists = ({ filter }: any) => {
  const classes = useStyles()
  const { i18n } = useI18n();
  const navigate = useNavigate()
  const graphqlService = useGraphQLService()

  const [search, searchInput] = useState("")
  const [data, setData] = useState<any[]>([])
  const [sort, setSort] = useState({
    field: "bid_status_response",
    order: "ASC",
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const { sfId: sfid } = useActiveUser()
  const [partner, setPartner] = useState<any>({})
  const prevFilter = usePrevious({ filter, perPage })

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f9f9f9",
      color: "#232F64",
      fontSize: "20px",
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }))

  const getPartnerData = useCallback(
    (signal?: AbortSignal) => {
      graphqlService
        .getPartnerBySfid({ sfid }, signal)
        .then((response) => {
          setPartner(response)
        })
        .catch(() => {})
    },
    [graphqlService, sfid]
  )

  useEffect(() => {
    const controller = new AbortController()
    getPartnerData(controller.signal)
    return () => controller.abort()
  }, [getPartnerData])

  useEffect(() => {
    const controller = new AbortController()

    if (
      (filter !== prevFilter?.filter || perPage !== prevFilter?.perPage) &&
      page !== 1
    ) {
      setPage(1)
    } else {
      setLoading(true)
      graphqlService
        .getBids(
          {
            sfid,
            limit: perPage,
            offset: (page - 1) * perPage,
            sort: sort.field,
            order: sort.order,
            filterBy:
              typeof filter?.filter === "string" ? filter?.filter : "All",
            search: filter?.search,
          },
          controller.signal
        )
        .then((response) => {
          setData(response?.bids)
          setTotal(response.pagination.found_rows)
          setLoading(false)
        })
        .catch((er) => {
          setLoading(false)
        })
      return () => controller.abort()
    }
  }, [graphqlService, page, perPage, filter, sfid, sort])

  const distance = (site_lat: any, site_lon: any) => {
    if (partner?.billinglatitude && partner?.billinglongitude) {
      let from = {
        lat: site_lat,
        lon: site_lon,
      }
      let to = {
        lat: partner?.billinglatitude,
        lon: partner?.billinglongitude,
      }
      return distanceInMiles(from, to)
    }
    return ""
  }

  const goToBidDetails = (bid: any) => {
    navigate(`/bid-details?id=${bid.case_bid_id}`)
  }

  const sortHandler = (by: any) => {
    if (by === sort.field) {
      if (sort.order === "ASC") {
        setSort({ field: sort.field, order: "DESC" })
      } else {
        setSort({ field: sort.field, order: "ASC" })
      }
    } else {
      setSort({ field: by, order: sort.order })
    }
  }

  if (error) {
    return <p>ERROR: {error}</p>
  } else {
    return (
      <>
        <div className={classes.listContainer}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    onClick={() => sortHandler("bid_location_name")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="flex flex-row items-center">
                      {sort.field === "bid_location_name" &&
                        sort.order === "DESC" && <DownIcon />}
                      {sort.field === "bid_location_name" &&
                        sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.siteName")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => sortHandler("bid_status")}
                    style={{ cursor: "pointer", minWidth: "120px" }}
                  >
                    <div className="flex flex-row items-center min-w-fit">
                      {sort.field === "bid_status" && sort.order === "DESC" && (
                        <DownIcon />
                      )}
                      {sort.field === "bid_status" && sort.order === "ASC" && (
                        <UpIcon />
                      )}
                      <span>{i18n.t("common.tableHeaders.status")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => sortHandler("service_line")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="flex flex-row items-center min-w-fit">
                      {sort.field === "service_line" &&
                        sort.order === "DESC" && <DownIcon />}
                      {sort.field === "service_line" &&
                        sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.service")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="flex flex-row items-center min-w-fit">
                      {sort.field === "site_lat" && sort.order === "DESC" && (
                        <DownIcon />
                      )}
                      {sort.field === "site_lat" && sort.order === "ASC" && (
                        <UpIcon />
                      )}
                      <span>Distance</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => sortHandler("bid_response_deadline")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="flex flex-row items-center min-w-fit">
                      {sort.field === "bid_response_deadline" &&
                        sort.order === "DESC" && <DownIcon />}
                      {sort.field === "bid_response_deadline" &&
                        sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.bidDeadline")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => sortHandler("contract_start_date")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="flex flex-row items-center min-w-fit">
                      {sort.field === "contract_start_date" &&
                        sort.order === "DESC" && <DownIcon />}
                      {sort.field === "contract_start_date" &&
                        sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.startDate")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => sortHandler("contract_end_date")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="flex flex-row items-center min-w-fit">
                      {sort.field === "contract_end_date" &&
                        sort.order === "DESC" && <DownIcon />}
                      {sort.field === "contract_end_date" &&
                        sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.endDate")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => sortHandler("created_date")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="flex flex-row items-center min-w-fit">
                      {sort.field === "created_date" &&
                        sort.order === "DESC" && <DownIcon />}
                      {sort.field === "created_date" &&
                        sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.createdDate")}</span>
                    </div>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {!loading && !!data.length && (
                <TableBody>
                  {data.map((row: any, i: any) => (
                    <StyledTableRow
                      key={`${i}_${row.sfid}`}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => goToBidDetails(row)}
                    >
                      <StyledTableCell>
                        {row?.bid_location_name}
                      </StyledTableCell>
                      <StyledTableCell>
                        {/* Awarded, Not Awarded, No Response, Accepted, Declined, Counter, Best and Final Offer, Terminated */}
                        <span
                          className={
                            row?.bid_status === "Awarded"
                              ? classes.blueStatus
                              : row?.bid_status === "Accepted"
                              ? classes.greenStatus
                              : row?.bid_status === "Declined" ||
                                row?.bid_status === "Not Awarded" ||
                                row?.bid_status === "Terminated"
                              ? classes.redStatus
                              : row?.bid_status === "No Response" &&
                                !isPastDate(row?.contract_end_date)
                              ? classes.newBidStatus
                              : row?.bid_status === "No Response" &&
                                isPastDate(row?.contract_end_date)
                              ? classes.expiredStatus
                              : classes.yellowStatus
                          }
                        >
                          {i18n.t(`bids.${row?.bid_status === "No Response" &&
                          !isPastDate(row?.contract_end_date)
                            ? "openbid"
                            : row?.bid_status === "No Response" &&
                              isPastDate(row?.contract_end_date)
                            ? "expired"
                            : row?.bid_status?.replace(/\s/g, "")?.toLowerCase()}`)}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="flex flex-row items-center">
                          {row?.service_line?.indexOf("Snow") >= 0 ? (
                            <SnowIcon className="w-7" />
                          ) : (
                            ""
                          )}
                          {row?.service_line?.indexOf("Land") >= 0 ? (
                            <TreeIcon className="w-7" />
                          ) : (
                            ""
                          )}
                          {row?.service_line?.indexOf("Sweep") >= 0 ? (
                            <SweepingIcon className="w-7" />
                          ) : (
                            ""
                          )}
                          {row?.service_line?.indexOf("Jani") >= 0 ? (
                            <JanitorialIcon className="w-7" />
                          ) : (
                            ""
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.site_lat && row?.site_lon
                          ? distance(row?.site_lat, row?.site_lon)
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.bid_response_deadline
                          ? formatDateUTC(row?.bid_response_deadline)
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.contract_start_date
                          ? formatDateUTC(row?.contract_start_date)
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.contract_end_date
                          ? formatDateUTC(row?.contract_end_date)
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.created_date
                          ? formatDate(row?.created_date)
                          : "-"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {loading && <Loader />}
          {!loading && !data.length && (
            <div className="text-center">
              <div className="inline-block">
                <p>{i18n.t("common.empty")}</p>
              </div>
            </div>
          )}

          <Pagination
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            total={total}
          />
        </div>
      </>
    )
  }
}
const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      width: "auto",
      marginLeft: 0,
      padding: "30px",
    },
    location: {
      fontWeight: "bold",
      color: "#1695CB",
    },
    greenStatus: {
      backgroundColor: "#d7f5e3",
      color: "#4e996c",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "10px",
    },
    redStatus: {
      backgroundColor: "#fed5d5",
      color: "#ff3b5f",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "10px",
    },
    yellowStatus: {
      backgroundColor: "#ffd978",
      color: "#665730",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "10px",
    },
    newBidStatus: {
      backgroundColor: "#fff",
      color: "#3d7556",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "10px",
      border: "solid 1px #3d7556",
    },
    expiredStatus: {
      backgroundColor: "gray",
      color: "black",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "10px",
    },
    blueStatus: {
      backgroundColor: "#4ec7f1",
      color: "#22586a",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "10px",
    },
  })
)

export default BidsLists
