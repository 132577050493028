import { useCallback, useEffect, useState } from "react"
import { makeStyles, createStyles, Container, Grid, Typography } from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import SitesLists from "./cards/SitesList"
import Divider from "@mui/material/Divider"
import { useActiveUser, useAuth } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"
import useI18n from "../../../hooks/useI18n"

function DashboardMobile(props: any) {
  const navigate = useNavigate()
  const classes = useStyles()
  const graphqlService = useGraphQLService()
  const { sfId: sfid } = useActiveUser()
  const { isCanada } = useAuth()
  const [data, setData] = useState<any>(null)
  const [activeSites, setActiveSites] = useState(0)
  const [noResponseBids, setNoResponseBids] = useState(0)
  const [pendingBids, setPendingBids] = useState(0)
  const [awardedBids, setAwardedBids] = useState(0)
  const [workOrders, setWorkOrders] = useState(0)
  const { i18n } = useI18n()

  const getPartnerData = useCallback(
    async (signal?: AbortSignal) => {
      const response = await graphqlService.getPartnerBySfid({ sfid }, signal)
      setData(response)
    },
    [graphqlService, sfid]
  )

  const getMarkers = useCallback(
    async (signal?: AbortSignal) => {
      const response = await graphqlService.getDashboardData({ sfid }, signal)
      setAllDashboardData(response)
    },
    [graphqlService, sfid]
  )

  useEffect(() => {
    const controller = new AbortController()

    getPartnerData(controller.signal)
    getMarkers(controller.signal)

    return () => controller.abort()
  }, [getMarkers, getPartnerData])

  const setAllDashboardData = (response: any) => {
    setActiveSites(response?.active_sites?.pagination?.found_rows)
    setNoResponseBids(response?.open_bids?.pagination?.found_rows)
    setPendingBids(response?.pending_bids?.pagination?.found_rows)
    setAwardedBids(response?.awarded_bids?.pagination?.found_rows)
  }

  const setWOs = useCallback((wos: any) => {
    setWorkOrders(wos)
  }, [])

  return (
    <>
      <div className="h-fit bg-gradient-to-b from-case-grad-mid to-case-grad-to-mobile pb-1">
        <div>
          <Container className={classes.containerHeader}>
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.secondaryTitleMobile}>
                  <Typography className={classes.titleFontMobile}>{data?.name}</Typography>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        {!isCanada && (
          <div className="h-1/5 min-w-full bg-slate-800 bg-opacity-40 p-4 shadow-md">
            <a href="https://go.paymode.com/CaseFMS/G-NB6MHN5KV" target="_blank" rel="noreferrer" className="text-xl font-bold text-white underline">
              {i18n.t("common.menuBar.signUpForDirectPayment")}
            </a>
          </div>
        )}
      </div>

      <div className="min-h-screen bg-gray">
        <Container className={classes.container}>
          <Grid container>
            <Grid item xs={12} onClick={() => navigate("/bids")} style={{ cursor: "pointer" }}>
              <div className={classes.gridBids}>
                <span className={classes.cardMessageTitle}>Procurement</span>
                <div className="flex flex-row items-center">
                  <div className="m-2 flex w-1/3 flex-col items-center rounded">
                    <span className={classes.circleOpenBids}>{noResponseBids}</span>
                    <span className={classes.cardMessage}>Open Bids</span>
                  </div>
                  <div className="m-2 flex w-1/3 flex-col items-center rounded">
                    <span className={classes.circleRecomendedBids}>{pendingBids}</span>
                    <span className={classes.cardMessage}>Pending Bids</span>
                  </div>
                  <div className="m-2 flex w-1/3 flex-col items-center rounded">
                    <span className={classes.circleNewBids}>{awardedBids}</span>
                    <span className={classes.cardMessage}>Awarded Bids</span>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.gridBids}>
                    <span className={classes.cardMessageTitle}>Performance</span>
                    <div className="flex flex-row items-center">
                      <Grid container>
                        <Grid item xs={4}>
                          <span className={classes.performanceNumber + " " + (activeSites > 9999 ? classes.smallNumber : "")}>{activeSites}</span>
                        </Grid>
                        <Grid item xs={8}>
                          <span className={classes.cardMessagePerformance}>Active Sites</span>
                        </Grid>
                      </Grid>
                    </div>
                    <Divider variant="middle" className={classes.divider} />
                    <div className="flex flex-row items-center">
                      <Grid container>
                        <Grid item xs={4}>
                          <span className={classes.performanceNumber + " " + (workOrders > 9999 ? classes.smallNumber : "")}>{workOrders}</span>
                        </Grid>
                        <Grid item xs={8}>
                          <span className={classes.cardMessagePerformance}>Completed Work Orders</span>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12}>
                <SitesLists {...props} setWOs={setWOs} />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    secondaryTitleFont: {
      fontSize: "18px",
      lineHeight: "113%",
      color: "#000",
      letterSpacing: "-1px",
      marginBottom: "10px",
      marginTop: "10px",
    },
    secondaryTitleMobile: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "100vw",
    },
    showTitle: {
      width: "auto",
      marginTop: "10px",
      marginLeft: "10px",
    },
    titleFont: {
      fontSize: "44px",
      fontWeight: "bold",
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
    },
    titleFontMobile: {
      fontSize: "44px",
      fontWeight: 600,
      lineHeight: "113%",
      color: "white",
      letterSpacing: "-1px",
      marginLeft: "20px",
      marginBottom: "20px",
      marginTop: "21px",
    },
    cardTitle: {
      fontSize: "30px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
    },
    direction: {
      fontSize: "13px",
      fontWeight: 600,
      lineHeight: "113%",
      letterSpacing: "-1px",
      marginLeft: "3px",
      color: "white",
    },
    cardMessage: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
    },
    cardMessagePerformance: {
      fontSize: "15px",
      fontWeight: 600,
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
      position: "relative",
      top: "30%",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      marginBottom: "5px",
      background: "#FFFFFF",
      padding: "15px",
      marginTop: "10px",
    },
    gridMap: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#FFFFFF",
      padding: "0px",
      marginTop: "10px",
    },
    gridBids: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      background: "#FFFFFF",
      padding: "15px",
      margin: "10px 0px 0px 0px",
    },
    containerHeader: {
      maxWidth: "100%",
      padding: 0,
    },
    container: {
      maxWidth: "100%",
    },
    secondaryTitle: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "10px",
      paddingTop: "20px",
    },
    circleNewBids: {
      backgroundColor: "white",
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "5px",
      fontSize: "20px",
      color: "#2eaf7d",
      fontWeight: "bold",
      border: "1px solid #2eaf7d",
    },
    circleRecomendedBids: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "5px",
      fontSize: "20px",
      color: "#232F64",
      fontWeight: "bold",
      border: "1px solid #232F64",
    },
    circleOpenBids: {
      backgroundColor: "#232F64",
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "5px",
      fontSize: "20px",
      color: "white",
      fontWeight: "bold",
    },
    imageMap: {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "cover",
      width: "100%",
    },
    locationName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    cardMessageTitle: {
      fontSize: "25px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "10px",
    },
    divider: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    performanceNumber: {
      display: "flex",
      backgroundColor: "red",
      backgroundImage: "linear-gradient(0, #4EC7F1, #0F2150)",
      backgroundSize: "100%",
      backgroundRepeat: "repeat",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      fontSize: "35px",
      "-moz-background-clip": "text",
      "-moz-text-fill-color": "transparent",
      fontWeight: "bold",
      marginBottom: "5px",
      marginLeft: "10px",
    },
    smallNumber: {
      fontSize: "30px!important",
    },
  })
)

export default DashboardMobile
