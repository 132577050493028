import { createContext, PropsWithChildren, useState } from "react"

import { ValidationState } from "../components/Validations/interfaces"

type ValidationContextProps = {
  validationState: ValidationState
  setValidation: React.Dispatch<React.SetStateAction<ValidationState>>
}

export const ValidationContext = createContext({} as ValidationContextProps)

export const ValidationProvider = ({ children }: PropsWithChildren) => {
  const [validationState, setValidationState] = useState<ValidationState>({} as ValidationState)

  return <ValidationContext.Provider value={{ validationState, setValidation: setValidationState }}>{children}</ValidationContext.Provider>
}
