import { useEffect, useState } from "react"
import { makeStyles, createStyles } from "@material-ui/core"

export const ServicesTab = (props: any) => {
  const classes = useStyles()
  const [error, setError] = useState("")
  const [partner, setPartner] = useState<any>({})

  useEffect(() => {
    setPartner(props?.partner)
  }, [props])

  if (error) {
    return <p>ERROR: {error}</p>
  } else {
    return (
      <>
        <div className={classes.listContainer}>
          <div className={classes.grid}></div>
        </div>
      </>
    )
  }
}
const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      width: "auto",
      marginLeft: 0,
      padding: "10px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      background: "#FFFFFF",
      cursor: "pointer",
    },
  })
)

export default ServicesTab
