import ApproveCancelBoxIconComponent from "./ApproveCancelBoxIconComponent"
import ReminderBangIconComponent from "./ReminderBangIconComponent"

export enum LandStatusColor {
  Validation = "Validation",
  Missing = "Missing",
  Approved = "Approved",
  ApprovedRed = "ApprovedRed",
  Reminder = "Reminder",
  ReminderBang = "ReminderBang",
  ApprovedCheck = "ApprovedCheck",
  ApprovedCancelled = "ApprovedCancelled",
  BlackStar = "BlackStar",
  GreenStar = "GreenStar",
  RedStar = "RedStar",
  RedOutlinedStar = "RedOutlinedStar",
}

type Props = {
  statusColor: LandStatusColor
  isSelected?: boolean
}

const LandChartStatusIconComponent = ({ statusColor, isSelected = false }: Props) => {
  const color =
    statusColor.toLowerCase() === "validation"
      ? "#FFE033"
      : statusColor.toLowerCase() === "missing"
      ? "#FF3333"
      : statusColor.toLowerCase() === "approved"
      ? "#3ED229"
      : statusColor.toLowerCase() === "approvedred"
      ? "#FF3333"
      : statusColor.toLowerCase() === "reminder"
      ? "#09B5F6"
      : statusColor.toLowerCase() === "reminderbang"
      ? "#09B5F6"
      : statusColor.toLowerCase() === "approvedcheck"
      ? "#3ED229"
      : statusColor.toLowerCase() === "approvedcancelled"
      ? "#FF3333"
      : statusColor.toLowerCase() === "blackstar"
      ? !isSelected
        ? "#000000"
        : "#FFFFFF"
      : statusColor.toLowerCase() === "greenstar"
      ? "#3ED229"
      : statusColor.toLowerCase() === "redstar"
      ? "#FF3333"
      : statusColor.toLowerCase() === "redoutlinedstar"
      ? "#FF3333"
      : ""

  return (
    <div className="flex h-6 w-6 items-center justify-center" style={{ color }}>
      {statusColor === LandStatusColor.Validation ? (
        <span className="text-4xl">&#9650;</span>
      ) : statusColor === LandStatusColor.Approved ? (
        <span className="text-3xl">&#9632;</span>
      ) : statusColor === LandStatusColor.ApprovedCheck ? (
        <ApproveCancelBoxIconComponent type="Approved" />
      ) : statusColor === LandStatusColor.ApprovedCancelled ? (
        <ApproveCancelBoxIconComponent type="Cancelled" />
      ) : statusColor === LandStatusColor.Reminder ? (
        <span className="text-3xl">&#9670;</span>
      ) : statusColor === LandStatusColor.ReminderBang ? (
        <ReminderBangIconComponent />
      ) : statusColor === LandStatusColor.BlackStar ? (
        <span className="text-3xl">&#9733;</span>
      ) : statusColor === LandStatusColor.GreenStar ? (
        <span className="text-3xl">&#9733;</span>
      ) : statusColor === LandStatusColor.RedStar ? (
        <span className="text-3xl">&#9733;</span>
      ) : statusColor === LandStatusColor.RedOutlinedStar ? (
        <span className="text-3xl">&#9734;</span>
      ) : statusColor === LandStatusColor.Missing ? (
        <span className="text-3xl">&#9679;</span>
      ) : statusColor === LandStatusColor.ApprovedRed ? (
        <span className="text-3xl">&#9632;</span>
      ) : (
        <></>
      )}
    </div>
  )
}

export default LandChartStatusIconComponent
