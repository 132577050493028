import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Loader } from "../../../common/Loader"
import {
  makeStyles,
  createStyles,
  Container,
  Grid,
  Typography,
  Button,
} from "@material-ui/core"
import { ReactComponent as DownIcon } from "./../../../../assets/down.svg"
import { ReactComponent as ErrorIcon } from "./../../../../assets/circle-red.svg"
import { formatDate, getMinFormat } from "../../../../utils/Util"
import Divider from "@mui/material/Divider"
import { useActiveUser } from "../../../../providers/AuthProvider"
import { useGraphQLService } from "../../../../services/graphqlService"

export const WorkOrdersLists = (props: any) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const graphqlService = useGraphQLService()

  const [data, setData] = useState<any[]>([])
  const [sort, setSort] = useState({ field: "service_date", order: "DESC" })
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [error, setError] = useState("")
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const { sfId: sfid } = useActiveUser()

  useEffect(() => {
    const controller = new AbortController()

    if (page === 1) {
      setLoading(true)
    } else {
      setLoadingMore(true)
    }
    graphqlService
      .getWorkOrders(
        {
          providerId: sfid,
          limit: perPage,
          offset: (page - 1) * perPage,
          sort: sort.field,
          order: sort.order,
          siteId: props.siteId,
          search: props?.filter?.search,
        },
        controller.signal
      )
      .then((response) => {
        setData((d) => [...d, ...response.worklogs])
        setLoading(false)
        setLoadingMore(false)
      })
      .catch(() => {
        setLoading(false)
        setLoadingMore(false)
      })

    return () => controller.abort()
  }, [graphqlService, page, perPage, props?.filter, props.siteId, sfid, sort])

  const openDetails = (order: any) => {
    navigate(`/site-details?id=${props.siteId}&workorderId=${order.sfid}`)
  }

  if (error) {
    return <p>ERROR: {error}</p>
  } else {
    return (
      <>
        {loading && (
          <div className={classes.listContainer}>
            <Loader />
          </div>
        )}

        {!loading && !data.length && (
          <div className={classes.container}>
            <div className="flex flex-col items-center">EMPTY</div>
          </div>
        )}

        {!loading && !!data.length && (
          <div>
            <Container className={classes.container}>
              {data.map((row: any, index: any) => (
                <Grid key={index} container onClick={() => openDetails(row)}>
                  <Grid item xs={12}>
                    <div className={classes.grid}>
                      <div className={classes.secondaryTitle}>
                        <Typography noWrap className={classes.cardTitle}>
                          <div className="flex flex-row items-center">
                            <span className="mr-3">{row.name}</span>
                            <span>
                              {row.cancelled ? (
                                <ErrorIcon className="w-2" />
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </Typography>
                      </div>
                      <div className={classes.divider}>
                        <Divider variant="middle" />
                      </div>
                      <div className={classes.data}>
                        <div className="bold text-case-d-gray">
                          {row.service_summary}
                        </div>
                        <div className="bold text-case-d-gray">
                          Date Reported:{" "}
                          <span className={classes.cardDetails}>
                            {row.service_date
                              ? formatDate(row.service_date)
                              : "-"}
                          </span>
                        </div>
                        <div className="bold text-case-d-gray">
                          Time Taken On Time:{" "}
                          <span className={classes.cardDetails}>
                            {row?.time_taken_minutes
                              ? getMinFormat(row?.time_taken_minutes)
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              ))}
              {loadingMore ? (
                <Loader />
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <div className="w-full flex flex-col align-items">
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => setPage(page + 1)}
                      >
                        <DownIcon />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}
            </Container>
          </div>
        )}
      </>
    )
  }
}
const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      width: "auto",
      marginLeft: 0,
    },
    location: {
      fontWeight: "bold",
      color: "#1695CB",
    },
    cardTitle: {
      fontWeight: 800,
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "5px",
      fontSize: "28px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#FFFFFF",
      padding: "8px",
    },
    container: {
      maxWidth: "100%",
    },
    secondaryTitle: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "5px",
    },
    data: {
      paddingLeft: "10px",
      paddingBottom: "20px",
    },
    cardDetails: {
      fontWeight: "bold",
      color: "#0F2150",
    },
    greenStatus: {
      backgroundColor: "#d7f5e3",
      color: "#4e996c",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "15px",
      margin: "5px",
    },
    redStatus: {
      backgroundColor: "#fed5d5",
      color: "#ff3b5f",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "15px",
      margin: "5px",
    },
    yellowStatus: {
      backgroundColor: "#ffd978",
      color: "#665730",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "15px",
      margin: "5px",
    },
    divider: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  })
)

export default WorkOrdersLists
