import { useImageService } from "../services/imageService"

export const useFetchImages = () => {
  const imageService = useImageService()
  return imageHelper(imageService)
}

export const imageHelper = (imageService: any) => {
  return {
    getImages: async (sfids: string[], siteId?: string, signal?: AbortSignal) => {
      const imageUrls: string[] = []
      for (const sfid of sfids) {
        let img = null
        if (siteId) {
          img = await imageService.getLocationImg({ sfid, siteId }, signal)
        } else {
          img = await imageService.getAttachmentImg({ sfid }, signal)
        }
        if (img) imageUrls.push(img)
      }
      return imageUrls
    },
  }
}
