type Props = {
  onClickNarrative: () => void
  siteName?: string
  userFullName?: string
  isCompleted?: boolean
  stormId?: string
  stormType?: string
  stormStart?: string
  stormEnd?: string
  totalAcc?: string
  iceAcc?: string
  narrative?: string
  validationSettings?: string
}

const StormDetailsComponent = ({
  onClickNarrative,
  siteName,
  userFullName,
  isCompleted,
  stormId,
  stormType,
  stormStart,
  stormEnd,
  totalAcc,
  iceAcc,
  narrative,
  validationSettings,
}: Props) => {
  return (
    <div className="siteDetailsContainer">
      <div className="siteInfo">
        <label className="siteName">{siteName}</label>
        <div className="amInfoContainer">
          <label className="amTitle">
            <b>Account Manager</b>
            <br />
            {userFullName}
          </label>
          {isCompleted ? (
            <div className="completedValidation">
              <label>Completed</label>
            </div>
          ) : null}
        </div>
      </div>

      <div className="siteDetails">
        <div className="columnDetail">
          <div className="detailItem">
            <label>Storm ID</label>
            <label>{stormId}</label>
          </div>
          <div className="detailItem">
            <label>Storm Type</label>
            <label>{stormType}</label>
          </div>
          <div className="detailItem">
            <label>Storm Start</label>
            <label>{stormStart}</label>
          </div>
          <div className="detailItem">
            <label>Storm End</label>
            <label>{stormEnd}</label>
          </div>
        </div>
        <div className="columnDetail">
          <div className="detailItem">
            <label>Total Accumulation</label>
            <label>{totalAcc}"</label>
          </div>
          <div className="detailItem">
            <label>Ice Accretion</label>
            <label>{iceAcc}"</label>
          </div>

          <div
            className="detailItem"
            onClick={() => (narrative ? onClickNarrative() : null)}
            style={{ width: "400px", overflow: "hidden", maxLines: 2 }}
          >
            <label>Narrative</label>
            <label
              className="narrative"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxLines: 2,
              }}
            >
              {narrative}
            </label>
          </div>
          {validationSettings && (
            <div className="detailItem">
              <label>Rule Evaluated</label>
              <label>{validationSettings}</label>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default StormDetailsComponent
