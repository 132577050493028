import { useCallback, useEffect, useState } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import "./App.css"
import BidsDesktop from "./components/Bids/desktop/BidsDesktop"
import BidsMobile from "./components/Bids/mobile/BidsMobile"
import DashboardDesktop from "./components/dashboard/desktop/DeshboardDesktop"
import Navbar from "./components/layout/Navbar"
import Sidebar from "./components/layout/Sidebar"
import Login from "./components/login/Login"
import SitesDesktop from "./components/Sites/desktop/SitesDesktop"
import SitesMobile from "./components/Sites/mobile/SitesMobile"
import WorkOrdersDesktop from "./components/WorkOrders/desktop/WorkOrdersDesktop"
import WorkOrdersMobile from "./components/WorkOrders/mobile/WorkOrdersMobile"
import Admin from "./components/Admin/Admin"
import DashboardMobile from "./components/dashboard/mobile/DashboardMobile"
import SiteDetailsDesktop from "./components/Sites/desktop/SiteDetails"
import SitesDetailsMobile from "./components/Sites/mobile/SiteDetails"
import BidDetailsDesktop from "./components/Bids/desktop/BidDetails"
import BidDetailsMobile from "./components/Bids/mobile/BidDetails"
import WorkOrderDetails from "./components/WorkOrders/mobile/WorkOrderDetails"
import ProfileDesktop from "./components/Profile/desktop/ProfileDesktop"
import ProfileMobile from "./components/Profile/mobile/ProfileMobile"
import { useLoadScript } from "@react-google-maps/api"
import ContactUsDesktop from "./components/ContactUs/desktop/ContactUsDesktop"
import ContactUsMobile from "./components/ContactUs/mobile/ContactUsMobile"
import { useAuth, useUser } from "./providers/AuthProvider"
import TeamDesktop from "./components/Team/desktop/TeamDesktop"
import TeamMobile from "./components/Team/mobile/TeamMobile"
import ValidationsPage from "./components/Validations/Validations"
import ValidationResults from "./components/Validations/pages/ValidationResultsPage/ValidationResults"
import SearchValidationPage from "./components/Validations/pages/SearchValidationPage/SearchValidationPage"
import SelectedValidationPage from "./components/Validations/pages/SelectedValidationPage/SelectedValidationPage"
import CompliancePage from "./components/Compliance/Compliance"
import { I18nCustomProvider } from "./providers/I18nProvider"

const googleApiKey = process.env?.REACT_APP_GOOGLE_API_KEY!

function App() {
  const { isAuthenticated, isMasquerading, activeUser, stopMasquerade } = useAuth()
  const [mobile, setMobile] = useState(false)
  const userInfo = useUser()

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleApiKey,
  })

  useEffect(() => {
    if (window.innerWidth < 1065) {
      setMobile(true)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1065) {
        setMobile(true)
      } else {
        setMobile(false)
      }
    }

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const privateRoute = useCallback(
    (path: string, element: React.ReactNode, children?: React.ReactNode) => {
      const el = !userInfo?.permissions?.some((m) => m === path) ? <Navigate to="/" replace /> : element
      return <Route path={`/${path}`} element={el} children={children} />
    },
    [userInfo]
  )

  return (
    <I18nCustomProvider>
      <BrowserRouter>
        {!isAuthenticated || !isLoaded ? (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        ) : (
          <>
            {mobile ? (
              <Navbar>
                <Routes>
                  <Route index element={<DashboardMobile />} />
                  {privateRoute("bids", <BidsMobile />)}
                  <Route path="/bid-details" element={<BidDetailsMobile />} />
                  {privateRoute("sites", <SitesMobile />)}
                  <Route path="/site-details" element={<SitesDetailsMobile />} />
                  {privateRoute("workorders", <WorkOrdersMobile />)}
                  <Route path="/contact-us" element={<ContactUsMobile />} />
                  <Route path="/profile" element={<ProfileMobile />} />
                  <Route path="/workorder-details" element={<WorkOrderDetails />} />
                  <Route path="/admin" element={<Admin />} />
                  {privateRoute(
                    "validation",
                    <ValidationsPage />,
                    <>
                      <Route path="" element={<SearchValidationPage />} />
                      <Route path="results" element={<ValidationResults />} />
                      <Route path="results/:v" element={<SelectedValidationPage />} />
                    </>
                  )}
                  {privateRoute("compliance", <CompliancePage />)}
                  {privateRoute("team", <TeamMobile />)}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Navbar>
            ) : (
              <Sidebar>
                {isMasquerading && (
                  <div className="mb-4 flex w-full flex-row items-center justify-between border-b border-b-slate-300 bg-red-200 px-7 py-2 shadow-md">
                    <span className="">
                      Masquerading as <strong>{activeUser?.partnerName}</strong> ({(activeUser as any)?.email || (activeUser as any)?.phoneNumber})
                    </span>

                    <div
                      className="cursor-pointer rounded-md bg-red-300 px-4 py-2 shadow-lg transition duration-300 ease-in-out hover:bg-white hover:font-bold"
                      onClick={stopMasquerade}
                    >
                      END
                    </div>
                  </div>
                )}
                <Routes>
                  <Route index element={<DashboardDesktop />} />
                  {privateRoute("bids", <BidsDesktop />)}
                  <Route path="/bid-details" element={<BidDetailsDesktop />} />
                  {privateRoute("sites", <SitesDesktop />)}
                  <Route path="/site-details" element={<SiteDetailsDesktop />} />
                  {privateRoute("workorders", <WorkOrdersDesktop />)}
                  <Route path="/profile" element={<ProfileDesktop />} />
                  <Route path="/contact-us" element={<ContactUsDesktop />} />
                  <Route path="/admin" element={<Admin />} />
                  {privateRoute(
                    "validation",
                    <ValidationsPage />,
                    <>
                      <Route path="" element={<SearchValidationPage />} />
                      <Route path="results" element={<ValidationResults />} />
                      <Route path="results/:v" element={<SelectedValidationPage />} />
                    </>
                  )}
                  {privateRoute("compliance", <CompliancePage />)}
                  {privateRoute("team", <TeamDesktop />)}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Sidebar>
            )}
          </>
        )}
      </BrowserRouter>
    </I18nCustomProvider>
  )
}

export default App
