import { Container, Grid, Typography } from "@material-ui/core"
import { MasqueradeCard } from "./MasqueradeCard"
import { useAdminStyles } from "./styles"
import { ReactComponent as RightIcon } from "./../../assets/right.svg"
import { useNavigate } from "react-router-dom"

export type InviteCardProps = {
  user?: UserProps
}
export type UserProps = {
  isAdmin: boolean
  sfId: string
  organizationId: string
  partnerName: string
  userId: string
}

const Admin = () => {
  const classes = useAdminStyles()
  const navigate = useNavigate()
  return (
    <>
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <div className={classes.showTitle}>
                  <div className="mb-4">
                    <Typography noWrap className="flex flex-row items-center">
                      <span
                        className="cursor-pointer  text-breadcrumb-blue font-bold"
                        onClick={() => navigate("/")}
                      >
                        Home
                      </span>
                      <RightIcon className="w-3.5 mr-3 ml-3" />
                      <span className="font-bold">Admin</span>
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.grid}>
              <Grid container className="flex-row items-center">
                <Grid item xs={12}>
                  <div className={classes.secondaryTitle}>
                    <Typography noWrap className={classes.cardTitle}>
                      Admin
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <MasqueradeCard />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Admin
